import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { DownloadTokenDto } from '@app/_models/downloadTokenDto';
import { BaseApiService } from './baseApi.service';

@Injectable({
  providedIn: 'root',
})
export class FileService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super('file');
  }

  getDownloadTokenForFile(fileName: string): Observable<DownloadTokenDto> {
    return this.http.get<DownloadTokenDto>(this.baseControllerUrl + `/token/${fileName}`);
  }

  uploadFiles(files: File[], saveSecure: boolean, overwriteFiles: boolean): Observable<CommandResult> {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    var params = new HttpParams().set('saveSecure', saveSecure).set('overwriteFiles', overwriteFiles);

    return this.http.post<CommandResult>(this.baseControllerUrl, formData, { params });
  }

  getSecuredFiles(extension: string): Observable<string[]> {
    var params = new HttpParams().set('extension', extension);

    return this.http.get<string[]>(this.baseControllerUrl + '/secured', { params });
  }
}
