import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceServiceProviderUsageTransform',
})
export class InvoiceServiceProviderUsageTransformPipe implements PipeTransform {
  public static usedAsMap = new Map([
    [0, 'Global.MainServiceProvider'],
    [1, 'Global.Assistant'],
    [2, 'Global.GuestAssistant'],
  ]);

  transform(usedAs: number): string {
    if (InvoiceServiceProviderUsageTransformPipe.usedAsMap.has(usedAs)) {
      var usedAsText = InvoiceServiceProviderUsageTransformPipe.usedAsMap.get(usedAs);
      return usedAsText ? usedAsText : '';
    } else {
      return '';
    }
  }
}
