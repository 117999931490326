import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';

export function openHirslandenPhysicianSearchLink(element: UserContractForInsuranceDto): void {
  if (element.user) {
    window.open(
      `https://www.hirslanden.ch/de/corporate/aerzte-und-pflege/aerztesuche.html?q=${element.user.lastName}%20${element.user.firstName}`,
      '_blank',
    );
  }
}
