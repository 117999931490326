import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { RoleDto } from '@app/_models/roleDto';
import { UserAdminDto } from '@app/_models/userAdminDto';
import { UserProfileDto } from '@app/_models/userProfileDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseTableDataService<UserAdminDto, string> {
  constructor(protected http: HttpClient) {
    super('user', '', http);
  }

  getUserProfile(): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(this.baseControllerUrl + '/profile');
  }

  updateUserProfile(userProfileDto: UserProfileDto): Observable<CommandResult> {
    return this.http.put<CommandResult>(this.baseControllerUrl + '/profile', userProfileDto);
  }

  getRoles(): Observable<RoleDto[]> {
    return this.http.get<RoleDto[]>(this.baseControllerUrl + '/roles');
  }

  getUserRoles(): Observable<string[]> {
    return this.http.get<string[]>(this.baseControllerUrl + '/userroles');
  }
}
