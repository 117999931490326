<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'Admin.EventLogs.EmailsDialogTitle' | translate: { id: eventLogId } }}</h4>
</div>
<div class="modal-body">
  <app-emails [baseFilter]="baseFilter" tableDivStyle="overflow: auto; height: calc(60vh - 132px);"></app-emails>
</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'Global.Close' | translate }}</button>
  </ng-container>
</div>
