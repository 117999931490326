import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

@Pipe({
  name: 'round',
})
export class RoundTransformPipe implements PipeTransform {
  transform(value: number, precicion: number): number {
    return round(value, precicion);
  }
}
