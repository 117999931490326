import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { InvoiceMultiplierDto } from '@app/_models/invoiceMultiplierDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceMultiplierService extends BaseTableDataService<InvoiceMultiplierDto, number> {
  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('invoiceMultiplier', '', http);
  }

  getInvoiceMultipliers(): Observable<InvoiceMultiplierDto[]> {
    return this.http.get<InvoiceMultiplierDto[]>(this.baseControllerUrl + '/all');
  }
}
