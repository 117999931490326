import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { dateCompare } from '@app/_helpers/validators/date-compare.validator';
import { EmployeeDto } from '@app/_models/employeeDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';
import { EditEmployeeContainerConfig } from './editEmployeeContainerConfig';

@Component({
  selector: 'app-edit-employee-container',
  templateUrl: './edit-employee-container.component.html',
  styleUrls: ['./edit-employee-container.component.scss'],
})
export class EditEmployeeContainerComponent
  implements IRowEditContainer<EmployeeDto, string, any, EditEmployeeContainerConfig>
{
  dialog!: RowEditDialogComponent<EmployeeDto, string, any, EditEmployeeContainerConfig>;
  data!: EmployeeDto;
  initialData?: EmployeeDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: EditEmployeeContainerConfig;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: () => Promise<boolean> = this.preConfirm;

  editEmployee: boolean = false;
  institutions!: InstitutionDto[];

  constructor(
    private institutionService: InstitutionService,
    private errorHandler: ErrorHandlerService,
    private employeeService: EmployeeService,
    private translate: TranslateService,
  ) {}

  initializeContainer(): void {
    this.dialog.loadingSubject.next(true);

    // If there is no input data we are adding a row
    if (!this.data) {
      this.data = <EmployeeDto>{
        employedFrom: new Date(2023, 0, 1, 0, 0, 0, 0), // Set date when the application was going into production.
        groups: this.config?.groups,
      };
    } else {
      this.editEmployee = true;
    }

    this.institutionService.getUserChildInstitutions().subscribe(
      (result) => {
        this.institutions = [];

        if (result.length > 0) {
          this.institutions.push(result[0]);
        }

        this.dialog.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.dialog.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );

    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.data.title, []),
      firstName: new UntypedFormControl(this.data.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.data.lastName, [Validators.required]),
      function: new UntypedFormControl(this.data.function, [Validators.required]),
      zsrNumber: new UntypedFormControl(this.data.zsrNumber, {
        validators: [Validators.pattern('(^$)|(^[a-zA-Z]\\d{6}$)|(^\\d{6}[a-zA-Z]$)')],
        updateOn: 'blur',
      }),
      glnNumber: new UntypedFormControl(this.data.glnNumber, {
        validators: [Validators.required, Validators.pattern('(^$)|(^\\d{13}$)')],
        updateOn: 'blur',
      }),
      employedFrom: new UntypedFormControl(this.data.employedFrom, [
        Validators.required,
        dateCompare('employedUntil', false),
      ]),
      employedUntil: new UntypedFormControl(this.data.employedUntil, [dateCompare('employedFrom', true)]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });

    if (this.editEmployee) {
      // Trigger form validation now
      this.form.markAllAsTouched();
    }
  }

  get f() {
    return this.form.controls;
  }

  preConfirm(): Promise<boolean> {
    return new Promise<any>((resolve) => {
      if (this.data.id) {
        // We edit an existing employee. Run the preUpdateCheck to see if there are any errors or warnings.
        this.employeeService.preUpdateCheck(this.data).subscribe(
          (result) => {
            if (result.warnings.length > 0) {
              var saveAnywaysText = this.translate.instant('Employee.Errors.SaveAnyways');

              this.errorHandler.displayWarningDialog(result, saveAnywaysText).then((modalRef) => {
                modalRef.content?.onClose.subscribe((onCloseResult) => {
                  resolve(onCloseResult);
                });
              });
            } else {
              resolve(true);
            }
          },
          (errorResponse: HttpErrorResponse) => {
            resolve(false);
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      } else {
        resolve(true);
      }
    });
  }
}
