<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="loading$ | async" class="loading-div">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
  <div>
    <div *ngIf="text">{{ text }}</div>
    <br *ngIf="text" />
    <iframe width="100%" height="500px" frameBorder="0" [srcdoc]="htmlSrc"></iframe>
  </div>
</div>
<div class="modal-footer">
  <ng-container>
    <div class="mr-auto" *ngIf="checkboxText">
      <form class="form-horizontal" [formGroup]="form" autocomplete="off">
        <input type="checkbox" id="footerCheckbox" formControlName="checkbox" />
        <label for="footerCheckbox">{{ checkboxText | translate }}</label>
      </form>
    </div>
    <button *ngIf="button_cancel_text" type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ button_cancel_text }}
    </button>
    <button *ngIf="button_confirm_text" type="button" class="btn btn-secondary" (click)="onConfirm()">
      {{ button_confirm_text }}
    </button>
  </ng-container>
</div>
