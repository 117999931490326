<div class="modal-header">
  <h4 class="modal-title pull-left">{{ dialogConfig.title }}</h4>
</div>
<div class="modal-body scroll-modal-body">
  <div *ngIf="loading$ | async" class="loading-div">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
  <div *ngIf="dialogConfig.text">
    <div [innerHTML]="dialogConfig.text | translate"></div>
    <br />
  </div>
  <ng-container #viewContainerRef></ng-container>
</div>
<div class="modal-footer">
  <ng-container>
    <button *ngIf="dialogConfig.button_cancel_text" type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ dialogConfig.button_cancel_text }}
    </button>
    <button
      *ngIf="dialogConfig.button_confirm_text"
      type="button"
      class="btn btn-secondary"
      (click)="onConfirm()"
      [disabled]="container?.form?.invalid">
      {{ dialogConfig.button_confirm_text }}
    </button>
  </ng-container>
</div>
