import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogViewService extends BaseTableDataService<CatalogViewDto, number> {
  constructor(protected http: HttpClient) {
    super('catalog', 'views', http);
  }

  getCatalogViews(): Observable<CatalogViewDto[]> {
    return this.http.get<CatalogViewDto[]>(this.baseControllerUrl + '/views/all');
  }

  getUserCatalogViews(): Observable<CatalogViewDto[]> {
    return this.http.get<CatalogViewDto[]>(this.baseControllerUrl + '/views/user');
  }
}
