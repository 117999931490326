import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';

import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';

import { IDataTableDataSource } from '@app/_datasources/dataTableDataSource';
import { ITableIdBase } from '@app/_models/tableIdBase';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { RowEditDialogConfig } from './row-edit-dialog.config';
import { RowEditDialogResult } from './row-edit-dialog.result';

@Component({
  selector: 'app-row-edit-dialog',
  templateUrl: './row-edit-dialog.component.html',
  styleUrls: ['./row-edit-dialog.component.css'],
})
export class RowEditDialogComponent<DataType extends ITableIdBase, IdType, ResultDataType, ConfigType>
  implements OnInit
{
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public dialogConfig: RowEditDialogConfig = {
    title: '',
    text: undefined,
    button_confirm_text: undefined,
    button_cancel_text: undefined,
  };
  public id: any;
  public initialData?: DataType;
  public additionalParams?: HttpParams;
  public containerType!: Type<IRowEditContainer<DataType, IdType, ResultDataType, ConfigType>>;
  public dataSource!: IDataTableDataSource<DataType, IdType>;
  public onClose!: Subject<RowEditDialogResult<DataType, ResultDataType>>;
  public container?: IRowEditContainer<DataType, IdType, ResultDataType, ConfigType>;
  public config?: ConfigType;
  public preActionData: any;

  @ViewChild('viewContainerRef', { read: ViewContainerRef }) set viewContainerRef(viewContainerRef: ViewContainerRef) {
    this.loadDataAndInitContainer(viewContainerRef);
  }

  constructor(
    private cfr: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public modalRef: BsModalRef,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  loadDataAndInitContainer(viewContainerRef: ViewContainerRef) {
    // If there is no id we are adding a row
    if (this.id) {
      this.dataSource.get(this.id, this.additionalParams).subscribe(
        (result) => {
          this.initContainer(viewContainerRef, result);
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
          // We have to close the dialog with a small delay. Maybe there is a better way to do this.
          setTimeout(() => {
            this.modalRef.hide();
          }, 500);
        },
      );
    } else {
      this.initContainer(viewContainerRef);
    }
  }

  private initContainer(viewContainerRef: ViewContainerRef, data?: DataType): void {
    let componentFactory = this.cfr.resolveComponentFactory(this.containerType);
    let childComponentRef = viewContainerRef.createComponent(componentFactory);

    this.container = childComponentRef.instance;

    if (data) {
      this.container.data = data;
    }

    this.container.dialog = this;
    this.container.initialData = this.initialData;
    this.container.config = this.config;
    this.container.preActionData = this.preActionData;

    this.loadingSubject.next(false);

    this.container.initializeContainer();
  }

  public onConfirm(): void {
    if (this.container?.preConfirmAction) {
      this.container.preConfirmAction().then((preActionResult) => {
        if (preActionResult) {
          this.confirm();
        }
      });
    } else {
      this.confirm();
    }
  }

  private confirm() {
    this.onClose.next({
      modalRef: this.modalRef,
      confim: true,
      data: this.container?.data,
      resultData: this.container?.resultData,
    });
  }

  public onCancel(): void {
    this.onClose.next({ modalRef: this.modalRef, confim: false, data: this.container?.data });
    this.modalRef.hide();
  }
}
