import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';

import { CustomRowActionParams } from '@app/_controls/data-table/actions/customRowActionParams';
import { CatalogItemRowDetailsComponent } from '@app/_controls/row-detail-views/catalog-item-row-details/catalog-item-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { CatalogItemPreEditDialogComponent } from '@app/_dialogs/catalogitem-preedit-dialog/catalogitem-preedit-dialog.component';
import { CatalogViewHistoryDialogComponent } from '@app/_dialogs/catalogview-history/catalogview-history.component';
import { CustomFormDialogFieldConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog-field.config';
import { CustomFormDialogComponent } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.component';
import { CustomFormDialogConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.config';
import { CatalogItemColumn } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogitem-container/catalogItemColumn';
import { EditCatalogItemContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogitem-container/edit-catalogitem-container.component';
import { EditCatalogItemConfig } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogitem-container/editCatalogItemConfig';
import { EditCatalogViewContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogview-container/edit-catalogview-container.component';
import { RowEditDialogComponent } from '@app/_dialogs/row-edit-dialog/row-edit-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { DownloadFileDirective } from '@app/_helpers/directives/download-file.directive';
import { formatDateOnly, formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { replaceNewLineWithBr } from '@app/_helpers/functions/string-functions';
import { CatalogItemTypeTransformPipe } from '@app/_helpers/transform/catalog-item-type.transform';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { ObjectPermissionsQueryDto } from '@app/_models/objectPermissionsQueryDto';
import { AccountService } from '@app/_services/account.service';
import { CatalogService } from '@app/_services/catalog.service';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-catalog-management',
  templateUrl: './catalog-management.component.html',
  styleUrls: ['./catalog-management.component.css'],
})
export class CatalogManagementComponent implements OnInit, AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  private catalogViewId!: number;
  private queryParamFilter?: string;
  public catalogItemFilterDate!: Date;
  public userCatalogView?: CatalogViewDto;
  public canEditCatalogView: boolean = false;
  public canExportCatalogView: boolean = false;
  public canCreateCatalogItems: boolean = false;
  public canUpdateCatalogItems: boolean = false;
  public canDeleteCatalogItems: boolean = false;
  public catalogCsvExportEnabled = appConfig.catalogCsvExportEnabled;
  private tableColumns!: Array<TableColumn>;
  private displayedColumns!: string[];
  private editDialogConfig!: EditCatalogItemConfig;
  private rowDetailColumns!: CatalogItemColumn[];

  public invoiceColumns: Array<CatalogItemColumn> = [
    {
      property: 'type',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 3,
      dataType: 'enum',
      valueMap: CatalogItemTypeTransformPipe.catalogItemTypeMap,
      displayTranslate: true,
    },
    {
      property: 'title',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 240,
      dataType: 'string',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'interpretation',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 5000,
      dataType: 'string',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'value',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 2147483647,
      dataType: 'number',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'feeKindMultiplier',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 1,
      dataType: 'number',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'surchargeMultiplier',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 1,
      dataType: 'number',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'dontShowInInvoice',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 240,
      dataType: 'boolean',
      valueMap: undefined,
      displayTranslate: false,
    },
    {
      property: 'maxSurchargeCount',
      header: '',
      headerTranslate: false,
      min: 0,
      max: 100,
      dataType: 'number',
      valueMap: undefined,
      displayTranslate: false,
    },
  ];
  public tableSettings?: TableSettings<CatalogItemDto, number>;

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<CatalogItemDto, number>;

  constructor(
    private route: ActivatedRoute,
    public catalogService: CatalogService,
    public accountService: AccountService,
    private catalogViewService: CatalogViewService,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.catalogItemFilterDate = new Date();

    combineLatest([this.route.params, this.route.queryParams]).subscribe(([params, queryParams]) => {
      this.loadingSubject.next(true);

      this.catalogViewId = Number(params.catalogViewId);
      this.queryParamFilter = queryParams.filter;

      // Reset custom sorting if catalog is changed. Default sorting is in Column02. (Done on server side if no custom sort is set)
      if (this.dataTableComponent) {
        this.dataTableComponent.sortActive = '';
        this.dataTableComponent.sortDirection = '';
      }

      this.loadCatalog();
    });
  }

  ngAfterViewInit(): void {
    this.loadCatalog();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  loadCatalog(): void {
    // When component is first loaded dataTableComponent is null, so we load in ngAfterViewInit().
    if (this.dataTableComponent) {
      this.catalogViewService.getUserCatalogViews().subscribe(
        (result) => {
          this.userCatalogView = result.find((x) => x.id == this.catalogViewId);

          // If the catalog view id is not in this array, the user does not have the object right for this view.
          if (this.userCatalogView) {
            // Set default sort if defined in CatalogView and no custom sort is set.
            if (this.userCatalogView.defaultSort && this.dataTableComponent.sortActive == '') {
              this.dataTableComponent.sortActive = this.userCatalogView.defaultSort;
              this.dataTableComponent.sortDirection = 'asc';
            }

            // Build table column definition and edit form config from the values in this catalog view.
            this.buildConfig(this.userCatalogView);
            this.queryObjectPermissions(this.userCatalogView?.catalogId);
          } else {
            this.showUserNotAuthorizedForCatalogViewDialog();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  queryObjectPermissions(catalogId: number): void {
    // Query user's permissions for this catalog and catalog view
    var objectPermissionsQuery: ObjectPermissionsQueryDto[] = [
      {
        object: PermissionObject.CatalogItem,
        objectIds: [catalogId],
      },
      {
        object: PermissionObject.CatalogView,
        objectIds: [this.catalogViewId],
      },
    ];

    this.loadingSubject.next(true);

    this.accountService.queryObjectPermissions(objectPermissionsQuery).subscribe(
      (result) => {
        var catalogItemPermission = result.find((x) => x.object == PermissionObject.CatalogItem);
        var catalogViewPermission = result.find((x) => x.object == PermissionObject.CatalogView);

        if (catalogItemPermission) {
          this.canCreateCatalogItems = catalogItemPermission.canCreate;
          this.canUpdateCatalogItems = catalogItemPermission.canUpdate;
          this.canDeleteCatalogItems = catalogItemPermission.canDelete;
        }

        if (catalogViewPermission) {
          this.canEditCatalogView = catalogViewPermission.canUpdate;
          this.canExportCatalogView = catalogViewPermission.canExport;
        }

        this.initTable();

        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  initTable(): void {
    if (this.userCatalogView) {
      // We search in every text column that is displayed.
      var filterColumns = this.tableColumns
        .filter(
          (x) =>
            x.columnProperty.indexOf('column') != -1 ||
            x.columnProperty.indexOf('title') != -1 ||
            x.columnProperty.indexOf('interpretation') != -1,
        )
        .map((x) => x.columnProperty);

      var rowActions: Array<RowAction> = [];

      if (this.canDeleteCatalogItems) {
        rowActions.push({
          action: RowActionEnum.Delete,
          tooltip: 'Global.Delete',
          buttonClass: 'btn btn-sm mb-1',
          iconClass: 'red fa fa-trash fa-lg',
        });
      }

      if (this.canUpdateCatalogItems) {
        rowActions.push({
          action: RowActionEnum.Edit,
          tooltip: 'Global.Edit',
          buttonClass: 'btn btn-sm mb-1',
          iconClass: 'green fa fa-pencil-alt fa-lg',
          preAction: this.showPreEditDialog,
        });
      }

      if (this.canCreateCatalogItems) {
        rowActions.push({
          action: RowActionEnum.Copy,
          tooltip: 'Global.Copy',
          buttonClass: 'btn btn-sm mb-1',
          iconClass: 'yellow fa fa-copy fa-lg',
        });
      }

      var showHistoryActionParams: CustomRowActionParams = {
        customFunction: this.showHistoryDialog.bind(this),
      };

      rowActions.push({
        action: RowActionEnum.Custom,
        tooltip: 'Catalog.Management.ShowHistory',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'fa-solid fa-clock-rotate-left fa-lg',
        actionParams: showHistoryActionParams,
        hideCondition: (element: CatalogItemDto) => {
          return !element.parentCatalogItemId && !element.hasChildCatalogItems;
        },
      });

      var tableActions: Array<TableAction> = [];

      if (this.canCreateCatalogItems) {
        tableActions.push({
          action: TableActionEnum.Add,
          text: 'Catalog.Management.AddCatalogItem',
          buttonClass: 'btn btn-success btn-sm mb-1',
        });
      }

      var additionalParams = new HttpParams().set('catalogViewId', this.catalogViewId);

      this.tableSettings = <TableSettings<CatalogItemDto, number>>{
        dataSource: new BaseTableDataServiceDataSource<CatalogItemDto, number>(
          this.catalogService,
          this.errorHandler,
          additionalParams,
        ),
        tableColumns: this.tableColumns,
        displayedColumns: this.displayedColumns,
        baseFilter: this.getDataTableBaseFilter(),
        globalFilterColumns: filterColumns,
        rowEditContainer: EditCatalogItemContainerComponent,
        rowEditContainerConfig: this.editDialogConfig,
        rowEditContainerStyle: 'modal-1200',
        rowDetailsContainerType: this.rowDetailColumns.length > 0 ? CatalogItemRowDetailsComponent : undefined,
        addRowTitle: 'Catalog.Management.AddCatalogItem',
        editRowTitle: 'Catalog.Management.EditCatalogItem',
        deleteRowTitle: 'Catalog.Management.DeleteCatalogItem',
        deleteRowText: 'Catalog.Management.DeleteCatalogItemText',
        rowActions: rowActions,
        rowActionsSticky: true,
        tableActions: tableActions,
        getAdditionalParams: additionalParams,
        updateAdditionalParams: additionalParams,
        createAdditionalParams: additionalParams,
        tableWidth: this.userCatalogView.table_Width,
      };

      this.dataTableComponent.rowDetailsData = this.rowDetailColumns;

      this.dataTableComponent.tableSettings = this.tableSettings;
      this.dataTableComponent.loadData();
    }
  }

  filterCatalogItemDate(): void {
    var baseFilter = this.getDataTableBaseFilter();

    if (baseFilter) {
      this.dataTableComponent.tableSettings.baseFilter = baseFilter;
      this.dataTableComponent.applyGlobalFilter();
    }
  }

  getDataTableBaseFilter(): string | undefined {
    if (this.userCatalogView) {
      var baseFilter = `catalogId==${this.userCatalogView.catalogId}`;

      if (this.catalogItemFilterDate) {
        var filterDateString = formatDateYYYYMMDD(this.catalogItemFilterDate);
        baseFilter = `${baseFilter}&validFrom<=${filterDateString}&(validUntil==null|validUntil>=${filterDateString})`;
      }

      // queryParamFilter overrides the userCatalogView filter.
      if (this.queryParamFilter) {
        baseFilter += `&(${this.queryParamFilter})`;
      } else if (this.userCatalogView.filter) {
        baseFilter += `&(${this.userCatalogView.filter})`;
      }

      return baseFilter;
    }

    return undefined;
  }

  buildConfig(catalogViewDto: CatalogViewDto): void {
    this.tableColumns = Array<TableColumn>();
    this.displayedColumns = cloneDeep(catalogViewDto.columnOrder);
    this.editDialogConfig = {
      catalogId: catalogViewDto.catalogId,
      columns: [],
      isInvoiceCatalog: catalogViewDto.catalogIsInvoiceCatalog,
    };
    this.rowDetailColumns = [];

    if (catalogViewDto.catalogIsInvoiceCatalog) {
      this.addInvoiceColumns(catalogViewDto);
    }

    for (let catalogViewDtoProperty in catalogViewDto) {
      if (catalogViewDtoProperty.indexOf('_Name') > 0) {
        var columnName = catalogViewDtoProperty.split('_')[0];

        // Skip columns that are already added.
        if (this.editDialogConfig.columns.some((x) => x.property === columnName)) {
          continue;
        }

        var columnHeader = catalogViewDto[catalogViewDtoProperty];
        var columnHide = catalogViewDto[`${columnName}_Hide`];
        var columnWidth = catalogViewDto[`${columnName}_Width`];

        if (columnHeader) {
          var maxLength = 5000;
          var multiline = false;

          if (columnName == 'column01' || columnName == 'column02') {
            maxLength = 240;
          } else if (columnName.startsWith('column')) {
            multiline = true;
          }

          // Columns that are hidden in the table go into the detail view.
          if (columnHide) {
            this.rowDetailColumns.push({ property: columnName, header: columnHeader } as CatalogItemColumn);
          } else {
            this.tableColumns.push(
              this.getTableColumn(columnName, columnHeader, false, multiline, false, columnWidth, undefined),
            );
          }

          this.editDialogConfig.columns.push({
            property: columnName,
            header: columnHeader,
            headerTranslate: false,
            min: 0,
            max: maxLength,
            dataType: 'string',
          } as CatalogItemColumn);
        }
      }
    }

    if (this.userCatalogView && this.userCatalogView.columnOrder) {
      var rowDetailColumnsPosition = 0;
      var editDialogColumnsPosition = 0;

      this.userCatalogView.columnOrder.forEach((name) => {
        // Reorder row detail columns regarding the defined column order in the database.
        var columnIndex = this.rowDetailColumns.findIndex((x) => x.property == name);
        if (columnIndex != -1) {
          if (columnIndex != rowDetailColumnsPosition) {
            moveItemInArray(this.rowDetailColumns, columnIndex, rowDetailColumnsPosition);
          }

          rowDetailColumnsPosition++;
        }

        // Reorder row edit columns regarding the defined column order in the database.
        columnIndex = this.editDialogConfig.columns.findIndex((x) => x.property == name);
        if (columnIndex != -1) {
          if (columnIndex != editDialogColumnsPosition) {
            moveItemInArray(this.editDialogConfig.columns, columnIndex, editDialogColumnsPosition);
          }

          editDialogColumnsPosition++;
        }
      });
    }

    if (catalogViewDto.validFrom_Show) {
      this.tableColumns.push(this.getValidFromTableColumn());
      this.displayedColumns.push('validFrom');
    }

    if (catalogViewDto.validUntil_Show) {
      this.tableColumns.push(this.getValidUntilTableColumn());
      this.displayedColumns.push('validUntil');
    }
  }

  addInvoiceColumns(catalogViewDto: CatalogViewDto): void {
    this.invoiceColumns.forEach((column) => {
      var columnHeader = catalogViewDto[`${column.property}_Name`];
      var columnHide = catalogViewDto[`${column.property}_Hide`];
      var columnWidth = catalogViewDto[`${column.property}_Width`];

      column.header = columnHeader;

      // Columns that are hidden in the table go into the detail view.
      if (columnHide == undefined) {
        // Columns with no hide property are not displayed in the table at all.
      } else if (columnHide) {
        this.rowDetailColumns.push(column);
      } else {
        this.tableColumns.push(
          this.getTableColumn(
            column.property,
            columnHeader,
            false,
            false,
            column.displayTranslate,
            columnWidth,
            column.valueMap,
          ),
        );
      }

      this.editDialogConfig.columns.push(column);
    });
  }

  getTableColumn(
    columnProperty: string,
    header: string,
    dateTransform: boolean,
    multiline: boolean,
    displayTranslate: boolean,
    flex?: string,
    valueMap?: Map<any, any>,
  ): TableColumn {
    var displayFunction = undefined;

    if (dateTransform) {
      displayFunction = (element: Date, row: CatalogItemDto) => formatDateOnly(element);
    } else if (multiline) {
      displayFunction = (element: string, row: CatalogItemDto) => (element ? replaceNewLineWithBr(element) : '');
    }

    if (flex && !isNaN(Number(flex.toString()))) {
      flex = `1 1 ${flex}%`;
    }

    return {
      columnProperty: columnProperty,
      header: header,
      displayTranslate: displayTranslate,
      flex: flex,
      displayFunction: displayFunction,
      valueMap: valueMap,
      renderHtml: true, // some catalog items contain links (e.g. PS25 - Kapitel)
    };
  }

  getValidFromTableColumn(): TableColumn {
    return this.getTableColumn(
      'validFrom',
      this.translate.instant('Global.ValidFrom'),
      true,
      false,
      false,
      '0 0 110px',
    );
  }

  getValidUntilTableColumn(): TableColumn {
    return this.getTableColumn(
      'validUntil',
      this.translate.instant('Global.ValidUntil'),
      true,
      false,
      false,
      '0 0 110px',
    );
  }

  editCatalogView(): void {
    this.translate.get(['Catalog.Management.EditCatalogView', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<RowEditDialogComponent<CatalogViewDto, number, any, any>>(RowEditDialogComponent, {
          class: 'modal-1400',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditCatalogViewContainerComponent,
            dataSource: new BaseTableDataServiceDataSource<CatalogViewDto, number>(
              this.catalogViewService,
              this.errorHandler,
            ),
            id: this.userCatalogView?.id,
            dialogConfig: {
              title: res['Catalog.Management.EditCatalogView'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.catalogViewService.update(onCloseResult.data).subscribe(
                (updateResult) => {
                  onCloseResult.modalRef.hide();
                  this.loadCatalog();
                },
                (errorResponse: HttpErrorResponse) => {
                  this.loadingSubject.next(false);
                  this.errorHandler.displayErrorDialog(errorResponse);
                },
              );
            }
          }
        });
    });
  }

  showHistoryDialog(element: CatalogItemDto) {
    if (this.userCatalogView) {
      // Always show valid from/until columns in catalog history.
      let tableColumnsClone = cloneDeep(this.tableColumns);

      let existingValidFrom = tableColumnsClone.findIndex((x) => x.columnProperty == 'validFrom') > -1;
      let existingValidUntil = tableColumnsClone.findIndex((x) => x.columnProperty == 'validUntil') > -1;

      if (!existingValidFrom) {
        tableColumnsClone.push(this.getValidFromTableColumn());
      }

      if (!existingValidUntil) {
        tableColumnsClone.push(this.getValidUntilTableColumn());
      }

      let columnOrderClone = cloneDeep(this.userCatalogView.columnOrder);

      existingValidFrom = columnOrderClone.findIndex((x) => x == 'validFrom') > -1;
      existingValidUntil = columnOrderClone.findIndex((x) => x == 'validUntil') > -1;

      if (!existingValidFrom) {
        columnOrderClone.push('validFrom');
      }

      if (!existingValidUntil) {
        columnOrderClone.push('validUntil');
      }

      CatalogViewHistoryDialogComponent.showDialog(
        this.modalService,
        this.userCatalogView?.catalogId,
        this.userCatalogView?.id,
        element.column01,
        tableColumnsClone,
        columnOrderClone,
        this.rowDetailColumns,
        this.userCatalogView.table_Width,
      );
    }
  }

  showPreEditDialog(element: CatalogItemDto, translate: TranslateService, modalService: BsModalService): Promise<any> {
    return new Promise<any>((resolve) => {
      if (element.hasChildCatalogItems) {
        CatalogItemPreEditDialogComponent.showDialogTranslated(
          translate,
          modalService,
          'Catalog.EditCatalogItem.PreEditDialogOnlyEdit.Title',
          'Catalog.EditCatalogItem.PreEditDialogOnlyEdit.Text',
          'Global.Ok',
          undefined,
          'Global.Cancel',
        ).then((modalRef) => {
          modalRef.content?.onClose.subscribe((onCloseResult) => {
            resolve(onCloseResult);
          });
        });
      } else {
        CatalogItemPreEditDialogComponent.showDialogTranslated(
          translate,
          modalService,
          'Catalog.EditCatalogItem.PreEditDialog.Title',
          'Catalog.EditCatalogItem.PreEditDialog.Text',
          'Catalog.EditCatalogItem.PreEditDialog.EditExisting',
          'Catalog.EditCatalogItem.PreEditDialog.CreateNew',
          'Global.Cancel',
          undefined,
          'modal-600',
        ).then((modalRef) => {
          modalRef.content?.onClose.subscribe((onCloseResult) => {
            resolve(onCloseResult);
          });
        });
      }
    });
  }

  showUserNotAuthorizedForCatalogViewDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.ForbiddenTitle',
      'Catalog.Errors.UserNotAuthorizedForCatalogViewText',
      'Global.Ok',
      undefined,
    );
  }

  showExcelDownloadDialog(): void {
    var dateDialogConfig = CustomFormDialogConfig.getDefaultDateConfig({
      dialogTitle: 'Global.ExcelExport',
      dialogText: 'Catalog.Management.ExportDialogText',
      fieldLabel: 'Catalog.Management.ExportValidDate',
      initialValue: new Date(),
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        // Download the document as a blob
        this.catalogService.getCatalogItemExcelExport(this.catalogViewId, onCloseResult.data.date).subscribe(
          (result) => {
            DownloadFileDirective.downloadFileFromHttpResponse(result);
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      }
    });
  }

  showExcelComparisonDownloadDialog(): void {
    var dateDialogConfig = new CustomFormDialogConfig({
      dialogTitle: {
        text: 'Global.ExcelExportComparison',
        translate: true,
      },
      dialogText: {
        text: 'Catalog.Management.ExportDialogText',
        translate: true,
      },
      confirmButtonText: {
        text: 'Global.Ok',
        translate: true,
      },
      cancelButtonText: {
        text: 'Global.Cancel',
        translate: true,
      },
      modalClass: 'modal-600',
      labelSize: 4,
      inputSize: 8,
      fields: [
        new CustomFormDialogFieldConfig<Date>({
          name: 'baseValidDate',
          label: {
            text: 'Catalog.Management.ExportComparisonBaseValidDate',
            translate: true,
          },
          required: true,
          requiredErrorText: {
            text: 'Global.Errors.DateIsRequired',
            translate: true,
          },
          initialValue: new Date(),
          uiType: 'date',
          minDate: undefined,
          maxDate: undefined,
        }),
        new CustomFormDialogFieldConfig<Date>({
          name: 'compareValidDate',
          label: {
            text: 'Catalog.Management.ExportComparisonCompareValidDate',
            translate: true,
          },
          required: true,
          requiredErrorText: {
            text: 'Global.Errors.DateIsRequired',
            translate: true,
          },
          initialValue: undefined,
          uiType: 'date',
          minDate: undefined,
          maxDate: undefined,
        }),
      ],
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        // Download the document as a blob
        this.catalogService
          .getCatalogItemComparisonExcelExport(
            this.catalogViewId,
            onCloseResult.data.baseValidDate,
            onCloseResult.data.compareValidDate,
          )
          .subscribe(
            (result) => {
              DownloadFileDirective.downloadFileFromHttpResponse(result);
            },
            (errorResponse: HttpErrorResponse) => {
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
      }
    });
  }

  showCsvDownloadDialog(): void {
    var dateDialogConfig = CustomFormDialogConfig.getDefaultDateConfig({
      dialogTitle: 'Global.CsvExport',
      dialogText: 'Catalog.Management.ExportDialogText',
      fieldLabel: 'Catalog.Management.ExportValidDate',
      initialValue: new Date(),
      required: true,
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        // Download the document as a blob
        this.catalogService.getCatalogItemCsvExport(this.catalogViewId, onCloseResult.data.date).subscribe(
          (result) => {
            DownloadFileDirective.downloadFileFromHttpResponse(result);
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      }
    });
  }
}
