<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.Title' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="title"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
      <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
        <div *ngIf="f.title.errors?.required">{{ 'Contract.Errors.TitleRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Admin.Rules.ResultType' | translate }}</label>
    <div [class]="formInputClass">
      <select formControlName="contractType" class="form-control">
        <option *ngFor="let key of contractTypeMapKeys" [ngValue]="key">
          {{ key | contractTypeTransform | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.CompensationType' | translate }}</label>
    <div [class]="formInputClass">
      <select formControlName="compensationType" class="form-control">
        <option *ngFor="let key of contractCompensationTypeMapKeys" [ngValue]="key">
          {{ key | contractCompensationTypeTransform | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.ParentContractHeader' | translate }}</label>
    <div [class]="formInputClass">
      <select class="form-control" formControlName="parentContractId">
        <option [ngValue]="undefined"></option>
        <option *ngFor="let parentContract of parentContracts" [ngValue]="parentContract.id">
          {{ parentContract.title }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.Insurance' | translate }}</label>
    <div [class]="formInputClass">
      <select class="form-control" formControlName="insuranceId">
        <option *ngFor="let insurance of insurances" [ngValue]="insurance.id">{{ insurance.title }}</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.Institution' | translate }}</label>
    <div [class]="formInputClass">
      <select class="form-control" formControlName="institutionId">
        <option *ngFor="let institution of institutions" [ngValue]="institution.id">{{ institution.title }}</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.CatalogView' | translate }}</label>
    <div [class]="formInputClass">
      <select class="form-control" formControlName="catalogViewId">
        <option [ngValue]="undefined"></option>
        <option *ngFor="let catalogView of catalogViews" [ngValue]="catalogView.id">{{ catalogView.title }}</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.Pdf.PdfTitle' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="pdf"
        class="form-control mr-sm-2"
        placeholder="{{ 'Contract.Pdf.PdfTitle' | translate }}"
        maxlength="256"
        [typeahead]="pdfFiles"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="4"
        [typeaheadOptionsLimit]="999" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.Pdf.Pdf02TitleLabel' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="pdf02"
        class="form-control mr-sm-2"
        placeholder="{{ 'Contract.Pdf.Pdf02Title' | translate }}"
        maxlength="256"
        [typeahead]="pdfFiles"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="4"
        [typeaheadOptionsLimit]="999" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.Pdf.Pdf03TitleLabel' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="pdf03"
        class="form-control mr-sm-2"
        placeholder="{{ 'Contract.Pdf.Pdf03Title' | translate }}"
        maxlength="256"
        [typeahead]="pdfFiles"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="4"
        [typeaheadOptionsLimit]="999" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.FactorP1' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="factorP1"
        class="form-control"
        placeholder="{{ 'Global.FactorP1' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.FactorP2' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="factorP2"
        class="form-control"
        placeholder="{{ 'Global.FactorP2' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.CashingFee' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="cashingFee"
        class="form-control"
        placeholder="{{ 'Global.CashingFee' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.OKPFactor' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="okpFactor"
        class="form-control"
        placeholder="{{ 'Global.OKPFactor' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.MaxValueHeader' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="maxValue"
        class="form-control"
        placeholder="{{ 'Contract.MaxValueHeader' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.WithdrawalPeriodHeader' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="number"
        formControlName="withdrawalPeriod"
        class="form-control"
        placeholder="{{ 'Contract.WithdrawalPeriodHeader' | translate }}"
        min="0" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.AddressHeader' | translate }}</label>
    <div [class]="formInputClass">
      <textarea
        rows="10"
        formControlName="address"
        class="form-control"
        placeholder="{{ 'Contract.AddressHeader' | translate }}"
        maxlength="2000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.SignatureHeader' | translate }}</label>
    <div [class]="formInputClass">
      <textarea
        rows="10"
        formControlName="signature"
        class="form-control"
        placeholder="{{ 'Contract.SignatureHeader' | translate }}"
        maxlength="2000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Contract.DefaultTerminationDateExpressionHeader' | translate }}</label>
    <div [class]="formInputClass">
      <textarea
        rows="10"
        formControlName="defaultTerminationDateExpression"
        class="form-control"
        placeholder="{{ 'Contract.DefaultTerminationDateExpressionHeader' | translate }}"
        maxlength="2000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.ValidFrom' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="validFrom"
        class="form-control"
        placeholder="{{ 'Global.ValidFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'Global.ValidUntil' | translate }}</label>
    <div [class]="formInputClass">
      <input
        type="text"
        formControlName="validUntil"
        class="form-control"
        placeholder="{{ 'Global.ValidUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label [class]="formLabelClass">{{ 'IndividualCompensation.Header' | translate }}</label>
    <div [class]="formInputClass">
      <mat-table [dataSource]="individualCompensationsDataSource" formArrayName="individualCompensations">
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> {{ 'Global.IndividualFactor' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input
              type="number"
              step="0.01"
              min="0"
              max="9"
              formControlName="value"
              placeholder="{{ 'Global.IndividualFactor' | translate }}" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reference">
          <mat-header-cell *matHeaderCellDef> {{ 'IndividualCompensation.Reference' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="text" formControlName="reference" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isDefault">
          <mat-header-cell *matHeaderCellDef> {{ 'IndividualCompensation.IsDefault' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input
              type="checkbox"
              formControlName="isDefault"
              (change)="individualCompensationIsDefaultChanged(element)" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleteRow">
          <mat-header-cell *matHeaderCellDef>
            {{ 'IndividualCompensation.DeleteIndividualCompensation' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <button class="btn btn-sm mb-1" (click)="deleteIndividualCompensation(index)">
              <i class="red fa fa-trash fa-lg"></i>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="errors">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <div *ngIf="element.invalid && (element.dirty || element.touched)">
              <i
                *ngIf="element.errors?.notUnique"
                class="red fa-solid fa-circle-exclamation fa-lg"
                placement="left"
                container="body"
                tooltip="{{ 'IndividualCompensation.Errors.NotUnique' | translate }}"></i>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="individualCompensationsDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: individualCompensationsDisplayedColumns"> </mat-row>
      </mat-table>

      <button class="btn btn-xs btn-success addIndividualCompensationButton" (click)="addIndividualCompensation(true)">
        {{ 'IndividualCompensation.AddIndividualCompensation' | translate }}
      </button>
    </div>
  </div>
</form>
