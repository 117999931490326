<br />
<div class="table-responsive">
  <table class="table table-striped table-bordered table-hover editables">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.DeclineReason' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ rowData.declineReason }}</td>
      </tr>
    </tbody>
  </table>
</div>
