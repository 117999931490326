import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractTypeTransform',
})
export class ContractTypeTransformPipe implements PipeTransform {
  public static contractTypeMap = new Map([
    [0, 'Contract.Type.Standard'],
    [1, 'Contract.Type.UserMustConfirm'],
    [2, 'Contract.Type.InvoiceOnly'],
  ]);

  transform(contractType?: number): string {
    if (contractType != undefined && ContractTypeTransformPipe.contractTypeMap.has(contractType)) {
      var contractTypeText = ContractTypeTransformPipe.contractTypeMap.get(contractType);
      return contractTypeText ? contractTypeText : '';
    } else {
      return '';
    }
  }
}
