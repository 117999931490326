import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';

import { TIMEOUT_TOKEN } from '@app/_providers/httpErrorInterceptor';

@Directive({
  selector: '[downloadFile]',
})
export class DownloadFileDirective {
  constructor(
    private readonly httpClient: HttpClient,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Input('downloadFile') downloadUrl!: string;
  @Input('downloadFileTimeout') downloadTimeout: number = 30000;

  @HostListener('click', ['$event'])
  public async onClick(event: any): Promise<void> {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');

    // Download the document as a blob
    this.httpClient
      .get(this.downloadUrl, {
        responseType: 'blob',
        observe: 'response',
        context: new HttpContext().set(TIMEOUT_TOKEN, this.downloadTimeout),
      })
      .subscribe(
        (response: any) => {
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');

          DownloadFileDirective.downloadFileFromHttpResponse(response);
        },
        (errorResponse: HttpErrorResponse) => {
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
  }

  public static downloadFileFromHttpResponse(response: HttpResponse<Blob>) {
    if (response.body) {
      // Create a URL for the blob
      const url = URL.createObjectURL(response.body);

      // Create an anchor element to "point" to it
      const anchor = document.createElement('a');
      anchor.href = url;

      // Get the suggested filename for the file from the response headers
      anchor.download = this.getFilenameFromHeaders(response.headers) || 'file';

      // Simulate a click on our anchor element
      anchor.click();

      // Discard the object data
      URL.revokeObjectURL(url);
    }
  }

  private static getFilenameFromHeaders(headers: HttpHeaders) {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    var utf8FilenameRegex = /filename\*=UTF-8''([^\s]+)/;
    var match = contentDisposition.match(utf8FilenameRegex);

    if (match) {
      return decodeURIComponent(match[1]);
    }

    var filenameRegex = /filename[^;=\n]*=(([\'"])(.*?)\2|[^;\n]*)/;
    match = contentDisposition.match(filenameRegex);

    if (match) {
      return match[3];
    } else {
      return null;
    }
  }
}
