import { Inject, Injectable } from '@angular/core';

@Injectable()
export class BaseApiService {
  baseUrl = '/api';
  baseControllerUrl!: string;

  constructor(@Inject(String) private controllerBaseUrl: string) {
    this.baseControllerUrl = `${this.baseUrl}/${this.controllerBaseUrl}`;
  }
}
