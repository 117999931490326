import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, zip } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { ContractsRowDetailsComponent } from '@app/_controls/row-detail-views/contracts-row-details/contracts-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditContractContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-contract-container/edit-contract-container.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { ContractTypeTransformPipe } from '@app/_helpers/transform/contract-type.transform';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { ContractDto } from '@app/_models/contractDto';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<ContractDto, number>;

  private insuranceTitleMap!: Map<number, string>;
  private institutionTitleMap!: Map<number, string>;
  private contractTypes!: ContractForUserDto[];
  private catalogViews!: CatalogViewDto[];

  constructor(
    private contractService: ContractService,
    private insuranceService: InsuranceService,
    private institutionService: InstitutionService,
    private catalogViewService: CatalogViewService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
    {
      action: RowActionEnum.Copy,
      tooltip: 'Global.Copy',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'yellow fa fa-copy fa-lg',
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'Contract.AddContract',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  ngAfterViewInit(): void {
    this.loadingSubject.next(true);

    var getInsurances = this.insuranceService.getInsurances();
    var getInstitutions = this.institutionService.getInstitutions();
    var getAllContractTypes = this.contractService.getAllContractTypes();
    var getCatalogViews = this.catalogViewService.getCatalogViews();

    zip(getInsurances, getInstitutions, getAllContractTypes, getCatalogViews).subscribe(
      (result) => {
        this.insuranceTitleMap = new Map<number, string>();
        this.institutionTitleMap = new Map<number, string>();

        result[0].forEach((element) => {
          this.insuranceTitleMap.set(element.id, element.title);
        });

        result[1].forEach((element) => {
          this.institutionTitleMap.set(element.id, element.title);
        });

        this.contractTypes = result[2];
        this.catalogViews = result[3];

        this.loadingSubject.next(false);
        this.initTable();
        this.dataTableComponent.loadData();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  initTable(): void {
    var tableColumns: Array<TableColumn> = [
      {
        columnProperty: 'id',
        header: 'Global.Id',
        searchType: 'Text',
        flex: '0 0 75px',
      },
      {
        columnProperty: 'title',
        header: 'Global.Title',
        searchType: 'Text',
        flex: '1 1 40%',
      },
      {
        columnProperty: 'insuranceId',
        header: 'Global.Insurance',
        searchType: 'Selection',
        flex: '1 1 30%',
        valueMap: this.insuranceTitleMap,
      },
      {
        columnProperty: 'institutionId',
        header: 'Global.Institution',
        searchType: 'Selection',
        flex: '1 1 30%',
        valueMap: this.institutionTitleMap,
      },
      {
        columnProperty: 'factorP1',
        header: 'Global.FactorP1',
        searchType: 'Decimal',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'factorP2',
        header: 'Global.FactorP2',
        searchType: 'Decimal',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'cashingFee',
        header: 'Global.CashingFee',
        searchType: 'Decimal',
        flex: '0 0 125px',
      },
      {
        columnProperty: 'okpFactor',
        header: 'Global.OKPFactor',
        searchType: 'Decimal',
        flex: '0 0 115px',
      },
      {
        columnProperty: 'contractType',
        header: 'Global.Type',
        searchType: 'Selection',
        flex: '0 0 150px',
        valueMap: ContractTypeTransformPipe.contractTypeMap,
        displayTranslate: true,
      },
      {
        columnProperty: 'validFrom',
        header: 'Global.ValidFrom',
        searchType: 'DateRange',
        flex: '0 0 125px',
        displayFunction: (element: Date, row: ContractDto) => formatDateOnly(element),
      },
      {
        columnProperty: 'validUntil',
        header: 'Global.ValidUntil',
        searchType: 'DateRange',
        flex: '0 0 125px',
        displayFunction: (element: Date, row: ContractDto) => formatDateOnly(element),
      },
    ];

    this.dataTableComponent.tableSettings = <TableSettings<ContractDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<ContractDto, number>(this.contractService, this.errorHandler),
      dataService: this.contractService,
      tableColumns: tableColumns,
      rowActions: this.rowActions,
      tableActions: this.tableActions,
      rowEditContainer: EditContractContainerComponent,
      rowEditContainerStyle: 'modal-1400',
      rowDetailsContainerType: ContractsRowDetailsComponent,
      addRowTitle: 'Contract.AddContract',
      editRowTitle: 'Contract.EditContract',
      deleteRowTitle: 'Contract.DeleteContract',
      deleteRowText: 'Contract.DeleteContractText',
    };

    this.dataTableComponent.rowDetailsData.contractTypes = this.contractTypes;
    this.dataTableComponent.rowDetailsData.catalogViews = this.catalogViews;
  }
}
