import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-hin-linked',
  templateUrl: './account-hin-linked.component.html',
})
export class AccountHinLinkedComponent implements OnInit {
  public success = true;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.success = params['error'] === undefined;
    });
  }
}
