import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sms-verification-dialog',
  templateUrl: './sms-verification-dialog.component.html',
  styleUrls: ['./sms-verification-dialog.component.scss'],
})
export class SmsVerificationDialogComponent implements OnInit {
  public onClose!: Subject<string>;
  public code?: string;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  onCodeChanged(code: string) {
    this.code = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.code = code;
  }

  public onConfirm(): void {
    this.onClose.next(this.code);
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(undefined);
    this.modalRef.hide();
  }
}
