<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'Dialog.SmsVerificationDialog.Title' | translate }}</h4>
</div>
<div class="modal-body">
  <code-input
    [isCodeHidden]="true"
    [codeLength]="6"
    [isCodeHidden]="false"
    (codeChanged)="onCodeChanged($event)"
    (codeCompleted)="onCodeCompleted($event)">
  </code-input>
</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ 'Global.Cancel' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">{{ 'Global.Ok' | translate }}</button>
  </ng-container>
</div>
