import { query } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditPatientContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-patient-container/edit-patient-container.component';
import { RowEditDialogComponent } from '@app/_dialogs/row-edit-dialog/row-edit-dialog.component';
import { formatDateOnly, formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { dateCompare } from '@app/_helpers/validators/date-compare.validator';
import {
  CaseDto,
  FromUntilDto,
  QueryRestrictionsDto,
  treatmentCategoryToInvoiceInsuranceType,
} from '@app/_models/caseDto';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { InvoiceInsuranceType } from '@app/_models/enums/insuranceType';
import { InstitutionWithConfigurationDto } from '@app/_models/institutionDto';
import { InsuranceDto } from '@app/_models/insuranceDto';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { PatientDto } from '@app/_models/patientDto';
import { AccountService } from '@app/_services/account.service';
import { CaseService } from '@app/_services/case.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { InvoiceService } from '@app/_services/invoice.service';
import { PatientService } from '@app/_services/patient.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

type InsuranceWithChildren = {
  insurance: InsuranceDto;
  children: InsuranceDto[];
};

const localStorageSkipSsoLoginKey = (ssoKey: string) => 'skipSsoLogin_' + ssoKey;

@Component({
  selector: 'app-user-invoice-create',
  templateUrl: './user-invoice-create.component.html',
  styleUrls: ['./user-invoice-create.component.scss'],
})
export class UserInvoiceCreateComponent implements OnInit, OnDestroy {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  institutions: InstitutionWithConfigurationDto[] = [];
  columnsToDisplay = [
    'caseNumber',
    'patientGivenName',
    'patientFamilyName',
    'patientBirthDate',
    'patientPid',
    'caseStartDate',
    'caseEndDate',
    'actions',
  ];

  form = this.fb.group({
    caseSearch: ['', Validators.minLength(3)],
    patientSearch: [undefined as string | PatientDto | undefined, Validators.required],
    institution: [null as InstitutionWithConfigurationDto | null, Validators.required],
    dateFrom: this.fb.control<Date | null>(null, [Validators.required, dateCompare('dateUntil', false, true)]),
    dateUntil: this.fb.control<Date | null>(null, [Validators.required, dateCompare('dateFrom', true, true)]),
    contract: [null as ContractForUserDto | null, Validators.required],
    insurance: [null as InsuranceDto | null, Validators.required],
    insuranceType: [null as InvoiceInsuranceType | null, Validators.required],
    caseNumber: [''],
    pid: [''],
    besr: [''],
    memo: [''],
  });
  cases$: Observable<CaseDto[]> = of([]);
  queryRestrictions?: QueryRestrictionsDto;
  contracts$: Observable<ContractForUserDto[]> = of([]);
  insuranceTypeOptions$ = this.form.controls.contract.valueChanges.pipe(
    map((contract) => {
      const insuranceTypes: InvoiceInsuranceType[] = [];
      if (contract?.factorP1) {
        insuranceTypes.push(InvoiceInsuranceType.Private);
      }
      if (contract?.factorP2) {
        insuranceTypes.push(InvoiceInsuranceType.SemiPrivate);
      }
      return insuranceTypes;
    }),
  );
  private destroy$ = new Subject();

  preSelectedPatient?: PatientDto;
  preSelectedPatientIsDifferent: boolean = false;
  shouldSelectCase: boolean = false;
  skipSsoLogin: boolean = false;
  // to be set only when the user selected institution was evaluated, to be able to hide the rest of the form until then
  processedInstitution?: InstitutionWithConfigurationDto | null = undefined;
  selectedCase?: CaseDto;
  selectedPatient?: PatientDto;

  contractInsurance$?: Observable<InsuranceDto | undefined>;
  childInsurances$?: Observable<InsuranceDto[] | undefined>;

  searchedPatients$: Observable<PatientDto[] | undefined> = of([]);

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private institutionService: InstitutionService,
    private caseService: CaseService,
    private contractService: ContractService,
    private insuranceService: InsuranceService,
    private invoiceService: InvoiceService,
    private accountService: AccountService,
    private translate: TranslateService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private patientService: PatientService,
    private modalService: BsModalService,
  ) {}

  compareContractForUserWithDetailsDto(c1: ContractForUserDto, c2: ContractForUserDto): boolean {
    return c1?.id == c2?.id;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private readonly _queryParamInstitutionId = 'institutionId';
  private readonly _queryParamPatientId = 'patientId';

  async ngOnInit(): Promise<void> {
    this.institutions = await this.institutionService.getUserChildInstitutions().toPromise();

    this.cases$ = this.form.controls.caseSearch.valueChanges.pipe(
      debounceTime(500),
      filter((it) => it != null && it.length >= 3),
      map((it) => it?.trim()),
      distinctUntilChanged(),
      switchMap((it) => {
        return this.caseService
          .queryByInstitutionAndSearchText(this.form.controls.institution.value!, this.form.controls.caseSearch.value!)
          .pipe(
            catchError((err) => []),
            map((it) => it.data),
          );
      }),
      shareReplay(1),
      takeUntil(this.destroy$),
    );

    this.form.controls.institution.valueChanges
      .pipe(
        tap((_) => this.loadingSubject.next(true)),
        switchMap((institution) => {
          if (institution?.institutionConfiguration.isInstitutionDataAvailable) {
            return this.caseService
              .getQueryRestrictions(institution)
              .pipe(map((queryRestrictions) => ({ institution, queryRestrictions })));
          }
          return of({ institution, queryRestrictions: undefined });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(({ institution, queryRestrictions }) => {
        this.skipSsoLogin = queryRestrictions?.requiredIdentityProvider
          ? localStorage.getItem(localStorageSkipSsoLoginKey(queryRestrictions?.requiredIdentityProvider)) != null
          : false;
        this.shouldSelectCase =
          (institution?.institutionConfiguration.isInstitutionDataAvailable &&
            queryRestrictions?.userInRequiredGroup &&
            !this.skipSsoLogin) ??
          false;
        this.queryRestrictions = queryRestrictions;
        this.selectCase(undefined);

        if (this.preSelectedPatient?.id && this.shouldSelectCase) {
          this.form.controls.caseSearch.setValue(
            `${formatDateOnly(this.preSelectedPatient.dateOfBirth)} ${this.preSelectedPatient.lastName}`,
          );
        }
        this.processedInstitution = institution;
        this.loadingSubject.next(false);
      });

    const insurances = await this.insuranceService.getInsurances().toPromise();
    const insuranceMap = this.reduceInsurancesWithChildren(insurances);

    this.contracts$ = this.form.controls.dateFrom.valueChanges.pipe(
      tap(() => this.loadingSubject.next(true)),
      switchMap((dateFrom) =>
        dateFrom
          ? this.contractService.getInvoiceUserContractTypes(dateFrom).pipe(
              map((contracts) =>
                contracts.filter((contract) => contract.institutionId === this.form.controls.institution.value?.id),
              ),
              catchError(() => of([])),
            )
          : of([]),
      ),
      tap(() => this.loadingSubject.next(false)),
      takeUntil(this.destroy$),
    );

    this.contracts$.pipe(takeUntil(this.destroy$)).subscribe((contracts) => {
      const contractToSelect = contracts.find(
        (contract) =>
          contract.institutionId == this.form.controls.institution.value?.id &&
          (contract.insuranceId == this.selectedCase?.insuranceId ||
            insuranceMap.get(contract.insuranceId)?.insurance.title == this.selectedCase?.insuranceName),
      );

      this.form.controls.contract.setValue(contractToSelect ?? null);
    });

    // Cross-Validation between dateFrom and dateUntil. This should probably be solved using https://v17.angular.io/guide/form-validation#cross-field-validation
    this.form.controls.dateFrom.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.controls.dateUntil.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
    this.form.controls.dateUntil.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.controls.dateUntil.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(async (params) => {
      const institutionIdParam = params.get(this._queryParamInstitutionId);
      const patientIdParam = params.get(this._queryParamPatientId);
      if (patientIdParam != null) {
        this.preSelectedPatient = await this.patientService.get(patientIdParam).toPromise();
        this.form.controls.patientSearch.setValue(this.preSelectedPatient);
      }
      if (institutionIdParam != null) {
        this.form.controls.institution.setValue(
          this.institutions.find((i) => i.id.toString() == institutionIdParam) || null,
        );
      }
    });

    this.searchedPatients$ = this.form.controls.patientSearch.valueChanges.pipe(
      debounceTime(500),
      map((it) => it?.trim()),
      distinctUntilChanged(),
      switchMap((searchText) => {
        if (typeof searchText !== 'string' || searchText.length < 3) {
          return of([]);
        }

        return this.patientService.queryFullText(searchText);
      }),
      takeUntil(this.destroy$),
    );

    this.contractInsurance$ = this.form.controls.contract.valueChanges.pipe(
      tap((_) => this.form.controls.insurance.reset()),
      map((selectedContract) =>
        !selectedContract ? undefined : insuranceMap.get(selectedContract.insuranceId)?.insurance,
      ),
      takeUntil(this.destroy$),
    );

    this.childInsurances$ = this.contractInsurance$.pipe(
      map((insurance) => insuranceMap.get(insurance?.id || 0)),
      map((insuranceAndChildren) => insuranceAndChildren?.children),
      tap((children) => {
        // en/disable the insurance control to control if it's required or not
        if (children && children.length > 0) {
          this.form.controls.insurance.enable();
        } else {
          this.form.controls.insurance.disable();
        }
      }),
      takeUntil(this.destroy$),
    );

    this.childInsurances$.pipe(takeUntil(this.destroy$)).subscribe((childInsurances) => {
      const insuranceToSelect =
        childInsurances?.filter(
          (insurance) =>
            insurance.insuranceId == this.selectedCase?.insuranceId ||
            insurance.title == this.selectedCase?.insuranceName,
        ) ?? [];

      this.form.controls.insurance.setValue(insuranceToSelect.length == 1 ? insuranceToSelect[0] : null);
    });

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    this.loadingSubject.next(false);
  }

  private reduceInsurancesWithChildren(insurances: InsuranceDto[]): Map<InsuranceDto['id'], InsuranceWithChildren> {
    return new Map(
      insurances.map((dto) => [
        dto.id,
        {
          insurance: dto,
          children: insurances.filter((insurance) => insurance.parentInsurance?.id === dto.id),
        },
      ]),
    );
  }

  async getPatientIdForSubmit() {
    if (this.selectedCase) {
      return await this.patientService.findOrCreatePatient(
        this.selectedCase!.patientGivenName!,
        this.selectedCase!.patientFamilyName,
        new Date(this.selectedCase!.patientBirthDate!),
      );
    } else {
      const patient = this.form.controls.patientSearch.value as PatientDto;

      if (!patient.id) {
        return (await this.patientService.create(patient).toPromise()).value;
      }

      return patient.id;
    }
  }

  async onSubmit() {
    const invoiceDto: InvoiceDto = {
      besr: this.form.controls.besr.value || '',
      createDate: new Date(),
      dateFrom: this.form.controls.dateFrom.value!,
      dateTo: this.form.controls.dateUntil.value!,
      factor: 0,
      id: 0,
      insuranceType: this.form.controls.insuranceType.value!,
      caseNumber: this.form.controls.caseNumber.value || undefined,
      catalogId: 0,
      catalogTitle: '',
      catalogViewId: 0,
      contractId: this.form.controls.contract.value!.id!,
      memo: this.form.controls.memo.value || undefined,
      patientId: await this.getPatientIdForSubmit(),
      sessions: [],
      pid: this.form.controls.pid.value || undefined,
      insuranceId: this.form.controls.insurance.value?.id,
    };
    this.invoiceService.create(invoiceDto).subscribe(
      (createResult) => {
        this.router.navigate(['/user/invoice', createResult.value]);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  protected readonly formatDateOnly = formatDateOnly;

  async selectCase(caze?: CaseDto) {
    this.selectedCase = caze;

    if (caze) {
      this.form.controls.dateFrom.setValue(caze.caseStartDate ? new Date(caze.caseStartDate) : null);
      this.form.controls.dateFrom.updateValueAndValidity();
      this.form.controls.dateUntil.setValue(caze.caseEndDate ? new Date(caze.caseEndDate) : null);
      this.form.controls.caseNumber.setValue(caze.caseNumber);
      this.form.controls.pid.setValue(caze.patientPid);
      this.form.controls.insuranceType.setValue(treatmentCategoryToInvoiceInsuranceType(caze.treatmentCategory));

      this.selectedPatient = await this.patientService.findOrInstantiatePatient(
        caze.patientGivenName,
        caze.patientFamilyName,
        new Date(caze.patientBirthDate),
      );

      if (this.preSelectedPatient && this.preSelectedPatient.id != this.selectedPatient.id) {
        this.preSelectedPatientIsDifferent = true;
      }
      this.form.controls.patientSearch.disable();
    } else {
      this.resetForm();
      this.form.controls.patientSearch.enable();
    }

    // show required fields
    this.form.markAllAsTouched();
  }

  protected readonly InvoiceInsuranceType = InvoiceInsuranceType;

  private resetForm() {
    this.form.controls.dateFrom.reset();
    this.form.controls.dateUntil.reset();
    this.form.controls.caseNumber.reset();
    this.form.controls.pid.reset();
    this.form.controls.insuranceType.reset();
    this.form.controls.contract.reset();
  }

  asDate(date: string | undefined) {
    return date ? new Date(date) : undefined;
  }

  protected readonly query = query;

  async linkRequiredIdp() {
    const identityProvider = this.queryRestrictions?.requiredIdentityProvider;

    if (!identityProvider) {
      return;
    }

    this.setSkipSsoLogin(identityProvider, false);

    const redirectPath = `${this.router.url}?${this._queryParamInstitutionId}=${this.form.controls.institution.value?.id}`;

    // This means the user is linked to this idp but not logged in with it
    if (!this.queryRestrictions?.actualIdentityProvider && this.queryRestrictions?.externalUserId != null) {
      await this.accountService.forceLoginWithIdp(identityProvider, redirectPath);
      return;
    }

    await this.accountService.navigateToLinkingIdp(
      identityProvider,
      `/account/force-login-with-idp?idp=${identityProvider}&postLoginRedirectPath=${redirectPath}`,
    );
  }

  protected readonly formatDateYYYYMMDD = formatDateYYYYMMDD;

  displayPatient(patientDto: PatientDto | undefined) {
    if (!patientDto) {
      return '';
    }

    const newPatientMarker = 'User.Invoices.NewPatientMarker';
    const translations = this.translate.instant([newPatientMarker]);

    return (
      `${patientDto.firstName} ${patientDto.lastName} ${formatDateOnly(patientDto.dateOfBirth)}` +
      (!patientDto.id ? ` ${translations[newPatientMarker]}` : '')
    );
  }

  formatAssignmentRanges(assignmentRanges: FromUntilDto[]) {
    const fromTranslation = 'User.Invoices.AssignmentFrom';
    const untilTranslation = 'User.Invoices.AssignmentUntil';
    const andTranslation = 'User.Invoices.AssignmentAnd';
    const translations = this.translate.instant([fromTranslation, untilTranslation, andTranslation]);
    return assignmentRanges
      .sort((a, b) => b.from.localeCompare(a.from))
      .map((it) => [formatDateOnly(new Date(it.from)), it.until ? formatDateOnly(new Date(it.until)) : ''])
      .map(
        (fromUntil) =>
          `${translations[fromTranslation]}: ${fromUntil[0]}` +
          (fromUntil[1] ? ` ${translations[untilTranslation]} ${fromUntil[1]}` : ''),
      )
      .join(` ${translations[andTranslation]} `);
  }

  createPatientDialog() {
    this.translate.get(['Global.Save', 'Global.Cancel', 'User.Invoices.NewPatient']).subscribe((res) => {
      let modalRef = this.modalService.show<
        RowEditDialogComponent<PatientDto, number, any, EditPatientContainerComponent>
      >(RowEditDialogComponent, {
        class: 'modal-1000',
        ignoreBackdropClick: true,
        initialState: {
          containerType: EditPatientContainerComponent,
          dataSource: new BaseTableDataServiceDataSource(this.patientService, this.errorHandler),
          preActionData: undefined,
          dialogConfig: {
            title: res['User.Invoices.NewPatient'],
            button_confirm_text: res['Global.Save'],
            button_cancel_text: res['Global.Cancel'],
          },
        },
      });
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult.confim) {
          if (onCloseResult.data) {
            onCloseResult.modalRef.hide();
            this.form.controls.patientSearch.setValue(onCloseResult.data);
          }
        }
      });
    });
  }

  /**
   * Check whether the value is not defined or a string (the autocomplete hasn't been completed yet)
   */
  isNoPatientSelected(value: any) {
    return !value || typeof value === 'string';
  }

  protected readonly treatmentCategoryToInvoiceInsuranceType = treatmentCategoryToInvoiceInsuranceType;

  setSkipSsoLogin(identityProvider: string, skip: boolean) {
    this.skipSsoLogin = skip;
    this.shouldSelectCase = !skip;
    if (skip) {
      localStorage.setItem(localStorageSkipSsoLoginKey(identityProvider), new Date().toString());
    } else {
      localStorage.removeItem(localStorageSkipSsoLoginKey(identityProvider));
    }
  }
}
