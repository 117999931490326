<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.Title' | translate }}</label>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="title"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
      <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
        <div *ngIf="f.title.errors?.required">{{ 'Insurance.Errors.TitleRequired' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.Identifier' | translate }}</label>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="identifier"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Identifier' | translate }}"
        maxlength="20" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Insurance.MaxValueHeader' | translate }}</label>
    <div class="col-sm-9">
      <input
        type="number"
        formControlName="maxValue"
        class="form-control"
        placeholder="{{ 'Insurance.MaxValueHeader' | translate }}"
        min="0" />
    </div>
  </div>
  <div class="form-group row" *ngIf="!this.data?.hasChildInsurances">
    <label class="col-sm-3 col-form-label">{{ 'Insurance.ParentInsurance' | translate }}</label>
    <div class="col-sm-9">
      <select formControlName="parentInsurance" class="form-control" [compareWith]="compareInsurances">
        <option [ngValue]="null"></option>
        <option *ngFor="let insurance of parentInsurances$ | async" [ngValue]="insurance">
          {{ insurance.title }}
        </option>
      </select>
    </div>
  </div>
</form>
