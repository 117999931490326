<div class="container container-plus">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-success" *ngIf="success">
        {{ 'Account.HinLinked.Success' | translate }}
      </div>
      <div class="alert alert-danger" *ngIf="!success">
        {{ 'Account.HinLinked.Error' | translate }}
      </div>
      <a [routerLink]="['/']" class="btn btn-light px-4 btn-bold mb-4">Zurück zum Dashboard</a>
    </div>
  </div>
</div>
