import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { zip } from 'rxjs';

import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';

import { GroupDto } from '@app/_models/groupDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { InsuranceDto } from '@app/_models/insuranceDto';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { objectPermissionUnique } from '@app/_helpers/validators/objectpermission-unique.validator';
import { CatalogDto } from '@app/_models/catalogDto';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { EmployeeGroupDto } from '@app/_models/employeeGroupDto';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { InfoLinkDto } from '@app/_models/infoLinkDto';
import { ObjectPermissionDto } from '@app/_models/objectPermissionDto';
import { RoleDto } from '@app/_models/roleDto';
import { CatalogService } from '@app/_services/catalog.service';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InfoLinkService } from '@app/_services/infolink.service';
import { UserService } from '@app/_services/user.service';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';

@Component({
  selector: 'app-edit-user-group-container',
  templateUrl: './edit-user-group-container.component.html',
  styleUrls: ['./edit-user-group-container.component.css'],
})
export class EditUserGroupContainerComponent implements IRowEditContainer<GroupDto, number, any, any> {
  dialog!: RowEditDialogComponent<GroupDto, number, any, any>;
  data!: GroupDto;
  initialData?: GroupDto;
  resultData: any;
  form!: UntypedFormGroup;
  objectPermissionsFormArray!: UntypedFormArray;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  institutions!: InstitutionDto[];
  insurances!: InsuranceDto[];
  roles!: RoleDto[];
  catalogs!: CatalogDto[];
  catalogViews!: CatalogViewDto[];
  infoLinks!: InfoLinkDto[];
  employeeGroups!: EmployeeGroupDto[];
  objectPermissionsDataSource!: MatTableDataSource<AbstractControl>;
  objectPermissionsDisplayedColumns = [
    'object',
    'objectId',
    'canRead',
    'canCreate',
    'canUpdate',
    'canDelete',
    'canExport',
    'deleteRow',
    'errors',
  ];
  permissionObjectOptions = [
    {
      value: PermissionObject.CatalogItem,
      text: 'ObjectPermissions.ObjectTypes.CatalogItem',
      canReadActive: true,
      canCreateActive: true,
      canUpdateActive: true,
      canDeleteActive: true,
      canExportActive: false,
    },
    {
      value: PermissionObject.CatalogView,
      text: 'ObjectPermissions.ObjectTypes.CatalogView',
      canReadActive: true,
      canCreateActive: false,
      canUpdateActive: true,
      canDeleteActive: false,
      canExportActive: true,
    },
    {
      value: PermissionObject.User,
      text: 'ObjectPermissions.ObjectTypes.User',
      canReadActive: false,
      canCreateActive: false,
      canUpdateActive: false,
      canDeleteActive: false,
      canExportActive: true,
    },
    {
      value: PermissionObject.Employee,
      text: 'ObjectPermissions.ObjectTypes.Employee',
      canReadActive: true,
      canCreateActive: true,
      canUpdateActive: true,
      canDeleteActive: true,
      canExportActive: true,
    },
    {
      value: PermissionObject.UserContract,
      text: 'ObjectPermissions.ObjectTypes.UserContract',
      canReadActive: false,
      canCreateActive: false,
      canUpdateActive: false,
      canDeleteActive: false,
      canExportActive: true,
    },
    {
      value: PermissionObject.InfoLink,
      text: 'ObjectPermissions.ObjectTypes.InfoLinks',
      canReadActive: true,
      canCreateActive: false,
      canUpdateActive: false,
      canDeleteActive: false,
      canExportActive: false,
    },
  ];

  constructor(
    public userService: UserService,
    public catalogService: CatalogService,
    public catalogViewService: CatalogViewService,
    public infoLinkService: InfoLinkService,
    public employeeService: EmployeeService,
    private errorHandler: ErrorHandlerService,
  ) {}

  initializeContainer(): void {
    this.dialog.loadingSubject.next(true);

    // If there is no input data we are adding a row
    if (!this.data) {
      if (this.initialData) {
        this.data = this.initialData;
        this.data.id = 0;

        for (var objectPermission of this.data.objectPermissions) {
          objectPermission.id = 0;
        }
      } else {
        this.data = <GroupDto>{
          objectPermissions: new Array<ObjectPermissionDto>(),
          adminGroup: false,
          invoiceGroup: false,
          invoiceGuestAssistant: false,
        };
      }
    }

    var getRoles = this.userService.getRoles();
    var getCatalogs = this.catalogService.getCatalogs();
    var getCatalogViews = this.catalogViewService.getCatalogViews();
    var getInfoLinks = this.infoLinkService.getInfoLinks();
    var getAllEmployeeGroups = this.employeeService.getAllEmployeeGroups();

    zip(getRoles, getCatalogs, getCatalogViews, getInfoLinks, getAllEmployeeGroups).subscribe(
      (result) => {
        this.roles = result[0];
        this.catalogs = result[1];
        this.catalogViews = result[2];
        this.infoLinks = result[3];
        this.employeeGroups = result[4];

        this.dialog.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.dialog.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );

    this.objectPermissionsFormArray = new UntypedFormArray([]);

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.name, [Validators.required]),
      description: new UntypedFormControl(this.data.description, []),
      adminGroup: new UntypedFormControl(this.data.adminGroup, []),
      invoiceGroup: new UntypedFormControl(this.data.invoiceGroup, []),
      invoiceGuestAssistant: new UntypedFormControl(this.data.invoiceGuestAssistant, []),
      roles: new UntypedFormControl(this.data.roles, []),
      objectPermissions: this.objectPermissionsFormArray,
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });

    this.data.objectPermissions.forEach((objectPermissionDto: ObjectPermissionDto) => {
      this.addObjectPermission(false, objectPermissionDto);
    });

    this.objectPermissionsDataSource = new MatTableDataSource(this.objectPermissionsFormArray.controls);
  }

  get f() {
    return this.form.controls;
  }

  public addObjectPermission(updateTable: boolean, objectPermissionDto: ObjectPermissionDto | undefined = undefined) {
    if (!objectPermissionDto) {
      objectPermissionDto = <ObjectPermissionDto>{
        id: 0,
        object: 0,
        canRead: false,
        canCreate: false,
        canUpdate: false,
        canDelete: false,
        canExport: false,
      };
    }

    var objectPermissionFormGroup = new UntypedFormGroup(
      {
        id: new UntypedFormControl(objectPermissionDto.id, []),
        object: new UntypedFormControl(objectPermissionDto.object, [Validators.required]),
        objectId: new UntypedFormControl(objectPermissionDto.objectId, [Validators.required]),
        canRead: new UntypedFormControl(objectPermissionDto.canRead, []),
        canCreate: new UntypedFormControl(objectPermissionDto.canCreate, []),
        canUpdate: new UntypedFormControl(objectPermissionDto.canUpdate, []),
        canDelete: new UntypedFormControl(objectPermissionDto.canDelete, []),
        canExport: new UntypedFormControl(objectPermissionDto.canExport, []),
      },
      [objectPermissionUnique(this.objectPermissionsFormArray)],
    );

    this.setObjectPermissionDisabled(objectPermissionFormGroup);

    this.objectPermissionsFormArray.push(objectPermissionFormGroup);

    if (updateTable) {
      this.objectPermissionsDataSource._updateChangeSubscription();
    }
  }

  public deleteObjectPermission(index: number) {
    this.objectPermissionsFormArray.removeAt(index);
    this.objectPermissionsDataSource._updateChangeSubscription();
  }

  public objectChanged(formGroup: UntypedFormGroup): void {
    this.setObjectPermissionDisabled(formGroup);
    formGroup.get('objectId')?.setValue(undefined);
  }

  setObjectPermissionDisabled(formGroup: UntypedFormGroup): void {
    var object = formGroup.get('object');

    if (object) {
      var permissionObjectOption = this.permissionObjectOptions.find((x) => x.value == formGroup.get('object')?.value);

      if (permissionObjectOption) {
        var canRead = formGroup.get('canRead');
        var canCreate = formGroup.get('canCreate');
        var canUpdate = formGroup.get('canUpdate');
        var canDelete = formGroup.get('canDelete');
        var canExport = formGroup.get('canExport');

        permissionObjectOption.canReadActive ? canRead?.enable() : canRead?.disable();
        permissionObjectOption.canCreateActive ? canCreate?.enable() : canCreate?.disable();
        permissionObjectOption.canUpdateActive ? canUpdate?.enable() : canUpdate?.disable();
        permissionObjectOption.canDeleteActive ? canDelete?.enable() : canDelete?.disable();
        permissionObjectOption.canExportActive ? canExport?.enable() : canExport?.disable();
      }
    }
  }
}
