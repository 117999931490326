<div class="sidebar-inner border-r-0 border-b-1 brc-secondary-l2 shadow-md">
  <div class="container container-plus px-0 d-flex flex-column flex-xl-row">
    <div class="flex-grow-1 d-xl-flex flex-xl-row ace-scroll" data-ace-scroll="{}">
      <app-sidebar-submenu
        NgAceSubmenu
        (onToggleItem)="onToggleItem($event)"
        class="nav nav-spaced nav-fill text-center nav-active-sm has-active-border active-on-top"
        [items]="sidebarItems"></app-sidebar-submenu>
    </div>
  </div>
  <!-- .container -->
</div>
<!-- .sidebar-inner -->
