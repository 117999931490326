import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventLogReferenceTypeTransform',
})
export class EventLogReferenceTypeTransformPipe implements PipeTransform {
  public static eventLogReferenceTypeMap = new Map([
    [undefined, ''],
    [0, 'Global.UserContracts'],
    [1, 'Global.Invoices'],
    [2, 'Global.InfoLinks'],
    [3, 'Global.Institutions'],
    [4, 'Global.Insurances'],
    [5, 'Global.Contracts'],
    [6, 'Global.CatalogItems'],
    [7, 'Global.CatalogViews'],
    [8, 'Global.Groups'],
  ]);

  transform(eventLogReferenceType: number): string {
    if (EventLogReferenceTypeTransformPipe.eventLogReferenceTypeMap.has(eventLogReferenceType)) {
      var accountTypeText = EventLogReferenceTypeTransformPipe.eventLogReferenceTypeMap.get(eventLogReferenceType);
      return accountTypeText ? accountTypeText : '';
    } else {
      return '';
    }
  }
}
