<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'Insurance.UserContracts.Header' | translate }}</h1>
  </div>
  <div class="header-toolbar">
    <button
      *ngIf="objectPermission?.canExport"
      downloadFile="api/insurance/excelexport"
      [downloadFileTimeout]="300000"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.ExcelExport' | translate }}
    </button>
    <button
      *ngIf="objectPermission?.canExport"
      (click)="showContractChangesExcelExportDialog()"
      class="btn btn-xs btn-success">
      {{ 'Insurance.UserContracts.ContractChangesExcelReport' | translate }}
    </button>
  </div>
</div>
<app-data-table id="insuranceUserContracts"></app-data-table>
