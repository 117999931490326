<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Title' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="title"
        class="form-control"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="32" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Description' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="2"
        formControlName="description"
        class="form-control"
        placeholder="{{ 'Global.Description' | translate }}"
        maxlength="512">
      </textarea>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.AdditionalInfo' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="2"
        formControlName="additionalInfo"
        class="form-control"
        placeholder="{{ 'Catalog.EditCatalogView.AdditionalInfo' | translate }}"
        maxlength="512">
      </textarea>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.IsActive' | translate }}</label>
    <div class="col-sm-10">
      <input type="checkbox" formControlName="isActive" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.CatalogViewOrder' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="number"
        formControlName="catalogViewOrder"
        class="form-control"
        placeholder="{{ 'Catalog.EditCatalogView.CatalogViewOrder' | translate }}"
        maxlength="2147483647" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.TableWidth' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="table_Width"
        class="form-control"
        placeholder="{{ 'Catalog.EditCatalogView.TableWidth' | translate }}"
        maxlength="20" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.Filter' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="filter"
        class="form-control"
        placeholder="{{ 'Catalog.EditCatalogView.Filter' | translate }}"
        maxlength="256" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Catalog.EditCatalogView.DefaultSort' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="defaultSort"
        class="form-control"
        placeholder="{{ 'Catalog.EditCatalogView.DefaultSort' | translate }}"
        maxlength="50" />
    </div>
  </div>

  <div class="column-list" (cdkDropListDropped)="drop($event)" cdkDropList>
    <div *ngFor="let column of columns" class="form-group row" cdkDrag>
      <div class="col-sm-2">
        <i class="fa-solid fa-lg fa-arrows-up-down" style="padding-right: 10px"></i>
        <label class="col-form-label">{{ column.labelTranslate ? (column.label | translate) : column.label }}</label>
      </div>
      <div class="col-sm-6" *ngIf="column.nameProperty">
        <input
          type="text"
          [formControlName]="column.nameProperty"
          class="form-control"
          [placeholder]="column.labelTranslate ? (column.label | translate) : column.label"
          maxlength="128" />
        <div
          *ngIf="
            getFormControl(column.nameProperty).invalid &&
            (getFormControl(column.nameProperty).dirty || getFormControl(column.nameProperty).touched)
          "
          class="alert alert-danger">
          <div *ngIf="getFormControl(column.nameProperty).errors?.required">
            {{ 'Catalog.Errors.ColumnRequired' | translate }}
          </div>
        </div>
      </div>

      <div class="col-sm-1" *ngIf="column.widthProperty">
        <input
          type="text"
          [formControlName]="column.widthProperty"
          class="form-control"
          placeholder="{{ 'Catalog.EditCatalogView.ColumnWidth' | translate }}"
          maxlength="20" />
      </div>

      <div class="col-sm-3" *ngIf="column.hideProperty">
        <label class="col-form-label">
          <input type="checkbox" [formControlName]="column.hideProperty" />
          <span>{{ 'Catalog.EditCatalogView.HideColumn' | translate }}</span>
        </label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.ValidFrom' | translate }}</label>
    <div class="col-sm-10">
      <label class="col-form-label">
        <input type="checkbox" formControlName="validFrom_Show" />
        <label>{{ 'Catalog.EditCatalogView.ShowColumn' | translate }}</label>
      </label>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.ValidUntil' | translate }}</label>
    <div class="col-sm-10">
      <label class="col-form-label">
        <input type="checkbox" formControlName="validUntil_Show" />
        <label>{{ 'Catalog.EditCatalogView.ShowColumn' | translate }}</label>
      </label>
    </div>
  </div>
</form>
