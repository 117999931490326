<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'Dialog.FileUploadDialog.Title' | translate }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="loading$ | async" class="loading-div">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
  <div style="padding: 0px 15px 0px 15px">
    <div>
      <div class="form-group row d-flex justify-content-center align-items-center">
        <div class="col-sm-1">
          <input type="checkbox" [(ngModel)]="saveSecure" />
        </div>
        <label class="col-sm-11 col-form-label">{{ 'Dialog.FileUploadDialog.SaveSecure' | translate }}</label>
      </div>
      <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
        <div class="text-wrapper">
          <div class="centered">
            <input type="file" name="file" id="file" (change)="onFileChange($event)" multiple />
            <label for="file">
              <span class="textLink">{{ 'Dialog.FileUploadDialog.SelectFiles' | translate }}</span>
              <br />
              <br />
              {{ 'Dialog.FileUploadDialog.OrDropHere' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h3 mat-subheader>{{ 'Dialog.FileUploadDialog.Files' | translate }}</h3>
    <div class="container-fluid">
      <div class="horizontal-scrollable row flex-row flex-nowrap mt-4 pb-4 pt-2">
        <div class="col-5 fileItem" *ngFor="let file of files">
          <div class="fileItemIconDiv" (click)="removeFile(file)">
            <i class="fa-6x fileItemIcon"></i>
          </div>
          <div class="fileItemText">
            <span>{{ file.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ 'Global.Cancel' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">
      {{ 'Dialog.FileUploadDialog.Upload' | translate }}
    </button>
  </ng-container>
</div>
