import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/*
 *  Injects ngsw-bypass header to bypass service worker caching.
 */
@Injectable()
export class CacheBypassInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var returnReq = req;

    if (!req.headers.has('ngsw-bypass') && !req.url.includes('/realms/contracto')) {
      // By default cache is disabled for all http calls.
      // Also does not append header for keycloak requests, otherwise a CORS error is returned
      returnReq = req.clone({
        headers: req.headers.append('ngsw-bypass', 'true'),
      });
    } else if (req.headers.get('ngsw-bypass') == 'false') {
      // If ngsw-bypass is set to false we remove the header because the service worker only check's if the header exists.
      returnReq = req.clone({
        headers: req.headers.delete('ngsw-bypass'),
      });
    }

    return next.handle(returnReq);
  }
}
