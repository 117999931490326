<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'Invoice.ServiceProviders.Header' | translate }}</h1>
  </div>
  <div class="header-toolbar">
    <button [downloadFile]="excelDownloadLink" target="_blank" class="btn btn-xs btn-success">
      {{ 'Global.ExcelExport' | translate }}
    </button>
  </div>
</div>
<app-data-table id="invoiceServiceProviders"></app-data-table>
