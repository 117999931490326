import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-emails-dialog',
  templateUrl: './emails-dialog.component.html',
  styleUrls: ['./emails-dialog.component.css'],
})
export class EmailsDialogComponent implements OnInit {
  public eventLogId?: number;
  public baseFilter?: string;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {
    if (this.eventLogId) {
      this.baseFilter = `eventLogId==${this.eventLogId}`;
    }
  }

  public onClose(): void {
    this.modalRef.hide();
  }

  static showDialog(modalService: BsModalService, eventLogId: number): BsModalRef<EmailsDialogComponent> {
    var modalRef = modalService.show(EmailsDialogComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        eventLogId: eventLogId,
      },
    });

    return modalRef;
  }
}
