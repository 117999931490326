<div>
  <div *ngIf="!showHtml">{{ showMore ? text : (text | slice: 0 : maxTextLength) }}</div>
  <div
    *ngIf="showHtml"
    style="display: block"
    [innerHtml]="
      showMore
        ? (text | nullEmptyString | newlineBr | safeHtml)
        : (text | nullEmptyString | slice: 0 : maxTextLength | newlineBr | safeHtml)
    "
    routeTransformer></div>
  <span *ngIf="showControls && !showMore">... </span>
  <a href="javascript:;" *ngIf="showControls && !showMore" (click)="showMore = true">{{
    'Global.ShowMore' | translate
  }}</a>
</div>
