<ng-container *ngFor="let navItem of items">
  <ng-container
    *ngIf="
      (!navItem.requiredRoles || (accountService.hasAllRoles(navItem.requiredRoles) | async)) &&
      (!navItem.anyRoles || (accountService.hasAnyRole(navItem.anyRoles) | async)) &&
      (!navItem.forbiddenRoles || !(accountService.hasAnyRole(navItem.forbiddenRoles) | async))
    ">
    <ng-container *ngIf="!navItem.type">
      <ng-container *ngTemplateOutlet="sidebarItem; context: { item: navItem }"></ng-container>
    </ng-container>

    <div *ngIf="navItem.type == 'caption'">
      <span *ngIf="navItem.translateTitle" class="fadeable pl-3">{{ navItem.title | translate }}</span>
      <span *ngIf="!navItem.translateTitle" class="fadeable pl-3">{{ navItem.title }}</span>
      <span class="fadeinable mt-n2 text-125">&hellip;</span>
    </div>

    <li *ngIf="navItem.type == 'divider'" class="dropdown-divider"></li>
  </ng-container>
</ng-container>

<ng-template #sidebarItem let-navItem="item">
  <div
    class="nav-item"
    [ngClass]="{ open: navItem.open, 'is-toggling': navItem.isToggling }"
    [routerLinkActive]="navItem.link ? 'active' : ''">
    <!-- if item has no children -->
    <a
      *ngIf="!navItem.hasChildren"
      [routerLink]="getRouterLink(navItem)"
      (click)="onLinkClick(navItem)"
      class="nav-link">
      <i *ngIf="navItem.icon" class="nav-icon {{ navItem.icon }}"></i>

      <span class="nav-text" [ngClass]="{ fadeable: navItem.isFirstLevel }">
        <span *ngIf="navItem.translateTitle" class="sidebar-menu-title">{{ navItem.title | translate }}</span>
        <span *ngIf="!navItem.translateTitle" class="sidebar-menu-title">{{ navItem.title }}</span>

        <span
          *ngIf="navItem.badge"
          class="badge {{ navItem['badge-class'] }}"
          placement="right"
          tooltip="{{ navItem.tooltip }}"
          tooltipClass="ng-custom-tooltip {{ navItem['tooltip-class'] }}"
          container="body">
          {{ navItem.badge }}
        </span>
      </span>
    </a>

    <!-- if item has children -->
    <ng-container *ngIf="navItem.hasChildren">
      <a
        (click)="toggleSubmenu(navItem, navItemSub)"
        class="nav-link dropdown-toggle"
        [ngClass]="{ collapsed: !navItem.open }"
        [attr.aria-expanded]="navItem.open">
        <i *ngIf="navItem.icon" class="nav-icon {{ navItem.icon }}"></i>

        <span class="nav-text" [ngClass]="{ fadeable: navItem.isFirstLevel }">
          <span *ngIf="navItem.translateTitle">{{ navItem.title | translate }}</span>
          <span *ngIf="!navItem.translateTitle">{{ navItem.title }}</span>

          <span
            *ngIf="navItem.badge"
            class="badge {{ navItem['badge-class'] }}"
            placement="right"
            tooltip="{{ navItem.tooltip }}"
            tooltipClass="ng-custom-tooltip {{ navItem['tooltip-class'] }}"
            container="body">
            {{ navItem.badge }}
          </span>
        </span>

        <b class="caret fa fa-angle-left rt-n90"></b>
      </a>

      <div
        class="submenu collapse"
        [ngClass]="{ hideable: navItem.isFirstLevel }"
        [collapse]="collapseSubmenu(navItem.open)"
        (shown)="navItem.isToggling = false"
        (hidden)="navItem.isToggling = false">
        <app-sidebar-submenu
          NgAceSubmenu
          #navItemSub="NgAceSubmenu"
          class="submenu-inner"
          [items]="navItem.children"></app-sidebar-submenu>
      </div>
    </ng-container>

    <!-- sub arrow -->
    <b class="sub-arrow" *ngIf="navItem.hasChildren || navItem.isFirstLevel"></b>
  </div>
</ng-template>
