<input
  type="text"
  [formControl]="control"
  class="form-control mr-sm-2"
  placeholder="{{ 'Global.BESR' | translate }}"
  mask="00 00000 00000 00000 00000 00000"
  [showMaskTyped]="true"
  [keepCharacterPositions]="true"
  placeHolderCharacter="#"
  (paste)="onPasteBesr($event)"
  (focus)="onFocusBesr($event)" />
