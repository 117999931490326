<form *ngIf="config.editInvoice" class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{
      'Dialog.EditInvoiceSessionContainerComponent.SessionDate' | translate
    }}</label>
    <div class="col-sm-4">
      <input
        #sessionDateInput="bsDatepicker"
        type="text"
        formControlName="date"
        class="form-control"
        placeholder="{{ 'Dialog.EditInvoiceSessionContainerComponent.SessionDate' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }"
        [minDate]="config.minDate"
        [maxDate]="config.maxDate" />
    </div>
    <div class="col-sm-6">
      <div *ngIf="f.date.invalid && (f.date.dirty || f.date.touched)" class="alert alert-danger">
        <div *ngIf="f.date.errors?.required">
          {{ 'Dialog.EditInvoiceSessionContainerComponent.Errors.SessionDateRequired' | translate }}
        </div>
      </div>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-sm-6">
    <div class="page-header">
      <h1 *ngIf="config.editSurchargePositions">
        {{ 'Dialog.EditInvoiceSessionContainerComponent.Surcharges' | translate }} ({{ config.catalogTitle }})
      </h1>
      <h1 *ngIf="config.editTariffPositions">
        {{ 'Dialog.EditInvoiceSessionContainerComponent.Performances' | translate }} ({{ config.catalogTitle }})
      </h1>
      <button (click)="toggleGetMostUsed()" class="btn btn-xs btn-success">
        <div *ngIf="getMostUsed">{{ 'Dialog.EditInvoiceSessionContainerComponent.ShowAllPositions' | translate }}</div>
        <div *ngIf="!getMostUsed">
          {{ 'Dialog.EditInvoiceSessionContainerComponent.MostUsedPositions' | translate }}
        </div>
      </button>
    </div>
    <app-data-table
      id="invoiceSessionSurchargePositions"
      #surchargePositions
      *ngIf="config.editSurchargePositions"></app-data-table>
    <app-data-table
      id="invoiceSessionTariffPositions"
      #tariffPositions
      *ngIf="config.editTariffPositions"></app-data-table>
  </div>
  <div class="col-sm-6">
    <div class="page-header">
      <h1 *ngIf="config.editSurchargePositions">
        {{ 'Dialog.EditInvoiceSessionContainerComponent.SelectedSurcharges' | translate }}
      </h1>
      <h1 *ngIf="config.editTariffPositions">
        {{ 'Dialog.EditInvoiceSessionContainerComponent.SelectedPerformances' | translate }}
      </h1>
    </div>
    <app-data-table #invoiceSurchargePositions *ngIf="config.editSurchargePositions"></app-data-table>
    <app-data-table #invoiceTariffPositions *ngIf="config.editTariffPositions"></app-data-table>
  </div>
</div>
