import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractCompensationTypeTransform',
})
export class ContractCompensationTypeTransformPipe implements PipeTransform {
  public static contractCompensationTypeMap = new Map([
    [0, 'Contract.CompensationType.Factor'],
    [1, 'Contract.CompensationType.TaxPointValue'],
    [2, 'Contract.CompensationType.FixedPrice'],
  ]);

  transform(contractCompensationType?: number): string {
    if (
      contractCompensationType != undefined &&
      ContractCompensationTypeTransformPipe.contractCompensationTypeMap.has(contractCompensationType)
    ) {
      var contractCompensationTypeText =
        ContractCompensationTypeTransformPipe.contractCompensationTypeMap.get(contractCompensationType);
      return contractCompensationTypeText ? contractCompensationTypeText : '';
    } else {
      return '';
    }
  }
}
