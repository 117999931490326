<div class="modal-header">
  <h4 class="modal-title pull-left">{{ dialogConfig.dialogTitle | translateObject }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="dialogConfig.dialogText?.text">
    <div [innerHTML]="dialogConfig.dialogText | translateObject"></div>
    <br />
  </div>
  <form class="form-horizontal" [formGroup]="form" autocomplete="off">
    <div class="form-group row" *ngFor="let field of dialogConfig.fields">
      <label [ngClass]="labelClass">{{ field.label | translateObject }}</label>
      <div [ngClass]="inputClass">
        <input
          *ngIf="field.uiType == 'text'"
          type="text"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          [maxlength]="field.maxlength" />
        <textarea
          *ngIf="field.uiType == 'textarea'"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          [rows]="field.textareaRows"
          [maxlength]="field.maxlength"></textarea>
        <input
          *ngIf="field.uiType == 'mask'"
          type="text"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          [mask]="field.mask"
          [specialCharacters]="field.specialCharacters" />
        <input
          *ngIf="field.uiType == 'number'"
          type="number"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          [step]="field.step"
          [min]="field.min"
          [max]="field.max" />
        <input
          *ngIf="field.uiType == 'date'"
          type="text"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          bsDatepicker
          [bsConfig]="{ adaptivePosition: true }"
          [minDate]="field.minDate"
          [maxDate]="field.maxDate" />
        <input
          *ngIf="field.uiType == 'dateRange'"
          type="text"
          [formControlName]="field.name"
          class="form-control"
          placeholder="{{ field.label | translateObject }}"
          bsDaterangepicker
          [bsConfig]="{ adaptivePosition: true }"
          [minDate]="field.minDate"
          [maxDate]="field.maxDate" />
        <div
          *ngIf="field.required && f[field.name].invalid && (f[field.name].dirty || f[field.name].touched)"
          class="alert alert-danger">
          <div *ngIf="f[field.name].errors?.required">{{ field.requiredErrorText | translateObject }}</div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <ng-container>
    <button *ngIf="dialogConfig.cancelButtonText" type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ dialogConfig.cancelButtonText | translateObject }}
    </button>
    <button
      *ngIf="dialogConfig.confirmButtonText"
      type="button"
      class="btn btn-secondary"
      (click)="onConfirm()"
      [disabled]="form.invalid">
      {{ dialogConfig.confirmButtonText | translateObject }}
    </button>
  </ng-container>
</div>
