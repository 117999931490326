import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditCatalogViewContainerColumnConfig } from './edit-catalogview-contrainer-column-config';

@Component({
  selector: 'app-edit-catalogview-container',
  templateUrl: './edit-catalogview-container.component.html',
  styleUrls: ['./edit-catalogview-container.component.css'],
})
export class EditCatalogViewContainerComponent implements IRowEditContainer<CatalogViewDto, number, any, any> {
  dialog!: RowEditDialogComponent<CatalogViewDto, number, any, any>;
  data!: CatalogViewDto;
  initialData?: CatalogViewDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: () => Promise<boolean> = this.preConfirm;

  public textColumns = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  ];

  public columns: Array<EditCatalogViewContainerColumnConfig> = [];
  public invoiceColumns: Array<EditCatalogViewContainerColumnConfig> = [
    {
      label: 'Type',
      labelTranslate: false,
      valueProperty: 'type',
      nameProperty: 'type_Name',
      hideProperty: 'type_Hide',
      widthProperty: 'type_Width',
    },
    {
      label: 'Title',
      labelTranslate: false,
      valueProperty: 'title',
      nameProperty: 'title_Name',
      hideProperty: 'title_Hide',
      widthProperty: 'title_Width',
    },
    {
      label: 'Interpretation',
      labelTranslate: false,
      valueProperty: 'interpretation',
      nameProperty: 'interpretation_Name',
      hideProperty: 'interpretation_Hide',
      widthProperty: 'interpretation_Width',
    },
    {
      label: 'Value',
      labelTranslate: false,
      valueProperty: 'value',
      nameProperty: 'value_Name',
      hideProperty: 'value_Hide',
      widthProperty: 'value_Width',
    },
    {
      label: 'FeeKindMultiplier',
      labelTranslate: false,
      valueProperty: 'feeKindMultiplier',
      nameProperty: 'feeKindMultiplier_Name',
      hideProperty: 'feeKindMultiplier_Hide',
      widthProperty: 'feeKindMultiplier_Width',
    },
    {
      label: 'SurchargeMultiplier',
      labelTranslate: false,
      valueProperty: 'surchargeMultiplier',
      nameProperty: 'surchargeMultiplier_Name',
      hideProperty: 'surchargeMultiplier_Hide',
      widthProperty: 'surchargeMultiplier_Width',
    },
    {
      label: 'DontShowInInvoice',
      labelTranslate: false,
      valueProperty: 'dontShowInInvoice',
      nameProperty: 'dontShowInInvoice_Name',
      hideProperty: 'dontShowInInvoice_Hide',
      widthProperty: 'dontShowInInvoice_Width',
    },
    {
      label: 'MaxSurchargeCount',
      labelTranslate: false,
      valueProperty: 'maxSurchargeCount',
      nameProperty: 'maxSurchargeCount_Name',
      hideProperty: 'maxSurchargeCount_Hide',
      widthProperty: 'maxSurchargeCount_Width',
    },
  ];

  constructor(private translate: TranslateService) {}

  initializeContainer(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.data.title, []),
      description: new UntypedFormControl(this.data.description, []),
      additionalInfo: new UntypedFormControl(this.data.additionalInfo, []),
      isActive: new UntypedFormControl(this.data.isActive, []),
      catalogViewOrder: new UntypedFormControl(this.data.catalogViewOrder, [
        Validators.min(0),
        Validators.max(2147483647),
      ]),
      table_Width: new UntypedFormControl(this.data.table_Width, []),
      filter: new UntypedFormControl(this.data.filter, []),
      defaultSort: new UntypedFormControl(this.data.defaultSort, []),
      validFrom_Show: new UntypedFormControl(this.data.validFrom_Show, []),
      validUntil_Show: new UntypedFormControl(this.data.validUntil_Show, []),
    });

    if (this.data.catalogIsInvoiceCatalog) {
      this.columns.push(...this.invoiceColumns);

      this.columns.forEach((column) => {
        if (column.nameProperty) {
          this.form.addControl(column.nameProperty, new UntypedFormControl(this.data[column.nameProperty], []));
        }

        if (column.hideProperty) {
          this.form.addControl(column.hideProperty, new UntypedFormControl(this.data[column.hideProperty], []));
        }

        if (column.widthProperty) {
          this.form.addControl(column.widthProperty, new UntypedFormControl(this.data[column.widthProperty], []));
        }
      });
    }

    var columnPrefix = this.translate.instant('Catalog.EditCatalogView.ColumnPrefix');

    this.textColumns.forEach((index) => {
      let valueProperty = this.getColumnName(index, '');
      let nameProperty = this.getColumnName(index, '_Name');
      let hideProperty = this.getColumnName(index, '_Hide');
      let widthProperty = this.getColumnName(index, '_Width');

      this.columns.push({
        label: `${columnPrefix} ${index}`,
        labelTranslate: false,
        valueProperty: valueProperty,
        nameProperty: nameProperty,
        hideProperty: hideProperty,
        widthProperty: widthProperty,
      });

      var validators = [];

      // Column01 is the unique id and must be entered.
      if (index == 1) {
        validators.push(Validators.required);
      }

      this.form.addControl(nameProperty, new UntypedFormControl(this.data[nameProperty], validators));
      this.form.addControl(hideProperty, new UntypedFormControl(this.data[hideProperty], []));
      this.form.addControl(widthProperty, new UntypedFormControl(this.data[widthProperty], []));
    });

    // Order columns on the ui regarding the defined column order in the database.
    if (this.data.columnOrder) {
      this.data.columnOrder.forEach((name, position) => {
        var columnIndex = this.columns.findIndex((x) => x.valueProperty == name);
        moveItemInArray(this.columns, columnIndex, position);
      });
    }

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  getFormControl(name: string) {
    return this.form.controls[name];
  }

  getColumnName(columnIndex: number, suffix: string): string {
    return `column${columnIndex.toString().padStart(2, '0')}${suffix}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  preConfirm(): Promise<boolean> {
    // Update column order in data before saving.
    return new Promise<any>((resolve) => {
      this.data.columnOrder = [];

      this.columns.forEach((column) => {
        this.data.columnOrder.push(column.valueProperty);
      });

      resolve(true);
    });
  }
}
