<ng-template #factorTemplate let-baseFactor="baseFactor" let-displayFactor="displayFactor">
  <label class="col-form-label" *ngIf="baseFactor !== null">
    {{ displayFactor }}
  </label>
  <label class="col-form-label" *ngIf="baseFactor === undefined && config?.showChangeCheckbox">
    {{ 'Dialog.EditInvoiceInsuranceContainerComponent.FactorsDifferently' | translate }}
  </label>
  <label class="col-form-label" *ngIf="baseFactor === null">
    {{ 'Dialog.EditInvoiceInsuranceContainerComponent.NoBaseFactor' | translate }}
  </label>
</ng-template>

<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.ValidFrom' | translate }}</label>
    <div class="col-sm-3">
      <input
        type="text"
        formControlName="validFrom"
        class="form-control"
        placeholder="{{ 'Global.ValidFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }"
        (bsValueChange)="changeDateValueEvent($event, 'changeCheckboxes.changeValidFrom', initialData?.validFrom)" />
      <div *ngIf="f.validFrom.invalid && (f.validFrom.dirty || f.validFrom.touched)" class="alert alert-danger">
        <div *ngIf="f.validFrom.errors?.required">{{ 'Global.Errors.ValidFromRequired' | translate }}</div>
        <div *ngIf="f.validFrom.errors?.dateIsAfter">
          {{ 'Global.Errors.ValidFromMustBeBeforeValidUntil' | translate }}
        </div>
      </div>
    </div>
    <div class="col-sm-6" *ngIf="config?.showChangeCheckbox" formGroupName="changeCheckboxes">
      <label class="col-form-label">
        <input
          type="checkbox"
          formControlName="changeValidFrom"
          (change)="onChangeCheckboxEvent($event, 'validFrom', initialData?.validFrom)" />
        <label *ngIf="changeCheckboxes.changeValidFrom.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillBeChanged' | translate
        }}</label>
        <label *ngIf="!changeCheckboxes.changeValidFrom.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillNotBeChanged' | translate
        }}</label>
      </label>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.ValidUntil' | translate }}</label>
    <div class="col-sm-3">
      <input
        type="text"
        formControlName="validUntil"
        class="form-control"
        placeholder="{{ 'Global.ValidUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }"
        (bsValueChange)="changeDateValueEvent($event, 'changeCheckboxes.changeValidUntil', initialData?.validUntil)" />
      <div *ngIf="f.validUntil.invalid && (f.validUntil.dirty || f.validUntil.touched)" class="alert alert-danger">
        <div *ngIf="f.validUntil.errors?.dateIsBefore">
          {{ 'Global.Errors.ValidUntilMustBeAfterValidFrom' | translate }}
        </div>
      </div>
    </div>
    <div class="col-sm-6" *ngIf="config?.showChangeCheckbox" formGroupName="changeCheckboxes">
      <label class="col-form-label">
        <input
          type="checkbox"
          formControlName="changeValidUntil"
          (change)="onChangeCheckboxEvent($event, 'validUntil', initialData?.validUntil)" />
        <label *ngIf="changeCheckboxes.changeValidUntil.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillBeChanged' | translate
        }}</label>
        <label *ngIf="!changeCheckboxes.changeValidUntil.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillNotBeChanged' | translate
        }}</label>
      </label>
    </div>
  </div>

  <div class="form-group row" *ngIf="enableIndividualFactor">
    <label class="col-sm-3 col-form-label">{{ 'Global.IndividualFactor' | translate }}</label>
    <div class="col-sm-3">
      <select
        class="form-control"
        formControlName="individualCompensationId"
        (change)="
          changeNumberValueEvent(
            $event,
            'changeCheckboxes.changeIndividualCompensationId',
            initialData?.individualCompensationId
          );
          calculateFactors()
        ">
        <option *ngFor="let option of this.individualCompensations" [ngValue]="option.id">
          {{ option.value }}
        </option>
      </select>
    </div>
    <div class="col-sm-6" *ngIf="config?.showChangeCheckbox" formGroupName="changeCheckboxes">
      <label class="col-form-label">
        <input
          type="checkbox"
          formControlName="changeIndividualCompensationId"
          (change)="onChangeCheckboxEvent($event, 'individualCompensationId', initialData?.individualCompensationId)" />
        <label *ngIf="changeCheckboxes.changeIndividualCompensationId.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillBeChanged' | translate
        }}</label>
        <label *ngIf="!changeCheckboxes.changeIndividualCompensationId.value">{{
          'Dialog.EditInvoiceInsuranceContainerComponent.FieldWillNotBeChanged' | translate
        }}</label>
      </label>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{
      'Global.VVGFactorP1WithCompensationType'
        | translate: { compensationType: data.compensationType | contractCompensationTypeTransform | translate }
    }}</label>
    <div class="col-sm-9">
      <ng-container
        *ngTemplateOutlet="
          factorTemplate;
          context: { baseFactor: data.factorP1, displayFactor: data.factorP1 }
        "></ng-container>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{
      'Global.VVGFactorP2WithCompensationType'
        | translate: { compensationType: data.compensationType | contractCompensationTypeTransform | translate }
    }}</label>
    <div class="col-sm-9">
      <ng-container
        *ngTemplateOutlet="
          factorTemplate;
          context: { baseFactor: data.factorP2, displayFactor: data.factorP2 }
        "></ng-container>
    </div>
  </div>

  <div class="form-group row" *ngIf="this.enableIndividualFactor">
    <label class="col-sm-3 col-form-label">{{ 'Global.OverallFactorP1' | translate }}</label>
    <div class="col-sm-9">
      <ng-container
        *ngTemplateOutlet="
          factorTemplate;
          context: { baseFactor: data.factorP1, displayFactor: data.overallFactorP1 }
        "></ng-container>
    </div>
  </div>

  <div class="form-group row" *ngIf="this.enableIndividualFactor">
    <label class="col-sm-3 col-form-label">{{ 'Global.OverallFactorP2' | translate }}</label>
    <div class="col-sm-9">
      <ng-container
        *ngTemplateOutlet="
          factorTemplate;
          context: { baseFactor: data.factorP2, displayFactor: data.overallFactorP2 }
        "></ng-container>
    </div>
  </div>
</form>
