import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutError } from 'rxjs';

import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { replaceNewLineWithBr } from '@app/_helpers/functions/string-functions';
import { CommandResult } from '@app/_models/command/commandResult';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private accountService: AccountService,
    private applicationService: ApplicationService,
  ) {}

  currentModalRef?: BsModalRef<TextDialogComponent>;

  displayErrorDialog(errorResponse: HttpErrorResponse): Promise<boolean> | undefined {
    return new Promise<boolean>((resolve) => {
      if (errorResponse.error && errorResponse.error instanceof Blob) {
        errorResponse.error.text().then((textError) => {
          if (errorResponse.error.type == 'application/json') {
            this.displayErrorDialogInternal(errorResponse, JSON.parse(textError), resolve);
          } else {
            this.displayErrorDialogInternal(errorResponse, textError, resolve);
          }
        });
      } else {
        this.displayErrorDialogInternal(errorResponse, errorResponse.error, resolve);
      }
    });
  }

  private displayErrorDialogInternal(
    errorResponse: HttpErrorResponse,
    error: any,
    resolve: (value: boolean | PromiseLike<boolean>) => void,
  ): void {
    if (
      errorResponse.status == 0 ||
      errorResponse.status == 408 ||
      errorResponse.status == 503 ||
      errorResponse instanceof TimeoutError
    ) {
      return this.showBackendOfflineDialog(resolve);
    } else if (errorResponse.status == 400) {
      if (error) {
        if (error.constructor === Array) {
          var errorText = this.errorsToHtmlList(error.map((x) => x.description));
          return this.showErrorDialog(errorText, resolve);
        } else if (error.constructor === Object) {
          var errorText = this.errorsToHtmlList([error.description]);
          return this.showErrorDialog(errorText, resolve);
        } else if (error.constructor === String) {
          var errorText = this.errorsToHtmlList([error]);
          return this.showErrorDialog(errorText, resolve);
        }
      }
    } else if (errorResponse.status == 403) {
      if (errorResponse.url?.endsWith('/catalog/views/user') || errorResponse.url?.endsWith('/employee/usergroups')) {
        this.applicationService.currentApplicationStatus$.subscribe((appStatus) => {
          if (appStatus.userLockoutEnabled) {
            // app is locked
            return this.showUserLockoutDialog(appStatus.lockoutText ?? '', resolve);
          }
          // 403 in user catalogs or groups means user is locked out or not approved yet
          return this.showUserLockoutDialog(undefined, resolve);
        });
      } else {
        return this.showForbiddenDialog(resolve);
      }
    } else if (errorResponse.status == 429) {
      var retryAfter = Number(errorResponse.headers.get('Retry-After'));
      return this.showTooManyRequestsDialog(retryAfter, resolve);
    } else if (errorResponse.status == 404 || errorResponse.status == 405 || errorResponse.status == 500) {
      return this.showInternalServerErrorDialog(error, resolve);
    }

    return undefined;
  }

  errorsToHtmlList(errors: any[]): string {
    var errorText = '';

    for (let error of errors) {
      errorText += `<li>${replaceNewLineWithBr(error)}</li>`;
      console.log(error);
    }

    return `<ol style=\"list-style: disc;\">${errorText}</ol>`;
  }

  displayWarningDialog(
    commandResult: CommandResult,
    textBottom: string | undefined = undefined,
    okLabel?: string,
  ): Promise<BsModalRef<TextDialogComponent>> {
    var text = '';
    for (let warningId in commandResult.warnings) {
      var warning = commandResult.warnings[warningId];
      text += '<li>' + warning.description + '</li>';
    }

    if (textBottom) {
      text += '<br>' + textBottom;
    }

    return this.showWarningDialog(text, okLabel);
  }

  checkErrorResponseForErrorCode(errorResponse: HttpErrorResponse, errorCode: string): boolean {
    if (errorResponse.status == 400) {
      if (errorResponse.error) {
        if (errorResponse.error.constructor === Array) {
          for (let errorId in errorResponse.error) {
            var error = errorResponse.error[errorId];

            if (error.code === errorCode) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  handleDialogResult(
    modalRef: BsModalRef<TextDialogComponent>,
    resolve: (value: boolean | PromiseLike<boolean>) => void,
  ): void {
    this.currentModalRef = modalRef;

    if (modalRef.content) {
      modalRef.content.onClose.subscribe((result) => {
        this.currentModalRef = undefined;
        resolve(true);
      });
    }
  }

  showErrorDialog(errorMessage: string, resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.BadRequestTitle',
      undefined,
      'Global.Ok',
      undefined,
      errorMessage,
      'modal-600',
    ).then((modalRef) => {
      this.handleDialogResult(modalRef, resolve);
    });
  }

  showWarningDialog(warningMessage: string, okLabel?: string): Promise<BsModalRef<TextDialogComponent>> {
    return TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.WarningTitle',
      undefined,
      okLabel ?? 'Global.Ok',
      'Global.Cancel',
      warningMessage,
      'modal-600',
    );
  }

  showForbiddenDialog(resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.BadRequestTitle',
      'ErrorHandler.ForbiddenText',
      'Global.Ok',
      undefined,
    ).then((modalRef) => {
      this.handleDialogResult(modalRef, resolve);
    });
  }

  showUserLockoutDialog(message: string | undefined, resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.UserLockoutTitle',
      message ? undefined : 'ErrorHandler.UserLockoutText',
      'Nav.Links.Logout',
      undefined,
      message ?? '',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((result) => {
        this.currentModalRef = undefined;
        resolve(true);
        this.accountService.logout();
      });
    });
  }

  showBackendOfflineDialog(resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.BackendOfflineErrorTitle',
      'ErrorHandler.BackendOfflineErrorText',
      'Global.Ok',
      undefined,
    ).then((modalRef) => {
      this.handleDialogResult(modalRef, resolve);
    });
  }

  showTooManyRequestsDialog(retryAfter: number, resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'ErrorHandler.TooManyRequestsErrorTitle',
      'ErrorHandler.TooManyRequestsErrorText',
      'Global.Ok',
      undefined,
      undefined,
      undefined,
      { retryAfter: retryAfter },
    ).then((modalRef) => {
      this.handleDialogResult(modalRef, resolve);
    });
  }

  showInternalServerErrorDialog(error: string, resolve: (value: boolean | PromiseLike<boolean>) => void): void {
    if (this.currentModalRef) {
      resolve(false);
      return;
    }

    if (error) {
      TextDialogComponent.showDialogTranslated(
        this.translate,
        this.modalService,
        'ErrorHandler.InternalServerErrorDevTitle',
        undefined,
        'Global.Ok',
        undefined,
        error,
        'modal-lg',
      ).then((modalRef) => {
        this.handleDialogResult(modalRef, resolve);
      });
    } else {
      TextDialogComponent.showDialogTranslated(
        this.translate,
        this.modalService,
        'ErrorHandler.InternalServerErrorProdTitle',
        'ErrorHandler.InternalServerErrorProdText',
        'Global.Ok',
        undefined,
      ).then((modalRef) => {
        this.handleDialogResult(modalRef, resolve);
      });
    }
  }
}
