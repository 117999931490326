import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FileUploadDialogComponent } from '@app/_dialogs/file-upload-dialog/file-upload-dialog.component';
import { InfoLinkDto } from '@app/_models/infoLinkDto';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InfoLinkService } from '@app/_services/infolink.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  public infoLinks!: InfoLinkDto[];

  constructor(
    private infoLinkService: InfoLinkService,
    public accountService: AccountService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  refreshView(): void {
    this.loadData();
  }

  loadData(): void {
    this.infoLinkService.getInfoLinksForUser().subscribe(
      (result) => {
        this.infoLinks = result;
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  openFileUploadDialog() {
    this.modalService.show(FileUploadDialogComponent, {
      class: 'modal-600',
    });
  }
}
