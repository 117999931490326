import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { UserContractDto } from '@app/_models/userContractDto';
import { UserContractsRequestDto } from '@app/_models/userContractsRequestDto';
import { BaseApiService } from './baseApi.service';

@Injectable({
  providedIn: 'root',
})
export class UserContractService extends BaseApiService {
  private currentUserSource = new ReplaySubject<UserContractDto>(1);
  currentUser$ = this.currentUserSource.asObservable();

  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('usercontract');
  }

  getUserContracts(userId?: string): Observable<UserContractDto[]> {
    var path = '/contracts';
    if (userId) {
      path = `/${userId}/contracts`;
    }
    return this.http.get<UserContractDto[]>(this.baseControllerUrl + path);
  }

  validateApplyUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/apply/validate', userContractsRequestDto);
  }

  validateConfirmUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/confirm/validate', userContractsRequestDto);
  }

  validateUserDeclineUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/decline/validate', userContractsRequestDto);
  }

  validateTerminateUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/terminate/validate', userContractsRequestDto);
  }

  applyUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/apply', userContractsRequestDto);
  }

  confirmUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/confirm', userContractsRequestDto);
  }

  userDeclineUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/decline', userContractsRequestDto);
  }

  terminateUserContracts(userContractsRequestDto: UserContractsRequestDto): Observable<CommandResult> {
    return this.http.post<any>(this.baseControllerUrl + '/terminate', userContractsRequestDto);
  }
}
