<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ValidFrom' | translate }}</label>
    <div class="col-sm-2">
      <input
        type="text"
        formControlName="validFrom"
        class="form-control"
        placeholder="{{ 'Global.ValidFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }"
        [minDate]="minValidFrom" />
      <div *ngIf="f.validFrom.invalid && (f.validFrom.dirty || f.validFrom.touched)" class="alert alert-danger">
        <div *ngIf="f.validFrom.errors?.required">{{ 'Global.Errors.DateIsRequired' | translate }}</div>
        <div *ngIf="f.validFrom.errors?.dateIsAfter">
          {{ 'Global.Errors.ValidFromMustBeBeforeValidUntil' | translate }}
        </div>
        <div *ngIf="f.validFrom.errors?.bsDate?.minDate">
          {{ 'Global.Errors.ValidFromMustBeAfterValidUntilOfParentEntry' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ValidUntil' | translate }}</label>
    <div class="col-sm-2">
      <input
        type="text"
        formControlName="validUntil"
        class="form-control"
        placeholder="{{ 'Global.ValidUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div *ngIf="f.validUntil.invalid && (f.validUntil.dirty || f.validUntil.touched)" class="alert alert-danger">
        <div *ngIf="f.validUntil.errors?.dateIsBefore">
          {{ 'Global.Errors.ValidUntilMustBeAfterValidFrom' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let tableColumn of config?.columns" class="form-group row">
    <label class="col-sm-4 col-form-label">{{
      tableColumn.headerTranslate ? (tableColumn.header | translate) : tableColumn.header
    }}</label>
    <div class="col-sm-8" *ngIf="tableColumn.dataType == 'string'">
      <input
        *ngIf="tableColumn.max == 240"
        type="text"
        [formControlName]="tableColumn.property"
        class="form-control"
        [placeholder]="tableColumn.headerTranslate ? (tableColumn.header | translate) : tableColumn.header"
        [attr.maxlength]="tableColumn.max" />
      <textarea
        *ngIf="tableColumn.max != 240"
        type="text"
        rows="2"
        [formControlName]="tableColumn.property"
        class="form-control"
        [placeholder]="tableColumn.headerTranslate ? (tableColumn.header | translate) : tableColumn.header"
        [attr.maxlength]="tableColumn.max"></textarea>
      <div
        *ngIf="
          getColumnFormControl(tableColumn.property).invalid &&
          (getColumnFormControl(tableColumn.property).dirty || getColumnFormControl(tableColumn.property).touched)
        "
        class="alert alert-danger">
        <div *ngIf="getColumnFormControl(tableColumn.property).errors?.required">
          {{ 'Catalog.Errors.ColumnRequired' | translate }}
        </div>
      </div>
    </div>
    <div class="col-sm-8" *ngIf="tableColumn.dataType == 'number'">
      <input
        type="number"
        step="0.01"
        [min]="tableColumn.min"
        [max]="tableColumn.max"
        [formControlName]="tableColumn.property"
        class="form-control"
        [placeholder]="tableColumn.headerTranslate ? (tableColumn.header | translate) : tableColumn.header" />
    </div>
    <div class="col-sm-8" *ngIf="tableColumn.dataType == 'boolean'">
      <input type="checkbox" [formControlName]="tableColumn.property" class="form-control" />
    </div>
    <div class="col-sm-8" *ngIf="tableColumn.dataType == 'enum'">
      <select *ngIf="tableColumn.valueMap" [formControlName]="tableColumn.property" class="form-control">
        <option *ngFor="let item of tableColumn.valueMap | keyvalue" [ngValue]="item.key">
          {{ item.value | translate }}
        </option>
      </select>
    </div>
  </div>
</form>
