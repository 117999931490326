import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';

/*
  Handles routerlinks and openPdf events from raw html content within [innerHtml].
*/
@Directive({
  selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
  constructor(
    private el: ElementRef,
    private router: Router,
    private readonly httpClient: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) {}

  @HostListener('click', ['$event'])
  public onClick(event: PointerEvent) {
    if (event && event.target && event.target instanceof HTMLElement && event.target.tagName === 'A') {
      if (event.target.hasAttribute('routerlink')) {
        var targetUrl = event.target.getAttribute('routerlink');
        if (targetUrl) {
          this.router.navigateByUrl(targetUrl);
          event.preventDefault();
        }
      } else if (event.target.hasAttribute('openPdf')) {
        let link = event.target.getAttribute('openPdf');

        if (link) {
          // Download the document as a blob
          this.httpClient.get(link, { responseType: 'blob' }).subscribe(
            (response: any) => {
              let blob = new Blob([response], { type: 'application/pdf' });

              // Create a URL for the blob
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank')?.focus();

              // Discard the object data
              URL.revokeObjectURL(url);
            },
            async (errorResponse: HttpErrorResponse) => {
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }

        event.preventDefault();
      } else {
        return;
      }
    } else {
      return;
    }
  }
}
