import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { InvoiceSessionDto } from '@app/_models/invoiceSessionDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSessionService extends BaseTableDataService<InvoiceSessionDto, number> {
  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('invoiceSession', '', http);
  }
}
