import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InsuranceDto } from '@app/_models/insuranceDto';
import { Observable } from 'rxjs';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService extends BaseTableDataService<InsuranceDto, number> {
  constructor(protected http: HttpClient) {
    super('insurance', '', http);
  }

  getInsurances(): Observable<InsuranceDto[]> {
    return this.http.get<InsuranceDto[]>(this.baseControllerUrl + '/all');
  }
}
