import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStatusTransform',
})
export class InvoiceStatusTransformPipe implements PipeTransform {
  public static statusMap = new Map([
    [0, 'Invoice.Status.Draft'],
    [1, 'Invoice.Status.Sent'],
    [2, 'Invoice.Status.Approved'],
    [3, 'Invoice.Status.Declined'],
  ]);

  transform(status: number): string {
    if (InvoiceStatusTransformPipe.statusMap.has(status)) {
      var statusText = InvoiceStatusTransformPipe.statusMap.get(status);
      return statusText ? statusText : '';
    } else {
      return '';
    }
  }
}
