import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { CatalogItemPreEditDialogResult } from '@app/_dialogs/catalogitem-preedit-dialog/catalogitem-preedit-dialog.result';
import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { dateCompare } from '@app/_helpers/validators/date-compare.validator';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditCatalogItemConfig } from './editCatalogItemConfig';

@Component({
  selector: 'app-edit-catalogitem-container',
  templateUrl: './edit-catalogitem-container.component.html',
  styleUrls: ['./edit-catalogitem-container.component.css'],
})
export class EditCatalogItemContainerComponent
  implements IRowEditContainer<CatalogItemDto, number, any, EditCatalogItemConfig>
{
  dialog!: RowEditDialogComponent<CatalogItemDto, number, any, EditCatalogItemConfig>;
  data!: CatalogItemDto;
  initialData?: CatalogItemDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: EditCatalogItemConfig;
  preActionData?: CatalogItemPreEditDialogResult;
  preConfirmAction: undefined;
  minValidFrom?: Date;

  constructor() {}

  initializeContainer(): void {
    this.form = new UntypedFormGroup({});

    if (this.config) {
      if (!this.data) {
        if (this.initialData) {
          // We are copying an existing catalog item.
          this.data = this.initialData;
          this.data.column01 = '';
          this.data.id = 0;
          this.data.parentCatalogItemId = undefined;
        } else {
          // We are adding a new catalog item.
          this.data = <CatalogItemDto>{ catalogId: this.config?.catalogId };
          if (this.config.isInvoiceCatalog) {
            this.data.value = 0;
            this.data.type = 0;
            this.data.feeKindMultiplier = 0;
            this.data.surchargeMultiplier = 0;
            this.data.maxSurchargeCount = 0;
            this.data.dontShowInInvoice = false;
          }
        }
      } else if (this.preActionData?.editType == 'createNew') {
        // User chose to create a new entry based on the exising one.
        this.minValidFrom = new Date(this.data.validFrom.getTime() + 86400000);
        // Set parentCatalogItemId for later change tracking
        this.data.parentCatalogItemId = this.data.id;
        // Remove id because we are adding a new row when updating
        this.data.id = undefined;

        var currentDate = new Date();

        // If parent entry is in the future set the min valid date as initial value.
        if (this.minValidFrom > currentDate) {
          this.data.validFrom = this.minValidFrom;
        } else {
          this.data.validFrom = currentDate;
        }

        this.data.validUntil = undefined;
      }

      this.form.addControl(
        'validFrom',
        new UntypedFormControl(this.data.validFrom, [Validators.required, dateCompare('validUntil', false)]),
      );
      this.form.addControl(
        'validUntil',
        new UntypedFormControl(this.data.validUntil, [dateCompare('validFrom', true)]),
      );

      this.config.columns.forEach((column) => {
        var formControl = new UntypedFormControl(this.data ? this.data[column.property] : undefined, []);

        // Column01 is the unique id ans must be entered.
        if (column.property == 'column01') {
          formControl.addValidators(Validators.required);

          if (this.data.id || this.data.parentCatalogItemId) {
            // If we are adding a new item or create a new one based on an existing one we don't allow to change the unique id.
            formControl.disable();
          }
        }

        this.form.addControl(column.property, formControl);
      });
    }

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  getColumnFormControl(property: string) {
    return this.form.controls[property];
  }
}
