import { ActionParams } from './actionParams';

import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface RowActionPreActionResult {
  showEditDialog: boolean;
}

export enum RowActionEnum {
  Delete,
  Edit,
  Copy,
  Navigate,
  Custom,
}

export interface RowAction {
  action: RowActionEnum;
  text?: string;
  textTranslateParams?: any;
  tooltip?: string;
  tooltipTranslateParams?: any;
  buttonClass?: string;
  iconClass?: string;
  actionParams?: ActionParams;
  hideCondition?: (element: any) => boolean;
  preAction?: (
    element: any,
    translate: TranslateService,
    modalService: BsModalService,
  ) => Promise<RowActionPreActionResult>;
}
