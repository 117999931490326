import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { dateCompare } from '@app/_helpers/validators/date-compare.validator';
import { ContractType } from '@app/_models/enums/contractType';
import { IndividualCompensationDto } from '@app/_models/individualCompensationDto';
import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';
import { IndividualCompensationService } from '@app/_services/individualCompensation.service';
import { round } from 'lodash-es';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';
import { EditUserContractInsuranceContainerResultData } from './EditUserContractInsuranceContainerResultData';

@Component({
  selector: 'app-edit-usercontract-insurance-container',
  templateUrl: './edit-usercontract-insurance-container.component.html',
  styleUrls: ['./edit-usercontract-insurance-container.component.scss'],
})
export class EditUserContractInsuranceContainerComponent
  implements IRowEditContainer<UserContractForInsuranceDto, number, EditUserContractInsuranceContainerResultData, any>
{
  dialog!: RowEditDialogComponent<UserContractForInsuranceDto, number, any, any>;
  data!: UserContractForInsuranceDto;
  initialData?: UserContractForInsuranceDto;
  resultData!: EditUserContractInsuranceContainerResultData;
  form!: UntypedFormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  changeCheckboxesForm!: UntypedFormGroup;
  individualCompensationIdFormControl?: UntypedFormControl;
  public enableIndividualFactor: boolean = false;
  public individualCompensations?: IndividualCompensationDto[];

  constructor(public individualCompensationService: IndividualCompensationService) {}

  initializeContainer(): void {
    this.resultData = <EditUserContractInsuranceContainerResultData>{};

    if (!this.data) {
      if (this.initialData) {
        this.data = Object.assign([], this.initialData);
      } else {
        this.data = <UserContractForInsuranceDto>{};
      }
    }

    // We only allow to change the factor's if every user contract has the same contractId and is of type
    // UserMustConfirm because the contract owner must be able to confirm those changes.
    this.enableIndividualFactor = this.data.contractType == ContractType.UserMustConfirm && this.data.contractId > 0;

    this.changeCheckboxesForm = new UntypedFormGroup({
      changeValidFrom: new UntypedFormControl(this.resultData?.changeValidFrom, []),
      changeValidUntil: new UntypedFormControl(this.resultData?.changeValidUntil, []),
      changeIndividualCompensationId: new UntypedFormControl(this.resultData?.changeIndividualCompensationId, []),
    });

    this.form = new UntypedFormGroup({
      validFrom: new UntypedFormControl(this.data.validFrom, [Validators.required, dateCompare('validUntil', false)]),
      validUntil: new UntypedFormControl(this.data.validUntil, [dateCompare('validFrom', true)]),
      changeCheckboxes: this.changeCheckboxesForm,
    });

    if (this.enableIndividualFactor) {
      this.individualCompensationIdFormControl = new UntypedFormControl(this.data.individualCompensationId, [
        Validators.required,
      ]);
      this.form.addControl('individualCompensationId', this.individualCompensationIdFormControl);
    }

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        if (key != 'changeCheckboxes') {
          this.data[key] = changes[key];
        }
      }
    });

    this.changeCheckboxesForm.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.resultData[key] = changes[key];
      }
    });

    if (this.enableIndividualFactor) {
      this.dialog.loadingSubject.next(true);

      this.individualCompensationService
        .getContractIndividualCompensations(this.data.contractId)
        .subscribe((result) => {
          this.individualCompensations = result;
          this.dialog.loadingSubject.next(false);
        });
    }
  }

  onChangeCheckboxEvent(event: Event, formControlName: string, initialData: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;

    if (!isChecked) {
      this.form.get(formControlName)?.setValue(initialData);
    }
  }

  changeNumberValueEvent(event: Event, checkboxFormControlName: string, initialData: any) {
    const number = Number((<HTMLInputElement>event.target).value);

    if (number != initialData) {
      this.form.get(checkboxFormControlName)?.setValue(true);
    }
  }

  changeDateValueEvent(event: Date | undefined, checkboxFormControlName: string, initialData: Date | undefined) {
    if (event != initialData) {
      this.form.get(checkboxFormControlName)?.setValue(true);
    }
  }

  calculateFactors(): void {
    var selectedIndividualCompensationId = this.individualCompensationIdFormControl?.value as number;

    if (selectedIndividualCompensationId && this.individualCompensations) {
      var selectedIndividualCompensation = this.individualCompensations.find(
        (x) => x.id == selectedIndividualCompensationId,
      );

      if (selectedIndividualCompensation) {
        if (this.data.factorP1 && selectedIndividualCompensation) {
          this.data.overallFactorP1 = round(this.data.factorP1 * selectedIndividualCompensation.value, 3);
        }

        if (this.data.factorP2 && selectedIndividualCompensation) {
          this.data.overallFactorP2 = round(this.data.factorP2 * selectedIndividualCompensation.value, 3);
        }
      }
    }
  }

  get f() {
    return this.form.controls;
  }

  get changeCheckboxes() {
    return (this.form.get('changeCheckboxes') as UntypedFormGroup).controls;
  }
}
