import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { CustomFormDialogConfig } from './custom-form-dialog.config';
import { CustomFormDialogResult } from './custom-form-dialog.result';

@Component({
  selector: 'app-customdialog',
  templateUrl: './custom-form-dialog.component.html',
  styleUrls: ['./custom-form-dialog.component.css'],
})
export class CustomFormDialogComponent implements OnInit {
  public form!: FormGroup;

  public onClose!: Subject<CustomFormDialogResult>;

  constructor(public modalRef: BsModalRef) {}

  public dialogConfig!: CustomFormDialogConfig;
  public labelClass?: string;
  public inputClass?: string;

  data: any = {};

  ngOnInit(): void {
    this.onClose = new Subject();
    this.labelClass = `col-sm-${this.dialogConfig.labelSize} col-form-label`;
    this.inputClass = `col-sm-${this.dialogConfig.inputSize}`;

    this.form = new FormGroup({});

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });

    for (var field of this.dialogConfig.fields) {
      this.form.addControl(field.name, field.getFormControl());
    }
  }

  get f() {
    return this.form.controls;
  }

  public onConfirm(): void {
    let result: CustomFormDialogResult = { confirm: true, data: this.data };
    this.onClose.next(result);
    this.modalRef.hide();
  }

  public onCancel(): void {
    let result: CustomFormDialogResult = { confirm: false, data: undefined };
    this.onClose.next(result);
    this.modalRef.hide();
  }

  static showDialog(
    modalService: BsModalService,
    config: CustomFormDialogConfig,
  ): BsModalRef<CustomFormDialogComponent> {
    return modalService.show(CustomFormDialogComponent, {
      class: config.modalClass,
      ignoreBackdropClick: true,
      initialState: {
        dialogConfig: config,
      },
    });
  }
}
