import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.css'],
})
export class ExpandableTextComponent {
  @Input() text: string = '';
  @Input() showHtml: boolean = false;
  @Input() maxTextLength?: number = undefined;
  @Input() cutAtNewline?: boolean = false;

  public showMore: boolean = false;
  public showControls: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.cutAtNewline && this.maxTextLength && this.text) {
      var newLineIndex = this.text.indexOf('\n');

      if (newLineIndex != -1 && newLineIndex < this.maxTextLength) {
        this.maxTextLength = newLineIndex;
      }
    }

    if (this.maxTextLength && this.text) {
      this.showControls = this.text.length > this.maxTextLength;
    }
  }
}
