<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="container container-plus">
  <div class="d-flex justify-content-between">
    <div class="page-header">
      <h1>{{ 'Info.Header' | translate }}</h1>
    </div>
    <div class="header-toolbar">
      <button
        *ngIf="accountService.hasRole('FileUpload') | async"
        target="_blank"
        (click)="openFileUploadDialog()"
        class="btn btn-xs btn-success">
        {{ 'Info.UploadFiles' | translate }}
      </button>
      <button
        *ngIf="accountService.hasRole('EditInfoLinks') | async"
        target="_blank"
        routerLink="/home/info/edit"
        class="btn btn-xs btn-success">
        {{ 'Info.EditInfoLinks' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngFor="let infoLink of infoLinks">
      <span class="d-inline-block radius-square py-2 px-1 text-center">
        <i class="fa fa-info w-4 text-125 text-dark-tp3"></i>
      </span>
      <a
        *ngIf="infoLink.linkAction == 'OpenInNewTab'"
        [href]="infoLink.link"
        target="_blank"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
      <a
        *ngIf="infoLink.linkAction == 'OpenPdf'"
        href="#"
        (click)="$event.preventDefault()"
        [openPdf]="infoLink.link"
        target="_blank"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
      <a
        *ngIf="infoLink.linkAction == 'RouterLink'"
        [routerLink]="infoLink.link"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
      <a
        *ngIf="infoLink.linkAction == 'DownloadFile' && infoLink.link.startsWith('api/')"
        href="#"
        (click)="$event.preventDefault()"
        [downloadFile]="infoLink.link"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
      <a
        *ngIf="infoLink.linkAction == 'DownloadFile' && !infoLink.link.startsWith('api/')"
        [href]="infoLink.link"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
      <a
        *ngIf="infoLink.linkAction == 'OpenPdfDialog'"
        href="#"
        (click)="$event.preventDefault()"
        [openPdfDialog]="infoLink.link"
        [openPdfDialogTitle]="infoLink.name"
        style="font-size: 15px; font-weight: bold; letter-spacing: 1px; margin-left: 10px">
        <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
        <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
      </a>
    </div>
  </div>
  <hr class="divider" />
  <div class="alert alert-info" [innerHTML]="'Info.RequestsInfo' | translate"></div>
</div>
