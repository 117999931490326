import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CatalogItemPreEditDialogResult } from './catalogitem-preedit-dialog.result';

@Component({
  selector: 'app-catalogitem-preedit-dialog',
  templateUrl: './catalogitem-preedit-dialog.component.html',
  styleUrls: ['./catalogitem-preedit-dialog.component.css'],
})
export class CatalogItemPreEditDialogComponent implements OnInit {
  public data = {
    title: '',
    text: '',
    button_create_new_text: undefined,
    button_edit_existing_text: undefined,
    button_cancel_text: undefined,
  };
  public onClose!: Subject<CatalogItemPreEditDialogResult>;

  constructor(
    public translateTest: TranslateService,
    public modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onCreateNew(): void {
    let result: CatalogItemPreEditDialogResult = { showEditDialog: true, editType: 'createNew' };
    this.onClose.next(result);
    this.modalRef.hide();
  }

  public onEditExisting(): void {
    let result: CatalogItemPreEditDialogResult = { showEditDialog: true, editType: 'editExisting' };
    this.onClose.next(result);
    this.modalRef.hide();
  }

  public onCancel(): void {
    let result: CatalogItemPreEditDialogResult = { showEditDialog: false };
    this.onClose.next(result);
    this.modalRef.hide();
  }

  static showDialogTranslated(
    translate: TranslateService,
    modalService: BsModalService,
    title?: string,
    text?: string,
    editExisting?: string,
    createNew?: string,
    cancel?: string,
    additionalText?: string,
    modalClass?: string,
    translateParams?: Object,
  ): Promise<BsModalRef<CatalogItemPreEditDialogComponent>> {
    return new Promise<BsModalRef<CatalogItemPreEditDialogComponent>>((resolve) => {
      var resourceArray = [];

      if (title) {
        resourceArray.push(title);
      }

      if (text) {
        resourceArray.push(text);
      }

      if (createNew) {
        resourceArray.push(createNew);
      }

      if (editExisting) {
        resourceArray.push(editExisting);
      }

      if (cancel) {
        resourceArray.push(cancel);
      }

      translate
        .get(resourceArray, translateParams)
        .toPromise()
        .then((res) => {
          var titleRes = title ? res[title] : '';
          var textRes = text ? res[text] : '';
          var createNewRes = createNew ? res[createNew] : undefined;
          var editExistingRes = editExisting ? res[editExisting] : undefined;
          var cancelRes = cancel ? res[cancel] : undefined;

          if (additionalText) {
            textRes += additionalText;
          }

          var modalRef = modalService.show(CatalogItemPreEditDialogComponent, {
            class: modalClass,
            ignoreBackdropClick: true,
            initialState: {
              data: {
                title: titleRes,
                text: textRes,
                button_create_new_text: createNewRes,
                button_edit_existing_text: editExistingRes,
                button_cancel_text: cancelRes,
              },
            },
          });
          resolve(modalRef);
        });
    });
  }
}
