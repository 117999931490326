import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'contract-contact-information-dialog',
  templateUrl: './contract-contact-information-dialog.component.html',
})
export class ContractContactInformationDialog {
  public contactInformation!: string;
  public contractTitle!: string;
  private onCloseSubject: Subject<void> = new Subject();

  constructor(public modalRef: BsModalRef) {}

  public onClose(): void {
    this.onCloseSubject.next();
    this.modalRef.hide();
  }
}
