import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IndividualCompensationDto } from '@app/_models/individualCompensationDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class IndividualCompensationService extends BaseTableDataService<IndividualCompensationDto, number> {
  constructor(protected http: HttpClient) {
    super('individualCompensation', '', http);
  }

  getContractIndividualCompensations(contractId: number): Observable<IndividualCompensationDto[]> {
    return this.http.get<IndividualCompensationDto[]>(this.baseControllerUrl + `/contract/${contractId}`);
  }
}
