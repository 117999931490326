import { Buffer } from 'buffer';

export function joinStringsWithNullCheck(strings: (string | undefined)[], separator: string): string {
  return strings.filter((x) => x).join(separator);
}

export function replaceNewLineWithBr(input: String): string {
  return input.replace(/(\r\n|\r|\n)/g, '<br>');
}

export function base64EncodeCustomPadding(input: string): string {
  // Replace = padding character with - because this can cause problems when parsing the query.
  // The backend will replace the - character again with = when parsing the query.
  return Buffer.from(input, 'utf8').toString('base64').replace('=', '-');
}

export function stripHtml(input: string): string {
  const document = new DOMParser().parseFromString(input, 'text/html');
  return document.body.textContent || '';
}
