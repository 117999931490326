<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Name' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="name"
        class="form-control"
        placeholder="{{ 'Global.Name' | translate }}"
        maxlength="256" />
      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
        <div *ngIf="f.name.errors?.required">{{ 'User.Groups.Errors.NameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Description' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="2"
        formControlName="description"
        class="form-control"
        placeholder="{{ 'Global.Description' | translate }}"
        maxlength="5000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'User.Groups.AdminGroup' | translate }}</label>
    <div class="col-sm-10">
      <input type="checkbox" formControlName="adminGroup" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'User.Groups.InvoiceGroup' | translate }}</label>
    <div class="col-sm-10">
      <input type="checkbox" formControlName="invoiceGroup" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'User.Groups.InvoiceGuestAssistant' | translate }}</label>
    <div class="col-sm-10">
      <input type="checkbox" formControlName="invoiceGuestAssistant" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Roles' | translate }}</label>
    <div class="col-sm-10">
      <mat-selection-list formControlName="roles">
        <mat-list-option *ngFor="let role of roles" [value]="role.id" checkboxPosition="before">
          <div class="row">
            <div class="col-10">
              {{ role.name }}
            </div>
            <div class="col-1">
              <span [tooltip]="role.description | translate" placement="left" container="body">{{
                'Global.Info' | translate
              }}</span>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.ObjectPermissions' | translate }}</label>
    <div class="col-sm-10">
      <mat-table [dataSource]="objectPermissionsDataSource" formArrayName="objectPermissions">
        <ng-container matColumnDef="object">
          <mat-header-cell *matHeaderCellDef
            ><span> {{ 'ObjectPermissions.Object' | translate }} </span></mat-header-cell
          >
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <select formControlName="object" (change)="objectChanged(element)">
              <option *ngFor="let option of this.permissionObjectOptions" [ngValue]="option.value">
                {{ option.text | translate }}
              </option>
            </select>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="objectId">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.ObjectId' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 0">
              <option *ngFor="let catalog of this.catalogs" [ngValue]="catalog.id">{{ catalog.title }}</option>
            </select>
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 1">
              <option *ngFor="let catalogView of this.catalogViews" [ngValue]="catalogView.id">
                {{ catalogView.title }}
              </option>
            </select>
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 2">
              <option [ngValue]="-1">{{ 'ObjectPermissions.ObjectTypeDescriptions.User' | translate }}</option>
            </select>
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 3">
              <option *ngFor="let employeeGroup of this.employeeGroups" [ngValue]="employeeGroup.id">
                {{ employeeGroup.name }}
              </option>
            </select>
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 4">
              <option [ngValue]="-1">{{ 'ObjectPermissions.ObjectTypeDescriptions.UserContract' | translate }}</option>
            </select>
            <select formControlName="objectId" *ngIf="element.get('object')?.value == 5">
              <option *ngFor="let infoLink of this.infoLinks" [ngValue]="infoLink.id">
                <span *ngIf="!infoLink.translateName">{{ infoLink.name }}</span>
                <span *ngIf="infoLink.translateName">{{ infoLink.name | translate }}</span>
              </option>
            </select>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canRead">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.CanRead' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="checkbox" formControlName="canRead" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canCreate">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.CanCreate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="checkbox" formControlName="canCreate" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canUpdate">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.CanUpdate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="checkbox" formControlName="canUpdate" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canDelete">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.CanDelete' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="checkbox" formControlName="canDelete" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canExport">
          <mat-header-cell *matHeaderCellDef> {{ 'ObjectPermissions.CanExport' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <input type="checkbox" formControlName="canExport" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleteRow">
          <mat-header-cell *matHeaderCellDef> {{ 'User.Groups.DeleteObjectPermission' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <button class="btn btn-sm mb-1" (click)="deleteObjectPermission(index)">
              <i class="red fa fa-trash fa-lg"></i>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="errors">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
            <div *ngIf="element.invalid && (element.dirty || element.touched)">
              <i
                *ngIf="element.errors?.notUnique"
                class="red fa-solid fa-circle-exclamation fa-lg"
                placement="left"
                container="body"
                tooltip="{{ 'User.Groups.Errors.ObjectPermissionNotUnique' | translate }}"></i>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="objectPermissionsDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: objectPermissionsDisplayedColumns"> </mat-row>
      </mat-table>
      <button class="btn btn-xs btn-success addObjectPermissionButton" (click)="addObjectPermission(true)">
        {{ 'User.Groups.AddObjectPermission' | translate }}
      </button>
    </div>
  </div>
</form>
