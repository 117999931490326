import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ruleResultTypeTransform',
})
export class RuleResultTypeTransformPipe implements PipeTransform {
  public static ruleResultTypeMap = new Map([
    [0, 'Admin.Rules.RuleResultTypes.Warning'],
    [1, 'Admin.Rules.RuleResultTypes.Error'],
  ]);

  transform(ruleResultType: number): string {
    if (RuleResultTypeTransformPipe.ruleResultTypeMap.has(ruleResultType)) {
      var ruleResultTypeText = RuleResultTypeTransformPipe.ruleResultTypeMap.get(ruleResultType);
      return ruleResultTypeText ? ruleResultTypeText : '';
    } else {
      return '';
    }
  }
}
