import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, zip } from 'rxjs';

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { InvoiceSessionType } from '@app/_models/enums/invoiceSessionType';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { InvoiceSessionDto } from '@app/_models/invoiceSessionDto';
import { InvoiceSessionPositionDto } from '@app/_models/invoiceSessionPositionDto';
import { PatientDto } from '@app/_models/patientDto';
import { CatalogService } from '@app/_services/catalog.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InvoiceService } from '@app/_services/invoice.service';
import { InvoiceSessionService } from '@app/_services/invoiceSession.service';
import { PatientService } from '@app/_services/patient.service';
import { VisitColumn } from './visitColumn';
import { VisitRow } from './visitRow';

@Component({
  selector: 'app-user-invoice-visits',
  templateUrl: './user-invoice-visits.component.html',
  styleUrls: ['./user-invoice-visits.component.scss'],
})
export class UserInvoiceVisitsComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  invoice!: InvoiceDto;
  patient?: PatientDto;
  visitAllcolumns?: VisitColumn[];
  visitRows?: VisitRow[];
  catalogItems?: CatalogItemDto[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private catalogService: CatalogService,
    private patientService: PatientService,
    private invoiceSessionService: InvoiceSessionService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.loadInvoice();
  }

  loadInvoice() {
    this.loadingSubject.next(true);
    this.invoiceService.get(this.route.snapshot.params.id).subscribe(
      (response) => {
        this.invoice = response;
        this.loadData(this.invoice.patientId, this.invoice.catalogId);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  loadData(patientId: number, catalogId: number) {
    this.loadingSubject.next(true);

    var getPatient = this.patientService.get(patientId);
    var getCatalogItems = this.catalogService.query(`catalogId==${catalogId}&type==1`, 0, 100, undefined, undefined);

    zip(getPatient, getCatalogItems).subscribe(
      (result) => {
        this.patient = result[0];
        this.catalogItems = result[1].data;
        this.buildvisitRows();
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  buildvisitRows(): void {
    this.visitAllcolumns = new Array<VisitColumn>();
    this.visitRows = new Array<VisitRow>();

    if (this.catalogItems) {
      for (var catalogItem of this.catalogItems) {
        var visitAllColumn = new VisitColumn(catalogItem);
        this.visitAllcolumns.push(visitAllColumn);
      }
    }

    var dateFrom = new Date(this.invoice.dateFrom);
    var dateTo = new Date(this.invoice.dateTo);

    for (let currentDate = dateFrom; currentDate <= dateTo; currentDate.setDate(currentDate.getDate() + 1)) {
      var visitRow = new VisitRow(currentDate);
      var invoiceSession = this.invoice.sessions.find(
        (x) => x.date?.getTime() == currentDate.getTime() && x.type == InvoiceSessionType.Visit,
      );

      if (this.catalogItems) {
        for (var catalogItem of this.catalogItems) {
          var visitColumn = new VisitColumn(catalogItem);

          if (
            invoiceSession &&
            invoiceSession.visitPosition &&
            invoiceSession.visitPosition.catalogItem.id == catalogItem.id
          ) {
            visitColumn.checked = true;
          }

          visitRow.columns.push(visitColumn);
        }
      }

      this.visitRows.push(visitRow);
    }

    this.setAllColumnChecked();
  }

  checkAllColumn(column: VisitColumn): void {
    if (this.visitRows) {
      for (var visitRow of this.visitRows) {
        for (var VisitColumn of visitRow.columns) {
          if (VisitColumn.catalogItem == column.catalogItem) {
            VisitColumn.checked = column.checked;
          } else {
            VisitColumn.checked = false;
          }
        }
      }
    }

    if (this.visitAllcolumns) {
      for (var visitAllColumn of this.visitAllcolumns) {
        if (visitAllColumn != column) {
          visitAllColumn.checked = false;
        }
      }
    }
  }

  columnCheckedChanged(row: VisitRow, column: VisitColumn): void {
    for (var VisitColumn of row.columns) {
      if (VisitColumn.catalogItem != column.catalogItem) {
        VisitColumn.checked = false;
      }
    }

    this.setAllColumnChecked();
  }

  // Update's the all checkboxes after loading or after selection changed
  setAllColumnChecked(): void {
    if (this.visitAllcolumns && this.visitRows) {
      for (var visitAllColumn of this.visitAllcolumns) {
        var everyColumnChecked = this.visitRows
          .map((x) => x.getCatalogItemColumn(visitAllColumn.catalogItem))
          .every((x) => x?.checked);

        visitAllColumn.checked = everyColumnChecked;
      }
    }
  }

  async saveVisits(ignoreWarnings = false): Promise<void> {
    if (this.visitRows) {
      this.loadingSubject.next(true);

      for (var visitRow of this.visitRows) {
        var invoiceSession = this.invoice.sessions.find(
          (x) => x.date?.getTime() == visitRow.day.getTime() && x.type == InvoiceSessionType.Visit,
        );
        var checkedVisitColumn = visitRow.columns.find((x) => x.checked);

        if (checkedVisitColumn && checkedVisitColumn.catalogItem.id) {
          if (!invoiceSession) {
            invoiceSession = <InvoiceSessionDto>{
              date: visitRow.day,
              invoiceId: this.invoice.id,
              type: InvoiceSessionType.Visit,
              visitPosition: {
                catalogItem: checkedVisitColumn.catalogItem,
                catalogItemId: checkedVisitColumn.catalogItem.id,
              },
            };

            this.invoice.sessions.push(invoiceSession);
          } else {
            if (!invoiceSession.visitPosition) {
              invoiceSession.visitPosition = <InvoiceSessionPositionDto>{};
            }

            invoiceSession.visitPosition.isMainItem = true;
            invoiceSession.visitPosition.catalogItem = checkedVisitColumn.catalogItem;
            invoiceSession.visitPosition.catalogItemId = checkedVisitColumn.catalogItem.id;
          }
        } else {
          if (invoiceSession) {
            invoiceSession.visitPosition = undefined;
          }
        }
      }

      var additionalParams = new HttpParams().set('updateVisit', true).set('ignoreWarnings', ignoreWarnings);

      var visitSessions = this.invoice.sessions.filter((x) => x.type == InvoiceSessionType.Visit);

      this.invoiceSessionService.updateMultiple(visitSessions, additionalParams).subscribe(
        (result) => {
          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  this.saveVisits(true);
                } else {
                  this.loadingSubject.next(false);
                }
              });
            });
          } else {
            this.loadingSubject.next(false);
            this.router.navigate(['/user/invoice', this.invoice.id]);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
          this.loadingSubject.next(false);
        },
      );
    }
  }
}
