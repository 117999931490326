import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CatalogItemColumn } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogitem-container/catalogItemColumn';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { IRowDetailView } from '../row-detail-view.interface';

@Component({
  selector: 'app-catalog-item-row-details',
  templateUrl: './catalog-item-row-details.component.html',
  styleUrls: ['./catalog-item-row-details.component.scss'],
})
export class CatalogItemRowDetailsComponent implements OnInit, IRowDetailView<CatalogItemDto> {
  @Input()
  rowData!: CatalogItemDto;

  @Input()
  additionalData?: CatalogItemColumn[];

  public tableArray: Array<any> = [];

  private tableMaxLength = 125;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.additionalData) {
      let currentDataArray: Array<any> = [];
      this.tableArray.push(currentDataArray);

      var totalLength = 0;

      for (let currentData of this.additionalData) {
        let header: string = currentData.header ? currentData.header : currentData.property;
        let data: any = this.rowData[currentData.property];

        if (currentData.valueMap) {
          data = currentData.valueMap.get(data);
        }

        if (currentData.displayTranslate) {
          data = this.translate.instant(data);
        }

        currentDataArray.push({ header: header, data: data });

        if (data) {
          totalLength += Math.max(header.length, data.length);

          if (totalLength > this.tableMaxLength) {
            currentDataArray = [];
            this.tableArray.push(currentDataArray);
            totalLength = 0;
          }
        } else {
          totalLength += header.length;
        }
      }
    }
  }
}
