<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.IsActive' | translate }}</label>
    <div class="col-sm-10">
      <input type="checkbox" formControlName="isActive" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Name' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="name"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Name' | translate }}"
        maxlength="100" />
      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
        <div *ngIf="f.name.errors?.required">{{ 'Admin.Rules.Errors.NameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Description' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="2"
        formControlName="description"
        class="form-control"
        placeholder="{{ 'Global.Description' | translate }}"
        maxlength="2000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.Context' | translate }}</label>
    <div class="col-sm-10">
      <select class="form-control" formControlName="context" [(ngModel)]="selectedContext">
        <option *ngFor="let context of config.contexts" [ngValue]="context">{{ context.name }}</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.Triggers' | translate }}</label>
    <div class="col-sm-10">
      <mat-selection-list formControlName="triggers">
        <mat-list-option *ngFor="let trigger of selectedContext?.triggers" [value]="trigger" checkboxPosition="before">
          {{ trigger }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.Expression' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="10"
        formControlName="expression"
        class="form-control"
        placeholder="{{ 'Admin.Rules.Expression' | translate }}"
        maxlength="2000"></textarea>
      <div *ngIf="f.expression.invalid && (f.expression.dirty || f.expression.touched)" class="alert alert-danger">
        <div *ngIf="f.expression.errors?.required">{{ 'Admin.Rules.Errors.ExpressionRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.Message' | translate }}</label>
    <div class="col-sm-10">
      <textarea
        rows="2"
        formControlName="message"
        class="form-control"
        placeholder="{{ 'Admin.Rules.Message' | translate }}"
        maxlength="2000"></textarea>
      <div *ngIf="f.message.invalid && (f.message.dirty || f.message.touched)" class="alert alert-danger">
        <div *ngIf="f.message.errors?.required">{{ 'Admin.Rules.Errors.MessageRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.ResultType' | translate }}</label>
    <div class="col-sm-10">
      <select formControlName="resultType" class="form-control">
        <option *ngFor="let key of ruleResultTypeMapKeys" [ngValue]="key">
          {{ key | ruleResultTypeTransform | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.ValidFrom' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="validFrom"
        class="form-control"
        placeholder="{{ 'Global.ValidFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.ValidUntil' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="validUntil"
        class="form-control"
        placeholder="{{ 'Global.ValidUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Admin.Rules.Order' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="order"
        class="form-control mr-sm-2"
        placeholder="{{ 'Admin.Rules.Order' | translate }}"
        maxlength="100" />
      <div *ngIf="f.order.invalid && (f.order.dirty || f.order.touched)" class="alert alert-danger">
        <div *ngIf="f.order.errors?.required">{{ 'Admin.Rules.Errors.OrderRequired' | translate }}</div>
      </div>
    </div>
  </div>
</form>
