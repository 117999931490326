import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

import { CustomTableActionParams } from '@app/_controls/data-table/actions/customTableActionParams';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EmailsDialogComponent } from '@app/_dialogs/emails-dialog/emails-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateWithTime } from '@app/_helpers/functions/date-functions';
import { EventLogReferenceTypeTransformPipe } from '@app/_helpers/transform/eventlog-referencetype.transform';
import { EventLogDto } from '@app/_models/eventLogDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { EventLogService } from '@app/_services/eventLog.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.scss'],
})
export class EventLogsComponent implements AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<EventLogDto, number>;

  constructor(
    public eventLogService: EventLogService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'timestamp',
      header: 'Admin.EventLogs.Timestamp',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: EventLogDto) => formatDateWithTime(element),
      flex: '1 1 10%',
    },
    {
      columnProperty: 'type',
      header: 'Admin.EventLogs.Type',
      searchType: 'Text',
      flex: '1 1 12%',
    },
    {
      columnProperty: 'referenceType',
      header: 'Admin.EventLogs.ReferenceType',
      searchType: 'Selection',
      flex: '1 1 10%',
      valueMap: EventLogReferenceTypeTransformPipe.eventLogReferenceTypeMap,
      displayTranslate: true,
    },
    {
      columnProperty: 'referenceId',
      header: 'Admin.EventLogs.ReferenceId',
      searchType: 'Text',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'text',
      header: 'Admin.EventLogs.Text',
      searchType: 'Text',
      flex: '1 1 58%',
      maxTextLength: 200,
      renderHtml: true,
    },
  ];

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      tooltip: 'Admin.EventLogs.ShowEmailsButtonToolTip',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'fa fa-envelope fa-lg',
      actionParams: {
        customFunction: this.showEventLogEmails.bind(this),
      },
    },
  ];

  private resendEmailsActionParams: CustomTableActionParams = {
    customFunction: this.resendEmails.bind(this),
  };

  private tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Custom,
      text: 'Admin.EventLogs.ResendEmails',
      buttonClass: 'btn btn-success btn-sm mb-1',
      actionParams: this.resendEmailsActionParams,
    },
  ];

  public tableSettings = <TableSettings<EventLogDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<EventLogDto, number>(this.eventLogService, this.errorHandler),
    tableColumns: this.tableColumns,
    rowActions: this.rowActions,
    tableActions: this.tableActions,
    enableRowSelection: true,
    baseColumnSort: [{ column: 'timestamp', direction: 'desc' }],
  };

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  showEventLogEmails(element: EventLogDto) {
    EmailsDialogComponent.showDialog(this.modalService, element.id);
  }

  resendEmails(): void {
    let eventLogIds = this.dataTableComponent.rowSelection.selected.map((x) => x.id);

    if (eventLogIds.length == 0) {
      this.showNoEventLogsSelectedDialog();
      return;
    }

    this.showResendEmailsConfirmationDialog(eventLogIds);
  }

  showNoEventLogsSelectedDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Admin.EventLogs.Errors.NoEventLogsSelectedTitle',
      'Admin.EventLogs.Errors.NoEventLogsSelectedText',
      'Global.Ok',
      undefined,
    );
  }

  showResendEmailsConfirmationDialog(eventLogIds: number[]): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Admin.EventLogs.ResendEmailsConfirmationDialogTitle',
      'Admin.EventLogs.ResendEmailsConfirmationDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          this.loadingSubject.next(true);

          this.eventLogService.resendEmails(eventLogIds).subscribe(
            (result) => {
              this.loadingSubject.next(false);
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }
}
