<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="loading$ | async" class="loading-div">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
  <ngx-extended-pdf-viewer
    [src]="pdfSrc"
    [authorization]="bearerToken"
    [useBrowserLocale]="true"
    [handTool]="false"
    [showHandToolButton]="true"
    [showRotateButton]="false"
    [showOpenFileButton]="false"
    [showTextEditor]="false"
    [showDrawEditor]="false"
    [showSecondaryToolbarButton]="false"
    height="80vh"
    (pdfLoaded)="pdfLoaded()"
    (pdfLoadingFailed)="pdfLoadingFailed($event)">
  </ngx-extended-pdf-viewer>
</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'Global.Close' | translate }}</button>
  </ng-container>
</div>
