import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceInsuranceTypeTransform',
})
export class InvoiceInsuranceTypeTransformPipe implements PipeTransform {
  public static invoiceInsuranceTypeMap = new Map([
    [0, 'Global.InsuranceTypePrivate'],
    [1, 'Global.InsuranceTypeSemiPrivate'],
  ]);

  transform(invoiceInsuranceType: number): string {
    if (InvoiceInsuranceTypeTransformPipe.invoiceInsuranceTypeMap.has(invoiceInsuranceType)) {
      var invoiceInsuranceTypeText =
        InvoiceInsuranceTypeTransformPipe.invoiceInsuranceTypeMap.get(invoiceInsuranceType);
      return invoiceInsuranceTypeText ? invoiceInsuranceTypeText : '';
    } else {
      return '';
    }
  }
}
