import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { Pagination } from '@app/_models/pagination';
import { QueryColumnSort } from '@app/_models/settings/queryColumnSort';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class BaseTableDataService<DataType, IdType> extends BaseApiService {
  baseTableDataUrl: string;

  constructor(
    @Inject(String) controllerBaseUrl: string,
    @Inject(String) tableDataBaseUrl: string,
    protected http: HttpClient,
  ) {
    super(controllerBaseUrl);
    if (tableDataBaseUrl) {
      this.baseTableDataUrl = `${this.baseControllerUrl}/${tableDataBaseUrl}`;
    } else {
      this.baseTableDataUrl = `${this.baseControllerUrl}`;
    }
  }

  query(
    filter: string,
    pageIndex: number,
    pageSize: number,
    sort?: QueryColumnSort[] | undefined,
    additionalParams?: HttpParams | undefined,
  ): Observable<Pagination<DataType>> {
    var params = new HttpParams().set('Filter', filter).set('PageIndex', pageIndex).set('PageSize', pageSize);

    if (sort) {
      var columnSort = [];

      for (var queryColumnSort of sort) {
        columnSort.push(`${queryColumnSort.column}:${queryColumnSort.direction}`);
      }

      params = params.set('Sort', columnSort.join('|'));
    }

    if (additionalParams) {
      for (let key of additionalParams.keys()) {
        var value = additionalParams?.get(key);

        if (value) {
          params = params.set(key, value);
        }
      }
    }

    return this.http.get<Pagination<DataType>>(this.baseTableDataUrl + '/query', { params });
  }

  get(id: any, additionalParams?: HttpParams | undefined): Observable<DataType> {
    return this.http.get<DataType>(`${this.baseTableDataUrl}/${id}`, { params: additionalParams });
  }

  update(data: DataType, additionalParams?: HttpParams | undefined): Observable<CommandResult> {
    return this.http.put<CommandResult>(this.baseTableDataUrl, data, { params: additionalParams });
  }

  updateMultiple(data: DataType[], additionalParams?: HttpParams | undefined): Observable<CommandResult> {
    return this.http.put<CommandResult>(this.baseTableDataUrl + '/updateMultiple', data, { params: additionalParams });
  }

  create(data: DataType, additionalParams?: HttpParams | undefined): Observable<CommandResultWithValue<IdType>> {
    return this.http.post<CommandResultWithValue<IdType>>(this.baseTableDataUrl, data, { params: additionalParams });
  }

  delete(id: any, additionalParams?: HttpParams | undefined): Observable<CommandResult> {
    return this.http.delete<CommandResult>(`${this.baseTableDataUrl}/${id}`, { params: additionalParams });
  }
}
