import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, zip } from 'rxjs';

import { CustomRowActionParams } from '@app/_controls/data-table/actions/customRowActionParams';
import { CustomTableActionParams } from '@app/_controls/data-table/actions/customTableActionParams';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { InsuranceUsercontractsRowDetailsComponent } from '@app/_controls/row-detail-views/insurance-usercontracts-row-details/insurance-usercontracts-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditUserContractInsuranceContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-usercontract-insurance-container/edit-usercontract-insurance-container.component';
import { EditUserContractInsuranceContainerResultData } from '@app/_dialogs/row-edit-dialog/containers/edit-usercontract-insurance-container/EditUserContractInsuranceContainerResultData';
import { RowEditDialogComponent } from '@app/_dialogs/row-edit-dialog/row-edit-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { openHirslandenPhysicianSearchLink } from '@app/_helpers/functions/insurance-functions';
import { ContractCompensationTypeTransformPipe } from '@app/_helpers/transform/contract-compensation-type.transform';
import { CommandResult } from '@app/_models/command/commandResult';
import { ContractType } from '@app/_models/enums/contractType';
import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';
import { UserContractsRequestForInsuranceDto } from '@app/_models/userContractsRequestForInsuranceDto';
import { AccountService } from '@app/_services/account.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { UserContractForInsuranceService } from '@app/_services/userContractForInsuranceService';
import { RefreshableView } from '@app/_views/refreshable-view';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-insurance-pending-usercontracts',
  templateUrl: './insurance-pending-usercontracts.component.html',
  styleUrls: ['./insurance-pending-usercontracts.component.scss'],
})
export class InsurancePendingUsercontractsComponent implements OnInit, AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  public writePermission!: boolean;

  constructor(
    public userContractForInsuranceService: UserContractForInsuranceService,
    public contractService: ContractService,
    private translate: TranslateService,
    private modalService: BsModalService,
    public accountService: AccountService,
    public institutionService: InstitutionService,
    private errorHandler: ErrorHandlerService,
  ) {}

  private acceptSelectedUserContractsActionParams: CustomTableActionParams = {
    customFunction: this.acceptSelectedUserContracts.bind(this),
  };

  private editAndAcceptSelectedUserContractsActionParams: CustomTableActionParams = {
    customFunction: this.editAndAcceptSelectedUserContracts.bind(this),
  };

  private declineSelectedUserContractsActionParams: CustomTableActionParams = {
    customFunction: this.declineSelectedUserContracts.bind(this),
  };

  private tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Custom,
      text: 'Insurance.PendingUserContracts.AcceptSelected',
      buttonClass: 'btn btn-green btn-sm mb-1',
      actionParams: this.acceptSelectedUserContractsActionParams,
    },
    {
      action: TableActionEnum.Custom,
      text: 'Insurance.PendingUserContracts.EditAndAcceptSelected',
      buttonClass: 'btn btn-green btn-sm mb-1',
      actionParams: this.editAndAcceptSelectedUserContractsActionParams,
    },
    {
      action: TableActionEnum.Custom,
      text: 'Insurance.PendingUserContracts.DeclineSelected',
      buttonClass: 'btn btn-danger btn-sm mb-1',
      actionParams: this.declineSelectedUserContractsActionParams,
    },
  ];

  physicianSearchLinkActionParams: CustomRowActionParams = {
    customFunction: openHirslandenPhysicianSearchLink.bind(this),
  };

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<UserContractForInsuranceDto, number>;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.accountService.hasRole('EditContracts').subscribe((result) => {
      this.writePermission = result;
    });

    if (appConfig.showPhysicianSearchLink) {
      this.rowActions.push({
        action: RowActionEnum.Custom,
        tooltip: 'Insurance.PhysicianSearchHirslanden',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'blue fa fa-user-doctor fa-lg',
        actionParams: this.physicianSearchLinkActionParams,
      });
    }

    var getInstitutions = this.institutionService.getInstitutions();
    var getAllContractTypes = this.contractService.getAllContractTypes();

    this.loadingSubject.next(true);
    zip(getInstitutions, getAllContractTypes).subscribe(
      (result) => {
        this.dataTableComponent.rowDetailsData.contractTypes = result[1];

        let institutionTitleMap = new Map<number, string>();

        result[0].forEach((element) => {
          institutionTitleMap.set(element.id, element.title);
        });

        let contractTitleMap = new Map<number, string>();

        result[1].forEach((element) => {
          contractTitleMap.set(element.id, element.title);
        });

        this.loadingSubject.next(false);
        this.initTable(institutionTitleMap, contractTitleMap);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  initTable(institutionTitleMap: Map<number, string>, contractTitleMap: Map<number, string>): void {
    let tableColumns: Array<TableColumn> = [
      {
        columnProperty: 'contractId',
        header: 'Global.Contract',
        valueMap: contractTitleMap,
        flex: '1 1 10%',
      },
      {
        columnProperty: 'user.lastName',
        header: 'Global.LastName',
      },
      {
        columnProperty: 'user.firstName',
        header: 'Global.FirstName',
      },
      {
        columnProperty: 'user.function',
        header: 'Global.FunctionTitle',
      },
      {
        columnProperty: 'user.email',
        header: 'Global.EMail',
      },
      {
        columnProperty: 'user.institutions',
        header: 'Global.InstitutionClinic',
        searchProperty: 'userInstitutions.InstitutionId',
        valueMap: institutionTitleMap,
        valueMapNewLine: true,
        disableSort: true,
      },
      {
        columnProperty: 'user.zsrNumber',
        header: 'Global.ZsrNumber',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'user.glnNumber',
        header: 'Global.GlnNumber',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'compensationType',
        header: 'Global.CompensationType',
        flex: '1 1 15%',
        valueMap: ContractCompensationTypeTransformPipe.contractCompensationTypeMap,
        displayTranslate: true,
        searchProperty: 'contract.compensationType',
      },
      {
        columnProperty: 'validFrom',
        header: 'Global.ValidFrom',
        displayFunction: (element: Date, row: UserContractForInsuranceDto) => formatDateOnly(element),
        flex: '0 0 100px',
      },
      {
        columnProperty: 'validUntil',
        header: 'Global.ValidUntil',
        displayFunction: (element: Date, row: UserContractForInsuranceDto) => formatDateOnly(element),
        flex: '0 0 100px',
      },
      {
        columnProperty: 'overallFactorP1',
        header: 'Global.FactorP1',
        flex: '0 0 100px',
        disableSort: true,
      },
      {
        columnProperty: 'overallFactorP2',
        header: 'Global.FactorP2',
        flex: '0 0 110px',
        disableSort: true,
      },
    ];

    let tableSettings = <TableSettings<UserContractForInsuranceDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<UserContractForInsuranceDto, number>(
        this.userContractForInsuranceService,
        this.errorHandler,
      ),
      tableColumns: tableColumns,
      rowActions: this.writePermission ? this.rowActions : undefined,
      rowEditContainer: EditUserContractInsuranceContainerComponent,
      rowEditContainerStyle: 'modal-1000',
      editRowTitle: 'Insurance.PendingUserContracts.EditUserContract',
      tableActions: this.writePermission ? this.tableActions : undefined,
      rowDetailsContainerType: InsuranceUsercontractsRowDetailsComponent,
      baseFilter: 'contractStatus==0',
      enableRowSelection: true,
      baseColumnSort: [{ column: 'id', direction: 'desc' }],
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.disableRowFilters = true; // Disable search on this page.
    this.dataTableComponent.loadData();
  }

  compareContractValues(value: any, compareValue: any): boolean {
    if (value instanceof Date && compareValue instanceof Date) {
      return (
        value.getFullYear() === compareValue.getFullYear() &&
        value.getMonth() === compareValue.getMonth() &&
        value.getDay() === compareValue.getDay()
      );
    } else {
      return value === compareValue;
    }
  }

  editAndAcceptSelectedUserContracts(): void {
    if (this.dataTableComponent.rowSelection.selected.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    // Clone user contracts to edit, so we do not edit the existing ones.
    var clonedUserContracts: UserContractForInsuranceDto[] = Object.assign(
      [],
      this.dataTableComponent.rowSelection.selected,
    );

    // Check which field are all matching for initial data in the dialog.
    var initialData = <UserContractForInsuranceDto>{};

    var showFactorEditHint = false;
    var everyContractIsStandard = clonedUserContracts.every((x) => x.contractType == ContractType.Standard);
    var everyContractIsUserMustConfirm = clonedUserContracts.every(
      (x) => x.contractType == ContractType.UserMustConfirm,
    );

    if (everyContractIsUserMustConfirm) {
      // We allow the factor's to be changed because every selected contract has the same base contract and will be confirmed by the user.
      initialData.contractType = ContractType.UserMustConfirm;
    } else if (!everyContractIsStandard) {
      // We have a mix of contract types, so we show a hint that factors cannot be edited.
      showFactorEditHint = true;
    }

    if (clonedUserContracts.every((val, i, arr) => this.compareContractValues(val.contractId, arr[0].contractId))) {
      initialData.contractId = clonedUserContracts[0].contractId;
    } else if (!everyContractIsStandard) {
      // We have various UserMustConfirm base contracts, so we show a hint that factors cannot be edited.
      showFactorEditHint = true;
    }

    if (
      clonedUserContracts.every((val, i, arr) =>
        this.compareContractValues(val.individualCompensationId, arr[0].individualCompensationId),
      )
    ) {
      initialData.individualCompensationId = clonedUserContracts[0].individualCompensationId;
    }

    if (clonedUserContracts.every((val, i, arr) => this.compareContractValues(val.validFrom, arr[0].validFrom))) {
      initialData.validFrom = clonedUserContracts[0].validFrom;
    }

    if (clonedUserContracts.every((val, i, arr) => this.compareContractValues(val.validUntil, arr[0].validUntil))) {
      initialData.validUntil = clonedUserContracts[0].validUntil;
    }

    if (clonedUserContracts.every((val, i, arr) => this.compareContractValues(val.factorP1, arr[0].factorP1))) {
      initialData.factorP1 = clonedUserContracts[0].factorP1;
    }

    if (clonedUserContracts.every((val, i, arr) => this.compareContractValues(val.factorP2, arr[0].factorP2))) {
      initialData.factorP2 = clonedUserContracts[0].factorP2;
    }

    if (
      clonedUserContracts.every((val, i, arr) =>
        this.compareContractValues(val.overallFactorP1, arr[0].overallFactorP1),
      )
    ) {
      initialData.overallFactorP1 = clonedUserContracts[0].overallFactorP1;
    }

    if (
      clonedUserContracts.every((val, i, arr) =>
        this.compareContractValues(val.overallFactorP2, arr[0].overallFactorP2),
      )
    ) {
      initialData.overallFactorP2 = clonedUserContracts[0].overallFactorP2;
    }

    if (
      clonedUserContracts.every((val, i, arr) =>
        this.compareContractValues(val.individualFactor, arr[0].individualFactor),
      )
    ) {
      initialData.individualFactor = clonedUserContracts[0].individualFactor;
    }

    if (
      clonedUserContracts.every((val, i, arr) =>
        this.compareContractValues(val.compensationType, arr[0].compensationType),
      )
    ) {
      initialData.compensationType = clonedUserContracts[0].compensationType;
    }

    this.showEditAndAcceptContractsDialog(clonedUserContracts, initialData, showFactorEditHint);
  }

  acceptSelectedUserContracts(): void {
    let userContractIds = this.dataTableComponent.rowSelection.selected.map((x) => x.id);

    if (userContractIds.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    this.showAcceptContractsDialog(userContractIds, false);
  }

  declineSelectedUserContracts(): void {
    let userContractIds = this.dataTableComponent.rowSelection.selected.map((x) => x.id);

    if (userContractIds.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    this.showDeclineContractsDialog(userContractIds);
  }

  showNoContractsSelectedDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.NoContractsSelectedTitle',
      'User.Contracts.Errors.NoContractsSelectedText',
      'Global.Ok',
      undefined,
    );
  }

  showEditAndAcceptContractsDialog(
    userContracts: UserContractForInsuranceDto[],
    initialData: UserContractForInsuranceDto,
    showFactorEditHint: boolean,
  ): void {
    this.translate.get(['DataTable.EditRowTitle', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<UserContractForInsuranceDto, number, EditUserContractInsuranceContainerResultData, any>
        >(RowEditDialogComponent, {
          class: 'modal-1000',
          ignoreBackdropClick: true,
          initialState: {
            initialData: initialData,
            containerType: EditUserContractInsuranceContainerComponent,
            config: {
              showChangeCheckbox: true,
            },
            dataSource: new BaseTableDataServiceDataSource<UserContractForInsuranceDto, number>(
              this.userContractForInsuranceService,
              this.errorHandler,
            ),
            id: undefined,
            additionalParams: undefined,
            preActionData: undefined,
            dialogConfig: {
              title: res['DataTable.EditRowTitle'],
              text: showFactorEditHint ? 'Insurance.PendingUserContracts.CannotEditFactorsHint' : '',
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);
            if (onCloseResult.data) {
              this.editMultipleContracts(userContracts, onCloseResult.data, onCloseResult.resultData).subscribe(
                (result) => {
                  this.loadingSubject.next(false);
                  onCloseResult.modalRef.hide();
                  if (result.success) {
                    let userContractIds = userContracts.map((x) => x.id);
                    this.showAcceptContractsDialog(userContractIds, true);
                  } else {
                    this.dataTableComponent.loadData();
                  }
                },
                (errorResponse: HttpErrorResponse) => {
                  this.loadingSubject.next(false);
                  this.errorHandler.displayErrorDialog(errorResponse);
                },
              );
            }
          }
        });
    });
  }

  editMultipleContracts(
    userContractsToUpdate: UserContractForInsuranceDto[],
    userContractEdit: UserContractForInsuranceDto,
    resultData?: EditUserContractInsuranceContainerResultData,
  ): Observable<CommandResult> {
    for (let userContractToUpdate of userContractsToUpdate) {
      if (resultData?.changeValidFrom) {
        userContractToUpdate.validFrom = userContractEdit.validFrom;
      }

      if (resultData?.changeValidUntil) {
        userContractToUpdate.validUntil = userContractEdit.validUntil;
      }

      if (resultData?.changeIndividualCompensationId) {
        userContractToUpdate.individualCompensationId = userContractEdit.individualCompensationId;
      }
    }

    return this.userContractForInsuranceService.updateMultiple(userContractsToUpdate);
  }

  showAcceptContractsDialog(userContractIds: number[], refreshDataOnCancel: boolean): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Insurance.PendingUserContracts.AcceptContractsDialogTitle',
      'Insurance.PendingUserContracts.AcceptContractsDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          let userContractsRequestForInsuranceDto: UserContractsRequestForInsuranceDto = {
            userContractIds: userContractIds,
          };

          this.loadingSubject.next(true);
          this.userContractForInsuranceService.acceptUserContracts(userContractsRequestForInsuranceDto).subscribe(
            (result) => {
              this.loadingSubject.next(false);
              this.dataTableComponent.loadData();
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              // We load the data anyways because there could be contracts that succeeded.
              this.dataTableComponent.loadData();
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        } else if (refreshDataOnCancel) {
          this.dataTableComponent.loadData();
        }
      });
    });
  }

  showDeclineContractsDialog(userContractIds: number[]): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Insurance.PendingUserContracts.DeclineContractsDialogTitle',
      'Insurance.PendingUserContracts.DeclineContractsDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          let userContractsRequestForInsuranceDto: UserContractsRequestForInsuranceDto = {
            userContractIds: userContractIds,
          };

          this.loadingSubject.next(true);
          this.userContractForInsuranceService.declineUserContracts(userContractsRequestForInsuranceDto).subscribe(
            (result) => {
              this.loadingSubject.next(false);
              this.dataTableComponent.loadData();
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              // We load the data anyways because there could be contracts that succeeded.
              this.dataTableComponent.loadData();
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }
}
