import { Component } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { InstitutionDto } from '@app/_models/institutionDto';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';

@Component({
  selector: 'app-edit-institution-container',
  templateUrl: './edit-institution-container.component.html',
  styleUrls: ['./edit-institution-container.component.css'],
})
export class EditInstitutionContainerComponent implements IRowEditContainer<InstitutionDto, number, any, any> {
  dialog!: RowEditDialogComponent<InstitutionDto, number, any, any>;
  data!: InstitutionDto;
  initialData?: InstitutionDto;
  resultData: any;
  form!: FormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  constructor() {}

  initializeContainer() {
    // If there is no input data we are adding a row
    if (!this.data) {
      this.data = {
        id: 0,
        title: '',
        zsrNumber: '',
      } as InstitutionDto;
    }

    this.form = new FormGroup({
      title: new FormControl<string>(this.data.title, [Validators.required]),
      zsrNumber: new FormControl<string>(this.data.zsrNumber, [Validators.pattern('^$|[a-zA-Z]\\d{6}')]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }
}
