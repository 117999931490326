import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { EmployeeDto } from '@app/_models/employeeDto';
import { EmployeeGroupDto } from '@app/_models/employeeGroupDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseTableDataService<EmployeeDto, string> {
  constructor(protected http: HttpClient) {
    super('employee', '', http);
  }

  preUpdateCheck(employeeDto: EmployeeDto): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/preUpdateCheck', employeeDto);
  }

  getAllEmployeeGroups(): Observable<EmployeeGroupDto[]> {
    return this.http.get<EmployeeGroupDto[]>(this.baseControllerUrl + '/allgroups');
  }

  getUserEmployeeGroups(): Observable<EmployeeGroupDto[]> {
    return this.http.get<EmployeeGroupDto[]>(this.baseControllerUrl + '/usergroups');
  }
}
