<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ userCatalogView?.title }}</h1>
  </div>
  <div class="header-toolbar">
    <button *ngIf="canEditCatalogView" class="btn btn-xs btn-success" (click)="editCatalogView()">
      {{ 'Catalog.Management.EditCatalogView' | translate }}
    </button>
    <button
      *ngIf="canExportCatalogView"
      (click)="showExcelDownloadDialog()"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.ExcelExport' | translate }}
    </button>
    <button
      *ngIf="canExportCatalogView"
      (click)="showExcelComparisonDownloadDialog()"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.ExcelExportComparison' | translate }}
    </button>
    <button
      *ngIf="canExportCatalogView && catalogCsvExportEnabled"
      (click)="showCsvDownloadDialog()"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.CsvExport' | translate }}
    </button>
  </div>
</div>
<div class="row">
  <div class="col-2 col-form-label">
    <label>{{ 'Catalog.Management.CatalogItemFilterDate' | translate }}:</label>
  </div>
  <div class="col-8">
    <input
      style="width: 125px"
      type="text"
      [(ngModel)]="catalogItemFilterDate"
      (ngModelChange)="filterCatalogItemDate()"
      class="form-control"
      bsDatepicker
      [bsConfig]="{ adaptivePosition: true }" />
  </div>
</div>
<div
  *ngIf="userCatalogView?.additionalInfo"
  [innerHTML]="userCatalogView?.additionalInfo | safeHtml"
  routeTransformer></div>
<app-data-table id="catalogManagement"></app-data-table>
