import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'besr-input',
  templateUrl: './besr-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BesrInputComponent),
    },
  ],
})
export class BesrInputComponent implements ControlValueAccessor {
  @Output() valueChange = new EventEmitter<string>();

  control: FormControl = new FormControl('');
  private onChange?: (value: string | undefined) => void;

  constructor() {
    this.control.valueChanges.subscribe((value) => {
      this.onChange?.(value);
    });
  }

  registerOnChange(fn: (value: string | undefined) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // not required for this component, but for interface implementation
  }

  writeValue(obj: string) {
    this.value = obj;
  }

  get value(): string {
    return this.control.value;
  }

  set value(val: string) {
    this.control.setValue(val);
  }

  onPasteBesr(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    if (clipboardData) {
      let pastedText = clipboardData.getData('text');

      pastedText = pastedText.replace(/\s/g, '');

      if (pastedText.length <= 27) {
        pastedText = pastedText.padStart(27, '0');

        this.value = pastedText;
        event.preventDefault();
      }
    }
  }

  onFocusBesr(event: FocusEvent) {
    // Workarround for problem when input focused by tab key.
    // Tab selects all text and when then entering any value the mask gets removed and does not work anymore.
    // Problem is reported here: https://github.com/JsDaddy/ngx-mask/issues/1277
    // The issue was already fixed, but only for the latest Angular Version.
    if (event.target) {
      const input = event.target as HTMLInputElement;
      (input.selectionStart = 0), (input.selectionEnd = 0);
    }
  }
}
