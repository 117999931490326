<br />
<table class="table table-striped table-bordered table-hover editables" *ngFor="let table of tableArray">
  <thead class="thin-border-bottom">
    <tr>
      <th *ngFor="let data of table">{{ data.header }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td
        *ngFor="let data of table"
        [innerHTML]="data.data | nullEmptyString | newlineBr | safeHtml"
        routeTransformer></td>
    </tr>
  </tbody>
</table>
