import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { IframeDialogComponent } from '@app/_dialogs/iframe-dialog/iframe-dialog.component';
import { AdminInfoDto } from '@app/_models/adminInfoDto';
import { ApplicationConfig } from '@app/_models/applicationConfigDto';
import { ApplicationStatus } from '@app/_models/applicationStatusDto';
import { CommandResult } from '@app/_models/command/commandResult';
import { AccountService } from './account.service';
import { BaseApiService } from './baseApi.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends BaseApiService {
  private static applicationStatusSubscription?: Subscription;
  private currentApplicationStatus = new ReplaySubject<ApplicationStatus>(1);
  currentApplicationStatus$ = this.currentApplicationStatus.asObservable();

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    public translate: TranslateService,
    private modalService: BsModalService,
  ) {
    super('application');
  }

  getAdminInfo(): Observable<AdminInfoDto> {
    return this.http.get<any>(this.baseControllerUrl + '/admininfo');
  }

  getApplicationStatus(): Observable<ApplicationStatus> {
    return this.http.get<ApplicationStatus>(this.baseControllerUrl + '/status');
  }

  setApplicationStatus(applicationStatus: ApplicationStatus): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/status', applicationStatus);
  }

  getApplicationConfig(): Observable<ApplicationConfig> {
    return this.http.get<ApplicationConfig>(this.baseControllerUrl + '/config');
  }

  lockoutText(): Observable<string | undefined> {
    return this.currentApplicationStatus$.pipe(
      map((status) => {
        if (status.userLockoutEnabled && status.lockoutText) {
          return status.lockoutText;
        }
        return undefined;
      }),
    );
  }

  /*
   * Load application status every minute.
   */
  subscribeApplicationStatus(): void {
    if (ApplicationService.applicationStatusSubscription) {
      return;
    }

    ApplicationService.applicationStatusSubscription = timer(0, 60000)
      .pipe(
        switchMap(() => {
          return this.getApplicationStatus().pipe(
            catchError((err) => {
              // Handle errors
              console.error(err);
              return of(undefined);
            }),
          );
        }),
        filter((data) => data !== undefined),
      )
      .subscribe((data) => {
        this.currentApplicationStatus.next(data);
        if (data?.userLockoutEnabled && this.accountService.userRoles?.indexOf('Admin') == -1) {
          this.accountService.logout(); // Logout all non admin users.
        }
      });
  }

  unsubscribeApplicationStatus(): void {
    if (ApplicationService.applicationStatusSubscription) {
      ApplicationService.applicationStatusSubscription.unsubscribe();
      ApplicationService.applicationStatusSubscription = undefined;
    }
  }

  showInvoiceInfoDialog(): void {
    this.currentApplicationStatus$.pipe(take(1)).subscribe((applicationStatus) => {
      if (applicationStatus.invoiceInfoDialogPage) {
        var dontShowInvoiceInfoDialogPage = localStorage.getItem('dontShowInvoiceInfoDialogPage');

        if (applicationStatus.invoiceInfoDialogPage != dontShowInvoiceInfoDialogPage) {
          IframeDialogComponent.showDialogTranslated(
            this.translate,
            this.modalService,
            applicationStatus.invoiceInfoDialogPage,
            undefined,
            undefined,
            'Global.Close',
            undefined,
            'modal-1000',
            undefined,
            'Global.DontShowAgain',
          ).then((modalRef) => {
            modalRef.content?.onClose.subscribe((onCloseResult) => {
              if (onCloseResult.checkboxValue) {
                if (applicationStatus.invoiceInfoDialogPage) {
                  localStorage.setItem('dontShowInvoiceInfoDialogPage', applicationStatus.invoiceInfoDialogPage);
                }
              }
            });
          });
        }
      }
    });
  }
}
