import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupDto } from '@app/_models/groupDto';

import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseTableDataService<GroupDto, number> {
  constructor(protected http: HttpClient) {
    super('group', '', http);
  }
}
