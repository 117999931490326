import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseTableDataService } from './baseTableData.service';

import { CaseDto, CaseQueryResultDto, QueryRestrictionsDto } from '@app/_models/caseDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends BaseTableDataService<CaseDto, number> {
  constructor(protected http: HttpClient) {
    super('case', '', http);
  }

  queryByInstitutionAndSearchText(institution: InstitutionDto, searchText: string): Observable<CaseQueryResultDto> {
    return this.http.get<CaseQueryResultDto>(this.baseTableDataUrl + '/query', {
      params: new HttpParams().set('institutionId', institution.id).set('searchText', searchText),
    });
  }

  getQueryRestrictions(institution: InstitutionDto): Observable<QueryRestrictionsDto> {
    return this.http.get<QueryRestrictionsDto>(this.baseTableDataUrl + '/query-restrictions', {
      params: new HttpParams().set('institutionId', institution.id),
    });
  }
}
