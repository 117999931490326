import { CatalogItemDto } from '@app/_models/catalogItemDto';

export class VisitColumn {
  catalogItem!: CatalogItemDto;
  checked: boolean = false;

  constructor(catalogItem: CatalogItemDto) {
    this.catalogItem = catalogItem;
  }
}
