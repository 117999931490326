import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountTypeTransform',
})
export class AccountTypeTransformPipe implements PipeTransform {
  public static accountTypeMap = new Map([
    [0, 'Global.User'],
    [1, 'Global.Employee'],
  ]);

  transform(accountType: number): string {
    if (AccountTypeTransformPipe.accountTypeMap.has(accountType)) {
      var accountTypeText = AccountTypeTransformPipe.accountTypeMap.get(accountType);
      return accountTypeText ? accountTypeText : '';
    } else {
      return '';
    }
  }
}
