import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, zip } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { isObject } from '@app/_helpers/validators/object.validator';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { EmployeeGroupDto } from '@app/_models/employeeGroupDto';
import { InvoiceServiceProviderGroupsDto } from '@app/_models/invoiceServiceProviderGroupsDto';
import { InvoiceServiceProviderMinimalDto } from '@app/_models/InvoiceServiceProviderMinimalDto';
import { InvoiceSessionServiceProviderDto } from '@app/_models/invoiceSessionServiceProviderDto';
import { CatalogService } from '@app/_services/catalog.service';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InvoiceServiceProviderService } from '@app/_services/invoiceServiceProvider.service';
import { TranslateService } from '@ngx-translate/core';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditInvoiceSessionServiceProviderContainerConfig } from './editInvoiceSessionServiceProviderContainerConfig';

@Component({
  selector: 'app-edit-invoice-session-service-provider-container',
  templateUrl: './edit-invoice-session-service-provider-container.component.html',
  styleUrls: ['./edit-invoice-session-service-provider-container.component.css'],
})
export class EditInvoiceSessionServiceProviderContainerComponent
  implements
    IRowEditContainer<InvoiceSessionServiceProviderDto, number, any, EditInvoiceSessionServiceProviderContainerConfig>
{
  dialog!: RowEditDialogComponent<
    InvoiceSessionServiceProviderDto,
    number,
    any,
    EditInvoiceSessionServiceProviderContainerConfig
  >;
  data!: InvoiceSessionServiceProviderDto;
  initialData?: InvoiceSessionServiceProviderDto;
  resultData: any;
  form!: UntypedFormGroup;
  config!: EditInvoiceSessionServiceProviderContainerConfig;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  public invoiceEmployeeGroups!: EmployeeGroupDto[];
  public feeKinds?: CatalogItemDto[];
  public serviceProviderGroups?: InvoiceServiceProviderGroupsDto;
  public filteredServiceProviders?: Observable<InvoiceServiceProviderMinimalDto[] | undefined>;
  public filteredGuestServiceProviders?: Observable<InvoiceServiceProviderMinimalDto[] | undefined>;

  constructor(
    private errorHandler: ErrorHandlerService,
    private invoiceServiceProviderService: InvoiceServiceProviderService,
    private catalogService: CatalogService,
    private employeeService: EmployeeService,
    private translate: TranslateService,
  ) {}

  initializeContainer(): void {
    var currentServiceProvider = undefined;

    if (!this.data) {
      if (this.initialData) {
        this.data = this.initialData;
      } else {
        this.data = <InvoiceSessionServiceProviderDto>{};
      }
    } else {
      currentServiceProvider = this.data;
    }

    this.form = new UntypedFormGroup({
      serviceProvider: new UntypedFormControl(currentServiceProvider, [Validators.required, isObject()]),
      feeKindId: new UntypedFormControl(this.data.feeKindId, [Validators.required]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        if (key == 'serviceProvider') {
          var serviceProvider: InvoiceServiceProviderMinimalDto = changes[key];

          if (serviceProvider) {
            this.data.userId = serviceProvider.id;
            this.data.guestAssistant = this.isGuestAssistant(serviceProvider);
          }
        } else {
          this.data[key] = changes[key];
        }
      }
    });

    this.loadData();
  }

  loadData(): void {
    if (this.config) {
      this.dialog.loadingSubject.next(true);

      var catalogValidityDateString = formatDateYYYYMMDD(this.config.catalogValidityDate);
      var catalogFilter = `catalogId==${this.config.catalogId}&type>=100&validFrom<=${catalogValidityDateString}&(validUntil==null|validUntil>=${catalogValidityDateString})`;

      var catalogQuery = this.catalogService.query(
        catalogFilter,
        0,
        100,
        [{ column: 'column01', direction: 'asc' }],
        undefined,
      );
      var getServiceProvidersForUser = this.invoiceServiceProviderService.getServiceProvidersForUser(
        this.config.invoiceSessionId,
      );
      var getUserEmployeeGroups = this.employeeService.getUserEmployeeGroups();

      zip(catalogQuery, getServiceProvidersForUser, getUserEmployeeGroups).subscribe(
        (result) => {
          this.feeKinds = result[0].data;
          this.serviceProviderGroups = result[1];
          this.invoiceEmployeeGroups = result[2].filter((x) => x.invoiceGroup);

          var currentServiceProvider: string | InvoiceServiceProviderMinimalDto | undefined = '';

          if (this.data.userId) {
            if (this.data.guestAssistant) {
              currentServiceProvider = this.serviceProviderGroups.guestServiceProviders.find(
                (x) => x.id == this.data.userId,
              );
            } else {
              currentServiceProvider = this.serviceProviderGroups.contractServiceProviders.find(
                (x) => x.id == this.data.userId,
              );
            }

            this.f['serviceProvider'].setValue(currentServiceProvider);
          }

          this.filteredServiceProviders = this.f['serviceProvider'].valueChanges.pipe(
            startWith(currentServiceProvider),
            map((value) =>
              this.filterServiceProvider(value || '', this.serviceProviderGroups?.contractServiceProviders),
            ),
          );

          this.filteredGuestServiceProviders = this.f['serviceProvider'].valueChanges.pipe(
            startWith(currentServiceProvider),
            map((value) => this.filterServiceProvider(value || '', this.serviceProviderGroups?.guestServiceProviders)),
          );

          this.dialog.loadingSubject.next(false);
        },
        (errorResponse: HttpErrorResponse) => {
          this.dialog.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  displayServiceProvider(serviceProvider: InvoiceServiceProviderMinimalDto): string {
    if (serviceProvider) {
      var isGuestAssistant = this.isGuestAssistant(serviceProvider);
      var guestDoctorText = '(' + this.translate.instant('Global.GuestDoctor') + ')';

      return [
        serviceProvider.title,
        serviceProvider.firstName,
        serviceProvider.lastName,
        isGuestAssistant ? guestDoctorText : undefined,
      ]
        .filter((element) => Boolean(element))
        .join(' ');
    }

    return '';
  }

  private filterServiceProvider(
    value: any,
    serviceProviders?: InvoiceServiceProviderMinimalDto[],
  ): InvoiceServiceProviderMinimalDto[] | undefined {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      if (serviceProviders) {
        return serviceProviders.filter(
          (option) =>
            option.firstName?.toLowerCase().includes(filterValue) ||
            option.lastName?.toLowerCase().includes(filterValue),
        );
      }
    }

    if (typeof value === 'object' && serviceProviders?.indexOf(value) !== -1) {
      return [value];
    }

    return [];
  }

  isGuestAssistant(serviceProvider: InvoiceServiceProviderMinimalDto): boolean {
    return this.serviceProviderGroups?.guestServiceProviders.indexOf(serviceProvider) !== -1;
  }

  get f() {
    return this.form.controls;
  }
}
