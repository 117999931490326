import { formatDate } from '@angular/common';

export function setMinTime(element: Date | undefined): any {
  if (element) {
    return new Date(element.getFullYear(), element.getMonth(), element.getDate(), 0, 0, 0);
  }

  return element;
}

export function formatDateWithTime(element: Date | undefined): any {
  return formatDateBase(element, 'dd.MM.yyyy HH:mm:ss');
}

export function formatDateOnly(element: Date | undefined): any {
  return formatDateBase(element, 'dd.MM.yyyy');
}

export function formatDateYYYYMMDD(element: Date | undefined): any {
  return formatDateBase(element, 'yyyy-MM-dd');
}

function formatDateBase(element: Date | undefined, format: string): any {
  if (element) {
    return formatDate(element, format, 'de-CH');
  } else {
    return '';
  }
}
