import { Component, Input, OnInit } from '@angular/core';

import { RuleDto } from '@app/_models/ruleDto';
import { IRowDetailView } from '../row-detail-view.interface';

@Component({
  selector: 'invoice-controlling-row-details',
  templateUrl: './invoice-controlling-row-details.component.html',
  styleUrls: ['./invoice-controlling-row-details.component.scss'],
})
export class InvoiceControllingRowDetailsComponent implements OnInit, IRowDetailView<RuleDto> {
  @Input()
  rowData!: RuleDto;

  @Input()
  additionalData?: any;

  constructor() {}

  ngOnInit(): void {}
}
