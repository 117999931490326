import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InfoLinkDto } from '@app/_models/infoLinkDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InfoLinkService extends BaseTableDataService<InfoLinkDto, number> {
  constructor(protected http: HttpClient) {
    super('infolink', '', http);
  }

  getInfoLinks(): Observable<InfoLinkDto[]> {
    return this.http.get<InfoLinkDto[]>(this.baseControllerUrl + '/all');
  }

  getInfoLinksForUser(): Observable<InfoLinkDto[]> {
    return this.http.get<InfoLinkDto[]>(this.baseControllerUrl + '/user');
  }

  getFiles(): Observable<string[]> {
    return this.http.get<string[]>(this.baseControllerUrl + '/files');
  }
}
