import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';

import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { TranslateService } from '@ngx-translate/core';
import { AcceptTermsDialogResult } from './accept-terms-dialog.result';

@Component({
  selector: 'app-accept-terms-dialog',
  templateUrl: './accept-terms-dialog.component.html',
  styleUrls: ['./accept-terms-dialog.component.css'],
})
export class AcceptTermsDialogComponent implements OnInit, AfterViewInit {
  public title!: string;
  public text!: string;
  public button_confirm_text!: string;
  public button_cancel_text!: string;
  public acceptTermsUrl!: string;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public htmlSrc?: SafeHtml;
  public form!: UntypedFormGroup;
  public onClose!: Subject<AcceptTermsDialogResult>;

  constructor(
    public httpClient: HttpClient,
    public errorHandler: ErrorHandlerService,
    public modalRef: BsModalRef,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.onClose = new Subject();

    this.form = new UntypedFormGroup({
      acceptTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
    });
  }

  ngAfterViewInit(): void {
    this.httpClient.get(this.acceptTermsUrl, { responseType: 'text' }).subscribe(
      (result) => {
        this.htmlSrc = this.sanitizer.bypassSecurityTrustHtml(result);
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        console.error(errorResponse);
        this.loadingSubject.next(false);

        this.errorHandler.displayErrorDialog(errorResponse)?.then(() => {
          this.onCancel();
        });
      },
    );
  }

  get f() {
    return this.form.controls;
  }

  public onConfirm(): void {
    this.onClose.next({ accepted: true });
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next({ accepted: false });
    this.modalRef.hide();
  }

  static showDialogTranslated(
    translate: TranslateService,
    modalService: BsModalService,
    acceptTermsUrl: string,
    title?: string,
    text?: string,
    ok?: string,
    cancel?: string,
    modalClass?: string,
    translateParams?: Object,
  ): Promise<BsModalRef<AcceptTermsDialogComponent>> {
    return new Promise<BsModalRef<AcceptTermsDialogComponent>>((resolve) => {
      var resourceArray = [];

      if (title) {
        resourceArray.push(title);
      }

      if (text) {
        resourceArray.push(text);
      }

      if (ok) {
        resourceArray.push(ok);
      }

      if (cancel) {
        resourceArray.push(cancel);
      }

      translate
        .get(resourceArray, translateParams)
        .toPromise()
        .then((res) => {
          var titleRes = title ? res[title] : '';
          var textRes = text ? res[text] : '';
          var okRes = ok ? res[ok] : undefined;
          var cancelRes = cancel ? res[cancel] : undefined;

          var modalRef = modalService.show(AcceptTermsDialogComponent, {
            class: modalClass,
            ignoreBackdropClick: true,
            initialState: {
              title: titleRes,
              text: textRes,
              button_confirm_text: okRes,
              button_cancel_text: cancelRes,
              acceptTermsUrl: acceptTermsUrl,
            },
          });
          resolve(modalRef);
        });
    });
  }
}
