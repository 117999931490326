import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { zip } from 'rxjs';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { stripHtml } from '@app/_helpers/functions/string-functions';
import { ContractCompensationTypeTransformPipe } from '@app/_helpers/transform/contract-compensation-type.transform';
import { ContractTypeTransformPipe } from '@app/_helpers/transform/contract-type.transform';
import { individualCompensationUnique } from '@app/_helpers/validators/individual-compensation-unique.validator';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { ContractDto } from '@app/_models/contractDto';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { IndividualCompensationDto } from '@app/_models/individualCompensationDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { InsuranceDto } from '@app/_models/insuranceDto';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { FileService } from '@app/_services/file.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';

@Component({
  selector: 'app-edit-contract-container',
  templateUrl: './edit-contract-container.component.html',
  styleUrls: ['./edit-contract-container.component.scss'],
})
export class EditContractContainerComponent implements IRowEditContainer<ContractDto, number, any, any> {
  dialog!: RowEditDialogComponent<ContractDto, number, any, any>;
  data!: ContractDto;
  initialData?: ContractDto;
  resultData: any;
  form!: FormGroup;
  individualCompensationsFormArray!: UntypedFormArray;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  parentContracts?: ContractForUserDto[];
  insurances?: InsuranceDto[];
  institutions?: InstitutionDto[];
  catalogViews?: CatalogViewDto[];
  pdfFiles?: string[];
  individualCompensationsDataSource!: MatTableDataSource<AbstractControl>;
  individualCompensationsDisplayedColumns = ['value', 'reference', 'isDefault', 'deleteRow', 'errors'];

  formLabelClass: string = 'col-sm-3 col-form-label';
  formInputClass: string = 'col-sm-9';

  public contractTypeMapKeys!: number[];
  public contractCompensationTypeMapKeys!: number[];

  constructor(
    private contractService: ContractService,
    private insuranceService: InsuranceService,
    private institutionService: InstitutionService,
    private catalogViewService: CatalogViewService,
    private fileService: FileService,
    private errorHandler: ErrorHandlerService,
  ) {}

  initializeContainer() {
    this.contractTypeMapKeys = Array.from(ContractTypeTransformPipe.contractTypeMap.keys());
    this.contractCompensationTypeMapKeys = Array.from(
      ContractCompensationTypeTransformPipe.contractCompensationTypeMap.keys(),
    );

    if (!this.data) {
      if (this.initialData) {
        this.data = this.initialData;
        this.data.id = 0;
      } else {
        this.data = <ContractDto>{
          factorP1: 0,
          factorP2: 0,
          cashingFee: 0,
          okpFactor: 0,
          withdrawalPeriod: 0,
          individualCompensations: [] as IndividualCompensationDto[],
        };
      }
    }

    this.individualCompensationsFormArray = new UntypedFormArray([]);

    this.form = new FormGroup({
      title: new FormControl<string>(this.data.title, [Validators.required]),
      contractType: new FormControl<number>(this.data.contractType, [Validators.required]),
      compensationType: new FormControl<number>(this.data.compensationType, [Validators.required]),
      parentContractId: new FormControl<number | undefined>(this.data.parentContractId),
      insuranceId: new FormControl<number>(this.data.insuranceId, [Validators.required]),
      institutionId: new FormControl<number>(this.data.institutionId, [Validators.required]),
      catalogViewId: new FormControl<number | undefined>(this.data.catalogViewId),
      pdf: new FormControl<string>(this.data.pdf),
      pdf02: new FormControl<string>(this.data.pdf02),
      pdf03: new FormControl<string>(this.data.pdf03),
      factorP1: new FormControl<number | undefined>(this.data.factorP1),
      factorP2: new FormControl<number | undefined>(this.data.factorP2),
      cashingFee: new FormControl<number | undefined>(this.data.cashingFee),
      okpFactor: new FormControl<number | undefined>(this.data.okpFactor),
      maxValue: new FormControl<number | undefined>(this.data.maxValue),
      withdrawalPeriod: new FormControl<number>(this.data.withdrawalPeriod),
      address: new FormControl<string>(this.data.address),
      signature: new FormControl<string>(this.data.signature),
      defaultTerminationDateExpression: new FormControl<string>(this.data.defaultTerminationDateExpression),
      validFrom: new FormControl<Date | undefined>(this.data.validFrom),
      validUntil: new FormControl<Date | undefined>(this.data.validUntil),
      individualCompensations: this.individualCompensationsFormArray,
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });

    var getInsurances = this.insuranceService.getInsurances();
    var getInstitutions = this.institutionService.getInstitutions();
    var getAllContractTypes = this.contractService.getAllContractTypes();
    var getCatalogViews = this.catalogViewService.getCatalogViews();
    var getSecuredFiles = this.fileService.getSecuredFiles('pdf');

    this.dialog.loadingSubject.next(true);

    zip(getInsurances, getInstitutions, getAllContractTypes, getCatalogViews, getSecuredFiles).subscribe(
      (result) => {
        // only insurances without parents can be used in contracts
        this.insurances = result[0].filter((insurance) => !insurance.parentInsurance);
        this.institutions = result[1];
        this.parentContracts = result[2].filter((x) => x.parentContractId == undefined);
        this.catalogViews = result[3];
        this.pdfFiles = result[4].map(stripHtml); // strip html because [typeahead] renders options using innerHtml

        this.dialog.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.dialog.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );

    this.data.individualCompensations.forEach((individualCompensationDto: IndividualCompensationDto) => {
      this.addIndividualCompensation(false, individualCompensationDto);
    });

    this.individualCompensationsDataSource = new MatTableDataSource(this.individualCompensationsFormArray.controls);
  }

  public addIndividualCompensation(
    updateTable: boolean,
    individualCompensationDto: IndividualCompensationDto | undefined = undefined,
  ) {
    if (!individualCompensationDto) {
      individualCompensationDto = <IndividualCompensationDto>{
        id: 0,
        value: 0,
        reference: '',
        isDefault: false,
      };
    }

    var isExistingIndividualCompensation = individualCompensationDto.id != 0;

    var individualCompensationFormGroup = new UntypedFormGroup(
      {
        id: new UntypedFormControl(individualCompensationDto.id, []),
        value: new UntypedFormControl(
          {
            value: individualCompensationDto.value,
            disabled: isExistingIndividualCompensation,
          },
          [Validators.required],
        ),
        reference: new UntypedFormControl(individualCompensationDto.reference),
        isDefault: new UntypedFormControl(individualCompensationDto.isDefault),
      },
      [individualCompensationUnique(this.individualCompensationsFormArray)],
    );

    this.individualCompensationsFormArray.push(individualCompensationFormGroup);

    if (updateTable) {
      this.individualCompensationsDataSource._updateChangeSubscription();
    }
  }

  public deleteIndividualCompensation(index: number) {
    this.individualCompensationsFormArray.removeAt(index);
    this.individualCompensationsDataSource._updateChangeSubscription();
  }

  public individualCompensationIsDefaultChanged(formGroup: UntypedFormGroup): void {
    var isDefault = formGroup.get('isDefault')?.value as boolean;

    if (isDefault) {
      // If default is set to true, set it to false for every other row.
      this.individualCompensationsFormArray.controls.forEach((control) => {
        if (control != formGroup) {
          control.get('isDefault')?.setValue(false);
        }
      });
      this.individualCompensationsDataSource._updateChangeSubscription();
    }
  }

  get f() {
    return this.form.controls;
  }
}
