import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { InvoiceSessionServiceProviderDto } from '@app/_models/invoiceSessionServiceProviderDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSessionServiceProviderService extends BaseTableDataService<
  InvoiceSessionServiceProviderDto,
  number
> {
  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('invoiceSessionServiceProvider', '', http);
  }
}
