import { Pipe } from '@angular/core';

/*
  Replaces null and undefined with empty string.
*/
@Pipe({ name: 'nullEmptyString' })
export class NullEmptyStringTransform {
  constructor() {}

  transform(input: any) {
    if (input == 0) {
      return input;
    }

    if (input) {
      return input;
    }

    return '';
  }
}
