<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>
      {{ 'User.InvoiceVisits.Title' | translate }}
    </h1>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="alert alert-danger">
      <span [innerHTML]="'User.InvoiceVisits.Info' | translate"></span>
    </div>
  </div>
</div>

<table class="table table-striped table-bordered" *ngIf="invoice && patient">
  <thead class="thin-border-bottom">
    <tr>
      <th>{{ 'Global.CaseNumber' | translate }}</th>
      <th>{{ 'Global.PatientID' | translate }}</th>
      <th>{{ 'Global.LastName' | translate }}</th>
      <th>{{ 'Global.FirstName' | translate }}</th>
      <th>{{ 'Global.InvoiceDate' | translate }}</th>
      <th>{{ 'Global.HospitalizationFrom' | translate }}</th>
      <th>{{ 'Global.HospitalizationUntil' | translate }}</th>
      <th>{{ 'Global.BESR' | translate }}</th>
      <th class="text-right">{{ 'Global.TotalTaxPoints' | translate }}</th>
      <th>{{ 'Global.Sent' | translate }}</th>
      <th>{{ 'Global.Memo' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr class="evenrow">
      <td>{{ invoice.caseNumber }}</td>
      <td>{{ patient.id }}</td>
      <td>{{ patient.lastName }}</td>
      <td>{{ patient.firstName }}</td>
      <td>{{ invoice.createDate | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.dateFrom | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.dateTo | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.besr }}</td>
      <td class="text-right">{{ invoice.total | number }}</td>
      <td>{{ invoice.lastSentDate | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.memo }}</td>
    </tr>
  </tbody>
</table>

<table class="table table-striped table-bordered" *ngIf="invoice">
  <thead class="thin-border-bottom">
    <tr>
      <th>{{ 'Global.Date' | translate }}</th>
      <th *ngFor="let catalogItem of catalogItems">
        <div [tooltip]="catalogItem.title" placement="bottom" containerClass="tooltip-300">
          {{ 'Global.Nr' | translate }}: {{ catalogItem.column01 }} / {{ 'Global.Value' | translate }}:
          {{ catalogItem.value }}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'Global.All' | translate }}</td>
      <td *ngFor="let column of visitAllcolumns">
        <input type="checkbox" [(ngModel)]="column.checked" (change)="checkAllColumn(column)" />
      </td>
    </tr>
    <ng-container *ngFor="let row of visitRows">
      <tr>
        <td>{{ row.day | date: 'dd.MM.yyyy' }}</td>
        <td *ngFor="let column of row.columns">
          <input type="checkbox" [(ngModel)]="column.checked" (change)="columnCheckedChanged(row, column)" />
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<div class="row" *ngIf="invoice">
  <button class="col-2 offset-1 btn btn-primary" (click)="saveVisits()">{{ 'Global.Save' | translate }}</button>
  <button class="col-2 offset-6 btn btn-info" routerLink="/user/invoice/{{ invoice.id }}">
    {{ 'Global.Cancel' | translate }}
  </button>
</div>
