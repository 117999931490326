import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountTypeTransformPipe } from '@app/_helpers/transform/account-type.transform';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';
import { IRowDetailView } from '../row-detail-view.interface';

@Component({
  selector: 'app-insurance-usercontracts-row-details',
  templateUrl: './insurance-usercontracts-row-details.component.html',
  styleUrls: ['./insurance-usercontracts-row-details.component.scss'],
})
export class InsuranceUsercontractsRowDetailsComponent implements OnInit, IRowDetailView<UserContractForInsuranceDto> {
  @Input()
  rowData!: UserContractForInsuranceDto;

  @Input()
  additionalData?: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  getContractType(contractId: number): ContractForUserDto | undefined {
    if (this.additionalData.contractTypes) {
      let contractTypes: ContractForUserDto[] = this.additionalData.contractTypes;

      return contractTypes.find((x) => x.id == contractId);
    }

    return undefined;
  }

  accountTypeDisplayFunction(row: UserContractForInsuranceDto): string | undefined {
    if (row.user?.accountType == 1) {
      return row.user?.employeeGroups.join(', ');
    } else {
      var accountTypeTranslateKey = AccountTypeTransformPipe.accountTypeMap.get(row.compensationType);

      if (accountTypeTranslateKey) {
        return this.translate.instant(accountTypeTranslateKey);
      }
    }

    return undefined;
  }
}
