import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { EditEmployeeContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-employee-container/edit-employee-container.component';
import { AccountService } from '@app/_services/account.service';
import { ContractService } from '@app/_services/contract.service';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';

import { NavigateActionParams } from '@app/_controls/data-table/actions/navigateActionParams';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { ContractStatusTransformPipe } from '@app/_helpers/transform/contract-status.transform';
import { ContractTerminationTypeTransformPipe } from '@app/_helpers/transform/contract-termination-type.transform';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { EmployeeDto } from '@app/_models/employeeDto';
import { EmployeeGroupDto } from '@app/_models/employeeGroupDto';
import { GroupType } from '@app/_models/enums/groupType';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { ObjectPermissionQueryResultDto } from '@app/_models/objectPermissionQueryResultDto';
import { UserContractDto } from '@app/_models/userContractDto';
import { ApplicationService } from '@app/_services/application.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-employee-management',
  templateUrl: './employee-management.component.html',
  styleUrls: ['./employee-management.component.css'],
})
export class EmployeeManagementComponent implements OnInit, AfterViewInit, RefreshableView {
  public institutionTitleMap = new Map<number, string>();
  public contractTypeMap!: Map<number, ContractForUserDto>;
  public objectPermission?: ObjectPermissionQueryResultDto;

  private employeeGroupId!: number;
  public employeeGroup: EmployeeGroupDto | undefined;
  public excelDownloadLink!: string;

  public filterContractsToDo: boolean = false;

  public filterColumns: string[] = [
    'title',
    'function',
    'firstName',
    'lastName',
    'zsrNumber',
    'glnNumber',
    'email',
    'title',
  ];

  private employeePerInstitutionTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'title',
      header: 'Global.Title',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'firstName',
      header: 'Global.FirstName',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'lastName',
      header: 'Global.LastName',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'function',
      header: 'Global.FunctionTitle',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'institutions',
      header: 'Global.InstitutionClinic',
      searchProperty: 'userInstitutions.InstitutionId',
      valueMap: this.institutionTitleMap,
      valueMapNewLine: true,
      disableSort: true,
      flex: '1 1 10%',
    },
    {
      columnProperty: 'zsrNumber',
      header: 'Global.ZsrNumber',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'employedFrom',
      header: 'Global.ActiveFrom',
      displayFunction: (element: Date, row: EmployeeDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
    {
      columnProperty: 'employedUntil',
      header: 'Global.ActiveUntil',
      displayFunction: (element: Date, row: EmployeeDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
    {
      columnProperty: 'userContracts',
      header: 'Global.Contracts',
      disableSort: true,
      displayFunction: (element: UserContractDto[], row: EmployeeDto) => this.getUserContractsString(element),
      renderWhitespace: true,
      flex: '1 1 40%',
    },
  ];

  private employeePerUserTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'title',
      header: 'Global.Title',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'firstName',
      header: 'Global.FirstName',
      flex: '1 1 25%',
    },
    {
      columnProperty: 'lastName',
      header: 'Global.LastName',
      flex: '1 1 25%',
    },
    {
      columnProperty: 'function',
      header: 'Global.FunctionTitle',
      flex: '1 1 20%',
    },
    {
      columnProperty: 'zsrNumber',
      header: 'Global.ZsrNumber',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'glnNumber',
      header: 'Global.GlnNumber',
      flex: '1 1 10%',
    },
    {
      columnProperty: 'employedFrom',
      header: 'Global.ActiveFrom',
      displayFunction: (element: Date, row: EmployeeDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
    {
      columnProperty: 'employedUntil',
      header: 'Global.ActiveUntil',
      displayFunction: (element: Date, row: EmployeeDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<EmployeeDto, string>;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    public employeeService: EmployeeService,
    public institutionService: InstitutionService,
    public accountService: AccountService,
    public contractService: ContractService,
    public contractStatusTransformPipe: ContractStatusTransformPipe,
    public contractTerminationTypeTransformPipe: ContractTerminationTypeTransformPipe,
    private errorHandler: ErrorHandlerService,
    private applicationService: ApplicationService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((routeParams) => {
      this.employeeGroupId = Number(routeParams.groupId);
      this.excelDownloadLink = `api/employee/excelexport/${this.employeeGroupId}`;
      this.loadEmployees();
    });
  }

  ngAfterViewInit(): void {
    this.loadEmployees();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  loadEmployees(): void {
    // When component is first loaded dataTableComponent is null, so we load in ngAfterViewInit().
    if (this.dataTableComponent) {
      var getInstitutions = this.institutionService.getInstitutions();
      var getContractTypes = this.contractService.getAllContractTypes();
      var getObjectPermission = this.accountService.getObjectPermission(
        PermissionObject.Employee,
        this.employeeGroupId,
      );
      var getUserEmployeeGroups = this.employeeService.getUserEmployeeGroups();

      zip(getInstitutions, getContractTypes, getObjectPermission, getUserEmployeeGroups).subscribe(
        (result) => {
          this.institutionTitleMap.clear();

          result[0].forEach((element) => {
            this.institutionTitleMap.set(element.id, element.title);
          });

          this.contractTypeMap = new Map<number, ContractForUserDto>();

          result[1].forEach((element) => {
            this.contractTypeMap.set(element.id, element);
          });

          this.objectPermission = result[2];

          this.employeeGroup = result[3].find((x) => x.id == this.employeeGroupId);

          this.initTable();

          if (this.employeeGroup?.invoiceGroup) {
            this.applicationService.showInvoiceInfoDialog();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  initTable(): void {
    let tableActions: Array<TableAction> = [];

    if (this.objectPermission?.canCreate) {
      tableActions.push({
        action: TableActionEnum.Add,
        text: this.employeeGroup?.invoiceGroup ? 'Employee.Management.AddAssistant' : 'Employee.Management.AddEmployee',
        buttonClass: 'btn btn-success btn-sm mb-1',
      });
    }

    let rowActions: Array<RowAction> = [];

    if (this.objectPermission?.canDelete) {
      rowActions.push({
        action: RowActionEnum.Delete,
        tooltip: 'Global.Delete',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'red fa fa-trash fa-lg',
      });
    }

    if (this.objectPermission?.canUpdate) {
      rowActions.push({
        action: RowActionEnum.Edit,
        tooltip: 'Global.Edit',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'green fa fa-pencil-alt fa-lg',
      });

      if (!this.employeeGroup?.invoiceGroup) {
        var contractNavigateActionParams: NavigateActionParams = {
          navigateCommandsFunction: (element: EmployeeDto) => {
            return ['user', element.id, 'contracts'];
          },
        };
        var contractsManagementAction: RowAction = {
          action: RowActionEnum.Navigate,
          tooltip: 'Global.Contracts',
          buttonClass: 'btn btn-sm mb-1',
          iconClass: 'fa fa-file-signature fa-lg',
          actionParams: contractNavigateActionParams,
        };
        rowActions.push(contractsManagementAction);
      }
    }

    let tableSettings = <TableSettings<EmployeeDto, string>>{
      dataSource: new BaseTableDataServiceDataSource<EmployeeDto, string>(this.employeeService, this.errorHandler),
      tableColumns:
        this.employeeGroup?.type == GroupType.EmployeePerInstitution
          ? this.employeePerInstitutionTableColumns
          : this.employeePerUserTableColumns,
      tableActions: tableActions,
      rowActions: rowActions,
      rowEditContainer: EditEmployeeContainerComponent,
      rowEditContainerConfig: { groups: [this.employeeGroupId], hideInstitution: this.employeeGroup?.invoiceGroup },
      rowEditContainerStyle: 'modal-1000',
      addRowTitle: 'Employee.Management.AddEmployee',
      editRowTitle: 'Employee.Management.EditEmployee',
      globalFilterColumns: this.filterColumns,
      baseFilter: this.getBaseFilter(),
      pageIndexQueryParamName: 'pageIndex',
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  getBaseFilter(): string {
    if (this.filterContractsToDo) {
      return `userGroups.GroupId==${this.employeeGroupId}&(userContracts.[[Count]]==0|userContracts.contractStatus==4)`;
    }

    return `userGroups.GroupId==${this.employeeGroupId}`;
  }

  public getUserContractsString(userContracts: UserContractDto[]): string {
    if (!userContracts) {
      return '';
    }

    var contractStrings: string[] = [];

    userContracts?.forEach((currentValue, index) => {
      var contractType = this.contractTypeMap.get(currentValue.contractId);

      if (contractType && currentValue.parentContractId) {
        var contractStatusTranslationKey = this.contractStatusTransformPipe.transform(currentValue.contractStatus);
        var terminatedDate = formatDateOnly(currentValue.terminatedDate);
        var terminatedBy = this.contractTerminationTypeTransformPipe.transform(
          currentValue.terminationType,
          'InSentence',
        );

        if (terminatedBy) {
          terminatedBy = this.translate.instant(terminatedBy);
        }

        var contractStatusText = this.translate.instant(contractStatusTranslationKey, {
          terminatedDate: terminatedDate,
          terminatedBy: terminatedBy,
        });

        contractStrings.push(`${contractType.title} (${contractStatusText})`);
      }
    });

    return contractStrings.join('\n');
  }

  filterContractsToDoChanged(): void {
    this.dataTableComponent.tableSettings.baseFilter = this.getBaseFilter();
    this.dataTableComponent.applyGlobalFilter();
  }
}
