import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// custom form group validator to validate that requiredControlName is required when any of the supplied requiredWhenControlNames has a value.
export function requiredWhen(
  requiredControlName: string,
  requiredWhen: (config: any) => boolean,
  config: any,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let requiredControl: UntypedFormControl = control.get(requiredControlName) as UntypedFormControl;
    var validationErrors: ValidationErrors | null = requiredControl.errors;

    if (requiredWhen(config)) {
      // Control is required, add required Validator.
      requiredControl.addValidators(Validators.required);

      if (requiredControl.value) {
        // Control is required, but has a value, so we remove the error.
        delete validationErrors?.required;
      } else {
        // Control is required, and has no value, so we add an error.
        validationErrors = { required: true };
      }
    } else {
      // Control is not required, remove required validator and error.
      requiredControl.removeValidators(Validators.required);
      delete validationErrors?.required;
    }

    // Set errors to null if no more errors are set.
    if (Object.keys(validationErrors || {}).length === 0) {
      validationErrors = null;
    }

    requiredControl.setErrors(validationErrors);
    return validationErrors;
  };
}
