import { Directive, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { PdfViewerDialogComponent } from '@app/_dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { OAuthService } from 'angular-oauth2-oidc';

@Directive({
  selector: '[openPdfDialog]',
})
export class OpenPdfDialogDirective {
  constructor(
    private translate: TranslateService,
    private modalService: BsModalService,
    private oauthService: OAuthService,
  ) {}

  @Input('openPdfDialog') url!: string;
  @Input('openPdfDialogTitle') title!: string;

  @HostListener('click', ['$event'])
  public async onClick(event: any): Promise<void> {
    var accessToken = this.url.startsWith('api/') ? this.oauthService.getAccessToken() : undefined;

    PdfViewerDialogComponent.showDialog(this.modalService, this.title, this.url, accessToken);
  }
}
