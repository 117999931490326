import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { InsuranceContractChangesExcelExportDto } from '@app/_models/insuranceContractChangesExcelExportDto';
import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';
import { UserContractsRequestForInsuranceDto } from '@app/_models/userContractsRequestForInsuranceDto';
import { UserContractsTerminateRequestForInsuranceDto } from '@app/_models/userContractsTerminateRequestForInsuranceDto';
import { TIMEOUT_TOKEN } from '@app/_providers/httpErrorInterceptor';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class UserContractForInsuranceService extends BaseTableDataService<UserContractForInsuranceDto, number> {
  constructor(protected http: HttpClient) {
    super('insurance', 'usercontracts', http);
  }

  acceptUserContracts(
    userContractsRequestForInsuranceDto: UserContractsRequestForInsuranceDto,
  ): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      this.baseControllerUrl + '/usercontracts/accept',
      userContractsRequestForInsuranceDto,
    );
  }

  declineUserContracts(
    userContractsRequestForInsuranceDto: UserContractsRequestForInsuranceDto,
  ): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      this.baseControllerUrl + '/usercontracts/decline',
      userContractsRequestForInsuranceDto,
    );
  }

  terminateUserContracts(
    userContractsTerminateRequestForInsuranceDto: UserContractsTerminateRequestForInsuranceDto,
  ): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      this.baseControllerUrl + '/usercontracts/terminate',
      userContractsTerminateRequestForInsuranceDto,
    );
  }

  contractChangesExcelExport(
    insuranceContractChangesExcelExportDto: InsuranceContractChangesExcelExportDto,
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(
      this.baseControllerUrl + '/contractChangesExcelExport',
      insuranceContractChangesExcelExportDto,
      {
        responseType: 'blob',
        observe: 'response',
        context: new HttpContext().set(TIMEOUT_TOKEN, 300000),
      },
    );
  }
}
