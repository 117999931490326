<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.Title' | translate }}</label>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="title"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
      <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
        <div *ngIf="f.title.errors?.required">{{ 'Institution.Errors.TitleRequired' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ 'Global.ZsrNumber' | translate }}</label>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="zsrNumber"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.ZsrNumber' | translate }}"
        maxlength="7" />
      <div *ngIf="f.zsrNumber.invalid && (f.zsrNumber.dirty || f.zsrNumber.touched)" class="alert alert-danger">
        <div *ngIf="f.zsrNumber.errors?.pattern">{{ 'Institution.Errors.ZsrFormat' | translate }}</div>
      </div>
    </div>
  </div>
</form>
