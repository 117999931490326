import { Pipe } from '@angular/core';
import { replaceNewLineWithBr } from '../functions/string-functions';

/*
  Replaces newline with <br>.
*/
@Pipe({ name: 'newlineBr' })
export class NewlineBrReplace {
  constructor() {}

  transform(input: any) {
    if (input && input.constructor === String) {
      return replaceNewLineWithBr(input);
    }

    return input;
  }
}
