import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, zip } from 'rxjs';

import { AdminInfoDto } from '@app/_models/adminInfoDto';
import { ApplicationStatus } from '@app/_models/applicationStatusDto';
import { ApplicationService } from '@app/_services/application.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-admin-info',
  templateUrl: './admin-info.component.html',
  styleUrls: ['./admin-info.component.scss'],
})
export class AdminInfoComponent implements OnInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  public adminInfo!: AdminInfoDto;
  public applicationStatus!: ApplicationStatus;
  applicationStatusForm!: UntypedFormGroup;

  constructor(
    public applicationService: ApplicationService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  refreshView(): void {
    this.loadData();
  }

  loadData(): void {
    var getAdminInfo = this.applicationService.getAdminInfo();
    var getApplicationStatus = this.applicationService.getApplicationStatus();

    zip(getAdminInfo, getApplicationStatus).subscribe(
      (result) => {
        this.adminInfo = result[0];
        this.applicationStatus = result[1];

        this.initForm();
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  initForm(): void {
    this.applicationStatusForm = new UntypedFormGroup({
      userLockoutEnabled: new UntypedFormControl(this.applicationStatus.userLockoutEnabled, []),
      lockoutText: new UntypedFormControl(this.applicationStatus.lockoutText, []),
      displayWarningMessage: new UntypedFormControl(this.applicationStatus.displayWarningMessage, []),
      warningMessage: new UntypedFormControl(this.applicationStatus.warningMessage, []),
      invoiceInfoDialogPage: new UntypedFormControl(this.applicationStatus.invoiceInfoDialogPage, []),
    });

    this.applicationStatusForm.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.applicationStatus[key] = changes[key];
      }
    });
  }

  saveApplicationStatus(): void {
    this.loadingSubject.next(true);
    this.applicationService.setApplicationStatus(this.applicationStatus).subscribe(
      (result) => {
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }
}
