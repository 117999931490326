import { CustomFormDialogFieldConfig } from './custom-form-dialog-field.config';

import { TranslateObject } from '@app/_helpers/transform/translateObject.transform';

interface IDefaultConfigArgs<DataType> {
  dialogTitle?: string;
  dialogText?: string;
  required?: boolean;
  fieldLabel?: string;
  initialValue?: DataType;
  modalClass?: string;
  labelSize?: number;
  inputSize?: number;
}

interface IDefaultDateConfigArgs<DataType> extends IDefaultConfigArgs<DataType> {
  minDate?: Date;
  maxDate?: Date;
}

interface IDefaultTextConfigArgs<DataType> extends IDefaultConfigArgs<DataType> {
  maxlength?: number;
}

interface IDefaultTextAreaConfigArgs<DataType> extends IDefaultTextConfigArgs<DataType> {
  textareaRows?: number;
}

export class CustomFormDialogConfig {
  dialogTitle!: TranslateObject;
  dialogText?: TranslateObject;
  confirmButtonText!: TranslateObject;
  cancelButtonText!: TranslateObject;
  modalClass!: string;
  labelSize?: number = 4;
  inputSize?: number = 8;
  fields!: Array<CustomFormDialogFieldConfig<any>>;

  constructor(obj: Partial<CustomFormDialogConfig>) {
    Object.assign(this, obj);
  }

  static getDefaultTextareaConfig({
    dialogTitle = undefined,
    dialogText = undefined,
    required = false,
    fieldLabel = undefined,
    initialValue = undefined,
    modalClass = 'modal-600',
    labelSize = 4,
    inputSize = 8,
    maxlength = 256,
    textareaRows = 2,
  }: IDefaultTextAreaConfigArgs<string>): CustomFormDialogConfig {
    return new CustomFormDialogConfig({
      dialogTitle: {
        text: dialogTitle,
        translate: true,
      },
      dialogText: {
        text: dialogText,
        translate: true,
      },
      confirmButtonText: {
        text: 'Global.Ok',
        translate: true,
      },
      cancelButtonText: {
        text: 'Global.Cancel',
        translate: true,
      },
      modalClass: modalClass,
      labelSize: labelSize,
      inputSize: inputSize,
      fields: [
        new CustomFormDialogFieldConfig<string>({
          name: 'text',
          label: {
            text: fieldLabel,
            translate: true,
          },
          required: required,
          requiredErrorText: {
            text: 'Global.Errors.TextIsRequired',
            translate: true,
          },
          initialValue: initialValue,
          uiType: 'textarea',
          maxlength: maxlength,
          textareaRows: textareaRows,
        }),
      ],
    });
  }

  static getDefaultDateConfig({
    dialogTitle = undefined,
    dialogText = undefined,
    required = false,
    fieldLabel = undefined,
    initialValue = undefined,
    modalClass = 'modal-600',
    labelSize = 4,
    inputSize = 8,
    minDate = undefined,
    maxDate = undefined,
  }: IDefaultDateConfigArgs<Date>): CustomFormDialogConfig {
    return new CustomFormDialogConfig({
      dialogTitle: {
        text: dialogTitle,
        translate: true,
      },
      dialogText: {
        text: dialogText,
        translate: true,
      },
      confirmButtonText: {
        text: 'Global.Ok',
        translate: true,
      },
      cancelButtonText: {
        text: 'Global.Cancel',
        translate: true,
      },
      modalClass: modalClass,
      labelSize: labelSize,
      inputSize: inputSize,
      fields: [
        new CustomFormDialogFieldConfig<Date>({
          name: 'date',
          label: {
            text: fieldLabel,
            translate: true,
          },
          required: required,
          requiredErrorText: {
            text: 'Global.Errors.DateIsRequired',
            translate: true,
          },
          initialValue: initialValue,
          uiType: 'date',
          minDate: minDate,
          maxDate: maxDate,
        }),
      ],
    });
  }

  static getDefaultDateRangeConfig({
    dialogTitle = undefined,
    dialogText = undefined,
    required = false,
    fieldLabel = undefined,
    initialValue = undefined,
    modalClass = 'modal-600',
    labelSize = 4,
    inputSize = 8,
    minDate = undefined,
    maxDate = undefined,
  }: IDefaultDateConfigArgs<Date>): CustomFormDialogConfig {
    return new CustomFormDialogConfig({
      dialogTitle: {
        text: dialogTitle,
        translate: true,
      },
      dialogText: {
        text: dialogText,
        translate: true,
      },
      confirmButtonText: {
        text: 'Global.Ok',
        translate: true,
      },
      cancelButtonText: {
        text: 'Global.Cancel',
        translate: true,
      },
      modalClass: modalClass,
      labelSize: labelSize,
      inputSize: inputSize,
      fields: [
        new CustomFormDialogFieldConfig<Date>({
          name: 'dateRange',
          label: {
            text: fieldLabel,
            translate: true,
          },
          required: required,
          requiredErrorText: {
            text: 'Global.Errors.DateIsRequired',
            translate: true,
          },
          initialValue: initialValue,
          uiType: 'dateRange',
          minDate: minDate,
          maxDate: maxDate,
        }),
      ],
    });
  }
}
