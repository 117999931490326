import { FormControl, Validators } from '@angular/forms';

import { TranslateObject } from '@app/_helpers/transform/translateObject.transform';

export class CustomFormDialogFieldConfig<DataType> {
  name!: string;
  label!: TranslateObject;
  required: boolean = false;
  requiredErrorText: TranslateObject = { text: 'Global.Errors.FieldIsRequired', translate: true };
  initialValue!: DataType;
  uiType!: 'text' | 'textarea' | 'mask' | 'number' | 'date' | 'dateRange';
  step?: number = 1;
  mask?: string;
  specialCharacters: string[] = [];
  maxlength: number = 256;
  textareaRows: number = 2;
  min: number | null = null;
  max: number | null = null;
  minDate?: Date;
  maxDate?: Date;

  constructor(obj: Partial<CustomFormDialogFieldConfig<DataType>>) {
    Object.assign(this, obj);
  }

  getFormControl(): FormControl {
    return new FormControl<DataType>(this.initialValue, this.required ? [Validators.required] : []);
  }
}
