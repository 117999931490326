import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommandResult } from '@app/_models/command/commandResult';
import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { Observable } from 'rxjs';

import { BaseApiService } from './baseApi.service';

@Injectable({
  providedIn: 'root',
})
export class SmsService extends BaseApiService {
  constructor(private http: HttpClient) {
    super('sms');
  }

  sendVerificationSms(): Observable<CommandResultWithValue<number | undefined>> {
    return this.http.post<CommandResultWithValue<number | undefined>>(this.baseControllerUrl + '/send', undefined);
  }

  verifySmsCode(code: number): Observable<CommandResult> {
    return this.http.get<CommandResult>(`${this.baseControllerUrl}/verify/${code}`);
  }
}
