import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractTerminationTypeTransform',
})
export class ContractTerminationTypeTransformPipe implements PipeTransform {
  public static contractTerminationTypeMap = new Map([
    [0, 'Contract.TerminationType.TerminatedByUser'],
    [1, 'Contract.TerminationType.TerminatedByInsurance'],
    [2, 'Contract.TerminationType.AutomaticallyTerminated'],
  ]);

  public static contractTerminationTypeInSentenceMap = new Map([
    [0, 'Contract.TerminationType.TerminatedByUserInSentence'],
    [1, 'Contract.TerminationType.TerminatedByInsuranceInSentence'],
    [2, 'Contract.TerminationType.AutomaticallyTerminatedInSentence'],
  ]);

  transform(contractTerminationType: number | undefined, ...args: any[]): string {
    if (contractTerminationType == null) {
      return '';
    }

    if (args.length == 0) {
      if (ContractTerminationTypeTransformPipe.contractTerminationTypeMap.has(contractTerminationType)) {
        var contractTerminationTypeText =
          ContractTerminationTypeTransformPipe.contractTerminationTypeMap.get(contractTerminationType);
        return contractTerminationTypeText ? contractTerminationTypeText : '';
      } else {
        return '';
      }
    } else if ((args[0] = 'InSentence')) {
      if (ContractTerminationTypeTransformPipe.contractTerminationTypeInSentenceMap.has(contractTerminationType)) {
        var contractTerminationTypeText =
          ContractTerminationTypeTransformPipe.contractTerminationTypeInSentenceMap.get(contractTerminationType);
        return contractTerminationTypeText ? contractTerminationTypeText : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
