<div class="body-container">
  <app-navbar
    class="navbar navbar-sm navbar-fixed-xl navbar-expand-lg navbar-white"
    *ngIf="!hideNavbar && (accountService.currentUser$ | async)"></app-navbar>
  <app-sidebar
    [NgAceSidebar]="{ dismiss: true, swipe: true, pullup: true }"
    #sidebar="NgAceSidebar"
    NgAceSidebar
    id="sidebar"
    class="sidebar sidebar-fixed sidebar-hover sidebar-h sidebar-white sidebar-top has-open sidebar-backdrop"
    *ngIf="!hideSidebar && (accountService.currentUser$ | async)"></app-sidebar>

  <div class="main-container bgc-white">
    <div role="main" class="main-content bgc-white">
      <div class="page-content px-md-4 px-xl-5">
        <router-outlet (activate)="setCurrentComponent($event)"></router-outlet>
        <div class="footer">
          <img [src]="footerImage" *ngIf="footerImage" alt="" class="footer-image" />
        </div>
      </div>
    </div>
  </div>
</div>
