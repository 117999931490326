import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'contractDateTransform',
})
export class ContractDateTransformPipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    public translate: TranslateService,
  ) {
    super(locale);
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string,
  ): string | null {
    if (value === undefined) {
      return '';
    } else if (value === null) {
      return this.translate.instant('Global.ValidPermanent');
    } else {
      return super.transform(value, format, timezone, locale);
    }
  }
}
