import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';

import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { base64EncodeCustomPadding } from '@app/_helpers/functions/string-functions';
import { ContractStatusTransformPipe } from '@app/_helpers/transform/contract-status.transform';
import { ContractTerminationTypeTransformPipe } from '@app/_helpers/transform/contract-termination-type.transform';
import { InvoiceServiceProviderUsageTransformPipe } from '@app/_helpers/transform/invoiceServiceProvider-usage.transform';
import { InvoiceServiceProviderDto } from '@app/_models/InvoiceServiceProviderDto';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InvoiceServiceProviderService } from '@app/_services/invoiceServiceProvider.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-invoice-serviceproviders',
  templateUrl: './invoice-serviceproviders.component.html',
  styleUrls: ['./invoice-serviceproviders.component.css'],
})
export class InvoiceServiceProvidersComponent implements OnInit, AfterViewInit, RefreshableView {
  public institutionTitleMap = new Map<number, string>();

  public excelDownloadLink!: string;

  private tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'title',
      header: 'Global.Title',
      flex: '1 1 10%',
      searchType: 'Text',
    },
    {
      columnProperty: 'firstName',
      header: 'Global.FirstName',
      flex: '1 1 20%',
      searchType: 'Text',
    },
    {
      columnProperty: 'lastName',
      header: 'Global.LastName',
      flex: '1 1 20%',
      searchType: 'Text',
    },
    {
      columnProperty: 'function',
      header: 'Global.FunctionTitle',
      flex: '1 1 15%',
      searchType: 'Text',
    },
    {
      columnProperty: 'institutions',
      header: 'Global.InstitutionClinic',
      searchProperty: 'userInstitutions.InstitutionId',
      valueMap: this.institutionTitleMap,
      valueMapNewLine: true,
      disableSort: true,
      flex: '1 1 15%',
      searchType: 'Selection',
    },
    {
      columnProperty: 'usedAs',
      header: 'Global.UsedAs',
      valueMap: InvoiceServiceProviderUsageTransformPipe.usedAsMap,
      valueMapNewLine: true,
      displayTranslate: true,
      flex: '0 0 120px',
    },
    {
      columnProperty: 'createdBy',
      header: 'Global.CreatedBy',
      flex: '1 1 20%',
      searchType: 'Text',
      disableSort: true,
      customSearchFunction: (searchValue: string) => this.searchCreatedBy(searchValue),
    },
    {
      columnProperty: 'zsrNumber',
      header: 'Global.ZsrNumber',
      flex: '0 0 100px',
      searchType: 'Text',
    },
    {
      columnProperty: 'glnNumber',
      header: 'Global.GlnNumber',
      flex: '0 0 120px',
      searchType: 'Text',
    },
    {
      columnProperty: 'employedFrom',
      header: 'Global.ActiveFrom',
      displayFunction: (element: Date, row: InvoiceServiceProviderDto) => formatDateOnly(element),
      flex: '0 0 100px',
      searchType: 'DateRange',
    },
    {
      columnProperty: 'employedUntil',
      header: 'Global.ActiveUntil',
      displayFunction: (element: Date, row: InvoiceServiceProviderDto) => formatDateOnly(element),
      flex: '0 0 100px',
      searchType: 'DateRange',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InvoiceServiceProviderDto, string>;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    public invoiceServiceProviderService: InvoiceServiceProviderService,
    public institutionService: InstitutionService,
    public accountService: AccountService,
    public contractService: ContractService,
    public contractStatusTransformPipe: ContractStatusTransformPipe,
    public contractTerminationTypeTransformPipe: ContractTerminationTypeTransformPipe,
    private errorHandler: ErrorHandlerService,
    private applicationService: ApplicationService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((routeParams) => {
      this.excelDownloadLink = `api/invoiceserviceprovider/excelexport`;
      this.loadServiceProviders();
    });
  }

  ngAfterViewInit(): void {
    this.loadServiceProviders();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  loadServiceProviders(): void {
    // When component is first loaded dataTableComponent is null, so we load in ngAfterViewInit().
    if (this.dataTableComponent) {
      var getInstitutions = this.institutionService.getInstitutions();

      zip(getInstitutions).subscribe(
        (result) => {
          this.institutionTitleMap.clear();

          result[0].forEach((element) => {
            this.institutionTitleMap.set(element.id, element.title);
          });

          this.initTable();
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  initTable(): void {
    let tableSettings = <TableSettings<InvoiceServiceProviderDto, string>>{
      dataSource: new BaseTableDataServiceDataSource<InvoiceServiceProviderDto, string>(
        this.invoiceServiceProviderService,
        this.errorHandler,
      ),
      tableColumns: this.tableColumns,
      baseColumnSort: [
        { column: 'firstName', direction: 'desc' },
        { column: 'lastName', direction: 'desc' },
      ],
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  searchCreatedBy(searchValue: string): string {
    var filterValueBase64 = base64EncodeCustomPadding(searchValue);
    return `AccountType==1&(Approver.FirstName#base64:${filterValueBase64}|Approver.LastName#base64:${filterValueBase64})`;
  }
}
