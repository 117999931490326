import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';

import { GroupDto } from '@app/_models/groupDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { InsuranceDto } from '@app/_models/insuranceDto';

import { DatePipe } from '@angular/common';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { AccountType } from '@app/_models/enums/accountType';
import { GroupType } from '@app/_models/enums/groupType';
import { RoleDto } from '@app/_models/roleDto';
import { UserAdminDto } from '@app/_models/userAdminDto';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { GroupService } from '@app/_services/group.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { UserService } from '@app/_services/user.service';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';

@Component({
  selector: 'app-edit-user-container',
  templateUrl: './edit-user-container.component.html',
  styleUrls: ['./edit-user-container.component.css'],
})
export class EditUserContainerComponent implements IRowEditContainer<UserAdminDto, string, any, any> {
  dialog!: RowEditDialogComponent<UserAdminDto, string, any, any>;
  data!: UserAdminDto;
  initialData?: UserAdminDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  institutions!: InstitutionDto[];
  insurances!: InsuranceDto[];
  groups!: GroupDto[];
  roles!: RoleDto[];

  constructor(
    private institutionService: InstitutionService,
    private insuranceService: InsuranceService,
    private userService: UserService,
    private groupService: GroupService,
    public accountService: AccountService,
    private errorHandler: ErrorHandlerService,
    public translate: TranslateService,
    private datePipe: DatePipe,
  ) {}

  initializeContainer(): void {
    this.dialog.loadingSubject.next(true);

    var getInstitutions = this.institutionService.getInstitutions();
    var getInsurances = this.insuranceService.getInsurances();
    var getGroups = this.groupService.query('', 0, 100, [{ column: 'name', direction: 'asc' }]);
    var getRoles = this.userService.getRoles();

    zip(getInstitutions, getInsurances, getGroups, getRoles).subscribe(
      (result) => {
        this.institutions = result[0];
        this.insurances = result[1];
        this.groups = result[2].data;
        this.roles = result[3];

        // Only display relevant groups for this account type.
        if (this.data.accountType == AccountType.Standard) {
          this.groups = this.groups.filter((x) => x.type == GroupType.Standard);
        } else if (this.data.accountType == AccountType.Employee) {
          this.groups = this.groups.filter(
            (x) => x.type == GroupType.EmployeePerInstitution || x.type == GroupType.EmployeePerUser,
          );
        }

        this.dialog.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.dialog.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );

    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.data.title, []),
      firstName: new UntypedFormControl(this.data.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.data.lastName, [Validators.required]),
      userName: new UntypedFormControl(this.data.userName, [Validators.required]),
      email: new UntypedFormControl(this.data.email, []),
      function: new UntypedFormControl(this.data.function, []),
      institutionName: new UntypedFormControl(this.data.institutionName, []),
      street: new UntypedFormControl(this.data.street, []),
      postalCode: new UntypedFormControl(this.data.postalCode, []),
      city: new UntypedFormControl(this.data.city, []),
      lockoutEnabled: new UntypedFormControl(this.data.lockoutEnabled, []),
      lockoutEnd: new UntypedFormControl(this.datePipe.transform(this.data.lockoutEnd, 'yyyy-MM-ddTHH:mm'), []),
      institutions: new UntypedFormControl(this.data.institutions, []),
      insurances: new UntypedFormControl(this.data.insurances, []),
      mobilePhoneNumber: new UntypedFormControl(this.data.mobilePhoneNumber, {
        validators: Validators.pattern('^(\\+41[0-9]{9})$'),
        updateOn: 'blur',
      }),
      zsrNumber: new UntypedFormControl(this.data.zsrNumber, []),
      glnNumber: new UntypedFormControl(this.data.glnNumber, []),
      employedFrom: new UntypedFormControl(this.data.employedFrom, []),
      employedUntil: new UntypedFormControl(this.data.employedUntil, []),
      groups: new UntypedFormControl(this.data.groups, []),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  canIssueGroup(group: GroupDto): Observable<boolean> {
    return this.accountService.hasRole('Admin').pipe(
      map((result) => {
        if (group.adminGroup) {
          return result;
        } else {
          return true;
        }
      }),
    );
  }

  mobilePhoneNumberChange(event: any): void {
    let inputValue: any = event.target.value;
    var matchLocalNumber = inputValue.match('^0([0-9]{9})$');

    if (matchLocalNumber) {
      this.form.controls['mobilePhoneNumber'].setValue('+41' + matchLocalNumber[1]);
    }
  }

  getGroupRoleDescription(groupId: number): string {
    var descriptions: string[] = [];
    var group: GroupDto = this.groups.filter((x) => x.id == groupId)[0];

    if (group) {
      if (group.description) {
        descriptions.push(group.description);
      }

      if (group.description && group.roles.length > 0) {
        descriptions.push('');
      }

      group.roles.forEach((roleId) => {
        var role = this.roles.filter((x) => x.id == roleId)[0];

        if (role) {
          var roleDescriptionTranslated = this.translate.instant(role.description);
          descriptions.push(`${role.name} -- ${roleDescriptionTranslated}`);
        }
      });
    }

    return descriptions.join('\n');
  }

  clearLockoutEnd(): void {
    this.f['lockoutEnd'].setValue(undefined);
  }
}
