import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AccountService } from '@app/_services/account.service';

@Component({
  selector: 'force-login-with-idp',
  templateUrl: './force-login-with-idp.component.html',
})
export class ForceLoginWithIdpComponent implements OnInit {
  public success = true;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.accountService.forceLoginWithIdp(params['idp'], params['postLoginRedirectPath']);
    });
  }
}
