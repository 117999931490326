<br />
<div class="table-responsive" style="width: 101%">
  <table class="table table-striped table-bordered table-hover editables" style="width: auto">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.Contract' | translate }}</th>
        <th>{{ 'Contract.Pdf.PdfTitle' | translate }}</th>
        <th>{{ 'Contract.Pdf.Pdf02Title' | translate }}</th>
        <th>{{ 'Contract.Pdf.Pdf03Title' | translate }}</th>
        <th>{{ 'Global.EMail' | translate }}</th>
        <th>{{ 'Global.LastChange' | translate }}</th>
        <th>{{ 'Contract.TerminationType.Title' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ getContractType(rowData.contractId)?.title }}</td>
        <td>
          <a
            href="#"
            (click)="$event.preventDefault()"
            openPdf="{{ 'api/file/' + getContractType(rowData.contractId)?.pdf }}"
            target="_blank"
            >{{ getContractType(rowData.contractId)?.pdf }}</a
          >
        </td>
        <td>
          <a
            href="#"
            (click)="$event.preventDefault()"
            openPdf="{{ 'api/file/' + getContractType(rowData.contractId)?.pdf02 }}"
            target="_blank"
            >{{ getContractType(rowData.contractId)?.pdf02 }}</a
          >
        </td>
        <td>
          <a
            href="#"
            (click)="$event.preventDefault()"
            openPdf="{{ 'api/file/' + getContractType(rowData.contractId)?.pdf03 }}"
            target="_blank"
            >{{ getContractType(rowData.contractId)?.pdf03 }}</a
          >
        </td>
        <td>{{ rowData.user?.email }}</td>
        <td>{{ rowData.changeDate | date: 'dd.MM.yyyy' }}</td>
        <td>{{ rowData.terminationType | contractTerminationTypeTransform | translate }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive" style="width: 101%">
  <table class="table table-striped table-bordered table-hover editables" style="width: auto">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.AccountType' | translate }}</th>
        <th>{{ 'Global.VVGFactorP1' | translate }}</th>
        <th>{{ 'Global.VVGFactorP2' | translate }}</th>
        <th>{{ 'Global.IndividualFactor' | translate }}</th>
        <th>{{ 'Global.OverallFactorP1' | translate }}</th>
        <th>{{ 'Global.OverallFactorP2' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ accountTypeDisplayFunction(rowData) }}</td>
        <td>{{ rowData.factorP1 }}</td>
        <td>{{ rowData.factorP2 }}</td>
        <td>{{ rowData.individualCompensation?.value }}</td>
        <td>{{ rowData.overallFactorP1 }}</td>
        <td>{{ rowData.overallFactorP2 }}</td>
      </tr>
    </tbody>
  </table>
</div>
