import { CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { Pagination } from '@app/_models/pagination';
import { QueryColumnSort } from '@app/_models/settings/queryColumnSort';
import { ITableIdBase } from '@app/_models/tableIdBase';
import { IDataTableDataSource } from './dataTableDataSource';

export class InMemoryDataSource<DataType extends ITableIdBase, IdType>
  implements IDataTableDataSource<DataType, IdType>
{
  private dataSubject = new BehaviorSubject<DataType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private data: Array<DataType>;
  private currentPageIndex: number | undefined;
  private currentPageSize: number | undefined;
  private currentSort: QueryColumnSort[] | undefined;
  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0;
  public paginationData?: Pagination<DataType>;

  constructor(initialData: Array<DataType> = []) {
    this.data = initialData;
  }

  get(id: any): Observable<DataType> {
    return new Observable<DataType>((subscriber) => {
      subscriber.next(undefined);
    });
  }

  update(data: DataType): Observable<CommandResult> {
    return new Observable<CommandResult>((subscriber) => {
      subscriber.next(<CommandResult>{ success: false });
    });
  }

  updateMultiple(data: DataType[]): Observable<CommandResult> {
    return new Observable<CommandResult>((subscriber) => {
      subscriber.next(<CommandResult>{ success: false });
    });
  }

  create(data: DataType): Observable<CommandResultWithValue<IdType>> {
    return new Observable<CommandResultWithValue<IdType>>((subscriber) => {
      if (this.paginationData) {
        this.data.push(data);
        this.refreshData();

        subscriber.next(<CommandResultWithValue<IdType>>{ success: true });
      } else {
        subscriber.next(<CommandResultWithValue<IdType>>{ success: false });
      }
    });
  }

  delete(data: DataType): Observable<CommandResult> {
    return new Observable<CommandResult>((subscriber) => {
      if (this.paginationData) {
        this.data.splice(this.data.indexOf(data), 1);
        this.refreshData();

        subscriber.next(<CommandResult>{ success: true });
      } else {
        subscriber.next(<CommandResult>{ success: false });
      }
    });
  }

  loadData(
    filter: string,
    pageIndex: number | undefined,
    pageSize: number | undefined,
    sort: QueryColumnSort[] | undefined,
  ) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.currentSort = sort;

    this.refreshData();
  }

  public refreshData(): void {
    if (this.currentSort && this.currentSort.length > 0) {
      this.data.sort((a: DataType, b: DataType) => {
        if (this.currentSort && this.currentSort.length > 0) {
          var sortResults: number[] = [];

          for (var sort of this.currentSort) {
            var columnSplit = sort.column.split('.');
            var valueA: any;
            var valueB: any;
            var comparatorResult: any;

            for (var column of columnSplit) {
              if (valueA && valueB) {
                valueA = valueA[column];
                valueB = valueB[column];
              } else {
                valueA = a[column];
                valueB = b[column];
              }
            }

            if (typeof valueA == 'string') {
              comparatorResult = valueA.localeCompare(valueB);
            } else {
              comparatorResult = valueA - valueB;
            }

            sortResults.push(comparatorResult * (sort.direction == 'asc' ? 1 : -1));
          }

          return sortResults.reduce((acc, cur) => acc + cur, 0);
        } else {
          return 1;
        }
      });
    }

    if (this.currentPageIndex != undefined && this.currentPageSize) {
      this.paginationData = <Pagination<DataType>>{
        data: this.data.slice(this.currentPageIndex, this.currentPageIndex * this.currentPageSize),
      };
    } else {
      this.paginationData = <Pagination<DataType>>{ data: this.data };
    }

    this.totalCount = this.data.length;
    this.dataSubject.next(this.paginationData.data);
  }

  connect(collectionViewer: CollectionViewer): Observable<DataType[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
