import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, zip } from 'rxjs';

import { UserProfileDto } from '@app/_models/userProfileDto';
import { UserService } from '@app/_services/user.service';

import { AppComponent } from '@app/app.component';
import { InstitutionDto } from '@app/_models/institutionDto';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public userProfileDto: UserProfileDto = {};
  public appComponent = AppComponent;
  institutions!: InstitutionDto[];
  userProfileForm!: UntypedFormGroup;
  submitted: boolean = false;
  isLoggedInWithIDP = false;

  email: string = '';
  firstname: string = '';
  lastname: string = '';

  constructor(
    public userService: UserService,
    public accountService: AccountService,
    public oauthService: OAuthService,
    public translate: TranslateService,
    private institutionService: InstitutionService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.loadData();

    if (this.oauthService.hasValidAccessToken()) {
      const decodedToken: any = new JwtHelperService().decodeToken(this.oauthService.getAccessToken());
      const idp = decodedToken.identity_provider;
      this.isLoggedInWithIDP = !!idp;

      this.email = decodedToken.email;
      this.firstname = decodedToken.given_name;
      this.lastname = decodedToken.family_name;
    }
  }

  refreshView(): void {
    this.loadData();
  }

  loadData(): void {
    var getUserProfile = this.userService.getUserProfile();
    var getInstitutions = this.institutionService.getInstitutions();

    zip(getUserProfile, getInstitutions).subscribe(
      (result) => {
        this.userProfileDto = result[0];
        this.institutions = result[1];

        this.initForm();
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  initForm(): void {
    this.userProfileForm = new UntypedFormGroup({
      language: new UntypedFormControl(this.userProfileDto.language, []),
      institutionName: new UntypedFormControl(this.userProfileDto.institutionName, []),
      title: new UntypedFormControl(this.userProfileDto.title, []),
      function: new UntypedFormControl(this.userProfileDto.function, []),
      mobilePhoneNumber: new UntypedFormControl(this.userProfileDto.mobilePhoneNumber, {
        validators: Validators.pattern('^(\\+41[0-9]{9})$'),
        updateOn: 'blur',
      }),
      street: new UntypedFormControl(this.userProfileDto.street, []),
      postalCode: new UntypedFormControl(this.userProfileDto.postalCode, []),
      city: new UntypedFormControl(this.userProfileDto.city, []),
      zsrNumber: new UntypedFormControl(this.userProfileDto.zsrNumber, {
        validators: Validators.pattern('(^[a-zA-Z]\\d{6}$)|(^\\d{6}[a-zA-Z]$)'),
        updateOn: 'blur',
      }),
      glnNumber: new UntypedFormControl(this.userProfileDto.glnNumber, {
        validators: Validators.pattern('^\\d{13}$'),
        updateOn: 'blur',
      }),
      institutions: new UntypedFormControl(this.userProfileDto.institutions, []),
    });

    this.userProfileForm.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.userProfileDto[key] = changes[key];
      }
    });

    // Trigger form validation now
    this.userProfileForm.markAllAsTouched();
  }

  get f() {
    return this.userProfileForm.controls;
  }

  save(): void {
    this.loadingSubject.next(true);
    this.userService.updateUserProfile(this.userProfileDto).subscribe(
      (result) => {
        if (this.userProfileDto.language) {
          this.accountService.setUserLanguage(this.userProfileDto.language);
        }

        this.loadingSubject.next(false);
        this.submitted = true;
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  mobilePhoneNumberChange(event: any): void {
    let inputValue: any = event.target.value;
    var matchLocalNumber = inputValue.match('^0([0-9]{9})$');

    if (matchLocalNumber) {
      this.userProfileForm.controls['mobilePhoneNumber'].setValue('+41' + matchLocalNumber[1]);
    }
  }

  changePersonalInfo() {
    window.open(this.oauthService.issuer + '/account/#/personal-info');
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (this.oauthService.tokenEndpoint) {
      this.oauthService.refreshToken().then(() => this.ngOnInit());
    }
  }
}
