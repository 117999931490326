import { Pipe, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

/*
  Allows custom tags using NgDompurifySanitizer in [innerHtml].
  Check DOMPURIFY_CONFIG in app.module.ts for allowed tags.
*/
@Pipe({ name: 'safeHtml' })
export class SafeHtml {
  constructor(
    private sanitizer: DomSanitizer,
    private dompurifySanitizer: NgDompurifySanitizer,
  ) {}

  transform(input: any) {
    if (typeof input === 'string') {
      const sanitizedHtml = this.dompurifySanitizer.sanitize(SecurityContext.HTML, input);
      return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
    }

    return input;
  }
}
