<div class="modal-header">
  <h4 class="modal-title pull-left">{{ data.title }}</h4>
</div>
<div class="modal-body" [innerHTML]="data.text | safeHtml"></div>
<div class="modal-footer">
  <ng-container>
    <button *ngIf="data.button_cancel_text" type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ data.button_cancel_text }}
    </button>
    <button *ngIf="data.button_confirm_text" type="button" class="btn btn-secondary" (click)="onConfirm()">
      {{ data.button_confirm_text }}
    </button>
  </ng-container>
</div>
