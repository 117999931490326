<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ employeeGroup?.name }}</h1>
  </div>
  <div class="header-toolbar">
    <button
      *ngIf="objectPermission?.canExport"
      [downloadFile]="excelDownloadLink"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.ExcelExport' | translate }}
    </button>
  </div>
</div>
<div class="form-check" *ngIf="!employeeGroup?.invoiceGroup">
  <div class="form-check">
    <input
      id="filterContractsToDo"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="filterContractsToDo"
      (ngModelChange)="filterContractsToDoChanged()" />
    <label class="form-check-label" for="filterContractsToDo">{{
      'Employee.Management.FilterContractToDo' | translate
    }}</label>
  </div>
</div>
<app-data-table id="employeeManagement"></app-data-table>
