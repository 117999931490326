import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseTableDataService } from './baseTableData.service';

import { RuleContextDto } from '@app/_models/ruleContextDto';
import { RuleDto } from '@app/_models/ruleDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RuleService extends BaseTableDataService<RuleDto, number> {
  constructor(protected http: HttpClient) {
    super('rule', '', http);
  }

  getRuleContexts(): Observable<RuleContextDto[]> {
    return this.http.get<RuleContextDto[]>(this.baseControllerUrl + '/contexts');
  }
}
