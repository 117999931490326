import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { CatalogItemRowDetailsComponent } from '@app/_controls/row-detail-views/catalog-item-row-details/catalog-item-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { CatalogItemColumn } from '@app/_dialogs/row-edit-dialog/containers/edit-catalogitem-container/catalogItemColumn';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { CatalogService } from '@app/_services/catalog.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-catalogview-history',
  templateUrl: './catalogview-history.component.html',
  styleUrls: ['./catalogview-history.component.css'],
})
export class CatalogViewHistoryDialogComponent implements OnInit {
  public catalogId!: number;
  public catalogViewId!: number;
  public column01!: string;
  public tableColumns!: Array<TableColumn>;
  public displayedColumns!: string[];
  public rowDetailColumns!: Array<CatalogItemColumn>;
  public tableWidth!: string;

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<CatalogItemDto, number>;

  constructor(
    public catalogService: CatalogService,
    private errorHandler: ErrorHandlerService,
    public modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    var additionalParams = new HttpParams().set('catalogViewId', this.catalogViewId);

    let tableSettings = <TableSettings<CatalogItemDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<CatalogItemDto, number>(
        this.catalogService,
        this.errorHandler,
        additionalParams,
      ),
      tableColumns: this.tableColumns,
      baseFilter: this.getDataTableBaseFilter(),
      baseColumnSort: [{ column: 'validFrom', direction: 'asc' }],
      rowDetailsContainerType: this.rowDetailColumns.length > 0 ? CatalogItemRowDetailsComponent : undefined,
      getAdditionalParams: additionalParams,
      updateAdditionalParams: additionalParams,
      createAdditionalParams: additionalParams,
      tableDivStyle: 'overflow: auto; height: calc(60vh - 132px);',
      displayedColumns: this.displayedColumns,
      tableWidth: this.tableWidth,
    };

    this.dataTableComponent.rowDetailsData = this.rowDetailColumns;

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  getDataTableBaseFilter(): string | undefined {
    var baseFilter = `catalogId==${this.catalogId}&column01==${this.column01}`;

    return baseFilter;
  }

  public onClose(): void {
    this.modalRef.hide();
  }

  static showDialog(
    modalService: BsModalService,
    catalogId: number,
    catalogViewId: number,
    column01: string,
    tableColumns: Array<TableColumn>,
    displayedColumns: string[],
    rowDetailColumns: Array<CatalogItemColumn>,
    tableWidth: string,
  ): BsModalRef<CatalogViewHistoryDialogComponent> {
    var modalRef = modalService.show(CatalogViewHistoryDialogComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        catalogId: catalogId,
        catalogViewId: catalogViewId,
        column01: column01,
        tableColumns: tableColumns,
        displayedColumns: displayedColumns,
        rowDetailColumns: rowDetailColumns,
        tableWidth: tableWidth,
      },
    });

    return modalRef;
  }
}
