<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Global.Name' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="name"
        class="form-control"
        placeholder="{{ 'Global.Name' | translate }}"
        maxlength="256" />
      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
        <div *ngIf="f.name.errors?.required">
          {{ 'User.Groups.Errors.NameRequired' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label pt-0">{{ 'Info.LinkActionHeader' | translate }}</label>
    <div class="col-sm-10">
      <mat-radio-group formControlName="linkAction">
        <mat-radio-button
          class="d-flex flex-column"
          *ngFor="let linkAction of linkActions | keyvalue"
          value="{{ linkAction.key }}"
          (change)="linkActionChanged($event)">
          {{ linkAction.value | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{ 'Info.LinkHeader' | translate }}</label>
    <div class="col-sm-10">
      <input
        type="text"
        formControlName="link"
        class="form-control"
        placeholder="{{ 'Info.LinkHeader' | translate }}"
        maxlength="256"
        [typeahead]="filteredFiles"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="4"
        [typeaheadOptionsLimit]="9999" />
      <div *ngIf="f.link.invalid && (f.link.dirty || f.link.touched)" class="alert alert-danger">
        <div *ngIf="f.link.errors?.required">
          {{ 'Dialog.InfoLinkDialog.Errors.LinkRequired' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label pt-0">{{ 'Info.IsPublic' | translate }}</label>
    <div class="col-sm-10">
      <mat-checkbox formControlName="isPublic"></mat-checkbox>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label pt-0">{{ 'Info.TranslateName' | translate }}</label>
    <div class="col-sm-10">
      <mat-checkbox formControlName="translateName"></mat-checkbox>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label pt-0">{{ 'Info.Order' | translate }}</label>
    <div class="col-sm-10">
      <input type="number" formControlName="order" class="form-control" placeholder="{{ 'Info.Order' | translate }}" />
    </div>
  </div>
</form>
