import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.css'],
})
export class PdfViewerDialogComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public title!: string;
  public pdfSrc!: string;
  public bearerToken?: string;

  constructor(public modalRef: BsModalRef) {
    // Fixes problem with print orientation.
    pdfDefaultOptions.enablePrintAutoRotate = false;
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
  }

  static showDialog(
    modalService: BsModalService,
    title: string,
    pdfSrc: string,
    bearerToken: string | undefined = undefined,
  ): BsModalRef<PdfViewerDialogComponent> {
    var modalRef = modalService.show(PdfViewerDialogComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        title: title,
        pdfSrc: pdfSrc,
        bearerToken: 'Bearer ' + bearerToken,
      },
    });

    return modalRef;
  }

  onClose(): void {
    this.modalRef.hide();
  }

  pdfLoaded(): void {
    this.loadingSubject.next(false);
  }

  pdfLoadingFailed(event: any): void {
    this.loadingSubject.next(false);
  }
}
