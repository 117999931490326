<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row" *ngIf="patient">
    <label class="col-sm-4 col-form-label">Patient</label>
    <div class="col-sm-8">
      <div>{{ patient.firstName }} {{ patient.lastName }}</div>
      <div>{{ patient.dateOfBirth | date: 'dd.MM.yyyy' }}</div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.HospitalizationFrom' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="dateFrom"
        class="form-control"
        placeholder="{{ 'Global.HospitalizationFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div *ngIf="f.dateFrom.invalid && (f.dateFrom.dirty || f.dateFrom.touched)" class="alert alert-danger">
        <div *ngIf="f.dateFrom.errors?.required">
          {{ 'User.Invoices.Errors.HospitalizationFromRequired' | translate }}
        </div>
        <div *ngIf="f.dateFrom.errors?.dateIsAfter">
          {{ 'User.Invoices.Errors.HospitalizationFromAfterHospitalizationUntil' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.HospitalizationUntil' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="dateTo"
        class="form-control"
        placeholder="{{ 'Global.HospitalizationUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div *ngIf="f.dateTo.invalid && (f.dateTo.dirty || f.dateTo.touched)" class="alert alert-danger">
        <div *ngIf="f.dateTo.errors?.required">
          {{ 'User.Invoices.Errors.HospitalizationUntilRequired' | translate }}
        </div>
        <div *ngIf="f.dateTo.errors?.dateIsBefore">
          {{ 'User.Invoices.Errors.HospitalizationUntilBeforeHospitalizationFrom' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.InstitutionClinic' | translate }}</label>
    <div class="col-sm-8">
      <select
        class="form-control"
        disabled="true"
        [(ngModel)]="selectedInstitution"
        [ngModelOptions]="{ standalone: true }">
        <option [ngValue]="selectedInstitution">
          {{ selectedInstitution?.title }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Contract' | translate }}</label>
    <div class="col-sm-8">
      <select formControlName="contractId" class="form-control" [(ngModel)]="selectedContract">
        <option [ngValue]="selectedContract">
          {{ selectedContract?.title }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Insurance' | translate }}</label>
    <div class="col-sm-8">
      <select
        class="form-control"
        disabled="true"
        [(ngModel)]="selectedInsurance"
        [ngModelOptions]="{ standalone: true }">
        <option [ngValue]="selectedInsurance">{{ selectedInsurance?.title }}</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.InsuranceType' | translate }}</label>
    <div class="col-sm-8">
      <select formControlName="insuranceType" class="form-control">
        <option *ngFor="let key of invoiceInsuranceTypes" [ngValue]="key">
          {{ key | invoiceInsuranceTypeTransform | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{
      'Dialog.EditInvoiceContainerComponent.CaseNumberOptional' | translate
    }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="caseNumber"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.CaseNumber' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row" *ngIf="selectedInstitution?.allowPidOnInvoice">
    <label class="col-sm-4 col-form-label">{{ 'Dialog.EditInvoiceContainerComponent.PIDOptional' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="pid"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.PID' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.BESR' | translate }}</label>
    <div class="col-sm-8">
      <besr-input formControlName="besr" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Memo' | translate }}</label>
    <div class="col-sm-8">
      <textarea
        rows="2"
        type="text"
        formControlName="memo"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.Memo' | translate }}"
        maxlength="5000"></textarea>
    </div>
  </div>
</form>
