import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseTableDataService } from './baseTableData.service';

import { CommandResult } from '@app/_models/command/commandResult';
import { EventLogDto } from '@app/_models/eventLogDto';

@Injectable({
  providedIn: 'root',
})
export class EventLogService extends BaseTableDataService<EventLogDto, number> {
  constructor(protected http: HttpClient) {
    super('eventlog', '', http);
  }

  resendEmails(eventLogIds: number[]): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/resendemails', eventLogIds);
  }
}
