import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { zip } from 'rxjs';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { UserManagementRowDetailsComponent } from '@app/_controls/row-detail-views/user-management-row-details/user-management-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditUserContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-user-container/edit-user-container.component';
import { AccountTypeTransformPipe } from '@app/_helpers/transform/account-type.transform';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { ObjectPermissionQueryResultDto } from '@app/_models/objectPermissionQueryResultDto';
import { UserAdminDto } from '@app/_models/userAdminDto';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { GroupService } from '@app/_services/group.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { UserService } from '@app/_services/user.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, AfterViewInit, RefreshableView {
  baseFilter?: string;
  public objectPermission?: ObjectPermissionQueryResultDto;
  public institutionTitleMap = new Map<number, string>();

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<UserAdminDto, string>;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private groupService: GroupService,
    public accountService: AccountService,
    private insuranceService: InsuranceService,
    public institutionService: InstitutionService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.url.length > 2 && this.route.snapshot.url[2].path == 'pending') {
      this.baseFilter = 'lockoutEnabled==true&&accountType==0';
    }
  }

  ngAfterViewInit(): void {
    var getInsurances = this.insuranceService.getInsurances();
    var getGroups = this.groupService.query('', 0, 100, [{ column: 'name', direction: 'asc' }]);
    var getObjectPermission = this.accountService.getObjectPermission(PermissionObject.User, -1);
    var getInstitutions = this.institutionService.getInstitutions();

    zip(getInsurances, getGroups, getObjectPermission, getInstitutions).subscribe(
      (result) => {
        this.dataTableComponent.rowDetailsData.insurances = result[0];

        let groupMap = new Map<number, string>();

        result[1].data.forEach((element) => {
          groupMap.set(element.id, element.name);
        });

        this.objectPermission = result[2];

        this.institutionTitleMap.clear();

        result[3].forEach((element) => {
          this.institutionTitleMap.set(element.id, element.title);
        });

        this.initTable(groupMap);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  initTable(groupMap: Map<number, string>): void {
    let tableColumns: Array<TableColumn> = [
      {
        columnProperty: 'title',
        header: 'Global.Title',
        searchType: 'Text',
        flex: '1 1 13%',
      },
      {
        columnProperty: 'function',
        header: 'Global.FunctionTitle',
        searchType: 'Text',
        flex: '1 1 13%',
      },
      {
        columnProperty: 'firstName',
        header: 'Global.FirstName',
        searchType: 'Text',
        flex: '1 1 13%',
      },
      {
        columnProperty: 'lastName',
        header: 'Global.LastName',
        searchType: 'Text',
        flex: '1 1 13%',
      },
      {
        columnProperty: 'email',
        header: 'Global.EMail',
        searchType: 'Text',
        flex: '1 1 13%',
      },
      {
        columnProperty: 'institutions',
        header: 'Global.Institutions',
        searchProperty: 'userInstitutions.InstitutionId',
        searchType: 'Selection',
        valueMap: this.institutionTitleMap,
        valueMapNewLine: true,
        disableSort: true,
        flex: '1 1 13%',
      },
      {
        columnProperty: 'accountType',
        header: 'Global.AccountType',
        searchType: 'Selection',
        valueMap: AccountTypeTransformPipe.accountTypeMap,
        displayTranslate: true,
        flex: '0 0 110px',
      },
      {
        columnProperty: 'groups',
        header: 'Global.Groups',
        searchProperty: 'userGroups.GroupId',
        searchType: 'Selection',
        disableSort: true,
        valueMap: groupMap,
        flex: '1 1 22%',
      },
    ];

    let tableSettings = <TableSettings<UserAdminDto, string>>{
      dataSource: new BaseTableDataServiceDataSource<UserAdminDto, string>(this.userService, this.errorHandler),
      tableColumns: tableColumns,
      rowActions: this.rowActions,
      rowEditContainer: EditUserContainerComponent,
      rowEditContainerStyle: 'modal-1200',
      rowDetailsContainerType: UserManagementRowDetailsComponent,
      editRowTitle: 'User.Management.EditUser',
      deleteRowTitle: 'User.Management.DeleteUser',
      deleteRowText: 'User.Management.DeleteUserText',
      baseFilter: this.baseFilter,
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }
}
