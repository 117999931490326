import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check if individualCompensation factor value is unique.
export function individualCompensationUnique(formArray: UntypedFormArray): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!formArray || !control.value) return null;

    let value: number = control.get('value')?.value as number;

    let notUnique = formArray.controls.findIndex((x) => x != control && x.get('value')?.value == value) != -1;

    if (!notUnique) {
      return null;
    }

    return { notUnique: true };
  };
}
