import { Component, Input, OnInit } from '@angular/core';

import { InsuranceDto } from '@app/_models/insuranceDto';
import { UserAdminDto } from '@app/_models/userAdminDto';
import { IRowDetailView } from '../row-detail-view.interface';

@Component({
  selector: 'app-user-management-row-details',
  templateUrl: './user-management-row-details.component.html',
  styleUrls: ['./user-management-row-details.component.scss'],
})
export class UserManagementRowDetailsComponent implements OnInit, IRowDetailView<UserAdminDto> {
  @Input()
  rowData!: UserAdminDto;

  @Input()
  additionalData?: any;

  constructor() {}

  ngOnInit(): void {}

  getInsurance(insuranceId: number): InsuranceDto | undefined {
    if (this.additionalData.insurances) {
      let insurances: InsuranceDto[] = this.additionalData.insurances;

      return insurances.find((x) => x.id == insuranceId);
    }

    return undefined;
  }
}
