import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'catalogItemTypeTransform',
})
export class CatalogItemTypeTransformPipe implements PipeTransform {
  public static catalogItemTypeMap = new Map([
    [0, 'Catalog.CatalogItemTypes.TariffPosition'],
    [1, 'Catalog.CatalogItemTypes.Visit'],
    [2, 'Catalog.CatalogItemTypes.Surcharge'],
    [3, 'Catalog.CatalogItemTypes.FlatRateForMainServiceProvider'],
    [4, 'Catalog.CatalogItemTypes.FlatRateForAssistant'],
    [5, 'Catalog.CatalogItemTypes.FlatRateSurcharge'],

    [100, 'Catalog.CatalogItemTypes.FirstAssistant'],
    [101, 'Catalog.CatalogItemTypes.AdditionalAssistant'],
  ]);

  transform(catalogItemType: number): string {
    if (CatalogItemTypeTransformPipe.catalogItemTypeMap.has(catalogItemType)) {
      var catalogItemTypeText = CatalogItemTypeTransformPipe.catalogItemTypeMap.get(catalogItemType);
      return catalogItemTypeText ? catalogItemTypeText : '';
    } else {
      return '';
    }
  }
}
