import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to compare date's
export function dateCompare(compareTo: string, compareDateAfter: boolean, sameDateValid: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !control.parent || !control.parent.value) return null;

    let controlDate: Date = control.value as Date;
    let compareDate: Date = (control.parent as UntypedFormGroup).controls[compareTo].value as Date;

    if (!compareDate) {
      return null;
    } else if (
      sameDateValid &&
      controlDate.getDay() == compareDate.getDay() &&
      controlDate.getMonth() == compareDate.getMonth() &&
      controlDate.getFullYear() == compareDate.getFullYear()
    ) {
      return null;
    } else if (compareDateAfter) {
      return controlDate > compareDate ? null : { dateIsBefore: true };
    } else {
      return controlDate < compareDate ? null : { dateIsAfter: true };
    }
  };
}
