<div [innerHTML]="'Invoice.ServiceProvider.Info' | translate"></div>
<br />
<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ServiceProvider' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'Global.TypeHereToSearch' | translate }}"
        matInput
        formControlName="serviceProvider"
        [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayServiceProvider.bind(this)">
        <mat-optgroup [label]="'Global.ContractDoctors' | translate">
          <mat-option *ngFor="let serviceProvider of filteredServiceProviders | async" [value]="serviceProvider">
            {{ serviceProvider.title }} {{ serviceProvider.firstName }} {{ serviceProvider.lastName }}
          </mat-option>
        </mat-optgroup>
        <mat-optgroup [label]="'Global.GuestDoctors' | translate">
          <mat-option *ngFor="let serviceProvider of filteredGuestServiceProviders | async" [value]="serviceProvider">
            <i class="fa-solid fa-person-shelter"></i> {{ serviceProvider.title }} {{ serviceProvider.firstName }}
            {{ serviceProvider.lastName }} <span>({{ 'Global.GuestDoctor' | translate }})</span>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Kind' | translate }}</label>
    <div class="col-sm-8">
      <select class="form-control" formControlName="feeKindId">
        <option [ngValue]="undefined"></option>
        <option *ngFor="let feeKind of feeKinds" [value]="feeKind.id">{{ feeKind.title }}</option>
      </select>
    </div>
  </div>
</form>

<div *ngFor="let group of invoiceEmployeeGroups">
  <div *ngIf="group.id">
    <a (click)="dialog.onCancel()" target="_blank" href="employee/management/{{ group.id }}">
      {{ 'Invoice.ServiceProvider.ManagementLinkText' | translate: { Name: group.name } }}
    </a>
    <br />
  </div>
</div>
