import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TableColumn } from '../settings/tableColumn';

@Pipe({
  name: 'tableColumnValueTransform',
})
export class TableColumnValueTransformPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(element: any, tableColumn: TableColumn, ignoreValueMap: boolean = false): any {
    var columnDefSplit = tableColumn.columnProperty.split('.');
    var returnElement = element[columnDefSplit[0]];
    for (let i = 1; i < columnDefSplit.length; i++) {
      returnElement = returnElement[columnDefSplit[1]];
    }

    let returnValue = undefined;

    if (tableColumn.displayFunction) {
      returnValue = tableColumn.displayFunction(returnElement, element);
    } else if (!ignoreValueMap && tableColumn.valueMap) {
      if (Array.isArray(returnElement)) {
        var divider = ', ';

        if (tableColumn.valueMapNewLine) {
          divider = '\n';
        }

        if (tableColumn.displayTranslate) {
          return Array.from(returnElement, (value) => this.translate.instant(tableColumn?.valueMap?.get(value)))
            .sort()
            .join(divider);
        } else {
          returnValue = Array.from(returnElement, (value) => tableColumn?.valueMap?.get(value))
            .sort()
            .join(divider);
        }
      } else {
        returnValue = tableColumn.valueMap.get(returnElement);
      }
    } else {
      returnValue = returnElement;
    }

    if (tableColumn.displayTranslate && returnValue) {
      return this.translate.instant(returnValue);
    } else {
      return returnValue;
    }
  }
}
