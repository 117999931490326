import { TranslateService } from '@ngx-translate/core';
import deBase from './base/de.json';
import frBase from './base/fr.json';
import itBase from './base/it.json';
import dePs25 from './ps25/de.json';
import frPs25 from './ps25/fr.json';
import itPs25 from './ps25/it.json';

export function loadTranslations(service: TranslateService) {
  service.setTranslation('de-CH', deBase);
  service.setTranslation('de-CH', dePs25, true);

  service.setTranslation('fr-CH', frBase);
  service.setTranslation('fr-CH', frPs25, true);

  service.setTranslation('it-CH', itBase);
  service.setTranslation('it-CH', itPs25, true);
}
