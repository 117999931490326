import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class NgxMatPaginatorIntl extends MatPaginatorIntl {
  translate?: TranslateService;
  itemsPerPageLabel = 'Items per page';
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const of = this.translate ? this.translate.instant('Providers.Paginator.Of_Label') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate?.instant('Providers.Paginator.ItemsPerPage_Label');
    this.nextPageLabel = this.translate?.instant('Providers.Paginator.NextPage_Label');
    this.previousPageLabel = this.translate?.instant('Providers.Paginator.PreviousPage_Label');
    this.firstPageLabel = this.translate?.instant('Providers.Paginator.FirstPage_Label');
    this.lastPageLabel = this.translate?.instant('Providers.Paginator.LastPage_Label');
    this.changes.next();
  }
}
