export enum CatalogItemType {
  /// General CatalogItem Types
  TariffPosition = 0,
  Visit = 1,
  Surcharge = 2,
  FlatRateForMainServiceProvider = 3,
  FlatRateForAssistant = 4,
  FlatRateSurcharge = 5,

  /// Assistant Types (Range 100 to 199)
  FirstAssistant = 100,
  AdditionalAssistant = 101,
}
