import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';

import { FileService } from '@app/_services/file.service';

@Directive({
  selector: '[openPdf]',
})
export class OpenPdfDirective {
  constructor(
    private readonly fileService: FileService,
    private readonly httpClient: HttpClient,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private errorHandler: ErrorHandlerService,
  ) {}

  private static downloadUrlPrefixOld: string = 'api/download/file/';
  private static downloadUrlPrefix: string = 'api/file/';

  private downloadUrl!: string;

  @Input('openPdf')
  public set url(url: string) {
    this.downloadUrl = url;
  }

  @HostListener('click', ['$event'])
  public async onClick(event: any): Promise<void> {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');

    // To avoid popup blockers we open the window right now.
    var pdfWindow: any = window.open('/assets/html/pleasewait.html', '_blank');
    pdfWindow.focus();

    if (this.downloadUrl.startsWith(OpenPdfDirective.downloadUrlPrefix)) {
      var fileName = this.downloadUrl.replace(OpenPdfDirective.downloadUrlPrefix, '');
      this.downloadFileUsingDownloadToken(pdfWindow, fileName);
    } else if (this.downloadUrl.startsWith(OpenPdfDirective.downloadUrlPrefixOld)) {
      var fileName = this.downloadUrl.replace(OpenPdfDirective.downloadUrlPrefixOld, '');
      this.downloadFileUsingDownloadToken(pdfWindow, fileName);
    } else {
      this.downloadFileUsingOAuth(pdfWindow, this.downloadUrl);
    }
  }

  downloadFileUsingDownloadToken(pdfWindow: any, fileName: string): void {
    // Get download token for file.
    this.fileService.getDownloadTokenForFile(fileName).subscribe(
      (response) => {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');

        // Create an URL using the generated token.
        const url = `/api/file?token=${response.token}`;
        pdfWindow.location.href = url;
      },
      (errorResponse: HttpErrorResponse) => {
        pdfWindow.close();
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  downloadFileUsingOAuth(pdfWindow: any, downloadUrl: string): void {
    // Download the document as a blob
    this.httpClient.get(downloadUrl, { responseType: 'blob' }).subscribe(
      (response: any) => {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');

        let blob = new Blob([response], { type: 'application/pdf' });

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);
        pdfWindow.location.href = url;

        // Discard the object data
        URL.revokeObjectURL(url);
      },
      async (errorResponse: HttpErrorResponse) => {
        pdfWindow.close();
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }
}
