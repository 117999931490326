// Angular modules
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDE_CH from '@angular/common/locales/de-CH';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular-ivy';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

// App modules
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './app.material.module';

// Addons
import { TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { DOMPURIFY_CONFIG, NgDompurifyModule } from '@tinkoff/ng-dompurify';
import { CodeInputModule } from 'angular-code-input';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { deLocale, frLocale, itLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxTranslateDebugParser } from 'ngx-translate-debug';

// Views
import { LoginComponent } from './_views/account/login/login.component';
import { AccountRegisterComponent } from './_views/account/register/account-register.component';
import { AdminInfoComponent } from './_views/admin/admin-info/admin-info.component';
import { ContractsComponent } from './_views/admin/contracts/contracts.component';
import { EmailsComponent } from './_views/admin/emails/emails.component';
import { EventLogsComponent } from './_views/admin/event-logs/event-logs.component';
import { InstitutionsComponent } from './_views/admin/institutions/institutions.component';
import { InsurancesComponent } from './_views/admin/insurances/insurances.component';
import { RulesComponent } from './_views/admin/rules/rules.component';
import { CatalogManagementComponent } from './_views/catalog/catalog-management/catalog-management.component';
import { EmployeeManagementComponent } from './_views/employee/employee-management/employee-management.component';
import { DashboardComponent } from './_views/home/dashboard/dashboard.component';
import { InfoEditComponent } from './_views/home/info-edit/info-edit.component';
import { InfoComponent } from './_views/home/info/info.component';
import { InsurancePendingUsercontractsComponent } from './_views/insurance/insurance-pending-usercontracts/insurance-pending-usercontracts.component';
import { InsuranceUsercontractsComponent } from './_views/insurance/insurance-usercontracts/insurance-usercontracts.component';
import { InvoiceControllingComponent } from './_views/invoice/invoice-controlling/invoice-controlling.component';
import { InvoiceServiceProvidersComponent } from './_views/invoice/invoice-serviceproviders/invoice-serviceproviders.component';
import { UserContractsComponent } from './_views/user/user-contracts/user-contracts.component';
import { UserGroupsComponent } from './_views/user/user-groups/user-groups.component';
import { UserInvoiceVisitsComponent } from './_views/user/user-invoice-visits/user-invoice-visits.component';
import { UserInvoicesComponent } from './_views/user/user-invoices/user-invoices.component';
import { UserManagementComponent } from './_views/user/user-management/user-management.component';
import { UserPatientsComponent } from './_views/user/user-patients/user-patients.component';
import { UserProfileComponent } from './_views/user/user-profile/user-profile.component';

// Dialogs
import { AcceptTermsDialogComponent } from './_dialogs/accept-terms-dialog/accept-terms-dialog.component';
import { CatalogItemPreEditDialogComponent } from './_dialogs/catalogitem-preedit-dialog/catalogitem-preedit-dialog.component';
import { CatalogViewHistoryDialogComponent } from './_dialogs/catalogview-history/catalogview-history.component';
import { CustomFormDialogComponent } from './_dialogs/custom-form-dialog/custom-form-dialog.component';
import { EmailsDialogComponent } from './_dialogs/emails-dialog/emails-dialog.component';
import { FileUploadDialogComponent } from './_dialogs/file-upload-dialog/file-upload-dialog.component';
import { IframeDialogComponent } from './_dialogs/iframe-dialog/iframe-dialog.component';
import { PdfViewerDialogComponent } from './_dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { EditCatalogItemContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-catalogitem-container/edit-catalogitem-container.component';
import { EditCatalogViewContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-catalogview-container/edit-catalogview-container.component';
import { EditContractContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-contract-container/edit-contract-container.component';
import { EditEmployeeContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-employee-container/edit-employee-container.component';
import { EditInfoLinkContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-info-link-container/edit-info-link-container.component';
import { EditInstitutionContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-institution-container/edit-institution-container.component';
import { EditInsuranceContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-insurance-container/edit-insurance-container.component';
import { EditInvoiceContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-invoice-container/edit-invoice-container.component';
import { EditInvoiceSessionContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-invoice-session-container/edit-invoice-session-container.component';
import { EditInvoiceSessionServiceProviderContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-invoice-session-service-provider-container/edit-invoice-session-service-provider-container.component';
import { EditPatientContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-patient-container/edit-patient-container.component';
import { EditRuleContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-rule-container/edit-rule-container.component';
import { EditUserContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-user-container/edit-user-container.component';
import { EditUserGroupContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-user-group-container/edit-user-group-container.component';
import { EditUserContractInsuranceContainerComponent } from './_dialogs/row-edit-dialog/containers/edit-usercontract-insurance-container/edit-usercontract-insurance-container.component';
import { RowEditDialogComponent } from './_dialogs/row-edit-dialog/row-edit-dialog.component';
import { SmsVerificationDialogComponent } from './_dialogs/sms-verification-dialog/sms-verification-dialog.component';
import { TextDialogComponent } from './_dialogs/textdialog/textdialog.component';

// Controls
import { BesrInputComponent } from '@app/_controls/besr-input/besr-input.component';
import { DataTableComponent } from './_controls/data-table/data-table.component';
import { ExpandableTextComponent } from './_controls/expandable-text/expandable-text.component';
import { NavbarComponent } from './_controls/navbar/navbar.component';
import { CatalogItemRowDetailsComponent } from './_controls/row-detail-views/catalog-item-row-details/catalog-item-row-details.component';
import { ContractsRowDetailsComponent } from './_controls/row-detail-views/contracts-row-details/contracts-row-details.component';
import { EmailRowDetailsComponent } from './_controls/row-detail-views/email-row-details/email-row-details.component';
import { InsuranceUsercontractsRowDetailsComponent } from './_controls/row-detail-views/insurance-usercontracts-row-details/insurance-usercontracts-row-details.component';
import { InvoiceControllingRowDetailsComponent } from './_controls/row-detail-views/invoice-controlling-row-details/invoice-controlling-row-details.component';
import { RuleRowDetailsComponent } from './_controls/row-detail-views/rule-row-details/rule-row-details.component';
import { UserManagementRowDetailsComponent } from './_controls/row-detail-views/user-management-row-details/user-management-row-details.component';
import { SidebarComponent } from './_controls/sidebar/sidebar.component';
import { SidebarSubmenuComponent } from './_controls/sidebar/submenu.component';

// Providers
import { CacheBypassInterceptor } from './_providers/cachebypassinterceptor';
import { HttpErrorInterceptor } from './_providers/httpErrorInterceptor';
import { JsonDateInterceptor } from './_providers/jsonDateInterceptor';
import { LanguageInterceptor } from './_providers/languageInterceptor';
import { TokenInterceptor } from './_providers/tokeninterceptor';

//Directives
import { NgAceSidebar } from './_ace/directives/ace-sidebar.directive';
import { NgAceSubmenu } from './_ace/directives/ace-submenu.directive';
import { NgAceDropdownBackrop } from './_ace/directives/dropdown-backdrop.directive';
import { NgAceNavbarDropdown } from './_ace/directives/navbar-dropdown.directive';
import { DebounceClickDirective } from './_helpers/directives/debounce-click.directive';
import { DebounceKeyupDirective } from './_helpers/directives/debounce-keyup.directive';
import { DownloadFileDirective } from './_helpers/directives/download-file.directive';
import { DynamicComponentDirective } from './_helpers/directives/dynamic-component.directive';
import { FileDragNDropDirective } from './_helpers/directives/file-drag-n-drop.directive';
import { OpenPdfDialogDirective } from './_helpers/directives/open-pdf-dialog.directive';
import { OpenPdfDirective } from './_helpers/directives/open-pdf.directive';
import { RouteTransformerDirective } from './_helpers/directives/route-transformer.directive';

//Transformations
import { TableColumnValueTransformPipe } from './_controls/data-table/transform/tablecolumn-value.transform';
import { CatalogItemInvoiceTextTransformPipe } from './_helpers/transform/catalog-item-invoice-text.transform';
import { CatalogItemTypeTransformPipe } from './_helpers/transform/catalog-item-type.transform';
import { ContractCompensationTypeTransformPipe } from './_helpers/transform/contract-compensation-type.transform';
import { ContractDateTransformPipe } from './_helpers/transform/contract-date.transform';
import { ContractStatusTransformPipe } from './_helpers/transform/contract-status.transform';
import { ContractTerminationTypeTransformPipe } from './_helpers/transform/contract-termination-type.transform';
import { ContractTypeTransformPipe } from './_helpers/transform/contract-type.transform';
import { EventLogReferenceTypeTransformPipe } from './_helpers/transform/eventlog-referencetype.transform';
import { InvoiceInsuranceTypeTransformPipe } from './_helpers/transform/invoice-insurance-type.transform';
import { InvoiceStatusTransformPipe } from './_helpers/transform/invoice-status.transform';
import { NewlineBrReplace } from './_helpers/transform/newline-br.transform';
import { NullEmptyStringTransform } from './_helpers/transform/null-empty-string.transform';
import { RoundTransformPipe } from './_helpers/transform/round.transform';
import { RuleResultTypeTransformPipe } from './_helpers/transform/rule-result-type.transform';
import { SafeHtml } from './_helpers/transform/safe-html.transform';
import { TranslateObjectPipe } from './_helpers/transform/translateObject.transform';

//Paginator translation
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ContractContactInformationDialog } from '@app/_dialogs/contract-contact-information-dialog/contract-contact-information-dialog.component';
import { AccountHinLinkedComponent } from '@app/_views/account/hin-linked/account-hin-linked.component';
import { UserInvoiceCreateComponent } from '@app/_views/user/user-invoice-create/user-invoice-create.component';
import { environment } from '../environments/environment';
import { NgxMatPaginatorIntl } from './_providers/ngxmatpaginatorintl';
import { UserInvoiceComponent } from './_views/user/user-invoice/user-invoice.component';

// ngx-bootstrap de locale
defineLocale('de-ch', deLocale);
defineLocale('fr-ch', frLocale);
defineLocale('it-ch', itLocale);

registerLocaleData(localeDE_CH, 'de-CH');

@NgModule({
  declarations: [
    AppComponent,
    ExpandableTextComponent,
    BesrInputComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarSubmenuComponent,
    LoginComponent,
    AccountRegisterComponent,
    AccountHinLinkedComponent,
    UserInvoicesComponent,
    UserPatientsComponent,
    UserInvoiceVisitsComponent,
    UserInvoiceCreateComponent,
    InvoiceControllingComponent,
    InvoiceServiceProvidersComponent,
    CatalogViewHistoryDialogComponent,
    TextDialogComponent,
    CustomFormDialogComponent,
    SmsVerificationDialogComponent,
    DataTableComponent,
    DashboardComponent,
    InfoComponent,
    InfoEditComponent,
    RowEditDialogComponent,
    EmailsDialogComponent,
    FileUploadDialogComponent,
    EditInfoLinkContainerComponent,
    CatalogItemPreEditDialogComponent,
    ContractContactInformationDialog,
    EditPatientContainerComponent,
    EditUserContainerComponent,
    EditUserGroupContainerComponent,
    EditEmployeeContainerComponent,
    EditUserContractInsuranceContainerComponent,
    EditCatalogViewContainerComponent,
    EditCatalogItemContainerComponent,
    EditInvoiceContainerComponent,
    EditInvoiceSessionContainerComponent,
    EditInvoiceSessionServiceProviderContainerComponent,
    EditRuleContainerComponent,
    EditInsuranceContainerComponent,
    EditInstitutionContainerComponent,
    EditContractContainerComponent,
    PdfViewerDialogComponent,
    AcceptTermsDialogComponent,
    IframeDialogComponent,
    UserManagementComponent,
    UserGroupsComponent,
    NgAceSidebar,
    NgAceSubmenu,
    NgAceNavbarDropdown,
    NgAceDropdownBackrop,
    DebounceKeyupDirective,
    DebounceClickDirective,
    DownloadFileDirective,
    OpenPdfDialogDirective,
    OpenPdfDirective,
    EventLogReferenceTypeTransformPipe,
    ContractStatusTransformPipe,
    ContractCompensationTypeTransformPipe,
    ContractDateTransformPipe,
    ContractTerminationTypeTransformPipe,
    SafeHtml,
    NewlineBrReplace,
    NullEmptyStringTransform,
    CatalogItemTypeTransformPipe,
    CatalogItemInvoiceTextTransformPipe,
    InvoiceInsuranceTypeTransformPipe,
    RuleResultTypeTransformPipe,
    InvoiceStatusTransformPipe,
    RoundTransformPipe,
    ContractTypeTransformPipe,
    TranslateObjectPipe,
    TableColumnValueTransformPipe,
    UserProfileComponent,
    UserContractsComponent,
    EmployeeManagementComponent,
    InfoComponent,
    AdminInfoComponent,
    EventLogsComponent,
    EmailsComponent,
    RulesComponent,
    InsurancesComponent,
    InstitutionsComponent,
    ContractsComponent,
    InsuranceUsercontractsComponent,
    InsurancePendingUsercontractsComponent,
    CatalogManagementComponent,
    InsuranceUsercontractsRowDetailsComponent,
    UserManagementRowDetailsComponent,
    CatalogItemRowDetailsComponent,
    EmailRowDetailsComponent,
    RuleRowDetailsComponent,
    InvoiceControllingRowDetailsComponent,
    ContractsRowDetailsComponent,
    DynamicComponentDirective,
    UserInvoicesComponent,
    UserInvoiceComponent,
    RouteTransformerDirective,
    FileDragNDropDirective,
  ],
  imports: [
    CodeInputModule,
    MaterialModule,
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [window.location.origin + '/api'],
        sendAccessToken: true,
      },
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      parser: { provide: TranslateParser, useClass: NgxTranslateDebugParser },
    }),
    NgxExtendedPdfViewerModule,
    NgDompurifyModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatTooltipModule,
    MatIconModule,
  ],
  providers: [
    OAuthService,
    { provide: OAuthStorage, useValue: localStorage },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheBypassInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new NgxMatPaginatorIntl();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
    {
      provide: DOMPURIFY_CONFIG,
      useValue: { ADD_ATTR: ['routerlink', 'openPdf', 'target'], FORBID_TAGS: ['style'] },
    },
    ContractStatusTransformPipe,
    ContractDateTransformPipe,
    ContractTerminationTypeTransformPipe,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
