import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditUserGroupContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-user-group-container/edit-user-group-container.component';
import { BooleanTransformPipe } from '@app/_helpers/transform/boolean.transform';
import { GroupTypeTransformPipe } from '@app/_helpers/transform/group-type.transform';
import { GroupDto } from '@app/_models/groupDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { GroupService } from '@app/_services/group.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css'],
})
export class UserGroupsComponent implements OnInit, AfterViewInit, RefreshableView {
  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
      hideCondition: (element: GroupDto) => {
        return element.type > 0;
      },
    },
    {
      action: RowActionEnum.Copy,
      tooltip: 'Global.Copy',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'yellow fa fa-copy fa-lg',
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'User.Groups.AddGroup',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'name',
      header: 'Global.Name',
      searchType: 'Text',
      flex: '1 1 20%',
    },
    {
      columnProperty: 'adminGroup',
      header: 'User.Groups.AdminGroup',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 125px',
    },
    {
      columnProperty: 'invoiceGroup',
      header: 'User.Groups.InvoiceGroup',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 135px',
    },
    {
      columnProperty: 'invoiceGuestAssistant',
      header: 'User.Groups.InvoiceGuestAssistant',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 170px',
    },
    {
      columnProperty: 'type',
      header: 'User.Groups.GroupType',
      searchType: 'Selection',
      valueMap: GroupTypeTransformPipe.groupTypeMap,
      displayTranslate: true,
      flex: '0 0 150px',
    },
    {
      columnProperty: 'description',
      header: 'Global.Description',
      searchType: 'Text',
      flex: '1 1 80%',
    },
  ];

  public tableSettings = <TableSettings<GroupDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<GroupDto, number>(this.groupService, this.errorHandler),
    tableColumns: this.tableColumns,
    rowActions: this.rowActions,
    tableActions: this.tableActions,
    rowEditContainer: EditUserGroupContainerComponent,
    rowEditContainerStyle: 'modal-1400',
    addRowTitle: 'User.Groups.AddGroup',
    editRowTitle: 'User.Groups.EditGroup',
  };

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<GroupDto, number>;

  constructor(
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }
}
