import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { ContractDto } from '@app/_models/contractDto';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { Observable } from 'rxjs';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseTableDataService<ContractDto, number> {
  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('contract', '', http);
  }

  getAllContractTypes(): Observable<ContractForUserDto[]> {
    return this.http.get<ContractForUserDto[]>(this.baseControllerUrl + '/types');
  }

  getUserContractTypes(userId?: string): Observable<ContractForUserDto[]> {
    var path = '/user/types';
    if (userId) {
      path = `/user/${userId}/types`;
    }
    return this.http.get<ContractForUserDto[]>(this.baseControllerUrl + path);
  }

  getInvoiceUserContractTypes(invoiceDate?: Date): Observable<ContractForUserDto[]> {
    var params = new HttpParams();

    if (invoiceDate && !isNaN(invoiceDate.getTime())) {
      var invoiceDateString = formatDateYYYYMMDD(invoiceDate);
      params = params.set('invoiceDate', invoiceDateString);
    }

    return this.http.get<ContractForUserDto[]>(this.baseControllerUrl + '/invoice/types', { params: params });
  }
}
