import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oauthService.hasValidAccessToken()) {
      const changedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.oauthService.getAccessToken()}`),
      });
      return next.handle(changedReq);
    } else {
      return next.handle(req);
    }
  }
}
