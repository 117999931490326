<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Title' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="title"
        class="form-control"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label"
      >{{ 'Global.FirstName' | translate }} <span class="required-asterisk">*</span></label
    >
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="firstName"
        class="form-control"
        placeholder="{{ 'Global.FirstName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
        <div *ngIf="f.firstName.errors?.required">{{ 'Account.Errors.FirstNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label"
      >{{ 'Global.LastName' | translate }} <span class="required-asterisk">*</span></label
    >
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="lastName"
        class="form-control"
        placeholder="{{ 'Global.LastName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
        <div *ngIf="f.lastName.errors?.required">{{ 'Account.Errors.LastNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label"
      >{{ 'Global.FunctionTitle' | translate }} <span class="required-asterisk">*</span></label
    >
    <div class="col-sm-8">
      <textarea
        rows="2"
        formControlName="function"
        class="form-control"
        placeholder="{{ 'Global.FunctionTitle' | translate }}"
        maxlength="1000"></textarea>
      <div *ngIf="f.function.invalid && (f.function.dirty || f.function.touched)" class="alert alert-danger">
        <div *ngIf="f.function.errors?.required">{{ 'Account.Errors.FunctionRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngIf="!config?.hideInstitution">
    <label class="col-sm-4 col-form-label">{{ 'Global.InstitutionClinic' | translate }}</label>
    <div class="col-sm-8">
      <li *ngFor="let institution of institutions">{{ institution.title }}</li>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ZsrNumber' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="zsrNumber"
        class="form-control"
        placeholder="{{ 'Global.ZsrNumber' | translate }}"
        maxlength="100" />
      <div *ngIf="f.zsrNumber.invalid && (f.zsrNumber.dirty || f.zsrNumber.touched)" class="alert alert-danger">
        <div *ngIf="f.zsrNumber.errors?.pattern">{{ 'Global.Errors.ZsrNumberInvalid' | translate }}</div>
      </div>
      <div [innerHTML]="'Dialog.EditEmployeeContainerComponent.ZSRNumberHint' | translate"></div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label"
      >{{ 'Global.GlnNumber' | translate }} <span class="required-asterisk">*</span></label
    >
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="glnNumber"
        class="form-control"
        placeholder="{{ 'Global.GlnNumber' | translate }}"
        maxlength="100" />
      <div *ngIf="f.glnNumber.invalid && (f.glnNumber.dirty || f.glnNumber.touched)" class="alert alert-danger">
        <div *ngIf="f.glnNumber.errors?.required">{{ 'Employee.Errors.GlnRequired' | translate }}</div>
        <div *ngIf="f.glnNumber.errors?.pattern">{{ 'Global.Errors.GlnNumberInvalid' | translate }}</div>
      </div>
      <div [innerHTML]="'Dialog.EditEmployeeContainerComponent.GLNNumberHint' | translate"></div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label"
      >{{ 'Global.ActiveFrom' | translate }} <span class="required-asterisk">*</span></label
    >
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="employedFrom"
        class="form-control"
        placeholder="{{ 'Global.ActiveFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div
        *ngIf="f.employedFrom.invalid && (f.employedFrom.dirty || f.employedFrom.touched)"
        class="alert alert-danger">
        <div *ngIf="f.employedFrom.errors?.required">{{ 'Account.Errors.EmployedFromRequired' | translate }}</div>
        <div *ngIf="f.employedFrom.errors?.dateIsAfter">
          {{ 'User.Errors.EmployedFromAfterEmployedUntil' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ActiveUntil' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="employedUntil"
        class="form-control"
        placeholder="{{ 'Global.ActiveUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div
        *ngIf="f.employedUntil.invalid && (f.employedUntil.dirty || f.employedUntil.touched)"
        class="alert alert-danger">
        <div *ngIf="f.employedUntil.errors?.dateIsBefore">
          {{ 'User.Errors.EmployedUntilAfterEmployedFrom' | translate }}
        </div>
      </div>
    </div>
  </div>
</form>
