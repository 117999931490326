import { InvoiceInsuranceType } from '@app/_models/enums/insuranceType';
import { ITableIdBase } from './tableIdBase';

export interface CaseQueryResultDto {
  data: CaseDto[];
  queryRestrictions: QueryRestrictionsDto;
}

export interface QueryRestrictionsDto {
  externalUserRequired: boolean;
  externalUserId?: string;
  externalInstitutionId?: string;
  requiredIdentityProvider?: string;
  actualIdentityProvider?: string;
  assignmentRanges: FromUntilDto[];
  validContractId?: number;
  earliestCaseStart?: string;
  requiredUserGroup?: string;
  userInRequiredGroup: boolean;
}

export interface FromUntilDto {
  from: string;
  until?: string;
}

export interface CaseDto extends ITableIdBase, Record<string, any> {
  institutionId: number;
  caseNumber: string;
  caseStartDate: string;
  caseEndDate: string;
  patientGivenName: string;
  patientFamilyName: string;
  patientBirthDate: string;
  patientPid: string;
  treatmentCategory: TreatmentCategory;
  discharged: boolean;
  insuranceName?: string;
  insuranceId?: number;
}

export enum TreatmentCategory {
  Private = 'Private',
  SemiPrivate = 'SemiPrivate',
  General = 'General',
}

export function treatmentCategoryToInvoiceInsuranceType(
  treatmentCategory: TreatmentCategory,
): InvoiceInsuranceType | null {
  switch (treatmentCategory) {
    case TreatmentCategory.Private:
      return InvoiceInsuranceType.Private;
    case TreatmentCategory.SemiPrivate:
      return InvoiceInsuranceType.SemiPrivate;
    default:
      return null;
  }
}
