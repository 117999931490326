import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractStatusTransform',
})
export class ContractStatusTransformPipe implements PipeTransform {
  public static contractStatusTypeMap = new Map([
    [0, 'Contract.Status.InProcessing'],
    [1, 'Contract.Status.Active'],
    [2, 'Contract.Status.Denied'],
    [3, 'Contract.Status.TerminatedDate'],
    [4, 'Contract.Status.UserConfirmationPending'],
    [5, 'Contract.Status.UserDeclined'],
    [6, 'Contract.Status.Replaced'],
  ]);

  public static contractStatusTypeMapNoParams = new Map([
    [0, 'Contract.Status.InProcessing'],
    [1, 'Contract.Status.Active'],
    [2, 'Contract.Status.Denied'],
    [3, 'Contract.Status.Terminated'],
    [4, 'Contract.Status.UserConfirmationPending'],
    [5, 'Contract.Status.UserDeclined'],
    [6, 'Contract.Status.Replaced'],
  ]);

  transform(contractStatus?: number): string {
    if (contractStatus != undefined && ContractStatusTransformPipe.contractStatusTypeMap.has(contractStatus)) {
      var contractStatusText = ContractStatusTransformPipe.contractStatusTypeMap.get(contractStatus);
      return contractStatusText ? contractStatusText : '';
    } else {
      return '';
    }
  }
}
