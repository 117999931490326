import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailStatusTransform',
})
export class EmailStatusTransformPipe implements PipeTransform {
  public static statusMap = new Map([
    [0, 'Email.Status.Pending'],
    [1, 'Email.Status.Sending'],
    [2, 'Email.Status.Sent'],
    [3, 'Email.Status.Error'],
  ]);

  transform(status: number): string {
    if (EmailStatusTransformPipe.statusMap.has(status)) {
      var statusText = EmailStatusTransformPipe.statusMap.get(status);
      return statusText ? statusText : '';
    } else {
      return '';
    }
  }
}
