import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { RuleRowDetailsComponent } from '@app/_controls/row-detail-views/rule-row-details/rule-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditRuleContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-rule-container/edit-rule-container.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { BooleanTransformPipe } from '@app/_helpers/transform/boolean.transform';
import { RuleResultTypeTransformPipe } from '@app/_helpers/transform/rule-result-type.transform';
import { RuleContextDto } from '@app/_models/ruleContextDto';
import { RuleDto } from '@app/_models/ruleDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { RuleService } from '@app/_services/rule.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-rules-logs',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit, AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private contexts: RuleContextDto[] = [];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<RuleDto, number>;

  constructor(
    public ruleService: RuleService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'id',
      header: 'Global.Id',
      searchType: 'Number',
      flex: '0 0 75px',
    },
    {
      columnProperty: 'name',
      header: 'Global.Name',
      searchType: 'Text',
    },
    {
      columnProperty: 'isActive',
      header: 'Admin.Rules.IsActive',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 50px',
    },
    {
      columnProperty: 'description',
      header: 'Global.Description',
      searchType: 'Text',
    },
    {
      columnProperty: 'context',
      header: 'Admin.Rules.Context',
      searchType: 'Text',
      flex: '0 0 150px',
    },
    {
      columnProperty: 'triggers',
      header: 'Admin.Rules.Triggers',
      searchType: 'Text',
      flex: '0 0 150px',
    },
    {
      columnProperty: 'message',
      header: 'Admin.Rules.Message',
      searchType: 'Text',
    },
    {
      columnProperty: 'resultType',
      header: 'Admin.Rules.ResultType',
      searchType: 'Selection',
      valueMap: RuleResultTypeTransformPipe.ruleResultTypeMap,
      displayTranslate: true,
      flex: '0 0 125px',
    },
    {
      columnProperty: 'validFrom',
      header: 'Global.ValidFrom',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: RuleDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
    {
      columnProperty: 'validUntil',
      header: 'Global.ValidUntil',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: RuleDto) => formatDateOnly(element),
      flex: '0 0 100px',
    },
    {
      columnProperty: 'order',
      header: 'Admin.Rules.Order',
      searchType: 'Number',
      flex: '0 0 100px',
    },
  ];

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
    {
      action: RowActionEnum.Copy,
      tooltip: 'Global.Copy',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'yellow fa fa-copy fa-lg',
    },
  ];

  private tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'Admin.Rules.AddRule',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  ngOnInit(): void {
    this.loadingSubject.next(true);
  }

  ngAfterViewInit(): void {
    this.ruleService.getRuleContexts().subscribe((result) => {
      this.contexts = result;
      this.initTable();
      this.loadingSubject.next(false);
    });
  }

  initTable(): void {
    let tableSettings = <TableSettings<RuleDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<RuleDto, number>(this.ruleService, this.errorHandler),
      rowEditContainer: EditRuleContainerComponent,
      rowEditContainerStyle: 'modal-1200',
      rowEditContainerConfig: { contexts: this.contexts },
      rowDetailsContainerType: RuleRowDetailsComponent,
      addRowTitle: 'Admin.Rules.AddRule',
      editRowTitle: 'Admin.Rules.EditRule',
      deleteRowTitle: 'Admin.Rules.DeleteRule',
      deleteRowText: 'Admin.Rules.DeleteRuleText',
      tableColumns: this.tableColumns,
      baseColumnSort: [{ column: 'order', direction: 'asc' }],
      rowActions: this.rowActions,
      tableActions: this.tableActions,
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }
}
