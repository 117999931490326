import { Component } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { stripHtml } from '@app/_helpers/functions/string-functions';
import { InfoLinkLinkActionTransformPipe } from '@app/_helpers/transform/infolink-linkaction.transform';
import { InfoLinkDto } from '@app/_models/infoLinkDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InfoLinkService } from '@app/_services/infolink.service';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';

@Component({
  selector: 'app-edit-info-link-container',
  templateUrl: './edit-info-link-container.component.html',
  styleUrls: ['./edit-info-link-container.component.css'],
})
export class EditInfoLinkContainerComponent implements IRowEditContainer<InfoLinkDto, number, any, any> {
  dialog!: RowEditDialogComponent<InfoLinkDto, number, any, any>;
  data!: InfoLinkDto;
  initialData?: InfoLinkDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  linkActions = InfoLinkLinkActionTransformPipe.infoLinkLinkActionMap;
  private files: string[] = [];
  public filteredFiles?: string[];

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public infoLinkService: InfoLinkService,
    public errorHandler: ErrorHandlerService,
  ) {}

  initializeContainer() {
    // If there is no input data we are adding a row
    if (!this.data) {
      this.data = {
        isPublic: true,
        translateName: false,
      } as InfoLinkDto;
    }

    this.dialog.loadingSubject.next(true);

    this.infoLinkService.getFiles().subscribe(
      (result) => {
        this.files = result.map(stripHtml); // strip html because [typeahead] renders options using innerHtml
        this.filteredFiles = this.files;
        this.dialog.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.dialog.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.name, [Validators.required]),
      linkAction: new UntypedFormControl(this.data.linkAction, [Validators.required]),
      link: new UntypedFormControl(this.data.link, [Validators.required]),
      isPublic: new UntypedFormControl(this.data.isPublic, []),
      translateName: new UntypedFormControl(this.data.translateName, []),
      order: new UntypedFormControl(this.data.order, [Validators.required]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  public linkActionChanged(event: MatRadioChange) {
    if (event.value) {
      this.filteredFiles = this.filterFiles(event.value);
    }
  }

  private filterFiles(linkAction: string): string[] {
    if (linkAction == 'DownloadFile') {
      return this.files;
    } else if (linkAction == 'OpenInNewTab') {
      return this.files.filter((file) => !file.startsWith('api/file/'));
    } else if (linkAction == 'OpenPdf') {
      return this.files.filter((file) => file.startsWith('api/file/') && file.endsWith('.pdf'));
    } else if (linkAction == 'OpenPdfDialog') {
      return this.files.filter((file) => file.startsWith('api/file/') && file.endsWith('.pdf'));
    }

    return [];
  }
}
