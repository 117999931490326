import { CollectionViewer } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CommandResult } from '@app/_models/command/commandResult';
import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { Pagination } from '@app/_models/pagination';
import { QueryColumnSort } from '@app/_models/settings/queryColumnSort';
import { ITableIdBase } from '@app/_models/tableIdBase';
import { BaseTableDataService } from '@app/_services/baseTableData.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { IDataTableDataSource } from './dataTableDataSource';

export class BaseTableDataServiceDataSource<DataType extends ITableIdBase, IdType>
  implements IDataTableDataSource<DataType, IdType>
{
  private dataSubject = new BehaviorSubject<DataType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0;
  public paginationData?: Pagination<DataType>;

  constructor(
    private dataService: BaseTableDataService<DataType, IdType>,
    private errorHandler: ErrorHandlerService,
    private additionalParams?: HttpParams | undefined,
  ) {}

  get(id: any): Observable<DataType> {
    return this.dataService.get(id, this.additionalParams);
  }

  update(data: DataType): Observable<CommandResult> {
    return this.dataService.update(data, this.additionalParams);
  }

  updateMultiple(data: DataType[]): Observable<CommandResult> {
    return this.dataService.updateMultiple(data, this.additionalParams);
  }

  create(data: DataType): Observable<CommandResultWithValue<IdType>> {
    return this.dataService.create(data, this.additionalParams);
  }

  delete(data: DataType): Observable<CommandResult> {
    return this.dataService.delete(data.id, this.additionalParams);
  }

  loadData(
    filter: string,
    pageIndex: number | undefined = 0,
    pageSize: number | undefined = 25,
    sort: QueryColumnSort[] | undefined,
  ) {
    var loading = true;

    this.dataService
      .query(filter, pageIndex, pageSize, sort, this.additionalParams)
      .pipe(
        finalize(() => {
          loading = false;
          this.loadingSubject.next(false);
        }),
      )
      .subscribe(
        (result) => {
          this.paginationData = result as Pagination<DataType>;
          this.totalCount = this.paginationData.count;
          this.dataSubject.next(this.paginationData.data);
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );

    setTimeout(() => {
      if (loading) this.loadingSubject.next(true);
    }, 250);
  }

  setAdditionalParams(additionalParams?: HttpParams | undefined): void {
    this.additionalParams = additionalParams;
  }

  connect(collectionViewer: CollectionViewer): Observable<DataType[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
