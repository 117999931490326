import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { FileService } from '@app/_services/file.service';
import { TextDialogComponent } from '../textdialog/textdialog.component';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css'],
})
export class FileUploadDialogComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public files: File[] = [];
  public saveSecure: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public fileService: FileService,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  onFileChange(event: any): void {
    var fileList: FileList | null;

    if (event.target) {
      fileList = (event.target as HTMLInputElement).files;
    } else {
      fileList = event as FileList;
    }

    if (fileList) {
      for (var fileKey in Object.keys(fileList)) {
        this.files.push(fileList[fileKey]);
      }
    }
  }

  removeFile(file: File) {
    this.files = this.files.filter(function (w) {
      return w.name != file.name;
    });
  }

  onConfirm(): void {
    this.uploadFiles(false);
  }

  uploadFiles(overwriteFiles: boolean): void {
    if (!this.files || this.files.length === 0) {
      return;
    }

    this.loadingSubject.next(true);

    this.fileService.uploadFiles(this.files, this.saveSecure, overwriteFiles).subscribe(
      (result) => {
        this.loadingSubject.next(false);

        if (result.warnings.length > 0) {
          var shouldOverwriteFilesText = this.translate.instant('Dialog.FileUploadDialog.ShouldOverwriteFiles');

          this.errorHandler.displayWarningDialog(result, shouldOverwriteFilesText).then((modalRef) => {
            modalRef.content?.onClose.subscribe((onCloseResult) => {
              if (onCloseResult) {
                this.uploadFiles(true);
              }
            });
          });
        } else {
          this.modalRef.hide();
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);

        // Handle request too large error.
        if (
          errorResponse.status == 400 &&
          errorResponse.error &&
          errorResponse.error.constructor === Array &&
          errorResponse.error[0].code &&
          errorResponse.error[0].code == 'ValidationError_'
        ) {
          TextDialogComponent.showDialogTranslated(
            this.translate,
            this.modalService,
            'Dialog.FileUploadDialog.Errors.RequestTooLargeTitle',
            'Dialog.FileUploadDialog.Errors.RequestTooLargeText',
            'Global.Ok',
          );
          return;
        }

        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  onCancel(): void {
    this.modalRef.hide();
  }
}
