import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InvoiceServiceProviderDto } from '@app/_models/InvoiceServiceProviderDto';
import { InvoiceServiceProviderGroupsDto } from '@app/_models/invoiceServiceProviderGroupsDto';
import { Observable } from 'rxjs';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceServiceProviderService extends BaseTableDataService<InvoiceServiceProviderDto, string> {
  constructor(protected http: HttpClient) {
    super('invoiceServiceProvider', '', http);
  }

  getServiceProvidersForUser(invoiceSessionId: number): Observable<InvoiceServiceProviderGroupsDto> {
    var params = new HttpParams().set('invoiceSessionId', invoiceSessionId);

    return this.http.get<InvoiceServiceProviderGroupsDto>(this.baseControllerUrl + '/user', { params });
  }
}
