import { Component, Input, OnInit } from '@angular/core';

import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { ContractDto } from '@app/_models/contractDto';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { IRowDetailView } from '../row-detail-view.interface';

@Component({
  selector: 'app-contracts-row-details',
  templateUrl: './contracts-row-details.component.html',
  styleUrls: ['./contracts-row-details.component.scss'],
})
export class ContractsRowDetailsComponent implements OnInit, IRowDetailView<ContractDto> {
  @Input()
  rowData!: ContractDto;

  @Input()
  additionalData?: any;

  constructor() {}

  ngOnInit(): void {}

  getCatalogView(catalogViewId: number | undefined): CatalogViewDto | undefined {
    if (catalogViewId && this.additionalData.catalogViews) {
      let catalogViews: CatalogViewDto[] = this.additionalData.catalogViews;

      return catalogViews.find((x) => x.id == catalogViewId);
    }

    return undefined;
  }

  getContractType(contractId: number | undefined): ContractForUserDto | undefined {
    if (contractId && this.additionalData.contractTypes) {
      let contractTypes: ContractForUserDto[] = this.additionalData.contractTypes;

      return contractTypes.find((x) => x.id == contractId);
    }

    return undefined;
  }
}
