import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InstitutionDto, InstitutionWithConfigurationDto } from '@app/_models/institutionDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService extends BaseTableDataService<InstitutionDto, number> {
  constructor(protected http: HttpClient) {
    super('institution', '', http);
  }

  getInstitutions(): Observable<InstitutionDto[]> {
    return this.http.get<InstitutionDto[]>(this.baseControllerUrl + '/all');
  }

  getChildInstitutions(): Observable<InstitutionDto[]> {
    return this.http.get<InstitutionDto[]>(this.baseControllerUrl + '/child');
  }

  getParentInstitutions(): Observable<InstitutionDto[]> {
    return this.http.get<InstitutionDto[]>(this.baseControllerUrl + '/parent');
  }

  getUserInstitutions(): Observable<InstitutionDto[]> {
    return this.http.get<InstitutionDto[]>(this.baseControllerUrl + '/userinstitutions');
  }

  getUserChildInstitutions(): Observable<InstitutionWithConfigurationDto[]> {
    return this.http.get<InstitutionWithConfigurationDto[]>(this.baseControllerUrl + '/userchildinstitutions');
  }

  getUserParentInstitutions(): Observable<InstitutionDto[]> {
    return this.http.get<InstitutionDto[]>(this.baseControllerUrl + '/userparentinstitutions');
  }
}
