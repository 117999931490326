<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.FirstName' | translate }}</label>
    <div class="col-sm-8">
      <input
        #firstName
        type="text"
        formControlName="firstName"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.FirstName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
        <div *ngIf="f.firstName.errors?.required">{{ 'User.Patients.Errors.FirstNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.LastName' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="lastName"
        class="form-control mr-sm-2"
        placeholder="{{ 'Global.LastName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
        <div *ngIf="f.lastName.errors?.required">{{ 'User.Patients.Errors.LastNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.DateOfBirth' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="dateOfBirth"
        class="form-control"
        placeholder="{{ 'Global.DateOfBirth' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
      <div *ngIf="f.dateOfBirth.invalid && (f.dateOfBirth.dirty || f.dateOfBirth.touched)" class="alert alert-danger">
        <div *ngIf="f.dateOfBirth.errors?.required">{{ 'User.Patients.Errors.DateOfBirthRequired' | translate }}</div>
      </div>
    </div>
  </div>
</form>
