import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';

import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { RuleResultTypeTransformPipe } from '@app/_helpers/transform/rule-result-type.transform';
import { RuleResultType } from '@app/_models/enums/ruleResultType';
import { RuleDto } from '@app/_models/ruleDto';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditRuleContainerConfig } from './editRuleContainerConfig';

@Component({
  selector: 'app-edit-rule-container',
  templateUrl: './edit-rule-container.component.html',
  styleUrls: ['./edit-rule-container.component.css'],
})
export class EditRuleContainerComponent implements IRowEditContainer<RuleDto, number, any, EditRuleContainerConfig> {
  dialog!: RowEditDialogComponent<RuleDto, number, any, any>;
  data!: RuleDto;
  initialData?: RuleDto;
  resultData: any;
  form!: UntypedFormGroup;
  config!: EditRuleContainerConfig;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  public ruleResultTypeMapKeys!: number[];

  public selectedContext: any;

  constructor() {}

  initializeContainer(): void {
    this.ruleResultTypeMapKeys = Array.from(RuleResultTypeTransformPipe.ruleResultTypeMap.keys());

    if (!this.data) {
      if (this.initialData) {
        this.data = this.initialData;
        this.data.id = 0;
        this.selectedContext = this.config.contexts.find((x) => x.name == this.data.context);
      } else {
        this.data = <RuleDto>{
          isActive: true,
          context: this.config.contexts[0].name,
          resultType: RuleResultType.Error,
          order: 0,
        };
        this.selectedContext = this.config.contexts[0];
      }
    } else {
      this.selectedContext = this.config.contexts.find((x) => x.name == this.data.context);
    }

    this.form = new UntypedFormGroup({
      isActive: new UntypedFormControl(this.data.isActive, []),
      name: new UntypedFormControl(this.data.name, [Validators.required]),
      description: new UntypedFormControl(this.data.description, []),
      context: new UntypedFormControl(this.data.context, [Validators.required]),
      triggers: new UntypedFormControl(this.data.triggers, [Validators.required]),
      expression: new UntypedFormControl(this.data.expression, [Validators.required]),
      message: new UntypedFormControl(this.data.message, [Validators.required]),
      resultType: new UntypedFormControl(this.data.resultType, [Validators.required]),
      validFrom: new UntypedFormControl(this.data.validFrom, []),
      validUntil: new UntypedFormControl(this.data.validUntil, []),
      order: new UntypedFormControl(this.data.order, [Validators.required]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        if (key == 'context') {
          this.data[key] = changes[key].name;
        } else {
          this.data[key] = changes[key];
        }
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  contextChanged(context: any): void {
    this.selectedContext = context.target.value;
  }
}
