<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'Catalog.Management.HistoryTitle' | translate: { uid: column01 } }}</h4>
</div>
<div class="modal-body">
  <app-data-table id="catalogViewHistory"></app-data-table>
</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'Global.Close' | translate }}</button>
  </ng-container>
</div>
