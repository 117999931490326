import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { zip } from 'rxjs';

import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { EmployeeGroupDto } from '@app/_models/employeeGroupDto';
import { AccountService } from '@app/_services/account.service';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import sidebarData from './sidebar.json';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  public sidebarItems: any; // top-level items (i.e. parent nodes)

  private userCatalogViews!: CatalogViewDto[];
  private mainEmployeeGroups!: EmployeeGroupDto[];
  private invoiceEmployeeGroups!: EmployeeGroupDto[];

  constructor(
    private el: ElementRef,
    public accountService: AccountService,
    public catalogViewService: CatalogViewService,
    public employeeService: EmployeeService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.accountService.currentUser$.subscribe((currentUserResult) => {
      if (currentUserResult) {
        const getUserCatalogViews = this.catalogViewService.getUserCatalogViews();
        const getUserEmployeeGroups = this.employeeService.getUserEmployeeGroups();

        zip(getUserCatalogViews, getUserEmployeeGroups).subscribe(
          (result) => {
            /**
             * We "deep copy" sidebarData
             * Otherwise when we navigate between different layouts, sidebar items will be persistent (i.e. unwanted ones remain 'open', etc)
             */
            let sidebarList = JSON.parse(JSON.stringify(sidebarData));

            this.userCatalogViews = result[0];
            this.mainEmployeeGroups = result[1].filter((x) => !x.invoiceGroup);
            this.invoiceEmployeeGroups = result[1].filter((x) => x.invoiceGroup);

            this.sidebarItems = this.createSidebarItemTree([], sidebarList, undefined);
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      }
    });
  }

  createSidebarItemTree(sidebarItemTree: Array<any>, sidebarList: any, parent: any): any {
    for (let $item of sidebarList) {
      let item: any = $item;

      if (parent) {
        if (!Array.isArray(parent.children)) {
          parent.children = [];
          parent.hasChildren = true;
        }

        if (!parent.children.includes(item)) {
          parent.children.push(item);
          item['parentNode'] = parent; //add a reference to parent node
        }
      } else {
        item['isFirstLevel'] = true;
        sidebarItemTree.push(item);
      }

      if (item['id'] == 'catalogs') {
        if (this.userCatalogViews.length == 0) {
          sidebarItemTree.splice(sidebarItemTree.indexOf(item));
          continue;
        }

        let catalogSubItems = [];

        for (let userCatalogView of this.userCatalogViews) {
          let userCatalogViewItem = {
            title: userCatalogView.title,
            link: `/catalog/management/${userCatalogView.id}`,
          };

          catalogSubItems.push(userCatalogViewItem);
        }

        this.createSidebarItemTree(sidebarItemTree, catalogSubItems, item);
      } else if (item['id'] == 'employee') {
        if (this.mainEmployeeGroups.length == 0) {
          sidebarItemTree.splice(sidebarItemTree.indexOf(item));
          continue;
        }

        let employeeSubItems = [];

        for (let employeeGroup of this.mainEmployeeGroups) {
          let userCatalogViewItem = {
            title: employeeGroup.name,
            link: `/employee/management/${employeeGroup.id}`,
          };

          employeeSubItems.push(userCatalogViewItem);
        }

        this.createSidebarItemTree(sidebarItemTree, employeeSubItems, item);
      } else if (item['id'] == 'invoice') {
        let employeeSubItems = item['subItems'] as Array<any>;
        var startIndex = employeeSubItems.findIndex((x) => x.id === 'user/invoices') + 1;

        for (let employeeGroup of this.invoiceEmployeeGroups) {
          let userCatalogViewItem = {
            title: employeeGroup.name,
            link: `/employee/management/${employeeGroup.id}`,
            icon: 'fa fa-user-doctor',
            requiredRoles: ['Invoice'],
          };

          employeeSubItems.splice(startIndex, 0, userCatalogViewItem);
          startIndex++;
        }

        this.createSidebarItemTree(sidebarItemTree, employeeSubItems, item);
      } else if (item['subItems']) {
        this.createSidebarItemTree(sidebarItemTree, item['subItems'], item);
      }
    }

    return sidebarItemTree;
  }

  // let's keep track of total open items
  // so we can add '.has-open' to '.sidebar'
  // .has-open class is only needed in some sidebar themes
  // so we can ignore this most of the time
  totalOpen = 0;
  onToggleItem($event: any) {
    let item = $event;
    if (item.open) this.totalOpen++;
    else this.totalOpen--;

    this.el?.nativeElement.classList.toggle('has-open', this.totalOpen > 0);
  }
}
