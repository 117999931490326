import { Pipe, PipeTransform } from '@angular/core';
import { InfoLinkLinkAction } from '@app/_models/enums/infoLinkLinkAction';

@Pipe({
  name: 'infoLinkLinkActionTransform',
})
export class InfoLinkLinkActionTransformPipe implements PipeTransform {
  public static infoLinkLinkActionMap = new Map(
    Object.values(InfoLinkLinkAction).map((linkAction) => [linkAction, `Info.LinkAction.${linkAction}`]),
  );

  transform(infoLinkLinkAction: InfoLinkLinkAction): string {
    return InfoLinkLinkActionTransformPipe.infoLinkLinkActionMap.get(infoLinkLinkAction) ?? '';
  }
}
