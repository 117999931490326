import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Optional, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { RowEditDialogComponent } from '@app/_dialogs/row-edit-dialog/row-edit-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { base64EncodeCustomPadding } from '@app/_helpers/functions/string-functions';
import { QueryColumnSort } from '@app/_models/settings/queryColumnSort';
import { ITableIdBase } from '@app/_models/tableIdBase';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { CustomRowActionParams } from './actions/customRowActionParams';
import { CustomTableActionParams } from './actions/customTableActionParams';
import { NavigateActionParams } from './actions/navigateActionParams';
import { RowAction, RowActionEnum } from './actions/rowAction';
import { TableAction, TableActionEnum } from './actions/tableAction';
import { TableBrowserSettings } from './settings/tableBrowserSettings';
import { TableColumn } from './settings/tableColumn';
import { TableSettings } from './settings/tableSettings';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent<DataType extends ITableIdBase, IdType> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private _tableSettings!: TableSettings<DataType, IdType>;
  @Input('tableSettings')
  get tableSettings(): TableSettings<DataType, IdType> {
    return this._tableSettings;
  }
  set tableSettings(tableSettings: TableSettings<DataType, IdType>) {
    this._tableSettings = tableSettings;
    this.init(tableSettings);
  }

  private _id: string | undefined;
  @Input('id')
  get id(): string | undefined {
    return this._id;
  }
  set id(id: string | undefined) {
    this._id = id;
    this.initTableId(id);
  }

  public tableDivStyle!: string;
  public rowEditContainerStyle!: string;
  public disableRowFilters: boolean = false;
  public rowSelection = new SelectionModel<DataType>(true, []);
  public allRowsSelected: boolean = false;
  public rowDetailsData: any = {};

  public initialized: boolean = false;
  public displayedColumns: Array<string> = [];
  public sortActive: string = '';
  public sortDirection: SortDirection = '';
  public pageSizeOptions: number[] = [25, 50, 100, 200];
  public pageIndex: number | undefined;
  public pageSize: number | undefined;
  public filters: any = {};
  public globalFilterValue?: string;
  public expandedElement: any;
  public rotateRefreshButton: boolean = false;
  public displayFullPage: boolean = false;
  public rowActionsFlex?: string;

  private filter: string = '';
  private browserSettingsStorageKey?: string;
  private browserSettings?: TableBrowserSettings;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
    @Optional() public appComponent?: AppComponent,
  ) {
    this.pageSizeOptions = AppComponent.pageSizeOptions;
  }

  initTableId(id: string | undefined) {
    if (id) {
      this.browserSettingsStorageKey = `tableSettings_${this.id}`;
    }

    this.initBrowserSettings();

    if (this.browserSettings && this.pageSizeOptions.includes(this.browserSettings.pageSize)) {
      this.pageSize = this.browserSettings.pageSize;
    }
  }

  setDefaultValues(): void {
    // Default style is 100 view height minus the size of the toolbar.
    this.tableDivStyle = this.getTableDivStyle();
    this.rowEditContainerStyle = 'modal-lg';
    this.disableRowFilters = false;
    this.rowSelection = new SelectionModel<DataType>(true, []);
    this.allRowsSelected = false;

    this.initialized = false;
    this.displayedColumns = [];
    this.pageIndex = 0;

    if (this.browserSettings && this.pageSizeOptions.includes(this.browserSettings.pageSize)) {
      this.pageSize = this.browserSettings.pageSize;
    }

    this.filters = {};
    this.globalFilterValue = undefined;
    this.expandedElement = undefined;
    this.rowActionsFlex = undefined;

    this.filter = '';
  }

  initBrowserSettings() {
    var minPageSize = Math.min(...this.pageSizeOptions);

    this.browserSettings = { pageSize: minPageSize };

    // If the datatable has a unique id we try to load browser settings.
    if (this.browserSettingsStorageKey) {
      var tableSettingsJson = localStorage.getItem(this.browserSettingsStorageKey);

      if (tableSettingsJson) {
        this.browserSettings = JSON.parse(tableSettingsJson) as TableBrowserSettings;

        if (!this.pageSizeOptions.includes(this.browserSettings.pageSize)) {
          this.browserSettings.pageSize = minPageSize;
        }
      }
    }
  }

  saveBrowserSettings() {
    // If the datatable has a unique id we store browser settings.
    if (this.browserSettingsStorageKey && this.browserSettings) {
      localStorage.setItem(this.browserSettingsStorageKey, JSON.stringify(this.browserSettings));
    }
  }

  savePageSizeBrowserSetting(pageSize: number) {
    if (this.browserSettings && this.browserSettings.pageSize != pageSize) {
      this.browserSettings.pageSize = pageSize;
      this.saveBrowserSettings();
    }
  }

  resetPageIndex(): void {
    this.pageIndex = 0;
  }

  private init(tableSettings: TableSettings<DataType, IdType>): void {
    // Fix for invisible columns if flex is empty string.
    this.tableSettings.tableColumns.forEach((column) => {
      if (!column.flex) {
        column.flex = undefined;
      }
    });

    this.setDefaultValues();

    // Check if we need to restore the page index.
    if (this.tableSettings.pageIndexQueryParamName) {
      var pageIndexQueryParam = Number(
        this.activatedRoute.snapshot.queryParamMap.get(this.tableSettings.pageIndexQueryParamName),
      );

      if (pageIndexQueryParam) {
        this.pageIndex = pageIndexQueryParam;
      }
    }

    if (this.tableSettings.tableDivStyle) {
      this.tableDivStyle = this.tableSettings.tableDivStyle;
    }

    if (this.tableSettings.rowEditContainerStyle) {
      this.rowEditContainerStyle = this.tableSettings.rowEditContainerStyle;
    }

    if (this.tableSettings.globalFilterColumns) {
      this.disableRowFilters = true;
    } else if (this.tableSettings.tableColumns.every((x) => !x.searchType || x.searchType == 'None')) {
      // Disable row filters if no column has search enabled.
      this.disableRowFilters = true;
    }

    if (tableSettings.disablePaging) {
      this.pageIndex = undefined;
      this.pageSize = undefined;
    }

    this.setFilter();

    this.displayedColumns = this.getDisplayedColumns();

    this.tableSettings.dataSource.loading$.subscribe((result) => {
      this.loadingSubject.next(result);
    });

    // If we have row details, show the row action to display the details.
    if (this.tableSettings.rowDetailsContainerType) {
      if (!this.tableSettings.rowActions) {
        this.tableSettings.rowActions = [];
      }

      var expandRowActionParams: CustomRowActionParams = {
        customFunction: this.expandRow.bind(this),
      };

      this.tableSettings.rowActions.push({
        action: RowActionEnum.Custom,
        tooltip: 'Global.ShowDetails',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'blue fa-solid fa-search-plus fa-lg',
        actionParams: expandRowActionParams,
      });
    }

    if (this.tableSettings.rowActions && this.tableSettings.rowActions.length > 0) {
      this.displayedColumns.unshift('rowActions');
    }

    if (this.tableSettings.enableRowSelection) {
      this.displayedColumns.unshift('rowSelection');
    }

    if (this.tableSettings.rowActions) {
      let rowActionCount = this.tableSettings.rowActions.length;
      this.rowActionsFlex = rowActionCount > 0 ? `0 0 ${rowActionCount * 40}px` : undefined;
    }

    this.tableSettings.addRowTitle ??= 'DataTable.AddRowTitle';
    this.tableSettings.editRowTitle ??= 'DataTable.EditRowTitle';
    this.tableSettings.deleteRowTitle ??= 'DataTable.DeleteRowTitle';
    this.tableSettings.deleteRowText ??= 'DataTable.DeleteRowText';

    this.initialized = true;
  }

  getTableDivStyle(): string {
    var headerHeight = 132;

    if (this.displayFullPage) {
      if (this.tableSettings.tableActions && this.tableSettings.tableActions.length) {
        headerHeight = 92;
      } else {
        headerHeight = 56;
      }
    } else if (this.tableSettings.tableActions && this.tableSettings.tableActions.length) {
      headerHeight += 38;
    }

    return `overflow: auto; height: calc(100vh - ${headerHeight}px); position: relative;`;
  }

  getDisplayedColumns(): string[] {
    if (this.tableSettings.displayedColumns && this.tableSettings.displayedColumns.length > 0) {
      return this.tableSettings.displayedColumns.filter((d) =>
        this.tableSettings.tableColumns.some((c) => c.columnProperty == d),
      );
    } else {
      return this.tableSettings.tableColumns.map((c) => c.columnProperty);
    }
  }

  expandRow(element: any): void {
    if (this.expandedElement == element) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }
  }

  onRefreshClicked(): void {
    this.rotateRefreshButton = !this.rotateRefreshButton;
    this.loadData();
  }

  onFullPageClicked(): void {
    this.displayFullPage = !this.displayFullPage;

    if (this.appComponent) {
      this.appComponent.hideNavbar = this.displayFullPage;
      this.appComponent.hideSidebar = this.displayFullPage;
    }

    if (!this.tableSettings.tableDivStyle) {
      this.tableDivStyle = this.getTableDivStyle();
    }
  }

  public loadData(): void {
    if (this.sort) {
      this.sortActive = this.sort.active;
      this.sortDirection = this.sort.direction;
    } else {
      if (this.tableSettings?.baseColumnSort?.length === 1) {
        this.sortActive = this.tableSettings.baseColumnSort[0].column;
        this.sortDirection = this.tableSettings.baseColumnSort[0].direction;
      }
    }

    var sort: QueryColumnSort[] = [];

    if (this.sortActive && this.sortDirection) {
      // Check if we can find the table column settings and search property to sort in sub fields of the table.
      var columnSortProperty = this.sortActive;
      var tableColumn = this.tableSettings.tableColumns.find((x) => x.columnProperty == this.sortActive);

      if (tableColumn && tableColumn.searchProperty) {
        columnSortProperty = tableColumn.searchProperty;
      }

      sort.push({ column: columnSortProperty, direction: this.sortDirection });
    } else if (this.tableSettings.baseColumnSort) {
      sort = this.tableSettings.baseColumnSort;
    }

    this.rowSelection.clear();
    this.allRowsSelected = false;
    this.tableSettings.dataSource.loadData(this.filter, this.pageIndex, this.pageSize, sort);
  }

  onSortData(): void {
    this.loadData();
  }

  onPageChanged(page: PageEvent): void {
    if (!this.tableSettings.disablePaging) {
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;

      this.savePageSizeBrowserSetting(page.pageSize);

      // Set page index to url if pageIndexQueryParamName is set.
      if (this.tableSettings.pageIndexQueryParamName) {
        var pageIndexQueryParam: Params = {};
        pageIndexQueryParam[this.tableSettings.pageIndexQueryParamName] = this.pageIndex;

        const url = this.router
          .createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: pageIndexQueryParam,
            queryParamsHandling: 'merge',
          })
          .toString();

        this.location.replaceState(url);
      }

      this.loadData();
    }
  }

  setFilter(filter?: string): void {
    if (filter && this.tableSettings.baseFilter) {
      this.filter = `${this.tableSettings.baseFilter}&${filter}`;
    } else if (this.tableSettings.baseFilter) {
      this.filter = this.tableSettings.baseFilter;
    } else if (filter) {
      this.filter = filter;
    } else {
      this.filter = '';
    }
  }

  generateGlobalFilter(filterValue?: string) {
    let filter: string | undefined;

    if (this.tableSettings?.globalFilterColumns) {
      if (filterValue) {
        if (filterValue.startsWith('"') && filterValue.endsWith('"')) {
          // If search string starts and ends with " we do not split the string
          let conditions: any = [];

          this.tableSettings?.globalFilterColumns?.forEach((filterKey: string) => {
            // We encode every word to base64 to not get conflicts with special characters.
            var filterValueBase64 = base64EncodeCustomPadding(filterValue.slice(1, -1));
            conditions.push(`${filterKey}?=base64:${filterValueBase64}`);
          });

          filter = conditions.join('|');
        } else {
          // In global search we split words and search for each word in every field.
          // Every word must exist in at least one field in the row.
          let conditions: any = [];

          filterValue.split(' ').forEach((filterValueWord) => {
            let keywordConditions: any = [];

            if (filterValueWord) {
              this.tableSettings?.globalFilterColumns?.forEach((filterKey: string) => {
                // We encode every word to base64 to not get conflicts with special characters.
                var filterValueWordBase64 = base64EncodeCustomPadding(filterValueWord);
                keywordConditions.push(`${filterKey}?=base64:${filterValueWordBase64}`);
              });

              conditions.push(`(${keywordConditions.join('|')})`);
            }

            filter = conditions.join('&');
          });
        }
      }
    }

    if (filter) {
      this.setFilter(`(${filter})`);
    } else {
      this.setFilter(undefined);
    }
  }

  applyGlobalFilter(): void {
    this.generateGlobalFilter(this.globalFilterValue);
    this.resetPageIndex();
    this.loadData();
  }

  generateColumnFilter() {
    let conditions: any = [];
    let filterKeys = Object.keys(this.filters);
    filterKeys.forEach((filterKey: string) => {
      var filterValue: any = this.filters[filterKey];
      var tableColumn = this.tableSettings.tableColumns.find((x) => x.columnProperty == filterKey);

      var compareOperator = tableColumn && tableColumn.compareOperator ? tableColumn?.compareOperator : '?=';

      if ((tableColumn?.searchValue === 0 || tableColumn?.searchValue) && typeof filterValue === 'function') {
        var customSearchFunction = filterValue as Function;
        var customSearchCondition = customSearchFunction(tableColumn?.searchValue);
        if (customSearchCondition) {
          conditions.push(customSearchFunction(tableColumn?.searchValue));
        }
      } else if (filterValue && typeof filterValue === 'string') {
        // We encode filter value base64 to not get conflicts with special characters.
        var filterValueBase64 = base64EncodeCustomPadding(filterValue);
        conditions.push(`${filterKey}${compareOperator}base64:${filterValueBase64}`);
      } else if (filterValue && filterValue instanceof Date) {
        conditions.push(...this.createFromUntilDateConditions(filterKey, filterValue));
      } else if (filterValue && Array.isArray(filterValue)) {
        conditions.push(...this.createFromUntilDateConditions(filterKey, filterValue[0], filterValue[1]));
      }
    });
    this.setFilter(conditions.join('&'));
  }

  private createFromUntilDateConditions(filterKey: string, from: Date, until: Date = from) {
    let untilBoundary = new Date(until);
    untilBoundary.setDate(until.getDate() + 1);
    return [`${filterKey}>=${formatDateYYYYMMDD(from)}`, `${filterKey}<${formatDateYYYYMMDD(untilBoundary)}`];
  }

  applyColumnFilter(tableColumn: TableColumn) {
    var filterKey = tableColumn.columnProperty;

    if (tableColumn.searchProperty) {
      filterKey = tableColumn.searchProperty;
    }

    if (!this.filters[filterKey]) {
      this.filters[filterKey] = '';
    }

    if (tableColumn.customSearchFunction) {
      this.filters[filterKey] = tableColumn.customSearchFunction;
    } else {
      this.filters[filterKey] = tableColumn.searchValue?.toString() ?? '';
    }

    this.generateColumnFilter();
    this.resetPageIndex();
    this.loadData();
  }

  applyDateTimeColumnFilter(
    tableColumn: TableColumn,
    value: Date | undefined | (Date | undefined)[] | undefined,
  ): void {
    this.filters[tableColumn.columnProperty] = value;

    this.generateColumnFilter();
    this.resetPageIndex();
    this.loadData();
  }

  getDateRangePickerPlacement(tableColumn: TableColumn): 'top' | 'bottom' | 'left' | 'right' {
    let index = this.displayedColumns.indexOf(tableColumn.columnProperty);

    if (index <= 3) {
      return 'right';
    } else if (index == this.displayedColumns.length - 1) {
      return 'left';
    }

    return 'bottom';
  }

  columnEdit(element: any, tableColumn: TableColumn, value: any): any {
    var columnDefSplit = tableColumn.columnProperty.split('.');

    if (columnDefSplit.length == 1) {
      element[columnDefSplit[0]] = value;
    } else {
      var editElement = element[columnDefSplit[0]];
      for (let i = 1; i < columnDefSplit.length; i++) {
        if (i == columnDefSplit.length - 1) {
          editElement[columnDefSplit[1]] = value;
        } else {
          editElement = editElement[columnDefSplit[1]];
        }
      }
    }
  }

  rowSelectHandler(row: DataType) {
    this.rowSelection.toggle(row);
    this.allRowsSelected = this.rowSelection.selected.length == this.tableSettings.dataSource.totalCount;
  }

  selectAllRows(event: any): void {
    this.tableSettings.dataSource.paginationData?.data.forEach((row) => {
      if (event.target.checked) {
        if (!this.rowSelection.isSelected(row)) {
          this.rowSelection.select(row);
        }
      } else {
        this.rowSelection.clear();
      }
    });
  }

  executeTableAction(action: TableAction): void {
    if (action.action == TableActionEnum.Add) {
      if (this.tableSettings.rowEditContainer) {
        this.showCreateDialog(action.postAction);
      }
    } else if (action.action == TableActionEnum.Custom) {
      var customTableActionParams = action.actionParams as CustomTableActionParams;
      if (customTableActionParams.customFunction) {
        customTableActionParams.customFunction();
      }
    }
  }

  executeRowAction(element: any, action: RowAction): void {
    if (action.action == RowActionEnum.Delete) {
      TextDialogComponent.showDialogTranslated(
        this.translate,
        this.modalService,
        this.tableSettings.deleteRowTitle!,
        this.tableSettings.deleteRowText!,
        'Global.Delete',
        'Global.Cancel',
      ).then((modalRef) => {
        modalRef.content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult) {
            this.loadingSubject.next(true);
            this.tableSettings.dataSource.delete(element).subscribe(
              (deleteResult) => {
                this.loadData();
              },
              (errorResponse: HttpErrorResponse) => {
                this.loadingSubject.next(false);
                this.errorHandler.displayErrorDialog(errorResponse);
              },
            );
          }
        });
      });
    } else if (action.action == RowActionEnum.Edit) {
      if (this.tableSettings.rowEditContainer) {
        if (action.preAction) {
          action.preAction(element, this.translate, this.modalService).then((preActionResult) => {
            if (preActionResult.showEditDialog) {
              this.showEditDialog(element, preActionResult);
            }
          });
        } else {
          this.showEditDialog(element);
        }
      }
    } else if (action.action == RowActionEnum.Copy) {
      if (this.tableSettings.rowEditContainer) {
        var initialData = cloneDeep(element);

        if (action.preAction) {
          action.preAction(element, this.translate, this.modalService).then((preActionResult) => {
            if (preActionResult.showEditDialog) {
              this.showCreateDialog(element, preActionResult, initialData);
            }
          });
        } else {
          this.showCreateDialog(undefined, undefined, initialData);
        }
      }
    } else if (action.action == RowActionEnum.Navigate) {
      var navigateActionParams = action.actionParams as NavigateActionParams;
      if (navigateActionParams.navigateCommandsFunction) {
        var commands = navigateActionParams.navigateCommandsFunction(element);
        var extras: NavigationExtras = navigateActionParams.navigationExtrasFunction
          ? navigateActionParams.navigationExtrasFunction(element)
          : {};
        this.router.navigate(commands, extras);
      }
    } else if (action.action == RowActionEnum.Custom) {
      var customRowActionParams = action.actionParams as CustomRowActionParams;
      if (customRowActionParams.customFunction) {
        customRowActionParams.customFunction(element);
      }
    }
  }

  showCreateDialog(
    postAction?: (result: any) => void,
    preActionData: any = undefined,
    initialData: any = undefined,
  ): void {
    this.translate.get([this.tableSettings.addRowTitle!, 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<RowEditDialogComponent<DataType, IdType, any, any>>(RowEditDialogComponent, {
          class: this.rowEditContainerStyle,
          ignoreBackdropClick: true,
          initialState: {
            containerType: this.tableSettings.rowEditContainer,
            config: this.tableSettings.rowEditContainerConfig,
            dataSource: this.tableSettings.dataSource,
            additionalParams: this.tableSettings.getAdditionalParams,
            preActionData: preActionData,
            initialData: initialData,
            dialogConfig: {
              title: res[this.tableSettings.addRowTitle!],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.tableSettings.dataSource.create(onCloseResult.data).subscribe(
                (createResult) => {
                  onCloseResult.modalRef.hide();
                  if (postAction) {
                    postAction(createResult.value);
                  } else {
                    this.loadData();
                  }
                },
                (errorResponse: HttpErrorResponse) => {
                  this.loadingSubject.next(false);
                  this.errorHandler.displayErrorDialog(errorResponse);
                },
              );
            }
          }
        });
    });
  }

  showEditDialog(element: any, preActionData: any = undefined): void {
    this.translate.get([this.tableSettings.editRowTitle!, 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<RowEditDialogComponent<DataType, IdType, any, any>>(RowEditDialogComponent, {
          class: this.rowEditContainerStyle,
          ignoreBackdropClick: true,
          initialState: {
            containerType: this.tableSettings.rowEditContainer,
            config: this.tableSettings.rowEditContainerConfig,
            dataSource: this.tableSettings.dataSource,
            id: element.id,
            additionalParams: this.tableSettings.getAdditionalParams,
            preActionData: preActionData,
            dialogConfig: {
              title: res[this.tableSettings.editRowTitle!],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);
            if (onCloseResult.data) {
              this.tableSettings.dataSource.update(onCloseResult.data).subscribe(
                (updateResult) => {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                },
                (errorResponse: HttpErrorResponse) => {
                  this.loadingSubject.next(false);
                  this.errorHandler.displayErrorDialog(errorResponse);
                },
              );
            }
          }
        });
    });
  }

  formatTextOrDecimal($event: Event, col: TableColumn) {
    const input = $event.target as HTMLInputElement;
    const inputValue = input.value;
    if (col.searchType == 'Decimal') {
      const decimal = parseFloat(inputValue);
      if (isFinite(decimal)) {
        col.searchValue = decimal;
      } else {
        col.searchValue = '';
      }
    } else {
      col.searchValue = inputValue;
    }
  }
}
