import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-textdialog',
  templateUrl: './textdialog.component.html',
  styleUrls: ['./textdialog.component.css'],
})
export class TextDialogComponent implements OnInit {
  public data = {
    title: '',
    text: '',
    button_confirm_text: undefined,
    button_cancel_text: undefined,
  };
  private onCloseSubject!: Subject<boolean>;
  public onClose!: Observable<boolean>;

  constructor(
    public translateTest: TranslateService,
    public modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {
    this.onCloseSubject = new Subject();
    this.onClose = this.onCloseSubject.pipe(throttleTime(500)); // prevent double click from triggering onClose multiple times
  }

  public onConfirm(): void {
    this.onCloseSubject.next(true);
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.onCloseSubject.next(false);
    this.modalRef.hide();
  }

  static showDialogTranslated(
    translate: TranslateService,
    modalService: BsModalService,
    title?: string,
    text?: string,
    ok?: string,
    cancel?: string,
    additionalText?: string,
    modalClass?: string,
    translateParams?: Object,
  ): Promise<BsModalRef<TextDialogComponent>> {
    return new Promise<BsModalRef<TextDialogComponent>>((resolve) => {
      var resourceArray = [];

      if (title) {
        resourceArray.push(title);
      }

      if (text) {
        resourceArray.push(text);
      }

      if (ok) {
        resourceArray.push(ok);
      }

      if (cancel) {
        resourceArray.push(cancel);
      }

      translate
        .get(resourceArray, translateParams)
        .toPromise()
        .then((res) => {
          var titleRes = title ? res[title] : '';
          var textRes = text ? res[text] : '';
          var okRes = ok ? res[ok] : undefined;
          var cancelRes = cancel ? res[cancel] : undefined;

          if (additionalText) {
            textRes += additionalText;
          }

          var modalRef = modalService.show(TextDialogComponent, {
            class: modalClass,
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              data: {
                title: titleRes,
                text: textRes,
                button_confirm_text: okRes,
                button_cancel_text: cancelRes,
              },
            },
          });
          resolve(modalRef);
        });
    });
  }
}
