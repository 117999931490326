export class EditSettings {
  type: 'text' | 'number' | 'mask' | 'select';
  step: number;
  mask: string;
  specialCharacters: string[];

  private constructor(
    type: 'text' | 'number' | 'mask' | 'select',
    step: number,
    mask: string,
    specialCharacters: string[],
  ) {
    this.type = type;
    this.step = step;
    this.mask = mask;
    this.specialCharacters = specialCharacters;
  }

  static withMask(mask: string, specialCharacters: string[]): EditSettings {
    return new EditSettings('mask', 0, mask, specialCharacters);
  }

  static withInput(type: 'text' | 'number' | 'select', step: number): EditSettings {
    return new EditSettings(type, step, '', []);
  }

  static withSelect(): EditSettings {
    return new EditSettings('select', 0, '', []);
  }
}
