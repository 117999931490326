import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { CatalogDto } from '@app/_models/catalogDto';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService extends BaseTableDataService<CatalogItemDto, number> {
  constructor(protected http: HttpClient) {
    super('catalog', 'items', http);
  }

  getCatalogs(): Observable<CatalogDto[]> {
    return this.http.get<CatalogDto[]>(this.baseControllerUrl + '/all');
  }

  getUserCatalogs(): Observable<CatalogDto[]> {
    return this.http.get<CatalogDto[]>(this.baseControllerUrl + '/user');
  }

  getCatalogItemExcelExport(catalogViewId: number, validDateFilter: Date | undefined): Observable<HttpResponse<Blob>> {
    var downloadLink = `/items/excelexport/${catalogViewId}`;
    var params = new HttpParams();

    if (validDateFilter) {
      var validDateFilterString = formatDateYYYYMMDD(validDateFilter);
      params = params.set('validDateFilter', validDateFilterString);
    }

    return this.http.get(this.baseControllerUrl + downloadLink, {
      params: params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getCatalogItemComparisonExcelExport(
    catalogViewId: number,
    baseValidDateFilter: Date,
    compareWithValidDateFilter: Date,
  ): Observable<HttpResponse<Blob>> {
    var downloadLink = `/items/excelexportcomparison/${catalogViewId}`;
    var params = new HttpParams();

    var baseValidDateFilterString = formatDateYYYYMMDD(baseValidDateFilter);
    params = params.set('baseValidDateFilter', baseValidDateFilterString);

    var compareWithValidDateFilterString = formatDateYYYYMMDD(compareWithValidDateFilter);
    params = params.set('compareWithValidDateFilter', compareWithValidDateFilterString);

    return this.http.get(this.baseControllerUrl + downloadLink, {
      params: params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getCatalogItemCsvExport(catalogViewId: number, validDateFilter: Date | undefined): Observable<HttpResponse<Blob>> {
    var downloadLink = `/items/csvexport/${catalogViewId}`;
    var params = new HttpParams();

    if (validDateFilter) {
      var validDateFilterString = formatDateYYYYMMDD(validDateFilter);
      params = params.set('validDateFilter', validDateFilterString);
    }

    return this.http.get(this.baseControllerUrl + downloadLink, {
      params: params,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
