import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { VisitColumn } from './visitColumn';

export class VisitRow {
  public day!: Date;
  public columns!: VisitColumn[];

  constructor(day: Date) {
    this.day = new Date(day);
    this.columns = new Array<VisitColumn>();
  }

  getCatalogItemColumn(catalogItem: CatalogItemDto): VisitColumn | undefined {
    return this.columns.find((x) => x.catalogItem == catalogItem);
  }
}
