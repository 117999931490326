<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'User.Management.Header' | translate }}</h1>
  </div>
  <div class="header-toolbar">
    <button
      *ngIf="objectPermission?.canExport"
      downloadFile="api/user/excelexport"
      [downloadFileTimeout]="300000"
      target="_blank"
      class="btn btn-xs btn-success">
      {{ 'Global.ExcelExport' | translate }}
    </button>
  </div>
</div>
<app-data-table id="userManagement"></app-data-table>
