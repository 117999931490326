import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { zip } from 'rxjs';

import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { dateCompare } from '@app/_helpers/validators/date-compare.validator';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { InvoiceInsuranceType } from '@app/_models/enums/insuranceType';
import { InstitutionDto } from '@app/_models/institutionDto';
import { InsuranceDto } from '@app/_models/insuranceDto';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { PatientDto } from '@app/_models/patientDto';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { PatientService } from '@app/_services/patient.service';
import { map } from 'rxjs/operators';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditInvoiceContainerConfig } from './editInvoiceContainerConfig';

@Component({
  selector: 'app-edit-invoice-container',
  templateUrl: './edit-invoice-container.component.html',
  styleUrls: ['./edit-invoice-container.component.css'],
})
export class EditInvoiceContainerComponent
  implements IRowEditContainer<InvoiceDto, number, any, EditInvoiceContainerConfig>
{
  dialog!: RowEditDialogComponent<InvoiceDto, number, any, EditInvoiceContainerConfig>;
  data!: InvoiceDto;
  initialData?: InvoiceDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: EditInvoiceContainerConfig;
  preActionData?: RowActionPreActionResult;

  patient?: PatientDto;

  invoiceInsuranceTypes!: InvoiceInsuranceType[];

  selectedInstitution?: InstitutionDto;
  selectedInsurance?: InsuranceDto;
  selectedContract?: ContractForUserDto;

  constructor(
    public institutionService: InstitutionService,
    public insuranceService: InsuranceService,
    public contractService: ContractService,
    public patientService: PatientService,
    public errorHandler: ErrorHandlerService,
  ) {}

  initializeContainer(): void {
    this.dialog.loadingSubject.next(true);

    this.loadData();

    this.form = new UntypedFormGroup({
      contractId: new UntypedFormControl({ value: this.data.contractId, disabled: true }, [Validators.required]),
      insuranceType: new UntypedFormControl(this.data.insuranceType, [Validators.required]),
      caseNumber: new UntypedFormControl(this.data.caseNumber, []),
      pid: new UntypedFormControl(this.data.pid, []),
      besr: new UntypedFormControl(this.data.besr, []),
      dateFrom: new UntypedFormControl({ value: this.data.dateFrom, disabled: true }, [
        Validators.required,
        dateCompare('dateTo', false, true),
      ]),
      dateTo: new UntypedFormControl(this.data.dateTo, [Validators.required, dateCompare('dateFrom', true, true)]),
      memo: new UntypedFormControl(this.data.memo),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  loadData(): void {
    this.dialog.loadingSubject.next(true);
    this.contractService
      .getUserContractTypes()
      .pipe(map((contracts) => contracts.find((contract) => contract.id == this.data.contractId)))
      .subscribe(
        (contract) => {
          if (!contract) {
            return;
          }

          this.selectedContract = contract;

          // 'normal' users currently can't get single institutions / insurances, hence we need to load all
          zip(
            this.institutionService
              .getUserChildInstitutions()
              .pipe(
                map((institutions) => institutions.find((institution) => institution.id == contract.institutionId)),
              ),
            this.insuranceService
              .getInsurances()
              .pipe(
                map((insurances) =>
                  insurances.find(
                    (insurance) => insurance.id == (this.data.insuranceId ?? this.selectedContract?.insuranceId),
                  ),
                ),
              ),
            this.patientService.get(this.data.patientId),
          ).subscribe((result) => {
            const institution = result[0]!;
            const insurance = result[1]!;
            const patient = result[2]!;

            this.selectedInstitution = institution;
            this.selectedInsurance = insurance;

            this.patient = patient;

            this.updateContractInsuranceTypes();
            this.dialog.loadingSubject.next(false);
          });
        },
        (errorResponse: HttpErrorResponse) => {
          this.dialog.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
  }
  updateContractInsuranceTypes(): void {
    this.f.insuranceType.setValue(this.data.insuranceType);

    if (this.selectedContract) {
      const selectableTypes = [];
      if (this.selectedContract.factorP1 != null) {
        selectableTypes.push(InvoiceInsuranceType.Private);
      }

      if (this.selectedContract.factorP2 != null) {
        selectableTypes.push(InvoiceInsuranceType.SemiPrivate);
      }

      this.invoiceInsuranceTypes = selectableTypes;
    }
  }
}
