import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { requiredWhen } from '@app/_helpers/validators/required-when.validator';
import { InstitutionDto } from '@app/_models/institutionDto';
import { RegisterDto } from '@app/_models/registerDto';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.css'],
})
export class AccountRegisterComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  model: RegisterDto = {};
  institutions!: InstitutionDto[];
  userRegisterForm!: UntypedFormGroup;
  submitted: boolean = false;

  public appComponent = AppComponent;

  constructor(
    public translate: TranslateService,
    private accountService: AccountService,
    private institutionService: InstitutionService,
    public applicationService: ApplicationService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    this.userRegisterForm = new UntypedFormGroup(
      {
        firstName: new UntypedFormControl('', [Validators.required]),
        lastName: new UntypedFormControl('', [Validators.required]),
        institutionName: new UntypedFormControl('', []),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        language: new UntypedFormControl(this.translate.currentLang, [Validators.required]),
        termsAccepted: new UntypedFormControl('', [Validators.requiredTrue]),
        title: new UntypedFormControl('', []),
        function: new UntypedFormControl('', []),
        street: new UntypedFormControl('', []),
        postalCode: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        mobilePhoneNumber: new UntypedFormControl('', {
          validators: Validators.pattern('^(\\+41[0-9]{9})$'),
          updateOn: 'blur',
        }),
        zsrNumber: new UntypedFormControl('', {
          validators: Validators.pattern('(^[a-zA-Z]\\d{6}$)|(^\\d{6}[a-zA-Z]$)'),
          updateOn: 'blur',
        }),
        glnNumber: new UntypedFormControl('', {
          validators: Validators.pattern('^\\d{13}$'),
          updateOn: 'blur',
        }),
        selectedInstitutions: new UntypedFormControl([], []),
      },
      AppComponent.features.simplifiedRegistration
        ? []
        : [
            requiredWhen('function', this.fieldRequired.bind(this), {
              requiredWhen: ['title', 'street', 'postalCode', 'city', 'zsrNumber', 'glnNumber'],
            }),
            requiredWhen('glnNumber', this.fieldRequired.bind(this), {
              requiredWhen: ['title', 'function', 'street', 'postalCode', 'city'],
            }),
          ],
    );

    this.accountService.hasRole('UserRegistrationPending').subscribe((isRegistrationPending) => {
      if (isRegistrationPending) {
        this.institutionService.getChildInstitutions().subscribe(
          (result) => {
            this.institutions = result;
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );

        // prefill form from JWT token
        if (this.oauthService.hasValidAccessToken()) {
          const jwtHelper = new JwtHelperService();
          const decodedToken: any = jwtHelper.decodeToken(this.oauthService.getAccessToken());

          this.userRegisterForm.controls.firstName.setValue(decodedToken.given_name);
          this.userRegisterForm.controls.lastName.setValue(decodedToken.family_name);
          this.userRegisterForm.controls.email.setValue(decodedToken.email);
        }

        this.userRegisterForm.valueChanges.subscribe((changes) => {
          for (let key of Object.keys(changes)) {
            this.model[key] = changes[key];

            if (key == 'language') {
              this.accountService.setUserLanguage(changes[key]);
            }
          }
        });
      } else {
        this.router.navigate(['/home/dashboard']);
      }
    });
  }

  fieldRequired(config: { requiredWhen: string[]; exceptWhen: string[] }): boolean {
    if (this.userRegisterForm && this.userRegisterForm.controls) {
      if (config.exceptWhen) {
        for (var element of config.exceptWhen) {
          if (this.userRegisterForm.controls[element].value) {
            return false;
          }
        }
      }

      if (config.requiredWhen) {
        for (var element of config.requiredWhen) {
          if (this.userRegisterForm.controls[element].value) {
            return true;
          }
        }
      }
    }

    return false;
  }

  get f() {
    return this.userRegisterForm.controls;
  }

  register(): void {
    this.loadingSubject.next(true);

    this.accountService.register(this.model).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.submitted = true;
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  mobilePhoneNumberChange(event: any): void {
    let inputValue: any = event.target.value;
    var matchLocalNumber = inputValue.match('^0([0-9]{9})$');

    if (matchLocalNumber) {
      this.userRegisterForm.controls['mobilePhoneNumber'].setValue('+41' + matchLocalNumber[1]);
    }
  }

  hasRequiredValidator(control: AbstractControl): Boolean {
    return control.hasValidator(Validators.required);
  }

  logout() {
    this.accountService.logout();
  }
}
