import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, zip } from 'rxjs';

import { CustomRowActionParams } from '@app/_controls/data-table/actions/customRowActionParams';
import { CustomTableActionParams } from '@app/_controls/data-table/actions/customTableActionParams';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { InsuranceUsercontractsRowDetailsComponent } from '@app/_controls/row-detail-views/insurance-usercontracts-row-details/insurance-usercontracts-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { CustomFormDialogFieldConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog-field.config';
import { CustomFormDialogComponent } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.component';
import { CustomFormDialogConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.config';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { DownloadFileDirective } from '@app/_helpers/directives/download-file.directive';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { openHirslandenPhysicianSearchLink } from '@app/_helpers/functions/insurance-functions';
import { ContractCompensationTypeTransformPipe } from '@app/_helpers/transform/contract-compensation-type.transform';
import { ContractStatusTransformPipe } from '@app/_helpers/transform/contract-status.transform';
import { ContractStatusType } from '@app/_models/enums/contractStatusType';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { InsuranceContractChangesExcelExportDto } from '@app/_models/insuranceContractChangesExcelExportDto';
import { ObjectPermissionQueryResultDto } from '@app/_models/objectPermissionQueryResultDto';
import { UserContractForInsuranceDto } from '@app/_models/userContractForInsuranceDto';
import { UserContractsTerminateRequestForInsuranceDto } from '@app/_models/userContractsTerminateRequestForInsuranceDto';
import { AccountService } from '@app/_services/account.service';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { UserContractForInsuranceService } from '@app/_services/userContractForInsuranceService';
import { RefreshableView } from '@app/_views/refreshable-view';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-insurance-usercontracts',
  templateUrl: './insurance-usercontracts.component.html',
  styleUrls: ['./insurance-usercontracts.component.scss'],
})
export class InsuranceUsercontractsComponent implements OnInit, AfterViewInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  public objectPermission?: ObjectPermissionQueryResultDto;
  public writePermission!: boolean;

  private contractTitleMap!: Map<number, string>;

  constructor(
    public userContractForInsuranceService: UserContractForInsuranceService,
    public contractService: ContractService,
    private translate: TranslateService,
    private modalService: BsModalService,
    public accountService: AccountService,
    private errorHandler: ErrorHandlerService,
  ) {}

  private terminateSelectedUserContractsActionParams: CustomTableActionParams = {
    customFunction: this.terminateSelectedUserContracts.bind(this),
  };

  private tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Custom,
      text: 'Insurance.UserContracts.TerminateSelected',
      buttonClass: 'btn btn-danger btn-sm mb-1',
      actionParams: this.terminateSelectedUserContractsActionParams,
    },
  ];

  physicianSearchLinkActionParams: CustomRowActionParams = {
    customFunction: openHirslandenPhysicianSearchLink.bind(this),
  };

  public rowActions: Array<RowAction> = [];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<UserContractForInsuranceDto, number>;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadingSubject.next(true);

    this.accountService.hasRole('EditContracts').subscribe((result) => {
      this.writePermission = result;
    });

    if (appConfig.showPhysicianSearchLink) {
      this.rowActions.push({
        action: RowActionEnum.Custom,
        tooltip: 'Insurance.PhysicianSearchHirslanden',
        buttonClass: 'btn btn-sm mb-1',
        iconClass: 'blue fa fa-user-doctor fa-lg',
        actionParams: this.physicianSearchLinkActionParams,
      });
    }

    var getAllContractTypes = this.contractService.getAllContractTypes();
    var getObjectPermission = this.accountService.getObjectPermission(PermissionObject.UserContract, -1);

    zip(getAllContractTypes, getObjectPermission).subscribe(
      (result) => {
        this.dataTableComponent.rowDetailsData.contractTypes = result[0];
        this.contractTitleMap = new Map<number, string>();

        result[0].forEach((element) => {
          this.contractTitleMap.set(element.id, element.title);
        });

        this.objectPermission = result[1];

        this.loadingSubject.next(false);
        this.initTable();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  initTable(): void {
    let tableColumns: Array<TableColumn> = [
      {
        columnProperty: 'contractId',
        header: 'Global.Contract',
        searchProperty: 'contract.title',
        searchType: 'Text',
        valueMap: this.contractTitleMap,
        flex: '1 1 20%',
      },
      {
        columnProperty: 'contractStatus',
        header: 'Global.State',
        searchType: 'Selection',
        valueMap: ContractStatusTransformPipe.contractStatusTypeMapNoParams,
        displayTranslate: true,
        flex: '1 1 20%',
      },
      {
        columnProperty: 'user.lastName',
        header: 'Global.LastName',
        searchType: 'Text',
        flex: '1 1 15%',
      },
      {
        columnProperty: 'user.firstName',
        header: 'Global.FirstName',
        searchType: 'Text',
        flex: '1 1 15%',
      },
      {
        columnProperty: 'user.function',
        header: 'Global.FunctionTitle',
        searchType: 'Text',
        flex: '1 1 15%',
      },
      {
        columnProperty: 'user.zsrNumber',
        header: 'Global.ZsrNumber',
        searchType: 'Text',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'user.glnNumber',
        header: 'Global.GlnNumber',
        searchType: 'Text',
        flex: '0 0 100px',
      },
      {
        columnProperty: 'validFrom',
        header: 'Global.ValidFrom',
        searchType: 'DateRange',
        displayFunction: (element: Date, row: UserContractForInsuranceDto) => formatDateOnly(element),
        flex: '0 0 100px',
      },
      {
        columnProperty: 'validUntil',
        header: 'Global.ValidUntil',
        searchType: 'DateRange',
        displayFunction: (element: Date, row: UserContractForInsuranceDto) => formatDateOnly(element),
        flex: '0 0 100px',
      },
      {
        columnProperty: 'overallFactorP1',
        header: 'Global.FactorP1',
        flex: '0 0 100px',
        disableSort: true,
        displayFunction: this.factorDisplayFunction.bind(this),
      },
      {
        columnProperty: 'overallFactorP2',
        header: 'Global.FactorP2',
        flex: '0 0 100px',
        disableSort: true,
        displayFunction: this.factorDisplayFunction.bind(this),
      },
      {
        columnProperty: 'compensationType',
        header: 'Global.CompensationType',
        flex: '1 1 15%',
        searchType: 'Selection',
        valueMap: ContractCompensationTypeTransformPipe.contractCompensationTypeMap,
        displayTranslate: true,
        searchProperty: 'contract.compensationType',
      },
    ];

    let tableSettings = <TableSettings<UserContractForInsuranceDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<UserContractForInsuranceDto, number>(
        this.userContractForInsuranceService,
        this.errorHandler,
      ),
      tableColumns: tableColumns,
      rowActions: this.rowActions,
      rowEditContainer: undefined,
      rowDetailsContainerType: InsuranceUsercontractsRowDetailsComponent,
      enableRowSelection: true,
      tableActions: this.writePermission ? this.tableActions : undefined,
      baseColumnSort: [{ column: 'id', direction: 'desc' }],
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  factorDisplayFunction(element: number, row: UserContractForInsuranceDto): number | undefined {
    // Don't show factors for pending contracts.
    if (row.contractStatus != ContractStatusType.Pending) {
      return element;
    }

    return undefined;
  }

  terminateSelectedUserContracts(): void {
    let userContracts = this.dataTableComponent.rowSelection.selected;

    if (userContracts.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    this.showTerminateContractsDialog(userContracts);
  }

  showNoContractsSelectedDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.NoContractsSelectedTitle',
      'User.Contracts.Errors.NoContractsSelectedText',
      'Global.Ok',
      undefined,
    );
  }

  showTerminateContractsDialog(userContracts: UserContractForInsuranceDto[]): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Insurance.UserContracts.TerminateContractsDialogTitle',
      'Insurance.UserContracts.TerminateContractsDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          var firstDefaultTerminationDate = new Date(userContracts[0].defaultTermination!.defaultTerminationDate);
          var sameDefaultDate = userContracts.every(
            (x) => x.defaultTermination!.defaultTerminationDate.getTime() == firstDefaultTerminationDate.getTime(),
          );

          var userContractIds = userContracts.map((x) => x.id);
          this.showSelectTerminationDateDialog(userContractIds, sameDefaultDate, firstDefaultTerminationDate);
        }
      });
    });
  }

  showSelectTerminationDateDialog(
    userContractIds: number[],
    sameDefaultDate: boolean,
    defaultTerminationDate?: Date,
  ): void {
    var customDialogConfig = new CustomFormDialogConfig({
      dialogTitle: {
        text: 'Insurance.UserContracts.SelectTerminationDateDialog.Title',
        translate: true,
      },
      dialogText: {
        text: sameDefaultDate
          ? undefined
          : 'Insurance.UserContracts.SelectTerminationDateDialog.VariousTerminationDatesHint',
        translate: true,
      },
      confirmButtonText: {
        text: 'Global.Ok',
        translate: true,
      },
      cancelButtonText: {
        text: 'Global.Cancel',
        translate: true,
      },
      modalClass: 'modal-1200',
      labelSize: 2,
      inputSize: 10,
      fields: [
        new CustomFormDialogFieldConfig<Date>({
          name: 'terminationDate',
          label: {
            text: 'Insurance.UserContracts.SelectTerminationDateDialog.TerminationDateLabel',
            translate: true,
          },
          required: true,
          requiredErrorText: {
            text: 'Global.Errors.DateIsRequired',
            translate: true,
          },
          initialValue: sameDefaultDate ? defaultTerminationDate : undefined,
          uiType: 'date',
        }),
        new CustomFormDialogFieldConfig<string>({
          name: 'additionalText',
          label: {
            text: 'Insurance.UserContracts.SelectTerminationDateDialog.AdditionalTextLabel',
            translate: true,
          },
          required: false,
          uiType: 'textarea',
          textareaRows: 10,
          maxlength: 5000,
        }),
      ],
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, customDialogConfig);
    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        let userContractsTerminateRequestForInsuranceDto: UserContractsTerminateRequestForInsuranceDto = {
          userContractIds: userContractIds,
          validUntilDate: onCloseResult.data.terminationDate,
          additionalText: onCloseResult.data.additionalText,
        };

        this.loadingSubject.next(true);
        this.userContractForInsuranceService
          .terminateUserContracts(userContractsTerminateRequestForInsuranceDto)
          .subscribe(
            (result) => {
              this.loadingSubject.next(false);
              this.dataTableComponent.loadData();
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              // We load the data anyways because there could be contracts that succeeded.
              this.dataTableComponent.loadData();
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
      }
    });
  }

  showContractChangesExcelExportDialog(): void {
    var dateRangeDialogConfig = CustomFormDialogConfig.getDefaultDateRangeConfig({
      dialogTitle: 'Insurance.UserContracts.ContractChangesExcelReport',
      fieldLabel: 'Global.SelectDateRange',
      modalClass: 'modal-800',
      required: true,
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateRangeDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm && onCloseResult.data.dateRange) {
        let insuranceContractChangesExcelExportDto: InsuranceContractChangesExcelExportDto = {
          fromDate: onCloseResult.data.dateRange[0],
          untilDate: onCloseResult.data.dateRange[1],
        };

        // Download the document as a blob
        this.userContractForInsuranceService
          .contractChangesExcelExport(insuranceContractChangesExcelExportDto)
          .subscribe(
            (response: any) => {
              DownloadFileDirective.downloadFileFromHttpResponse(response);
            },
            (errorResponse: HttpErrorResponse) => {
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
      }
    });
  }
}
