import { Pipe } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

export interface TranslateObject {
  text?: string;
  args?: any[];
  translate: boolean;
}

@Pipe({
  name: 'translateObject',
})
export class TranslateObjectPipe extends TranslatePipe {
  transform(query: any, ...args: any[]): any {
    if (query) {
      var translateObject: TranslateObject = query;

      if (translateObject.translate && translateObject.text) {
        return super.transform(translateObject.text, translateObject.args);
      }

      return translateObject.text;
    }

    return query;
  }
}
