import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-account-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public headerImageUrl1 = appConfig.headerImageUrl1;
  public headerImageUrl2 = appConfig.headerImageUrl2;

  isTermsAccepted: boolean = false;

  constructor(
    public translate: TranslateService,
    public accountService: AccountService,
    public applicationService: ApplicationService,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.accountService.updateUserRoles();
      this.accountService.loadUserLanguage();
    } else {
      this.oauthService.events.subscribe((event) => {
        if (event.type === 'token_received' && this.oauthService.hasValidAccessToken()) {
          this.accountService.setUsernameFromToken();
          this.accountService.updateUserRoles();
          this.accountService.loadUserLanguage();
        }
      });
    }
  }

  loginEmail() {
    this.oauthService.initLoginFlow();
  }

  loginHIN() {
    this.accountService.loginWithIdp('hin');
  }
}
