import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanTransform',
})
export class BooleanTransformPipe implements PipeTransform {
  public static booleanMap = new Map([
    [false, 'Global.No'],
    [true, 'Global.Yes'],
  ]);

  transform(booleanValue: boolean): string {
    if (BooleanTransformPipe.booleanMap.has(booleanValue)) {
      var booleanText = BooleanTransformPipe.booleanMap.get(booleanValue);
      return booleanText ? booleanText : '';
    } else {
      return '';
    }
  }
}
