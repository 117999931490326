import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseTableDataService } from './baseTableData.service';

import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { base64EncodeCustomPadding } from '@app/_helpers/functions/string-functions';
import { PatientDto } from '@app/_models/patientDto';

@Injectable({
  providedIn: 'root',
})
export class PatientService extends BaseTableDataService<PatientDto, number> {
  constructor(protected http: HttpClient) {
    super('patient', '', http);
  }

  queryFullText(searchText: string) {
    return this.http.get<PatientDto[]>(this.baseTableDataUrl + '/query-full-text', {
      params: new HttpParams().set('searchText', searchText),
    });
  }

  async findOrCreatePatient(firstName: string, lastName: string, birthDate: Date) {
    const patient = await this.findOrInstantiatePatient(firstName, lastName, birthDate);

    if (patient.id > 0) {
      return patient.id;
    }

    return (
      await this.create({
        id: 0,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: birthDate,
      }).toPromise()
    ).value;
  }

  async findOrInstantiatePatient(firstName: string, lastName: string, birthDate: Date): Promise<PatientDto> {
    const from = new Date(birthDate);
    const until = new Date(birthDate);
    until.setDate(until.getDate() + 1);
    const patients = await this.query(
      `FirstName?=base64:${base64EncodeCustomPadding(firstName)}&LastName?=base64:${base64EncodeCustomPadding(lastName)}&DateOfBirth>=${formatDateYYYYMMDD(from)}&DateOfBirth<${formatDateYYYYMMDD(until)}`,
      0,
      2,
    ).toPromise();

    if (patients.data.length == 1) {
      // Sanity check that the patient actually has the expected data...
      if (
        patients.data[0].firstName != firstName ||
        patients.data[0].lastName != lastName ||
        formatDateYYYYMMDD(patients.data[0].dateOfBirth) != formatDateYYYYMMDD(birthDate)
      ) {
        throw Error('Patient incorrectly matched');
      }

      return patients.data[0];
    }

    return {
      id: 0,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: birthDate,
    };
  }
}
