import { ActionParams } from './actionParams';

export enum TableActionEnum {
  Add,
  Custom,
}

export interface TableAction {
  action: TableActionEnum;
  text?: string;
  textTranslateParams?: any;
  tooltip?: string;
  tooltipTranslateParams?: any;
  buttonClass?: string;
  iconClass?: string;
  actionParams?: ActionParams;
  /** If this action is defined it will be executed after this TableAction is successfully executed instead of just refreshing the table data. */
  postAction?: (result: any) => void;
}
