<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="loading$ | async" class="loading-div">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
  <div>
    <div *ngIf="text">{{ text }}</div>
    <br />
    <iframe width="100%" height="500px" frameBorder="0" [srcdoc]="htmlSrc"></iframe>
  </div>
  <br />
  <br />
  <form class="form-horizontal" [formGroup]="form" autocomplete="off">
    <div class="form-group row d-flex justify-content-center">
      <label>
        <input type="checkbox" formControlName="acceptTerms" />
        <span>{{ 'Dialog.AcceptTermsDialog.AcceptTermsCheckboxText' | translate }}</span>
      </label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <ng-container>
    <button *ngIf="button_cancel_text" type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ button_cancel_text }}
    </button>
    <button
      *ngIf="button_confirm_text"
      type="button"
      class="btn btn-secondary"
      (click)="onConfirm()"
      [disabled]="form.invalid">
      {{ button_confirm_text }}
    </button>
  </ng-container>
</div>
