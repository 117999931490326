import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '@app/_services/account.service';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

export const TIMEOUT_TOKEN = new HttpContextToken<number>(() => 30000);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(request.context.get(TIMEOUT_TOKEN)),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // Token expired or user not authorized, trigger logout.
          this.accountService.logout();
        }

        return throwError(error);
      }),
    );
  }
}
