import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';

import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { PatientDto } from '@app/_models/patientDto';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';

@Component({
  selector: 'app-edit-patient-container',
  templateUrl: './edit-patient-container.component.html',
  styleUrls: ['./edit-patient-container.component.css'],
})
export class EditPatientContainerComponent implements IRowEditContainer<PatientDto, number, any, any>, AfterViewInit {
  @ViewChild('firstName') firstName?: ElementRef;

  dialog!: RowEditDialogComponent<PatientDto, number, any, any>;
  data!: PatientDto;
  initialData?: PatientDto;
  resultData: any;
  form!: UntypedFormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;

  constructor() {}

  initializeContainer(): void {
    if (!this.data) {
      this.data = <PatientDto>{};
    }

    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.data.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.data.lastName, [Validators.required]),
      dateOfBirth: new UntypedFormControl(this.data.dateOfBirth, [Validators.required]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });
  }

  ngAfterViewInit() {
    (this.firstName?.nativeElement as HTMLInputElement).focus();
  }

  get f() {
    return this.form.controls;
  }
}
