<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ contractTitle }} <br />
    {{ 'Global.ContractContactInformation' | translate }}
  </h4>
</div>
<div class="modal-body" [innerHTML]="contactInformation"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ 'Global.Ok' | translate }}
  </button>
</div>
