import { Pipe, PipeTransform } from '@angular/core';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { joinStringsWithNullCheck } from '../functions/string-functions';

@Pipe({
  name: 'catalogItemInvoiceTextTransform',
})
export class CatalogItemInvoiceTextTransformPipe implements PipeTransform {
  transform(catalogItem: CatalogItemDto): string {
    return showInvoiceText(catalogItem);
  }
}

export function showInvoiceText(catalogItem: CatalogItemDto): any {
  return joinStringsWithNullCheck(
    [catalogItem.title, catalogItem.column03, catalogItem.column04, catalogItem.interpretation],
    '\n\n',
  );
}
