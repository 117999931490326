import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupTypeTransform',
})
export class GroupTypeTransformPipe implements PipeTransform {
  public static groupTypeMap = new Map([
    [0, 'User.Groups.Types.Standard'],
    [1, 'User.Groups.Types.EmployeePerInstitution'],
    [2, 'User.Groups.Types.EmployeePerUser'],
  ]);

  transform(accountType: number): string {
    if (GroupTypeTransformPipe.groupTypeMap.has(accountType)) {
      var groupTypeText = GroupTypeTransformPipe.groupTypeMap.get(accountType);
      return groupTypeText ? groupTypeText : '';
    } else {
      return '';
    }
  }
}
