import { Component, OnDestroy } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { InsuranceDto } from '@app/_models/insuranceDto';
import { InsuranceService } from '@app/_services/insurance.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { IRowEditContainer } from '../row-edit-container.interface';

@Component({
  selector: 'app-edit-insurance-container',
  templateUrl: './edit-insurance-container.component.html',
  styleUrls: ['./edit-insurance-container.component.css'],
})
export class EditInsuranceContainerComponent implements IRowEditContainer<InsuranceDto, number, any, any>, OnDestroy {
  dialog!: RowEditDialogComponent<InsuranceDto, number, any, any>;
  data!: InsuranceDto;
  initialData?: InsuranceDto;
  resultData: any;
  form!: FormGroup;
  config?: any;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;
  parentInsurances$?: Observable<InsuranceDto[]>;

  private destroy$ = new Subject();

  constructor(private insuranceService: InsuranceService) {}

  initializeContainer() {
    // If there is no input data we are adding a row
    if (!this.data) {
      this.data = {
        id: 0,
        title: '',
        maxValue: undefined,
      } as InsuranceDto;
    }

    if (!this.data.hasChildInsurances) {
      this.parentInsurances$ = this.insuranceService.getInsurances().pipe(
        map((insurances) => insurances.filter((i) => !i.parentInsurance && i.id !== this.data.id)),
        takeUntil(this.destroy$),
      );
    }

    this.form = new FormGroup({
      title: new FormControl<string>(this.data.title, [Validators.required]),
      identifier: new FormControl<string | undefined>(this.data.identifier),
      maxValue: new FormControl<number | undefined>(this.data.maxValue),
      parentInsurance: new FormControl<InsuranceDto | undefined>(this.data.parentInsurance),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        if (key === 'identifier' && changes.identifier === '') {
          this.data.identifier = undefined;
        } else {
          this.data[key] = changes[key];
        }
      }
    });
  }

  compareInsurances(insurance1: InsuranceDto, insurance2: InsuranceDto): boolean {
    return insurance1?.id == insurance2?.id;
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
