import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to compare date's
export function objectPermissionUnique(formArray: UntypedFormArray): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!formArray || !control.value) return null;

    let object: number = control.get('object')?.value as number;
    let objectId: number = control.get('objectId')?.value as number;

    let notUnique =
      formArray.controls.findIndex(
        (x) => x != control && x.get('object')?.value == object && x.get('objectId')?.value == objectId,
      ) != -1;

    if (!notUnique) {
      return null;
    }

    return { notUnique: true };
  };
}
