<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="container container-plus">
  <div class="row">
    <div class="col">
      <div class="alert alert-info title text-center">
        <h1>{{ 'Account.Register.Title' | translate }}</h1>
      </div>
    </div>
  </div>
  <div *ngIf="submitted">
    <div class="row">
      <div class="col">
        <div class="alert alert-success" [innerHTML]="'Account.Register.RegistrationSucceeded' | translate"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 offset-sm-4">
        <button class="btn btn-light btn-block px-4 btn-bold mb-4" (click)="logout()">
          {{ 'Global.BackToLoginButton' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!submitted && (applicationService.currentApplicationStatus$ | async)?.displayWarningMessage">
    <div class="col">
      <div class="alert alert-danger">
        {{ (applicationService.currentApplicationStatus$ | async)?.warningMessage }}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!submitted">
    <div class="col-sm-8 offset-sm-2">
      <!-- show this in desktop -->
      <div class="d-none d-lg-block col-md-8 mt-lg-4 px-0">
        <h4 class="text-dark-tp4 border-b-1 brc-secondary-l2 pb-1 text-130">
          {{ 'Account.Register.Registration' | translate }}
        </h4>
      </div>

      <!-- show this in mobile device -->
      <div class="d-lg-none text-secondary-m1 my-4 text-center">
        {{ 'Account.Register.Registration' | translate }}
      </div>
      <div>
        <p class="text-95" [innerHTML]="'Account.Register.RegistrationInfo' | translate"></p>
        <form [formGroup]="userRegisterForm" class="form-row mt-4" (ngSubmit)="register()" autocomplete="off">
          <div class="col-sm-6" [class.mx-auto]="appComponent.features.simplifiedRegistration">
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="firstName"
                  placeholder="{{ 'Account.Register.FirstnamePlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="100"
                  readonly />
                <i class="fa fa-user text-grey-m2 ml-n4"></i>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.FirstnamePlaceholder' | translate }}
                  <span class="required-asterisk">*</span>
                </label>
              </div>
              <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
                <div *ngIf="f.firstName.errors?.required">{{ 'Account.Errors.FirstNameRequired' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="lastName"
                  placeholder="{{ 'Account.Register.LastnamePlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="100"
                  readonly />
                <i class="fa fa-user text-grey-m2 ml-n4"></i>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.LastnamePlaceholder' | translate }}
                  <span class="required-asterisk">*</span>
                </label>
              </div>
              <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
                <div *ngIf="f.lastName.errors?.required">{{ 'Account.Errors.LastNameRequired' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="institutionName"
                  placeholder="{{ 'Account.Register.InstitutionNamePlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="200" />
                <i class="fa fa-building text-grey-m2 ml-n4"></i>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.InstitutionNamePlaceholder' | translate }}
                  <span *ngIf="hasRequiredValidator(f.institutionName)" class="required-asterisk">*</span>
                </label>
              </div>
              <div
                *ngIf="f.institutionName.invalid && (f.institutionName?.dirty || f.institutionName?.touched)"
                class="alert alert-danger">
                <div *ngIf="f.institutionName.errors?.required">
                  {{ 'Account.Errors.InstitutionRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-sm-12" *ngIf="appComponent.features.simplifiedRegistration">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <textarea
                  type="text"
                  formControlName="function"
                  placeholder="{{ 'Account.Register.FunctionPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="1000">
                </textarea>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.FunctionPlaceholder' | translate }}
                  <span class="required-asterisk">*</span>
                </label>
              </div>
              <div *ngIf="f.function.invalid && (f.function?.dirty || f.function?.touched)" class="alert alert-danger">
                <div *ngIf="f.function.errors?.required">{{ 'Account.Errors.FunctionRequired' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="email"
                  placeholder="{{ 'Account.Register.EmailPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="256"
                  readonly />
                <i class="fa fa-at text-grey-m2 ml-n4"></i>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.EmailPlaceholder' | translate }}
                  <span class="required-asterisk">*</span>
                </label>
              </div>
              <div *ngIf="f.email?.invalid && (f.email?.dirty || f.email?.touched)" class="alert alert-danger">
                <div *ngIf="f.email?.errors?.required">{{ 'Account.Errors.EmailRequired' | translate }}</div>
                <div *ngIf="f.email?.errors?.email">{{ 'Account.Errors.EmailInvalid' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <select
                  formControlName="language"
                  class="form-control form-control-lg pr-4 shadow-none select-no-arrow">
                  <option *ngFor="let languageId of translate.getLangs()" [ngValue]="languageId">
                    {{ 'Languages.' + languageId | translate }}
                  </option>
                </select>
                <i class="fa fa-globe text-grey-m2 ml-n4 pe-none"></i>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Global.Language' | translate }}
                  <span class="required-asterisk">*</span>
                </label>
              </div>
            </div>
            <div class="form-group col-sm-10">
              <label class="d-inline-block mt-0 mb-0 text-dark-l1">
                <input type="checkbox" class="mr-1" formControlName="termsAccepted" />
                <span [innerHTML]="'Account.AcceptTerms' | translate"></span>
                <span class="required-asterisk">*</span>
              </label>
              <div
                *ngIf="f.termsAccepted?.invalid && (f.termsAccepted?.dirty || f.termsAccepted?.touched)"
                class="alert alert-danger">
                <div *ngIf="f.termsAccepted?.errors?.required">
                  {{ 'Account.Errors.TermsAcceptedRequired' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6" *ngIf="!appComponent.features.simplifiedRegistration">
            <!-- show this in desktop -->
            <div class="d-none d-lg-block col-md-12 px-0">
              <h4 class="text-dark-tp4 border-b-1 brc-secondary-l2 pb-1 text-100">
                {{ 'Account.Register.FormDataForDoctors' | translate }}
              </h4>
            </div>

            <!-- show this in mobile device -->
            <div class="d-lg-none text-secondary-m1 my-4 text-center">
              {{ 'Account.Register.FormDataForDoctors' | translate }}
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="title"
                  placeholder="{{ 'Account.Register.TitlePlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="100" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.TitlePlaceholder' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <textarea
                  type="text"
                  formControlName="function"
                  placeholder="{{ 'Account.Register.FunctionPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="1000">
                </textarea>
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.FunctionPlaceholder' | translate }}
                  <span *ngIf="hasRequiredValidator(f.function)" class="required-asterisk">*</span>
                </label>
              </div>
              <div class="" [innerHTML]="'Account.Register.FunctionHint' | translate"></div>
              <div *ngIf="f.function.invalid && (f.function?.dirty || f.function?.touched)" class="alert alert-danger">
                <div *ngIf="f.function.errors?.required">{{ 'Account.Errors.FunctionRequired' | translate }}</div>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="street"
                  placeholder="{{ 'Account.Register.StreetPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="200" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.StreetPlaceholder' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="postalCode"
                  placeholder="{{ 'Account.Register.PostalCodeConfimPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="30" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.PostalCodeConfimPlaceholder' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="city"
                  placeholder="{{ 'Account.Register.CityPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="60" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.CityPlaceholder' | translate }}
                </label>
              </div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="mobilePhoneNumber"
                  (input)="mobilePhoneNumberChange($event)"
                  placeholder="{{ 'Global.MobilePhoneNumber' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="30" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Global.MobilePhoneNumber' | translate }}
                </label>
              </div>
              <div
                *ngIf="f.mobilePhoneNumber.invalid && (f.mobilePhoneNumber.dirty || f.mobilePhoneNumber.touched)"
                class="alert alert-danger">
                <div *ngIf="f.mobilePhoneNumber.errors?.pattern">
                  {{ 'Global.Errors.MobilePhoneNumberPattern' | translate }}
                </div>
              </div>
            </div>
            <!-- show this in desktop -->
            <div class="d-none d-lg-block col-md-12 px-0">
              <h4 class="text-dark-tp4 border-b-1 brc-secondary-l2 pb-1 text-100">
                {{ 'Account.Register.AccreditedInfo' | translate }}
              </h4>
            </div>

            <!-- show this in mobile device -->
            <div class="d-lg-none text-secondary-m1 my-4 text-center">
              {{ 'Account.Register.AccreditedInfo' | translate }}
            </div>
            <mat-selection-list formControlName="selectedInstitutions" class="mat-list">
              <mat-list-option
                *ngFor="let institution of institutions"
                [value]="institution.id"
                checkboxPosition="before">
                {{ institution.title }}
              </mat-list-option>
            </mat-selection-list>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="glnNumber"
                  placeholder="{{ 'Account.Register.GLNNumberPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="100" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.GLNNumberPlaceholder' | translate }}
                  <span *ngIf="hasRequiredValidator(f.glnNumber)" class="required-asterisk">*</span>
                </label>
              </div>
              <div
                *ngIf="f.glnNumber.invalid && (f.glnNumber?.dirty || f.glnNumber?.touched)"
                class="alert alert-danger">
                <div *ngIf="f.glnNumber.errors?.required">{{ 'Account.Errors.GlnRequired' | translate }}</div>
                <div *ngIf="f.glnNumber.errors?.pattern">{{ 'Global.Errors.GlnNumberInvalid' | translate }}</div>
              </div>
              <div class="" [innerHTML]="'Account.Register.GLNNumberHint' | translate"></div>
            </div>
            <div class="form-group col-sm-12">
              <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                <input
                  type="text"
                  formControlName="zsrNumber"
                  placeholder="{{ 'Account.Register.ZSRNumberPlaceholder' | translate }}"
                  class="form-control form-control-lg pr-4 shadow-none"
                  maxlength="100" />
                <!-- <i class="fa fa-user text-grey-m2 ml-n4"></i> -->
                <label class="floating-label text-grey-l1 ml-n3">
                  {{ 'Account.Register.ZSRNumberPlaceholder' | translate }}
                  <span *ngIf="hasRequiredValidator(f.zsrNumber)" class="required-asterisk">*</span>
                </label>
              </div>
              <div
                *ngIf="f.zsrNumber.invalid && (f.zsrNumber?.dirty || f.zsrNumber?.touched)"
                class="alert alert-danger">
                <div *ngIf="f.zsrNumber.errors?.required">{{ 'Account.Errors.ZsrRequired' | translate }}</div>
                <div *ngIf="f.zsrNumber.errors?.pattern">{{ 'Global.Errors.ZsrNumberInvalid' | translate }}</div>
              </div>
              <div class="" [innerHTML]="'Account.Register.ZSRNumberHint' | translate"></div>
            </div>
          </div>
          <div class="col-sm-4 offset-sm-4">
            <button
              class="btn btn-primary btn-block px-4 btn-bold mt-2 mb-1"
              [disabled]="userRegisterForm.invalid"
              type="submit">
              {{ 'Account.Register.RegisterButton' | translate }}
            </button>
            <button class="btn btn-light btn-block px-4 btn-bold mb-4" (click)="logout()">
              {{ 'Global.BackToLoginButton' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
