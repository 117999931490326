import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '@app/_services/account.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private oauthService: OAuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentRoles = this.accountService.userRoles;
    if (this.oauthService.hasValidAccessToken()) {
      // check if route is restricted by role
      if (
        (route.data.role && currentRoles?.indexOf(route.data.role) === -1) ||
        (route.data.forbiddenRole && currentRoles?.indexOf(route.data.forbiddenRole) !== -1)
      ) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    }

    var queryParams = {};

    // returnUrl only for pages other than login and dashboard.
    if (state.url != '/account/login' && state.url != '/home/dashboard') {
      queryParams = { returnUrl: state.url };
    }

    // not logged in so redirect to login page with the return url
    this.accountService.updateUserRoles();
    this.router.navigate(['/account/login'], { queryParams: queryParams });
    return false;
  }
}
