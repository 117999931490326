<div *ngIf="initialized">
  <div [ngClass]="displayFullPage ? 'fullpage' : ''">
    <div class="header-toolbar d-flex justify-content-end" *ngIf="tableSettings?.tableActions">
      <button
        *ngFor="let action of tableSettings?.tableActions"
        [ngClass]="action.buttonClass ? action.buttonClass : ''"
        (click)="executeTableAction(action)"
        [tooltip]="action.tooltip ? (action.tooltip | translate: action.tooltipTranslateParams) : ''">
        <div [ngClass]="action.iconClass ? action.iconClass : ''">
          {{ action.text ? (action.text | translate: action.textTranslateParams) : '' }}
        </div>
      </button>
    </div>

    <div>
      <div [hidden]="tableSettings.hideToolbar" class="no-gutters row align-items-center toolbar">
        <div class="col-8">
          <mat-paginator
            *ngIf="!tableSettings.disablePaging"
            style="background-color: transparent"
            #paginatorTop
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [length]="tableSettings.dataSource.totalCount"
            (page)="onPageChanged($event)"
            showFirstLastButtons>
          </mat-paginator>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <input
            style="margin-right: 10px"
            *ngIf="tableSettings?.globalFilterColumns"
            debounceKeyUp
            [debounceTime]="500"
            (onEvent)="applyGlobalFilter()"
            [(ngModel)]="globalFilterValue"
            [placeholder]="'Global.Search' | translate" />
          <button
            noDoubleClick
            class="btn btn-sm mb-1 rotate"
            tooltip="{{ 'Global.Refresh' | translate }}"
            [delay]="500"
            (click)="onRefreshClicked()"
            [class.down]="rotateRefreshButton">
            <i class="fa-solid fa-arrows-rotate fa-xl"></i>
          </button>
          <button
            class="btn btn-sm mb-1"
            [tooltip]="displayFullPage ? ('Global.Shrink' | translate) : ('Global.Enlarge' | translate)"
            [delay]="500"
            (click)="onFullPageClicked()"
            style="margin-right: 10px">
            <i
              class="fa-solid fa-lg"
              [ngClass]="
                displayFullPage ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'
              "></i>
          </button>
        </div>
      </div>

      <div [style]="tableDivStyle">
        <div *ngIf="loading$ | async" class="loading-div">
          <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <mat-table
          [style.width]="tableSettings.tableWidth"
          matSort
          multiTemplateDataRows
          [dataSource]="tableSettings.dataSource"
          (matSortChange)="onSortData()"
          [matSortActive]="sortActive"
          [matSortDirection]="sortDirection">
          <ng-container *ngIf="tableSettings.enableRowSelection" matColumnDef="rowSelection">
            <mat-header-cell *matHeaderCellDef style="flex: 0 0 32px">
              <input
                type="checkbox"
                (click)="$event.stopPropagation()"
                (change)="selectAllRows($event)"
                [checked]="allRowsSelected"
                [tooltip]="'Global.SelectAll' | translate"
                [delay]="500"
                placement="right" />
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 32px">
              <input
                type="checkbox"
                (click)="$event.stopPropagation()"
                (change)="rowSelectHandler(row)"
                [checked]="rowSelection.isSelected(row)" />
            </mat-cell>
          </ng-container>

          <ng-container
            *ngIf="tableSettings.rowActions"
            matColumnDef="rowActions"
            [sticky]="tableSettings.rowActionsSticky">
            <mat-header-cell *matHeaderCellDef [style.flex]="rowActionsFlex"> </mat-header-cell>
            <mat-cell *matCellDef="let element" [style.flex]="rowActionsFlex">
              <button
                *ngFor="let action of tableSettings.rowActions"
                [ngClass]="action.buttonClass ? action.buttonClass : ''"
                style="flex: 0 0 35px; padding: unset; margin: 0px"
                [hidden]="action.hideCondition ? action.hideCondition(element) : false"
                (click)="executeRowAction(element, action)"
                [tooltip]="action.tooltip ? (action.tooltip | translate: action.tooltipTranslateParams) : ''"
                [delay]="500"
                container="body"
                containerClass="tooltipClass">
                <div [ngClass]="action.iconClass ? action.iconClass : ''">
                  {{ action.text ? (action.text | translate: action.textTranslateParams) : '' }}
                </div>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngFor="let col of tableSettings.tableColumns" matColumnDef="{{ col.columnProperty }}">
            <mat-header-cell
              [style.flex]="col.flex"
              *matHeaderCellDef
              [ngClass]="disableRowFilters ? '' : 'filterheader'">
              <div>
                <div mat-sort-header [disabled]="col.disableSort">
                  <span style="text-align: left" [translate]="col.header"></span>
                </div>
                <div *ngIf="!disableRowFilters">
                  <input
                    style="width: 95%; height: 24px"
                    *ngIf="col.searchType == 'Text' || col.searchType == 'Decimal'"
                    (input)="formatTextOrDecimal($event, col)"
                    debounceKeyUp
                    [debounceTime]="500"
                    (onEvent)="applyColumnFilter(col)"
                    [(ngModel)]="col.searchValue" />
                  <input
                    style="width: 95%; height: 24px"
                    *ngIf="col.searchType == 'Number'"
                    debounceKeyUp
                    [debounceTime]="500"
                    (onEvent)="applyColumnFilter(col)"
                    type="number"
                    [(ngModel)]="col.searchValue" />
                  <select
                    *ngIf="col.searchType == 'Selection' && col.valueMap"
                    (change)="applyColumnFilter(col)"
                    [(ngModel)]="col.searchValue">
                    <option value=""></option>
                    <option *ngFor="let item of col.valueMap | keyvalue" [ngValue]="item.key">
                      {{ col.displayTranslate ? (item.value | translate) : item.value }}
                    </option>
                  </select>
                  <input
                    *ngIf="col.searchType == 'SingleDate'"
                    style="width: 95%; height: 24px"
                    type="text"
                    class="form-control"
                    bsDatepicker
                    [placement]="getDateRangePickerPlacement(col)"
                    (bsValueChange)="applyDateTimeColumnFilter(col, $event)" />
                  <input
                    *ngIf="col.searchType == 'DateRange'"
                    style="width: 95%; height: 24px"
                    type="text"
                    class="form-control"
                    bsDaterangepicker
                    [placement]="getDateRangePickerPlacement(col)"
                    (bsValueChange)="applyDateTimeColumnFilter(col, $event)" />
                </div>
              </div>
            </mat-header-cell>
            <mat-cell
              style="word-break: break-word"
              [style.flex]="col.flex"
              [style.white-space]="col.renderWhitespace || col.valueMapNewLine ? 'pre' : undefined"
              *matCellDef="let element">
              <div
                *ngIf="!col.editSettings && !col.maxTextLength && col.renderHtml"
                style="display: block"
                [innerHtml]="element | tableColumnValueTransform: col | nullEmptyString | safeHtml"
                routeTransformer></div>
              <!-- the following comment stops prettier from introducing whitespace (relevant because white-space) -->
              <!-- display: inline -->
              <div *ngIf="!col.editSettings && !col.maxTextLength && !col.renderHtml" style="display: block">{{
                element | tableColumnValueTransform: col | nullEmptyString
              }}</div>
              <app-expandable-text
                *ngIf="!col.editSettings && col.maxTextLength"
                [text]="element | tableColumnValueTransform: col"
                [showHtml]="!!col.renderHtml"
                [maxTextLength]="col.maxTextLength"
                [cutAtNewline]="col.cutTextAtNewLine"></app-expandable-text>
              <input
                *ngIf="col.editSettings && col.editSettings.type == 'mask'"
                type="text"
                style="width: 95%; height: 24px"
                [mask]="col.editSettings.mask"
                [specialCharacters]="col.editSettings.specialCharacters"
                [ngModel]="element | tableColumnValueTransform: col"
                (ngModelChange)="columnEdit(element, col, $event)" />
              <input
                *ngIf="col.editSettings && (col.editSettings.type == 'text' || col.editSettings.type == 'number')"
                style="width: 95%; height: 24px"
                [type]="col.editSettings.type"
                [step]="col.editSettings.step"
                [ngModel]="element | tableColumnValueTransform: col"
                (ngModelChange)="columnEdit(element, col, $event)" />
              <select
                *ngIf="col.editSettings && col.editSettings.type == 'select'"
                style="width: 95%; height: 24px"
                [ngModel]="element | tableColumnValueTransform: col : true"
                (ngModelChange)="columnEdit(element, col, $event)">
                <option *ngFor="let item of col.valueMap | keyvalue" [ngValue]="item.key">
                  {{ col.displayTranslate ? (item.value | translate) : item.value }}
                </option>
              </select>
            </mat-cell>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column -->
          <ng-container matColumnDef="expandedDetail" *ngIf="tableSettings.rowDetailsContainerType">
            <mat-cell *matCellDef="let element">
              <ng-template
                [dynamic-component]="tableSettings.rowDetailsContainerType"
                [inputs]="{ rowData: element, additionalData: rowDetailsData }">
              </ng-template>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <ng-container *ngIf="!tableSettings.rowDetailsContainerType">
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </ng-container>
          <ng-container *ngIf="tableSettings.rowDetailsContainerType">
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              matRipple
              class="element-row"
              [class.expanded]="this.expandedElement == row"></mat-row>
            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
              style="overflow: hidden"></mat-row>
          </ng-container>
        </mat-table>
      </div>
    </div>
  </div>
</div>
