import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommandResult } from '@app/_models/command/commandResult';
import { EmailDto } from '@app/_models/emailDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseTableDataService<EmailDto, number> {
  constructor(protected http: HttpClient) {
    super('email', '', http);
  }

  resetEmailError(emailId: string): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/resetEmailError', emailId);
  }

  resetMultipleEmailErrors(emailIds: string[]): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/resetMultipleEmailErrors', emailIds);
  }
}
