<div class="navbar-inner shadow-md">
  <div class="container container-plus">
    <div class="navbar-intro justify-content-xl-start bgc-transparent pr-lg-3 w-auto">
      <button
        type="button"
        class="btn btn-burger burger-arrowed static collapsed ml-2 d-flex d-xl-none btn-h-white"
        data-toggle-mobile="sidebar"
        data-target="#sidebar"
        aria-controls="sidebar"
        aria-expanded="false"
        aria-label="Toggle sidebar">
        <span class="bars text-dark-tp5"></span>
      </button>
      <a [style]="headerImageUrl && { height: 'inherit' }" class="navbar-brand ml-lg-1" routerLink="account/login">
        <img
          *ngIf="headerImageUrl; else elseBlock"
          style="max-height: 100%"
          [src]="headerImageUrl"
          [alt]="'Nav.Title' | translate" />
        <ng-template #elseBlock
          ><span>{{ 'Nav.Title' | translate }}</span></ng-template
        > </a
      ><!-- /.navbar-brand -->
    </div>
    <!-- /.navbar-intro -->
    <!-- mobile #navbarMenu toggler button -->
    <button
      class="navbar-toggler ml-1 mr-2 px-1"
      type="button"
      (click)="collapse.toggle()"
      data-target="#navbarMenu"
      aria-controls="navbarMenu"
      [attr.aria-expanded]="!isNavbarMenuCollapsed"
      aria-label="Toggle navbar menu">
      <span class="pos-rel">
        <img class="border-2 brc-white-tp1 radius-round" src="assets/image/doctor.png" alt="Arzt" width="36" />
      </span>
    </button>
    <div
      class="navbar-menu navbar-collapse navbar-backdrop"
      #collapse="bs-collapse"
      [collapse]="isNavbarMenuCollapsed"
      id="navbarMenu">
      <div class="navbar-nav">
        <ul class="nav">
          <li
            dropdown
            #userDropdown="bs-dropdown"
            class="nav-item order-first order-lg-last navbar-dropdown"
            (mouseenter)="userDropdown.show()"
            (mouseleave)="userDropdown.hide()">
            <a
              dropdownToggle
              class="nav-link dropdown-toggle px-lg-2 ml-lg-3 color-white"
              role="button"
              aria-haspopup="true"
              aria-expanded="false">
              <img
                id="navbar-user-image"
                class="d-none d-lg-inline-block mr-1"
                src="assets/image/doctor.png"
                alt="Arzt"
                height="42" />
              <span class="d-inline-block d-lg-none"
                ><!-- show only on mobile -->
                <span class="text-90" id="id-user-welcome">{{ 'Nav.Menus.UserMenu' | translate }}</span>
              </span>
              <i class="caret fa fa-caret-down d-none d-xl-block"></i>
              <i class="caret fa fa-angle-left d-block d-lg-none"></i>
            </a>
            <div
              *dropdownMenu
              class="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3 py-1">
              <div class="dropdown-label">
                <b>{{ accountService.currentUser$ | async }}</b>
              </div>
              <div class="dropdown-divider brc-secondary-l2"></div>
              <a
                class="dropdown-item btn btn-outline-grey btn-h-light-blue btn-a-light-primblueary"
                routerLink="user/profile">
                <i class="fa fa-user text-primary-m1 text-105 mr-1"></i>
                {{ 'Nav.Links.EditProfile' | translate }}
              </a>
              <a
                *ngIf="!isLoggedInWithIDP"
                class="dropdown-item dropdown-item btn btn-outline-grey btn-h-light-green btn-a-light-green"
                (click)="changePassword()">
                <i class="fa fa-key text-success-m1 text-105 mr-1"></i>
                {{ 'Nav.Links.ChangePassword' | translate }}
              </a>
              <a
                *ngIf="!isLoggedInWithIDP"
                class="dropdown-item dropdown-item btn btn-outline-grey btn-h-light-orange btn-a-light-orange"
                (click)="linkHin()">
                <i class="fa fa-lock text-orange-m1 text-105 mr-1"></i>
                {{ 'Nav.Links.LinkHin' | translate }}
              </a>
              <a
                class="dropdown-item dropdown-item btn btn-outline-grey btn-h-light-green btn-a-light-green"
                (click)="translateDebugService.toggleDebug()"
                *ngIf="accountService.hasRole('Admin') | async">
                <i class="fa fa-bug text-dark-tp5 text-105 mr-1"></i>
                {{ 'Nav.Links.DebugMode' | translate }}
              </a>
              <div class="dropdown-divider brc-secondary-l2"></div>
              <a class="dropdown-item btn btn-outline-grey btn-h-light-orange btn-a-light-orange" (click)="logout()">
                <i class="fa fa-power-off text-orange-d2 text-105 mr-1"></i>
                {{ 'Nav.Links.Logout' | translate }}
              </a>
            </div>
          </li>
          <!-- /.nav-item:last -->
        </ul>
        <!-- /.navbar-nav menu -->
      </div>
      <!-- /.navbar-nav -->
    </div>
    <!-- /.navbar-menu.navbar-collapse -->
  </div>
  <!-- /.container -->
</div>
<!-- /.navbar-inner -->
