<br />
<div class="table-responsive" style="width: 101%">
  <table class="table table-striped table-bordered table-hover editables" style="width: auto">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.CatalogView' | translate }}</th>
        <th>{{ 'Contract.Pdf.PdfTitle' | translate }}</th>
        <th>{{ 'Contract.Pdf.Pdf02Title' | translate }}</th>
        <th>{{ 'Contract.Pdf.Pdf03Title' | translate }}</th>
        <th>{{ 'Contract.ParentContractHeader' | translate }}</th>
        <th>{{ 'Contract.MaxValueHeader' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ getCatalogView(rowData.catalogViewId)?.title }}</td>
        <td>
          <a href="#" (click)="$event.preventDefault()" openPdf="{{ 'api/file/' + rowData.pdf }}" target="_blank">{{
            rowData.pdf
          }}</a>
        </td>
        <td>
          <a href="#" (click)="$event.preventDefault()" openPdf="{{ 'api/file/' + rowData.pdf02 }}" target="_blank">{{
            rowData.pdf02
          }}</a>
        </td>
        <td>
          <a href="#" (click)="$event.preventDefault()" openPdf="{{ 'api/file/' + rowData.pdf03 }}" target="_blank">{{
            rowData.pdf03
          }}</a>
        </td>
        <td>{{ getContractType(rowData.parentContractId)?.title }}</td>
        <td>{{ rowData.maxValue }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive" style="width: 101%">
  <table class="table table-striped table-bordered table-hover editables" style="width: auto">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Contract.AddressHeader' | translate }}</th>
        <th>{{ 'Contract.SignatureHeader' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ rowData.address }}</td>
        <td>{{ rowData.signature }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive" style="width: 101%">
  <table class="table table-striped table-bordered table-hover editables" style="width: auto">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Contract.DefaultTerminationDateExpressionHeader' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ rowData.defaultTerminationDateExpression }}</td>
      </tr>
    </tbody>
  </table>
</div>
